define("mldp/account/reports/ph-contact-list/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "h/XGr41r",
    "block": "[[[1,[28,[35,0],[\"Pronghorn Contact List\"],null]],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"report\",\"query\",\"getReport\",\"filterComponent\"],[[30,0,[\"report\"]],[30,0,[\"query\"]],[28,[37,2],[[30,0],\"getReport\"],null],[50,\"reports/contact-list-filters\",0,null,[[\"onChange\"],[[28,[37,2],[[30,0],\"updateQuery\"],null]]]]]]]]],[],false,[\"page-banner\",\"report-container\",\"action\",\"component\"]]",
    "moduleName": "mldp/account/reports/ph-contact-list/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});