define("mldp/account/action/route", ["exports", "@ember/routing/route", "@ember/string", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _route, _string, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let ActionRoute = classic(_class = class ActionRoute extends _route.default.extend(_authenticatedRouteMixin.default) {
    beforeModel() {
      let {
        userAction_id: actionId
      } = this.paramsFor('account.action');

      let _super = super.beforeModel.bind(this); // Check if the action needs a redirect
      // otherwise proceed with normal authorization


      return this.store.findRecord('user-action', actionId).then(() => _super(...arguments)).catch(_ref => {
        let {
          errors
        } = _ref;
        let [error] = errors;

        if (error && error.title.includes('owner-needs-account')) {
          return this.transitionTo('need-account');
        } else {
          return _super(...arguments);
        }
      });
    }

    setupController(controller, model) {
      let actionType = model.get('actionType');
      let agreementComponentName;

      switch (actionType) {
        case 'PropertyAcceptPoolInvite':
          {
            agreementComponentName = model.get('aggregateId') ? 'agreements/aggregate-invite' : 'agreements/coop-invite';
            break;
          }

        case 'VerifyLandowner':
          {
            agreementComponentName = 'agreements/landowner-pre-rta';
            break;
          }
      }

      let componentName = this.generateComponentName(actionType);
      let title = model.get('notificationTitle');
      let isNPOLAction = title && title.includes('Error encountered during property creation');
      controller.setProperties({
        model,
        isNPOLAction,
        agreementComponentName,
        showAgreement: agreementComponentName !== undefined,
        componentName
      });
    }

    generateComponentName(actionType) {
      actionType = actionType.replace('CoOp', 'coop');

      if (['NavigatePath', 'ShowFeeReceipt'].includes(actionType)) {
        return 'actions/' + (0, _string.dasherize)(actionType);
      }

      return (0, _string.dasherize)(actionType);
    }

  }) || _class;

  _exports.default = ActionRoute;
});