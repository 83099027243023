define("mldp/account/property/unit/practice/route", ["exports", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PracticeRoute = (_dec = (0, _service.inject)('state'), classic(_class = (_class2 = class PracticeRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);
    }

    async model(opts) {
      let types = this.store.findAll('tlkHabitatPracticeType');
      let subtypes = this.store.findAll('tlkHabitatPracticeSubType');
      let crops = this.store.findAll('tlkHabitatPracticeCropGrown').then(list => list.sortBy('sortOrder'));
      let treatments = this.store.findAll('tlkHabitatPracticeTreatment').then(list => list.sortBy('sortOrder'));
      let treatmentTypes = this.store.findAll('tlkHabitatPracticeTreatmentType').then(list => list.sortBy('sortOrder'));
      let property = this.modelFor('account.property').property;
      let unit = this.modelFor('account.property.unit');
      let model = this.store.findRecord('habitat-practice', opts.practiceId, {
        reload: true
      });
      let seasons = await this.store.findAll('tlkDeerSeason');
      seasons = seasons.sortBy('sortOrder');
      let seasonAdapter = this.store.adapterFor('tlk-deer-season');
      let current = await seasonAdapter.findCurrent();
      let resp = await _rsvp.default.hash({
        types,
        subtypes,
        crops,
        treatments,
        treatmentTypes,
        seasons,
        current
      });
      let state = this.state;
      state.setProperties(resp);
      return _rsvp.default.hash({
        model,
        property,
        unit
      });
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = PracticeRoute;
});