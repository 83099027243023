define("mldp/components/site-coop-card/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "UVz+KQoG",
    "block": "[[[11,0],[24,0,\"coop-card site-card clearfix\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"row-fluid\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-xs-12\"],[12],[1,\"\\n      \"],[10,\"h4\"],[12],[1,\"\\n        Co-Op Information\\n        \"],[8,[39,0],null,[[\"@route\",\"@models\"],[\"account.property\",[28,[37,1],[[30,0,[\"site\",\"siteType\"]],[30,0,[\"site\",\"id\"]]],null]]],[[\"default\"],[[[[1,\"view\"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"row-fluid\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n      \"],[10,\"dl\"],[14,0,\"dl-horizontal\"],[12],[1,\"\\n        \"],[10,\"dt\"],[12],[1,\"Property Name\"],[13],[1,\"\\n        \"],[10,\"dd\"],[12],[1,[30,0,[\"site\",\"siteName\"]]],[13],[1,\"\\n        \"],[10,\"dt\"],[12],[1,\"County\"],[13],[1,\"\\n        \"],[10,\"dd\"],[12],[1,[30,0,[\"site\",\"primaryCounty\",\"displayValue\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n      \"],[10,\"dl\"],[14,0,\"dl-horizontal\"],[12],[1,\"\\n        \"],[10,\"dt\"],[12],[1,\"Manager\"],[13],[1,\"\\n        \"],[10,\"dd\"],[12],[1,\"\\n          \"],[10,\"button\"],[14,0,\"btn btn-link open-user-details\"],[15,\"onclick\",[28,[37,2],[\"openUserDetails\",[30,0,[\"manager\"]]],null]],[14,4,\"button\"],[12],[1,\"\\n            \"],[1,[30,0,[\"manager\",\"firstName\"]]],[1,\" \"],[1,[30,0,[\"manager\",\"lastName\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"link-to\",\"array\",\"route-action\"]]",
    "moduleName": "mldp/components/site-coop-card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});