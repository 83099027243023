define("mldp/router", ["exports", "@ember/service", "ember-router-scroll", "mldp/config/environment"], function (_exports, _service, _emberRouterScroll, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Router = (_dec = (0, _service.inject)('state'), _dec2 = (0, _service.inject)('notifications'), (_class = class Router extends _emberRouterScroll.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);

      this.on('routeWillChange', transition => {
        let route = transition.targetName;
        this.notifications.clear();
        let maintenance = this.get('state.maintenance.enabled');

        if (!route) {
          return;
        }

        if (maintenance && route !== 'maintenance') {
          transition.abort();
          this.transitionTo('maintenance');
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Router;
  Router.map(function () {
    this.route('login');
    this.route('register');
    this.route('confirm-email', {
      path: 'confirm-email/:confirmationCode'
    });
    this.route('forgot-password', function () {
      this.route('verify', {
        path: 'verify/:confirmationCode'
      });
    });
    this.route('tag-estimator');
    this.route('expired-password');
    this.route('imported-user');
    this.route('contact');
    this.route('account', function () {
      this.route('profile');
      this.route('action', {
        path: '/action/:userAction_id'
      });
      this.route('help');
      this.route('properties', function () {
        this.route('create', {
          path: ':type/create'
        });
      });
      this.route('property', {
        path: '/properties/:type/:siteId'
      }, function () {
        // detail view in index
        this.route('edit');
        this.route('agreement');
        this.route('rta');
        this.route('harvests', function () {
          this.route('create');
        });
        this.route('harvest', {
          path: '/harvests/:harvestId'
        }, function () {
          this.route('edit');
        });
        this.route('units', function () {
          // index shows all units for property
          this.route('create');
        });
        this.route('unit', {
          path: '/units/:unitId'
        }, function () {
          // index shows details
          this.route('edit');
          this.route('harvests', function () {
            this.route('create');
          });
          this.route('harvest', {
            path: '/harvests/:harvestId'
          }, function () {
            this.route('edit');
          });
          this.route('practices', function () {
            this.route('create');
          });
          this.route('practice', {
            path: '/practices/:practiceId'
          }, function () {
            this.route('edit');
          });
          this.route('population');
          this.route('surveyseason', {
            path: '/survey-by-season/:seasonId'
          });
          this.route('populationestimate', {
            path: '/population-estimator/:seasonId'
          });
          this.route('harvestcalculator', {
            path: '/harvest-calculator/:seasonId/species/:speciesId'
          });
        });
        this.route('invite', function () {
          this.route('agent');
          this.route('owner');
          this.route('manager');
          this.route('member');
          this.route('biologist');
        });
        this.route('enrollments', function () {
          this.route('create');
        });
        this.route('enrollment', {
          path: '/enrollments/:enrollmentId'
        }, function () {
          this.route('edit');
          this.route('recommendations');
          this.route('reporting-status');
        });
        this.route('permits', {
          path: 'permits/:permitType'
        }, function () {});
        this.route('permit', {
          path: 'permits/:permitType/:permitId'
        }, function () {
          this.route('index', {
            path: '/'
          });
          this.route('amd-recommendations', function () {
            this.route('view');
            this.route('edit');
          });
          this.route('ph-recommendations', function () {
            this.route('view');
            this.route('edit');
          });
          this.route('reporting', function () {
            this.route('harvest');
            this.route('status');
          });
        });
        this.route('population');
        this.route('surveyseason', {
          path: '/survey-by-season/:seasonId'
        });
        this.route('populationestimate', {
          path: '/population-estimator/:seasonId'
        });
        this.route('harvestcalculator', {
          path: '/harvest-calculator/:seasonId/species/:speciesId'
        });
        this.route('project', {
          path: 'project/:projectId'
        }, function () {
          this.route('edit');
        });
        this.route('projects', function () {
          this.route('create');
        });
      });
      this.route('users', function () {
        this.route('edit', {
          path: ':user_id'
        });
        this.route('merge', {
          path: ':fromUserId/merge/:intoUserId'
        });
        this.route('new');
      });
      this.route('rtas', function () {});
      this.route('enrollments', function () {});
      this.route('consultations', function () {
        this.route('create');
      });
      this.route('consultation', {
        path: '/consultations/:consultationId'
      }, function () {
        this.route('edit');
      });
      this.route('outreaches', function () {
        this.route('create');
      });
      this.route('outreach', {
        path: '/outreaches/:outreachId'
      }, function () {
        this.route('edit');
      });
      this.route('reports', function () {
        this.route('staff-activity');
        this.route('mldp-summary');
        this.route('mldp-fee-summary');
        this.route('lbb-summary');
        this.route('lbb-details');
        this.route('mldp-site-specific');
        this.route('staff-log');
        this.route('quarterly-summary');
        this.route('my-customers');
        this.route('amd-summary');
        this.route('amd-site-specific');
        this.route('ph-summary');
        this.route('ph-site-specific');
        this.route('available-vs-issued');
        this.route('contact-list-generator');
        this.route('amd-contact-list');
        this.route('ph-contact-list');
        this.route('mldp-transactions');
        this.route('mldp-contact-report');
        this.route('habitat-practice-details');
        this.route('habitat-practice-summary');
      });
      this.route('permits', {
        path: 'permits/:type'
      }, function () {
        this.route('create');
        this.route('ph-recommendations', {
          path: '/recs-by-herd/:herdUnitId'
        });
        this.route('amd-recommendations', {
          path: '/recs-by-county/:countyId'
        });
      });
      this.route('permit', {
        path: '/permits/:type/:permitId'
      });
      this.route('payment', function () {
        this.route('history');
      });
      this.route('admin');
    });
    this.route('four-oh-four', {
      path: '*path'
    });
    this.route('need-account');
    this.route('maintenance');
    this.route('propert', function () {
      this.route('permit', function () {
        this.route('ph-recommendations', function () {});
      });
    });
    this.route('reports', function () {});
  });
});