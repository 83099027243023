define("mldp/components/invite-form/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kX0cVRB/",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[6,[39,0],[[30,0,[\"invite\"]]],[[\"submit\",\"reset\"],[[28,[37,1],[[30,0],[30,0,[\"onSave\"]]],null],[28,[37,1],[[30,0],[30,0,[\"onCancel\"]]],null]]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[1,[28,[30,2,[\"text-field\"]],[\"firstName\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[1,[28,[30,2,[\"text-field\"]],[\"lastName\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[1,[28,[30,2,[\"email-field\"]],[\"email\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[1,[28,[30,2,[\"reset\"]],[\"Cancel\"],[[\"class\"],[\"btn-transparent\"]]]],[1,\"\\n      \"],[1,[28,[30,2,[\"submit\"]],[[30,0,[\"submitBtnText\"]]],[[\"class\",\"pending\"],[\"pull-right\",\"Processing..\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]]]]],[13]],[\"&attrs\",\"f\"],false,[\"form-for\",\"action\"]]",
    "moduleName": "mldp/components/invite-form/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});