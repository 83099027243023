define("mldp/adapters/user", ["exports", "@ember/service", "rsvp", "@ember/application", "mldp/adapters/application", "mldp/models/profile"], function (_exports, _service, _rsvp, _application, _application2, _profile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let User = (_dec = (0, _service.inject)('notifications'), classic(_class = (_class2 = class User extends _application2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "modelName", 'user');

      _initializerDefineProperty(this, "notifications", _descriptor, this);
    }

    init() {
      super.init(...arguments);
      let namespace = this.namespace;
      let url = `/${namespace}/account/profile`;
      this.set('profileUrl', url);
    }

    urlForFindAll(modelName, snapshot) {
      var _snapshot$adapterOpti;

      let url = super.urlForFindAll(...arguments);

      if ((_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.type) {
        let type = snapshot.adapterOptions.type;
        url += `/${type}`;
      }

      return url;
    } // Custom methods..


    findBiologists() {
      let namespace = this.namespace;
      let url = `/${namespace}/user/biologists`;
      let ajax = this.ajaxService;
      return ajax.request(url, {
        contentType: 'application/json'
      }).then(result => {
        if (result) {
          let users = result.map(item => {
            return {
              type: 'user',
              id: item.id,
              attributes: item
            };
          });
          let models = this.store.push({
            data: users
          });
          return models;
        }
      });
    } // Custom methods..


    findTPWDUsers() {
      let namespace = this.namespace;
      let url = `/${namespace}/user?filter%5Botherstaff%5D=1`;
      let ajax = this.ajaxService;
      return ajax.request(url, {
        contentType: 'application/json'
      }).then(result => {
        if (result !== null && result !== void 0 && result.data) {
          let users = result.data.map(item => {
            return {
              type: 'user',
              id: item.id,
              attributes: item
            };
          });
          let models = this.store.push({
            data: users
          });
          return models;
        }
      });
    }

    getAccountProfile() {
      let url = this.profileUrl;
      let ajax = this.ajaxService;
      return ajax.request(url).then(res => {
        let data = _profile.default.serialize(res);

        return _profile.default.create((0, _application.getOwner)(this).ownerInjection(), data);
      });
    }

    getSubordinates(supervisorId) {
      let ajax = this.ajaxService;
      let namespace = this.namespace;
      let url = `/${namespace}/user/${supervisorId}/subordinates`;
      return ajax.request(url);
    }

    updateProfile(data) {
      let url = this.profileUrl;
      let ajax = this.ajaxService;

      let profile = _profile.default.create((0, _application.getOwner)(this).ownerInjection(), data);

      let updated = profile.deserialize();
      return ajax.post(url, {
        data: JSON.stringify(updated),
        dataType: 'json',
        contentType: 'application/json' //TODO: move this to route
        // see how this is supposed to work with data attribtues

      }).catch(error => {
        console.log('error', error);

        if (error.payload.errors.length) {
          this.notifications.error(error.payload.errors[0].detail);
        }

        throw error;
      });
    }

    updateRoles(user) {
      let updatedRoles = user.get('displayRoles');
      let currentRoles = user.get('roles');
      let toAdd = [];
      let toRemove = [];

      if (!updatedRoles) {
        return _rsvp.default.resolve();
      }

      updatedRoles.forEach(updated => {
        if (!currentRoles.findBy('roleId', Number(updated.id))) {
          toAdd.pushObject(updated);
        }
      });
      currentRoles.forEach(current => {
        if (!updatedRoles.findBy('id', String(current.get('roleId')))) {
          toRemove.pushObject(current);
        }
      });
      toAdd = toAdd.map(add => {
        return this.store.createRecord('user-xrole', {
          roleId: add.id,
          userId: user.id
        });
      });
      currentRoles.addObjects(toAdd);
      currentRoles.removeObjects(toRemove);
      let addPromises = toAdd.invoke('save');
      let removePromises = toRemove.invoke('destroyRecord');
      return _rsvp.default.all(addPromises.concat(removePromises));
    }

    updateCounties(user) {
      let updatedCounties = user.get('displayCounties');
      let currentCounties = user.get('counties');
      let toAdd = [];
      let toRemove = [];

      if (!updatedCounties) {
        return _rsvp.default.resolve();
      }

      updatedCounties.forEach(updated => {
        if (!currentCounties.findBy('countyId', Number(updated.id))) {
          toAdd.pushObject(updated);
        }
      });
      currentCounties.forEach(current => {
        if (!updatedCounties.findBy('id', String(current.get('countyId')))) {
          toRemove.pushObject(current);
        }
      });
      toAdd = toAdd.map(add => {
        return this.store.createRecord('user-xcounty', {
          countyId: add.id,
          userId: user.id
        });
      });
      currentCounties.addObjects(toAdd);
      currentCounties.removeObjects(toRemove);
      let addPromises = toAdd.invoke('save');
      let removePromises = toRemove.invoke('destroyRecord');
      return _rsvp.default.all(addPromises.concat(removePromises));
    }

    changePassword(data) {
      let ajax = this.ajaxService;
      let namespace = this.namespace;
      let url = `/${namespace}/account/changepassword`;

      if (!data.newPassword) {
        return _rsvp.default.reject('New password must be specified');
      }

      if (data.newPassword !== data.confirmNewPassword) {
        return _rsvp.default.reject('Confirmation password must match new password');
      }

      return ajax.post(url, {
        data: JSON.stringify(data),
        dataType: 'json',
        contentType: 'application/json'
      });
    }

    requestPasswordChange(email) {
      let ajax = this.ajaxService;
      let namespace = this.namespace;
      let url = `/${namespace}/account/forgotpassword`;
      return ajax.post(url, {
        data: JSON.stringify({
          email
        }),
        dataType: 'json',
        contentType: 'application/json'
      });
    }

    completeChangePassword(data) {
      let ajax = this.ajaxService;
      let namespace = this.namespace;
      let url = `/${namespace}/account/forgotpassword/${data.get('code')}`;
      return ajax.post(url, {
        data: JSON.stringify(data.deserialize()),
        dataType: 'json',
        contentType: 'application/json'
      });
    }

    confirmEmailAddress(confirmationCode) {
      let ajax = this.ajaxService;
      let namespace = this.namespace;
      let url = `/${namespace}/account/ConfirmEmail`;
      return ajax.post(url, {
        data: JSON.stringify({
          'EmailConfirmationCode': confirmationCode
        }),
        dataType: 'json',
        contentType: 'application/json'
      });
    } //should only be available to admin


    confirmUserEmailAddress(id) {
      let ajax = this.ajaxService;
      let namespace = this.namespace;
      let url = `/${namespace}/user/${id}/confirmemail`;
      return ajax.post(url, {
        dataType: 'json',
        contentType: 'application/json'
      });
    }

    registerUser(model) {
      let ajax = this.ajaxService;
      let namespace = this.namespace;
      let url = `/${namespace}/account/register`;
      return ajax.post(url, {
        data: JSON.stringify(model.deserialize()),
        dataType: 'json',
        contentType: 'application/json'
      });
    }

    changeExpiredPassword(model) {
      let ajax = this.ajaxService;
      let namespace = this.namespace;
      let url = `/${namespace}/account/ForceChangePassword`;
      return ajax.post(url, {
        data: JSON.stringify(model.deserialize()),
        dataType: 'json',
        contentType: 'application/json'
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = User;
});