define("mldp/account/users/index/route", ["exports", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const params = ['county', 'role', 'firstName', 'lastName', 'email'];
  const queryParams = {
    page: {
      refreshModel: true
    },
    county: {
      refreshModel: true
    },
    role: {
      refreshModel: true
    },
    firstName: {
      refreshModel: true
    },
    lastName: {
      refreshModel: true
    },
    email: {
      refreshModel: true
    }
  };

  var _default = _route.default.extend({
    state: (0, _service.inject)('state'),
    session: (0, _service.inject)('session'),
    queryParams,
    breadCrumb: {
      title: 'Users'
    },

    model(opts) {
      let state = this.state;
      let counties = state.get('counties');
      let roles = state.get('roles');
      let page = opts.page;
      let options = {
        'page[number]': page,
        'page[size]': 25,
        reload: true
      }; // Apply query params as filters to the api request

      for (let param of params) {
        if (opts[param]) {
          options[`filter[${param}]`] = opts[param];
        }
      }

      return _rsvp.default.hash({
        users: this.store.query('user', options),
        counties,
        roles
      }).then(_ref => {
        let {
          users
        } = _ref;
        return {
          users,
          meta: users.meta
        };
      });
    },

    setupController(controller, _ref2) {
      let {
        users,
        meta
      } = _ref2;
      controller.setProperties({
        paging: meta.paging,
        users: users.sortBy('firstName')
      });
    }

  });

  _exports.default = _default;
});