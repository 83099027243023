define("mldp/components/practice/form-body/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FormBody = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class FormBody extends _component2.default {
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      let type = this.type;

      if (type && typeof type === 'number') {
        type = type.toString();
      }

      let subType = this.subtype;

      if (subType && typeof subType === 'number') {
        subType = subType.toString();
      }

      const componentName = this.getComponentName(type, subType);
      this.set('componentName', componentName);
    }

    getComponentName(type, subType) {
      switch (type) {
        case '1':
          {
            return 'practice/proscribed-burning';
          }

        case '2':
          {
            switch (subType) {
              case '1':
                return 'practice/grazing-active';

              case '2':
                return 'practice/grazing-deferment';

              case '3':
                return 'practice/default-other';

              default:
                return;
            }
          }

        case '3':
          {
            switch (subType) {
              case '4':
                return 'practice/brush-mechanical';

              case '5':
                return 'practice/brush-chemical';

              case '6':
                return 'practice/default-other';

              default:
                return;
            }
          }

        case '4':
          {
            switch (subType) {
              case '7':
                return 'practice/type-acres';

              case '8':
                return 'practice/vegetation-reseeding';

              case '9':
              case '10':
                return 'practice/vegetation-planting';

              case '11':
                return 'practice/default-other';

              default:
                return;
            }
          }

        case '5':
          {
            switch (subType) {
              case '12':
              case '13':
                return 'practice/water-ponds-guzzlers';

              case '14':
              case '15':
              case '16':
                return 'practice/water-wetland';

              case '17':
                return 'practice/water-riparian-fencing';

              case '18':
                return 'practice/water-riparian-brush';

              case '19':
              case '20':
              case '21':
                return 'practice/water-playa';

              case '22':
                return 'practice/default-other';

              default:
                return;
            }
          }

        case '6':
          {
            switch (subType) {
              case '23':
              case '24':
                return 'practice/erosion-managment';

              case '25':
                return 'practice/erosion-re-vegetation';

              case '26':
                return 'practice/default-other';

              default:
                return;
            }
          }

        case '7':
          {
            switch (subType) {
              case '27':
                return 'practice/fence-cross';

              case '28':
                return 'practice/fence-riparian';

              case '29':
                return 'practice/fence-pronghorn';

              case '30':
                return 'practice/default-other';

              default:
                return;
            }
          }

        case '8':
          {
            switch (subType) {
              case '31':
                return 'practice/exotic-vegetation';

              case '32':
                return 'practice/exotic-hog';

              case '33':
                return 'practice/exotic-animal';

              case '34':
                return 'practice/default-other';

              default:
                return;
            }
          }

        case '9':
          {
            switch (subType) {
              case '35':
                return 'practice/timber-clearcut';

              case '36':
                return 'practice/timber-thinning';

              case '37':
                return 'practice/timber-smz';

              case '38':
                return 'practice/timber-bottomland';

              case '39':
              case '40':
                return 'practice/timber-understory';

              case '41':
                return 'practice/timber-daylighting';

              case '42':
                return 'practice/timber-opening';

              case '43':
                return 'practice/type-acres';

              case '44':
                return 'practice/default-other';

              default:
                return;
            }
          }

        case '10':
          {
            switch (subType) {
              case '45':
                return 'practice/shelter-structure';

              case '46':
                return 'practice/shelter-snag';

              case '47':
                return 'practice/type-number';

              case '48':
              case '49':
                return 'practice/type-acres';

              case '50':
                return 'practice/default-other';

              default:
                return;
            }
          }

        case '11':
          {
            switch (subType) {
              case '51':
                return 'practice/cropland-un-harvested';

              case '52':
                return 'practice/cropland-stubble';

              case '53':
                return 'practice/cropland-travel';

              case '54':
                return 'practice/cropland-residue';

              case '55':
                return 'practice/default-other';

              default:
                return;
            }
          }

        case '12':
          {
            return 'practice/default-other';
          }
      }
    }

  }) || _class) || _class);
  _exports.default = FormBody;
});