define("mldp/components/reporting-wizard/pronghorn-app-select/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/component", "ember-concurrency"], function (_exports, _component, _object, _service, _component2, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PronghornAppSelect = (_dec = (0, _component.tagName)(""), _dec2 = (0, _service.inject)('store'), _dec3 = (0, _service.inject)('router'), _dec4 = (0, _emberConcurrency.task)(function* () {
    let {
      permitApps
    } = this;

    if (permitApps) {
      permitApps = yield (0, _emberConcurrency.all)(permitApps.map(app => this.setupPermitApp.perform(app)));
    }

    this.set('loading', false);
  }), _dec5 = (0, _emberConcurrency.task)(function* (permitApp) {
    let reporting = yield permitApp.fetchReporting();
    let managementUnits = yield (0, _emberConcurrency.all)(permitApp.mUs.map(id => this.store.find('management-unit', id)));
    let appName = managementUnits.map(unit => unit.siteName).join(', ');
    permitApp.set('phReportingStatus', reporting.harvest.length);
    permitApp.set('appName', appName);
  }), _dec(_class = classic(_class = (_class2 = class PronghornAppSelect extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "routerService", _descriptor2, this);

      _defineProperty(this, "loading", true);

      _initializerDefineProperty(this, "componentSetup", _descriptor3, this);

      _initializerDefineProperty(this, "setupPermitApp", _descriptor4, this);
    }

    init() {
      super.init(...arguments);
      this.componentSetup.perform();
    }

    checkPronghorn(permitApp) {
      if (!permitApp) {
        return this.onProngHornIneligible();
      } // check for any recommendations greater than zero where owner accepted


      let hasNonZeroRecs = permitApp.phPartitions.some(part => part.phRecommendations.some(rec => rec.ownerAccepted && rec.recommendedBucks > 0)); // check if application status is 'approved'(2) or submitted (1)

      let approvedOrSubmitted = permitApp.applicationStatusId === 2 || permitApp.applicationStatusId === 1;

      if (!approvedOrSubmitted || !hasNonZeroRecs) {
        this.onProngHornIneligible();
      } else {
        this.routerService.transitionTo('account.property.permit.reporting.harvest', permitApp.primarySite.siteType, permitApp.primarySite.id, 'ph', permitApp.id);
        this.onReportOpen();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "routerService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "componentSetup", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "setupPermitApp", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "checkPronghorn", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "checkPronghorn"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = PronghornAppSelect;
});