define("mldp/account/reports/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GZl6g6wc",
    "block": "[[[1,[28,[35,0],[\"Reports\"],null]],[1,\"\\n\\n\"],[6,[39,1],null,null,[[\"default\"],[[[[1,\"  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"page-banner\",\"reports-navigation\",\"component\",\"-outlet\"]]",
    "moduleName": "mldp/account/reports/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});