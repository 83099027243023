define("mldp/account/users/route", ["exports", "@ember/service", "@ember/routing/route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _service, _route, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, {
    roleService: (0, _service.inject)('role-service'),
    breadCrumb: {
      title: 'Manage Users'
    },

    beforeModel() {
      let result = this._super(...arguments);

      let isAdmin = this.get('roleService.isAdmin');
      let isWPO = this.get('roleService.isWPO');

      if (!isAdmin && !isWPO) {
        return this.transitionTo('account');
      }

      return result;
    }

  });

  _exports.default = _default;
});