define("mldp/components/permit/amd-bulk-recommendation/permit-mu/component", ["exports", "@ember-decorators/component", "@ember/service", "@ember/component", "@ember/object", "ember-state-services/state-for"], function (_exports, _component, _service, _component2, _object, _stateFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PermitMu = (_dec = (0, _component.tagName)(''), _dec2 = (0, _service.inject)('store'), _dec3 = (0, _stateFor.default)('amd-recommendations', 'muInfo'), _dec4 = (0, _object.computed)('muInfo.partitions'), classic(_class = _dec(_class = (_class2 = class PermitMu extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "state", _descriptor2, this);
    }

    get existingRecommendations() {
      return this.muInfo.partitions.reduce((all, partition) => {
        partition.amdRecommendations.forEach(rec => {
          let found = all.findBy('groupId', rec.groupId);
          let part = Object.assign({
            partitionId: partition.id,
            compartment: partition.compartment,
            count: rec.recommendedCount
          }, rec);
          part = _object.default.create(part);

          if (found) {
            found.partitions.push(part);
          } else {
            all.push({
              entryDate: rec.entryDate,
              groupId: rec.groupId,
              partitions: [part]
            });
          }
        });
        return all;
      }, []).sortBy('entryDate').reverse();
    }

    async addRecommendation() {
      let adapter = this.store.adapterFor('amd-recommendation');
      let {
        groupId
      } = await adapter.nextGroupId();
      this.state.addRecommendation(groupId, this.muInfo.partitions);
    }

    async getPastSeasonHarvest() {
      if (!this.previousHarvest) {
        this.set('loading', true);
        let mu = await this.store.findRecord('management-unit', this.muInfo.site.id);
        let results = await mu.findHarvests();
        let previousHarvest = results.find(recommendation => recommendation.deerSeasonId === this.permit.seasonId - 1);
        this.set('previousHarvest', previousHarvest);
        this.set('loading', false);
      }
    }

    async getPastSeasonIssuance() {
      this.set('loading', true);
      const lastSeasonPermits = await this.store.query('amd-permit-app', {
        'filter[siteid]': this.permit.primarySiteId,
        'filter[seasonid]': this.permit.seasonId - 1
      });
      let permit = lastSeasonPermits.get('firstObject');

      if (!permit) {
        this.set('previousIssuance', []);
        this.set('previousTotalIssuance', 0);
        this.set('loading', false);
        return;
      }

      let previousIssuance = [];
      let totalIssuance = 0;
      let siteId = this.muInfo.site.id;
      permit.amdPartitions.forEach(part => {
        let issuance = 0;
        if (part.siteId !== siteId) return;
        part.amdRecommendations.forEach(rec => {
          if (rec.ownerAccepted && !rec.invalidated) {
            issuance += rec.recommendedCount;
            totalIssuance += rec.recommendedCount;
          }
        });
        let existingCompartment = previousIssuance.find(issuance => issuance.compartment === part.compartment);

        if (existingCompartment) {
          existingCompartment.issuance += issuance;
        } else {
          previousIssuance.push({
            compartment: part.compartment,
            issuance
          });
        }
      });
      this.set('previousIssuance', previousIssuance);
      this.set('previousTotalIssuance', totalIssuance);
      this.set('loading', false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "existingRecommendations", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "existingRecommendations"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addRecommendation", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "addRecommendation"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getPastSeasonHarvest", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "getPastSeasonHarvest"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getPastSeasonIssuance", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "getPastSeasonIssuance"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = PermitMu;
});