define("mldp/account/enrollments/index/route", ["exports", "@ember/service", "@ember/routing/route"], function (_exports, _service, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  const params = ['county', 'biologist', 'siteName', 'season', 'species', 'gender', 'type', 'approved', 'archived'];
  let IndexRoute = (_dec = (0, _service.inject)('state'), _dec2 = (0, _service.inject)('session'), classic(_class = (_class2 = class IndexRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        county: {
          refreshModel: true
        },
        biologist: {
          refreshModel: true
        },
        siteName: {
          refreshModel: true
        },
        season: {
          refreshModel: true
        },
        species: {
          refreshModel: true
        },
        gender: {
          refreshModel: true
        },
        type: {
          refreshModel: true
        },
        approved: {
          refreshModel: true
        },
        archived: {
          refreshModel: true
        }
      });
    }

    model() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let options = {
        page: {
          number: opts.page,
          size: 25
        },
        reload: true
      };
      let biologists = this.get('state.biologists');
      let result = {};
      let selectedBiologist;
      let selectedSeason;

      if (opts.biologist === 'init') {
        let currentUserEmail = this.get('session.email');
        selectedBiologist = biologists.findBy('email', currentUserEmail);
      } else if (opts.biologist !== 'null') {
        selectedBiologist = biologists.findBy('id', opts.biologist);
      } // if(opts.season === 'init') {
      //   selectedSeason = this.get('state.currentSeason');
      // } else if (opts.season) {


      if (opts.season) {
        let seasons = this.get('state.seasons');
        selectedSeason = seasons.findBy('displayValue', opts.season);
      }

      if (selectedBiologist && opts.biologist !== null) {
        opts.biologist = selectedBiologist.id;
        result.selectedBiologist = selectedBiologist;
        result.biologist = opts.biologist;
      } else {
        opts.biologist = undefined;
      }

      if (selectedSeason && opts.season !== null) {
        opts.season = selectedSeason.get('displayValue');
        result.selectedSeason = selectedSeason;
        result.season = opts.season;
      } else {
        opts.season = undefined;
      } // Don't send to the server if 'cleared'


      if (opts.type === 'null') {
        opts.type = undefined;
      }

      for (let param of params) {
        if (param === 'archived') {
          options[param] = opts[param];
        } else if (opts[param]) {
          options[`filter[${param}]`] = opts[param];
        }
      }

      return this.store.query('enrollment', options).then(enrollments => {
        result.paging = enrollments.meta.paging;
        result.enrollments = enrollments;
        return result;
      });
    }

    setupController(controller, resolved) {
      let {
        query
      } = resolved.enrollments;
      resolved.searchValues = {
        season: resolved.selectedSeason,
        biologist: resolved.selectedBiologist,
        siteName: query['filter[siteName]'],
        approved: query['filter[approved]'],
        type: query['filter[type]']
      };
      controller.setProperties(resolved);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = IndexRoute;
});