define("mldp/components/search/outreach-form/component", ["exports", "rsvp", "@ember/object", "@ember/service", "@ember/component", "ember-concurrency"], function (_exports, _rsvp, _object, _service, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    state: (0, _service.inject)('state'),
    store: (0, _service.inject)('store'),

    init() {
      this._super(...arguments);

      this.show = false;
      this.types = this.get('state.outreachEventTypes').map(item => {
        return {
          label: item.get('displayValue'),
          value: item.get('displayValue')
        };
      });
      this.types.unshift(_object.default.create({
        label: '-- Filter by Type --',
        value: ''
      }));
      this.groups = this.get('state.outreachGroups').map(item => {
        return {
          label: item.get('displayValue'),
          value: item.get('displayValue')
        };
      });
      this.groups.unshift(_object.default.create({
        label: '-- Filter by Group --',
        value: ''
      }));
      this.fundings = this.get('state.outreachFundings').map(item => {
        return {
          label: item.get('displayValue'),
          value: item.get('displayValue')
        };
      });
      this.fundings.unshift(_object.default.create({
        label: '-- Filter by Funding --',
        value: ''
      }));
      this.wmas = this.get('state.wmas').map(item => {
        return {
          label: item.get('displayValue'),
          value: item.get('displayValue')
        };
      });
      this.wmas.unshift(_object.default.create({
        label: '-- Filter by WMA --',
        value: ''
      }));
      this.counties = this.get('state.counties').map(item => {
        return {
          label: item.get('displayValue'),
          value: item.get('displayValue')
        };
      });
      this.counties.unshift(_object.default.create({
        label: '-- Filter by County --',
        value: ''
      }));
    },

    searchContacts: (0, _emberConcurrency.task)(function* (query) {
      // debounce
      yield (0, _emberConcurrency.timeout)(400);
      let all = [];
      let store = this.store;
      let users = yield store.query('user', {
        'filter[identity]': query
      });
      let contacts = yield store.query('contact', {
        'filter[identity]': query
      });
      all = all.concat(users.toArray()).concat(contacts.toArray()); // Populate select so on subsequent open your last results are still there

      this.set('lastContactResults', all);
      return all;
    }).restartable(),
    // used for otherstaff and created by
    searchUsers: (0, _emberConcurrency.task)(function* () {
      let store = this.store;
      let users = yield store.query('user', {
        'filter[otherstaff]': true
      });
      return users.sortBy('lastName');
    }).on('init'),
    actions: {
      updateForm(e) {
        this.updateForm(e);
        return (0, _rsvp.resolve)(true);
      },

      updateProperty(object, property, value) {
        if (property === 'type') {
          let type = value;
          let out = {
            page: 1,
            type
          };
          object.setProperties(out);
        } else {
          object.set(property, value);
        }
      },

      setOtherStaff(staff) {
        this.set('otherStaff', staff);
        this.set('data.biologistIdOther', staff.id);
      },

      setBiologist(biologist) {
        this.set('biologist', biologist);
        this.set('data.biologistId', biologist.id);
      },

      updateContact(contact) {
        this.set('selectedContact', contact);

        if (contact.constructor.modelName === 'user') {
          this.set('data.userId', contact.id);
        } else if (contact.constructor.modelName === 'contact') {
          this.set('data.contactId', contact.id);
        }
      }

    }
  });

  _exports.default = _default;
});