define("mldp/mirage/config", ["exports", "mldp/mirage/routes/fee"], function (_exports, _fee) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // import enrollment from './routes/enrollment';
  function _default() {
    // enrollment(this);
    (0, _fee.default)(this);
    this.passthrough();
    this.passthrough('https://storage.googleapis.com/**');
  }
});