define("mldp/components/site-aggregate-details/invite-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pofKroNa",
    "block": "[[[11,0],[24,0,\"modal-backdrop\"],[4,[30,0,[\"overlayClick\"]],null,null],[12],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"modal-dialog\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"modal-content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"modal-header\"],[12],[1,\"\\n      \"],[10,\"button\"],[14,0,\"close\"],[14,\"aria-label\",\"Close\"],[15,\"onclick\",[30,1]],[14,4,\"button\"],[12],[1,\"\\n        \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n          ×\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[1,\"\\n        \"],[1,[30,2]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[15,0,[29,[\"modal-body clearfix \",[52,[30,0,[\"preventOverflow\"]],\"prevent-modal-overflow\"]]]],[12],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        If you continue with this invitation, the active enrollment for this aggregate will be canceled when the new member accepts the invite. Do not continue if it is after the enrollment/application deadline.\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-default pull-left\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n        Close\\n      \"],[13],[1,\"\\n\\n      \"],[8,[39,2],[[24,0,\"btn btn-primary\"]],[[\"@route\",\"@models\"],[\"account.property.invite.member\",[28,[37,3],[[30,3,[\"siteType\"]],[30,3,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"Invite Member\"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@close\",\"@title\",\"@model\"],false,[\"if\",\"on\",\"link-to\",\"array\"]]",
    "moduleName": "mldp/components/site-aggregate-details/invite-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});