define("mldp/account/property/enrollments/create/controller", ["exports", "@ember/object", "@ember/controller"], function (_exports, _object, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CreateController = (_dec = (0, _object.computed)('units.{[],@each.poolActive}', 'site.siteType'), classic(_class = (_class2 = class CreateController extends _controller.default {
    get availableUnits() {
      let units = this.units;
      let siteType = this.get('site.siteType');

      if (siteType !== 'property') {
        return units.filterBy('poolActive', true);
      } else {
        return units;
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "availableUnits", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "availableUnits"), _class2.prototype)), _class2)) || _class);
  _exports.default = CreateController;
});