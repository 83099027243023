define("mldp/components/permit/select-herd-unit/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HMfQVJ5p",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"loadHerdUnits\",\"isRunning\"]],[[[1,\"    Loading herd units...\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"container-fluid permit-app-info \"],[12],[1,\"\\n      \"],[10,0],[14,0,\"row-fluid\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"loadHerdUnits\",\"last\",\"value\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"col-xs-2\"],[12],[1,\"\\n            \"],[8,[39,3],[[24,0,\"btn btn-link btn-block text-left\"]],[[\"@route\",\"@models\"],[\"account.permits.ph-recommendations\",[28,[37,4],[\"ph\",[30,2]],null]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,2]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],[[[1,\"          No herd units\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"unit\"],false,[\"if\",\"each\",\"-track-array\",\"link-to\",\"array\"]]",
    "moduleName": "mldp/components/permit/select-herd-unit/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});