define("mldp/account/enrollments/index/controller", ["exports", "@ember/service", "@ember/controller", "@ember/object"], function (_exports, _service, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const params = ['type', 'county', 'siteName', 'season', 'species', 'gender', 'biologist', 'approved'];

  var _default = _controller.default.extend({
    state: (0, _service.inject)('state'),
    ajaxService: (0, _service.inject)('general-ajax'),
    queryParams: ['page', ...params],
    type: undefined,
    county: undefined,
    biologist: 'init',
    siteName: undefined,
    searching: undefined,
    // season: 'init',
    gender: undefined,
    species: undefined,
    approved: undefined,
    archived: false,
    tagUrl: '',
    tagFormData: _object.default.create({}),
    invalidTagMessag: '',
    page: 1,
    formData: (0, _object.computed)(...params, function () {
      var obj = {};

      for (let param of params) {
        obj[param] = this.get(param);
      }

      return _object.default.create(obj);
    }),
    showClearSearch: (0, _object.computed)('formData', 'state.currentSeason', function () {
      let formData = this.formData;
      let currentSeason = this.get('state.currentSeason').displayValue;

      for (let param of params) {
        let value = formData.get(param);

        if (param === 'season' && value === currentSeason) {
          continue;
        } else if (value === 'init') {
          continue;
        } else if (value !== undefined) {
          return true;
        }
      }

      return false;
    }),
    actions: {
      updateForm(data) {
        this.set('searching', true);

        for (let param of params) {
          if (!data[param]) {
            data.set(param, undefined);
          }
        }

        const queryValues = new Map([['siteName', null], ['approved', null], ['type', null], ['season', 'displayValue'], ['biologist', 'id']]);
        let newQuery = {};
        queryValues.forEach((value, key) => {
          if (!data[key]) {
            newQuery[key] = undefined;
          } else if (value) {
            newQuery[key] = data[key][value];
          } else {
            newQuery[key] = data[key];
          }
        }); // Reset the page to 1

        newQuery.page = 1; // Apply the data to QPs to update the model in the route

        this.setProperties(newQuery);
      },

      queryTags() {
        let ajax = this.ajaxService;
        this.set('tagUrl', '');
        return ajax.request(`/tags/tag/${this.tagFormData.tagId}/validate`).then((result, error) => {
          if (result !== null && result !== void 0 && result.valid) {
            this.set('tagUrl', `/tags/tag/${this.tagFormData.tagId}/?auth=${result.token}`);
          } else if (result && !result.valid) {
            this.set('invalidTagMessage', result.message);
          } else if (error) {
            this.set('invalidTagMessage', 'Something went wrong, please try again.');
          }
        });
      },

      clearSearch() {
        this.set('searching', true);
        params.forEach(param => {
          this.set(param, undefined);
        });
        let season = this.get('state.currentSeason');
        this.set('searchValues', {
          season
        });
      }

    }
  });

  _exports.default = _default;
});