define("mldp/account/property/units/index/controller", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let IndexController = classic(_class = class IndexController extends _controller.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ['archivedMus']);

      _defineProperty(this, "archivedMus", false);
    }

  }) || _class;

  _exports.default = IndexController;
});