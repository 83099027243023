define("mldp/account/property/unit/practices/index/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "q69e1/4Y",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"Practices\"]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"clearfix\"],[12],[1,\"\\n\"],[41,[28,[37,2],[\"edit habitat of units\",[30,0,[\"property\"]]],null],[[[1,\"    \"],[8,[39,3],[[24,0,\"btn btn-primary pull-right\"]],[[\"@route\",\"@models\"],[\"account.property.unit.practices.create\",[28,[37,4],[[30,0,[\"property\",\"siteType\"]],[30,0,[\"property\",\"id\"]],[30,0,[\"unit\",\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n      Report Habitat Practices\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"data-list form-group\"],[12],[1,\"\\n  \"],[8,[39,5],null,[[\"@practices\"],[[30,0,[\"practices\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@practice\",\"@property\",\"@unit\"],[[30,1],[30,0,[\"property\"]],[30,0,[\"unit\"]]]],null],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"practice\"],false,[\"page-banner\",\"if\",\"can\",\"link-to\",\"array\",\"practice-list\",\"practice-row\"]]",
    "moduleName": "mldp/account/property/unit/practices/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});