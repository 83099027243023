define("mldp/components/wmp-confirm-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "N81mW6cr",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"Are you sure you'd like to set \"],[1,[30,0,[\"propertyName\"]]],[1,\"'s WMP status to \"],[1,[52,[30,0,[\"status\"]],\"inactive\",\"active\"]],[1,\"?\"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"if\"]]",
    "moduleName": "mldp/components/wmp-confirm-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});