define("mldp/register/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "@ember/application", "ember-simple-auth/mixins/unauthenticated-route-mixin", "mldp/models/user-register"], function (_exports, _object, _service, _route, _application, _unauthenticatedRouteMixin, _userRegister) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let RegisterRoute = (_dec = (0, _service.inject)('session'), _dec2 = (0, _service.inject)('notifications'), classic(_class = (_class2 = class RegisterRoute extends _route.default.extend(_unauthenticatedRouteMixin.default) {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);
    }

    model() {
      return _userRegister.default.create((0, _application.getOwner)(this).ownerInjection());
    }

    resetController(controller, isExiting) {
      let model = controller.model;

      if (isExiting && model) {
        controller.set('model', _userRegister.default.create((0, _application.getOwner)(this).ownerInjection()));
      }
    }

    register(data) {
      let notifications = this.notifications;
      return data.validate().then(() => {
        if (data.get('validations.isValid')) {
          return this.store.adapterFor('user').registerUser(data).then(() => this.transitionTo('index')).then(() => notifications.add('You have successfully registered. Please check your email to confirm your account before proceeding.')).catch(error => {
            if (error.jqXHR) {
              if (error.jqXHR.status === 403 || error.jqXHR.status === 404 || error.jqXHR.status === 409) {
                notifications.error(error.payload.errors[0].message);
              }
            } else if (error.errors) {
              notifications.error(error.errors[0].detail); //TODO: what changed that now some errors are coming back embedded in payload object?
            } else if (error.payload.errors) {
              notifications.error(error.payload.errors[0].detail);
            } else {
              console.error('invite agent error', {
                error
              });
              notifications.error('An error has occurred');
            }
          });
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "register", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "register"), _class2.prototype)), _class2)) || _class);
  _exports.default = RegisterRoute;
});