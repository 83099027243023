define("mldp/components/reporting-wizard/habitat-practice-units-list/component", ["exports", "@ember/object", "@ember-decorators/component", "@ember/service", "@ember/component", "ember-concurrency"], function (_exports, _object, _component, _service, _component2, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let HabitatPracticeUnitsList = (_dec = (0, _component.tagName)(''), _dec2 = (0, _service.inject)('store'), _dec3 = (0, _service.inject)('router'), _dec4 = (0, _service.inject)('state'), _dec5 = (0, _emberConcurrency.task)(function* () {
    if (!this.units) {
      yield this.fetchUnits.perform(this.site);
    }

    if (!this.currentSeason) {
      let currentSeason = yield this.stateService.get('currentSeason');
      this.set('currentSeason', currentSeason);
    }

    yield (0, _emberConcurrency.all)(this.units.map(unit => this.fetchHabitatPractices.perform(unit)));
  }), _dec6 = (0, _emberConcurrency.task)(function* (site) {
    let adapter = this.storeService.adapterFor(site.get('siteType'));
    let units = yield adapter.getManagementUnits(site.id);
    this.set('units', units);
  }), _dec7 = (0, _emberConcurrency.task)(function* (unit) {
    let practices = yield unit.findPractices();
    practices = practices.filter(cur => Number(cur.deerSeasonId) === Number(this.currentSeason.id));
    unit.set('practices', practices);
  }), classic(_class = _dec(_class = (_class2 = class HabitatPracticeUnitsList extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "storeService", _descriptor, this);

      _initializerDefineProperty(this, "routerService", _descriptor2, this);

      _initializerDefineProperty(this, "stateService", _descriptor3, this);

      _initializerDefineProperty(this, "componentSetup", _descriptor4, this);

      _initializerDefineProperty(this, "fetchUnits", _descriptor5, this);

      _initializerDefineProperty(this, "fetchHabitatPractices", _descriptor6, this);
    }

    init() {
      super.init(...arguments);
      this.componentSetup.perform();
    }

    openHabitat(site, unit) {
      // transition to route based on site type
      const siteType = site.get('siteType');
      this.routerService.transitionTo('account.property.unit.practices.create', siteType, site.id, unit.id); //close modal

      this.onReportOpen();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "storeService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "routerService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "componentSetup", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "fetchUnits", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "fetchHabitatPractices", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "openHabitat", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "openHabitat"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = HabitatPracticeUnitsList;
});