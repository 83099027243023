define("mldp/services/confirm-window", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let ConfirmWindowService = classic(_class = class ConfirmWindowService extends _service.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "isOpen", false);

      _defineProperty(this, "title", '');

      _defineProperty(this, "body", '');
    }

    init() {
      super.init(...arguments);
    }

    //TODO: flesh this out with defaults
    open(options) {
      this.setProperties(options);
      this.set('isOpen', true);
    }

    close() {
      this.set('isOpen', false);
      this.set('body', '');
      this.set('title', '');
    }

  }) || _class;

  _exports.default = ConfirmWindowService;
});