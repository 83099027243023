define("mldp/components/site-coop-details/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/component", "mldp/utils/has-manager"], function (_exports, _component, _object, _service, _component2, _hasManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SiteCoopDetails = (_dec = (0, _component.tagName)(''), _dec2 = (0, _service.inject)('session'), _dec3 = (0, _service.inject)('router'), _dec4 = (0, _object.computed)('model.associatedUsers.@each.type'), _dec(_class = classic(_class = (_class2 = class SiteCoopDetails extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "routerService", _descriptor2, this);

      _defineProperty(this, "showArchiveModal", false);
    }

    async archive() {
      let site = this.model;
      let router = this.routerService;
      await site.archive();
      let siteType = site.get('siteType');
      let id = site.id;
      let queryParams = {
        archived: true
      };
      await router.transitionTo('account.property', siteType, id, {
        queryParams
      });
    }

    async unarchive(event) {
      let site = this.model;
      let router = this.routerService;
      event.preventDefault();
      await site.unarchive();
      let siteType = site.get('siteType');
      let id = site.id;
      let queryParams = {
        archived: false
      };
      await router.transitionTo('account.property', siteType, id, {
        queryParams
      });
    }

    get needsManager() {
      let users = this.get('model.associatedUsers');
      return !(0, _hasManager.default)(users);
    }

    removeUser(user) {
      let associatedUsers = this.model.get('associatedUsers');
      associatedUsers.removeObject(user);
    }

    toggleLayer(layerName) {
      this.set(layerName, !this.get(layerName));
    }

    togglePracticeLayers() {
      if (this.selectedPracticeLayers && this.selectedPracticeLayers.length) {
        this.set('selectedPracticeLayers', []);
      } else {
        this.set('selectedPracticeLayers', this.practiceLayers);
      }
    }

    toggleIndividualPracticeLayer(layer) {
      let index = this.selectedPracticeLayers.findIndex(cur => cur[0] === layer[0]);
      let newArr = this.selectedPracticeLayers.slice();

      if (index >= 0) {
        newArr.splice(index, 1);
        this.set('selectedPracticeLayers', newArr);
      } else {
        newArr.push(layer);
        this.set('selectedPracticeLayers', newArr);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "routerService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "archive", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "archive"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "unarchive", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "unarchive"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "needsManager", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "needsManager"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeUser", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "removeUser"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleLayer", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleLayer"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "togglePracticeLayers", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "togglePracticeLayers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleIndividualPracticeLayer", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleIndividualPracticeLayer"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = SiteCoopDetails;
});