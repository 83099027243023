define("mldp/adapters/aggregate", ["exports", "mldp/adapters/site", "mldp/mixins/has-members", "mldp/mixins/has-manager", "mldp/mixins/survey-types-by-site", "mldp/mixins/can-invite-member"], function (_exports, _site, _hasMembers, _hasManager, _surveyTypesBySite, _canInviteMember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Aggregate = classic(_class = class Aggregate extends _site.default.extend(_hasMembers.default, _hasManager.default, _surveyTypesBySite.default, _canInviteMember.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "modelName", 'aggregate');
    }

  }) || _class;

  _exports.default = Aggregate;
});