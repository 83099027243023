define("mldp/utils/remove-slivers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = removeSlivers;

  function removeSlivers(cc, minAngle, minDistance) {
    if (!cc || cc.length < 3) {
      return cc;
    }

    let cosMinAngle = Math.cos(minAngle * Math.PI / 180); // if the polygon is closed, remove the last vertex to simplify the loop below

    let isClosed = cc[0][0] === cc[cc.length - 1][0] && cc[0][0] === cc[cc.length - 1][0];

    if (isClosed) {
      cc.pop();
    }

    let i = 0;

    while (cc.length && i < cc.length) {
      // get three consecutive vertices
      let n = (cc.length + i - 1) % cc.length;
      let a = cc[n];
      let b = cc[(n + 1) % cc.length];
      let c = cc[(n + 2) % cc.length];
      let bax = a[0] - b[0];
      let bay = a[1] - b[1];
      let dba = Math.sqrt(bax * bax + bay * bay); // if the middle vertex is closer than the minimum distance to the first vertex, remove the 
      // middle vertex and backup one so that we can test if this removal created a sliver at the 
      // previous angle

      if (dba <= minDistance) {
        cc.splice(i, 1);
        i -= 2;
      } else {
        // this conditional branch ->  determine if the angle formed by these vertices is a sliver
        let bcx = c[0] - b[0];
        let bcy = c[1] - b[1];
        let dot = bax * bcx + bay * bcy; // if the vector dot product is less than zero the angle is greater than 90 degrees
        // and definitely not a sliver so we can skip this

        if (dot > 0) {
          let dbc = Math.sqrt(bcx * bcx + bcy * bcy);
          let cos = dot / (dba * dbc); // if the cosine of the angle is close to one then the angle is a sliver; remove the
          // middle vertex and backup one so that we can test if this removal created a sliver at
          // the previous angle

          if (cos >= cosMinAngle) {
            cc.splice(i, 1);
            i -= 2;
          }
        }
      }

      i += 1;
    } // if the polygon was originally closed, restore the last vertex


    if (isClosed && cc.length) {
      cc.push([cc[0][0], cc[0][1]]);
    }

    return cc;
  }
});