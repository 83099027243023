define("mldp/components/site-list-map/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "j6bABmA5",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[15,0,[28,[37,0],[[30,0,[\"classNames\"]],\"site-list-map\"],null]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[10,0],[14,0,\"map-tip\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[6,[39,2],null,null,[[\"default\"],[[[[1,\"      \"],[18,5,[[30,3],[30,4]]],[1,\"\\n\"],[41,[30,0,[\"sites\",\"length\"]],[[[1,\"        \"],[1,[28,[35,4],null,[[\"showFootPrint\",\"parentSite\",\"sites\",\"hoveredSite\",\"onAdd\"],[[30,4,[\"showFootPrint\"]],[30,0,[\"parentSite\"]],[30,0,[\"sites\"]],[30,0,[\"hoveredSite\"]],[30,4,[\"fitBoundsByGeoJSON\"]]]]]],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showDrawSearch\"]],[[[1,\"        \"],[1,[28,[35,5],null,[[\"drawSearch\",\"forceSinglePoly\",\"geoJSON\",\"onGeoJSONUpdate\"],[true,[30,0,[\"forceSinglePoly\"]],[30,0,[\"geoJSON\"]],[30,0,[\"updateShape\"]]]]]],[1,\"\\n\"]],[]],null]],[3,4]]]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@mapTip\",\"map\",\"ops\",\"&default\"],false,[\"or\",\"if\",\"app-map\",\"yield\",\"site-list-layer\",\"app-map-editable\"]]",
    "moduleName": "mldp/components/site-list-map/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});