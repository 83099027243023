define("mldp/utils/handle-upload", ["exports", "rsvp", "mldp/utils/extname", "@mapbox/togeojson"], function (_exports, _rsvp, _extname, _togeojson) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = handleFile;
  let worker;
  const map = new Map();
  const WORKER_PATH = '/worker.js';
  const types = new Set(['Polygon', 'MultiPolygon']);

  const fixPoly = coordinates => {
    let i = -1;
    let len = coordinates.length;

    while (++i < len) {
      let ring = coordinates[i];
      let j = -1;
      let len2 = ring.length;

      while (++j < len2) {
        let coord = ring[j];

        if (coord.length > 2) {
          ring[j] = [coord[0], coord[1]];
        } else if (ring.length < 2) {
          throw new Error('invalid coordinates');
        }
      }
    }
  };

  const fixMultiPoly = coordinates => {
    let i = -1;
    let len = coordinates.length;

    while (++i < len) {
      fixPoly(coordinates[i]);
    }
  };

  const filter = data => {
    var newData = [];

    for (let feature of data.features) {
      if (!types.has(feature.geometry.type)) {
        continue;
      }

      if (feature.geometry.type === 'Polygon') {
        fixPoly(feature.geometry.coordinates);
      } else if (feature.geometry.type === 'MultiPolygon') {
        fixMultiPoly(feature.geometry.coordinates);
      }

      newData.push(feature);
    }

    data.features = newData;
    return data;
  };

  async function handleFile(file) {
    let {
      data,
      type
    } = await loadFile(file);

    if (type === '.zip') {
      return handleShp(data);
    }

    if (type !== '.gpx' && type !== '.kml') {
      throw new Error('unknown filetype');
    }

    const parser = new DOMParser();
    const xml = parser.parseFromString(data, 'text/xml');

    if (type === '.gpx') {
      return filter(_togeojson.default.gpx(xml));
    } else if (type === '.kml') {
      return handleKml(xml);
    }
  }

  function handleKml(xml) {
    const gj = _togeojson.default.kml(xml);

    const out = filter(gj);
    return out;
  }

  function handleShp(data) {
    if (!worker) {
      createWorker();
    }

    let id = Math.random().toString();
    let handle = {};
    let promise = new _rsvp.Promise(function (resolve, reject) {
      handle.resolve = resolve;
      handle.reject = reject;
    });
    map.set(id, handle);
    worker.postMessage({
      id: id,
      data: data,
      type: 'shp'
    }, [data]);
    return promise;
  }

  async function loadFile(file) {
    const type = (0, _extname.default)(file.name);
    let data;

    if (type === '.zip') {
      data = await readAsArrayBuffer(file);
    } else {
      data = await readAsText(file);
    }

    return {
      type,
      data
    };
  }

  function readAsText(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener('loadend', function () {
        resolve(reader.result);
      });
      reader.addEventListener('error', function (e) {
        reject(e);
      });
      reader.readAsText(file);
    });
  }

  function readAsArrayBuffer(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener('loadend', function () {
        resolve(reader.result);
      });
      reader.addEventListener('error', function (e) {
        reject(e);
      });
      reader.readAsArrayBuffer(file);
    });
  }

  function createWorker() {
    worker = new Worker(WORKER_PATH);
    worker.addEventListener('message', workerHandler);
    worker.addEventListener('error', handleError);
  }

  function handleError(e) {
    worker.removeEventListener('message', workerHandler);
    worker.removeEventListener('error', handleError);
    worker.terminate();
    worker = null;

    for (let [key, value] of map) {
      map.delete(key);
      value.reject(e);
    }
  }

  function workerHandler(e) {
    let id = e.data.id;
    let data = e.data.data;
    let ok = e.data.ok;

    if (!map.has(id)) {
      return;
    }

    let handle = map.get(id);
    map.delete(id);

    if (ok) {
      handle.resolve(data);
    } else {
      handle.reject(data);
    }
  }
});