define("mldp/components/recommendations/edit-card/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/component", "mldp/utils/total-from-gender"], function (_exports, _component, _object, _component2, _totalFromGender) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EditCard = (_dec = (0, _component.tagName)(""), _dec2 = (0, _object.computed)('recommendation.{buckTagsAny,doeTags}'), _dec(_class = classic(_class = (_class2 = class EditCard extends _component2.default {
    get totalCount() {
      let recommendation = this.recommendation;
      let gender = this.gender;
      return (0, _totalFromGender.default)(gender, recommendation);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "totalCount", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "totalCount"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = EditCard;
});