define("mldp/components/wizard-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pxHMiXLo",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"modal-header\"],[12],[1,\"\\n      \"],[10,\"button\"],[14,0,\"close\"],[14,\"aria-label\",\"Close\"],[15,\"onclick\",[30,2]],[14,4,\"button\"],[12],[1,\"\\n        \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"×\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"titleComponent\"]],[[[1,\"        \"],[1,[30,0,[\"titleComponent\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[8,[30,4],null,[[\"@onsubmit\",\"@close\",\"@Body\",\"@Footer\"],[[30,5],[30,2],[50,\"wizard-modal-body\",0,null,null],[50,\"wizard-modal-footer\",0,null,[[\"close\"],[[30,2]]]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@close\",\"@title\",\"@Content\",\"@onsubmit\"],false,[\"modal-window\",\"if\",\"component\"]]",
    "moduleName": "mldp/components/wizard-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});