define("mldp/services/ajax", ["exports", "@ember/object", "@ember/service", "ember-ajax/services/ajax"], function (_exports, _object, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let _AjaxService = (_dec = (0, _service.inject)('session'), _dec2 = (0, _object.computed)('session.data.authenticated.accessToken'), classic(_class = (_class2 = class _AjaxService extends _ajax.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _defineProperty(this, "namespace", '/api');
    }

    get headers() {
      let headers = {};
      let token = this.get('session.data.authenticated.accessToken');

      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }

      return headers;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "headers", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "headers"), _class2.prototype)), _class2)) || _class);

  _exports.default = _AjaxService;
});