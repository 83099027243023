define("mldp/components/permit/unit-property-item/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VsJRZQmR",
    "block": "[[[10,\"td\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"top-bar form-group clearfix\"],[12],[1,\"\\n    \"],[10,\"b\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@route\",\"@models\"],[\"account.property.unit\",[28,[37,1],[[30,0,[\"site\",\"siteType\"]],[30,0,[\"site\",\"id\"]],[30,0,[\"unit\",\"id\"]]],null]]],[[\"default\"],[[[[1,[30,0,[\"unit\",\"siteName\"]]]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"pull-right\"],[12],[1,\"\\n\"],[41,[30,0,[\"isSelected\"]],[[[1,\"        \"],[10,\"button\"],[14,0,\"btn btn-success\"],[15,\"onclick\",[28,[37,3],[[30,0],[30,0,[\"removeUnit\"]],[30,0,[\"unit\"]]],null]],[14,4,\"button\"],[12],[1,\"Remove\"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"button\"],[15,\"disabled\",[28,[37,4],[[28,[37,5],[[30,0,[\"unit\",\"eligibility\",\"isEligible\"]]],null],[28,[37,5],[[30,0,[\"siteEligibility\",\"isEligible\"]]],null]],null]],[14,0,\"btn btn-default\"],[15,\"onclick\",[28,[37,3],[[30,0],[30,0,[\"addUnit\"]],[30,0,[\"unit\"]]],null]],[14,4,\"button\"],[12],[1,\"Add\"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"unit\",\"eligibility\",\"isEligible\"]]],null],[[[1,\"    \"],[1,[28,[35,6],null,[[\"errors\"],[[30,0,[\"unit\",\"eligibility\",\"errors\"]]]]]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"link-to\",\"array\",\"if\",\"action\",\"or\",\"not\",\"eligibility-errors\"]]",
    "moduleName": "mldp/components/permit/unit-property-item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});