define("mldp/components/notices/reporting/completed-reports/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "u21T+ubG",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"You have reported deer harvest for all management units for this site for the \"],[1,[30,0,[\"currentSeason\",\"displayValue\"]]],[1,\".\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"ATTENTION: Make sure to report your habitat management practices if you are enrolled in the MLDP Conservation Option.\"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/notices/reporting/completed-reports/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});