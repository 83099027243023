define("mldp/components/practice/brush-chemical/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "029A3R+7",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"create\"]],[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[33,1,[\"number-field\"]],[\"acres\"],[[\"required\"],[true]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[33,1,[\"text-field\"]],[\"description\"],[[\"label\",\"required\"],[\"Chemical(s) used\",true]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"create\",\"f\"],[[30,0,[\"create\"]],[30,0,[\"f\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,3],null,[[\"model\",\"type\"],[[30,0,[\"model\"]],\"acres\"]]]],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"model\",\"type\",\"label\"],[[30,0,[\"model\"]],\"description\",\"Chemical(s) used\"]]]],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"create\",\"model\"],[[30,0,[\"create\"]],[30,0,[\"model\"]]]]]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\"],false,[\"if\",\"f\",\"practice/brush-primary-target\",\"practice/display-field\"]]",
    "moduleName": "mldp/components/practice/brush-chemical/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});