define("mldp/components/reporting-wizard/ineligible-pronghorn/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2SIyLVkn",
    "block": "[[[11,0],[24,0,\"reporting-wizard-step\"],[17,1],[12],[1,\"\\n  \"],[10,\"h4\"],[12],[1,\"\\n    \"],[1,[30,2,[\"siteName\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Not Available. \\n    Pronghorn harvest reporting is only available for sites that have applied for and received pronghorn permits for the current hunting season. \\n    Contact the Help Desk if you believe you are seeing this message in error.\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@site\"],false,[]]",
    "moduleName": "mldp/components/reporting-wizard/ineligible-pronghorn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});