define("mldp/models/habitat-project", ["exports", "@ember-data/model", "@ember/object"], function (_exports, _model, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let HabitatProjectModel = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('number'), _dec14 = (0, _model.attr)(), _dec15 = (0, _model.attr)(), _dec16 = (0, _model.attr)(), _dec17 = (0, _model.attr)(), _dec18 = (0, _model.attr)(), _dec19 = (0, _model.attr)(), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _object.computed)('shape'), classic(_class = (_class2 = class HabitatProjectModel extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "habitatProjectTypeId", _descriptor, this);

      _initializerDefineProperty(this, "siteId", _descriptor2, this);

      _initializerDefineProperty(this, "coordinatorUserId", _descriptor3, this);

      _initializerDefineProperty(this, "startDate", _descriptor4, this);

      _initializerDefineProperty(this, "endDate", _descriptor5, this);

      _initializerDefineProperty(this, "projectName", _descriptor6, this);

      _initializerDefineProperty(this, "projectNumber", _descriptor7, this);

      _initializerDefineProperty(this, "statedProjectAcreage", _descriptor8, this);

      _initializerDefineProperty(this, "contactName", _descriptor9, this);

      _initializerDefineProperty(this, "contactPhone", _descriptor10, this);

      _initializerDefineProperty(this, "contactEmail", _descriptor11, this);

      _initializerDefineProperty(this, "contactAffiliation", _descriptor12, this);

      _initializerDefineProperty(this, "totalCost", _descriptor13, this);

      _initializerDefineProperty(this, "comments", _descriptor14, this);

      _initializerDefineProperty(this, "site", _descriptor15, this);

      _initializerDefineProperty(this, "coordinatorUserId", _descriptor16, this);

      _initializerDefineProperty(this, "habitatProjectType", _descriptor17, this);

      _initializerDefineProperty(this, "habitatProjectComments", _descriptor18, this);

      _initializerDefineProperty(this, "habitatProjectXPractice", _descriptor19, this);

      _initializerDefineProperty(this, "shape", _descriptor20, this);
    }

    get geoJSON() {
      let shape = this.shape;

      if (!shape) {
        return;
      }

      try {
        return JSON.parse(shape);
      } catch (e) {// noop
      }
    }

    set geoJSON(value) {
      if (value) {
        this.set('shape', JSON.stringify(value));
      } else {
        this.set('shape', null);
      }

      return value;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "habitatProjectTypeId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "siteId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "coordinatorUserId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "startDate", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "endDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "projectName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "projectNumber", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "statedProjectAcreage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "contactName", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "contactPhone", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "contactEmail", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "contactAffiliation", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "totalCost", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "comments", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "site", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "coordinatorUserId", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class2.prototype, "habitatProjectType", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class2.prototype, "habitatProjectComments", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class2.prototype, "habitatProjectXPractice", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class2.prototype, "shape", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "geoJSON", [_dec21], Object.getOwnPropertyDescriptor(_class2.prototype, "geoJSON"), _class2.prototype)), _class2)) || _class);
  _exports.default = HabitatProjectModel;
});