define("mldp/models/invite-model", ["exports", "mldp/models/custom-model", "ember-cp-validations"], function (_exports, _customModel, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  const Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', true),
    lastName: (0, _emberCpValidations.validator)('presence', true),
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });

  let Model = classic(_class = class Model extends _customModel.default.extend(Validations) {}) || _class;

  window.__CLASSIC_OWN_CLASSES__.set(Model, true);

  Model.reopenClass({
    attrs: ['firstName', 'lastName', 'email']
  });
  var _default = Model;
  _exports.default = _default;
});