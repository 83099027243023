define("mldp/components/site-property-create/ownership-question-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bhD+60ek",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"radio-inline\"],[12],[1,\"\\n        \"],[10,\"input\"],[14,0,\"radio-inline small-form-control\"],[15,\"checked\",[28,[37,0],[[30,2,[\"isAgent\"]]],null]],[14,2,\"yes\"],[15,\"onclick\",[28,[37,1],[[30,0],[28,[37,2],[[30,2,[\"isAgent\"]]],null],false],null]],[14,4,\"radio\"],[12],[13],[1,\"Yes\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"radio-inline\"],[12],[1,\"\\n        \"],[10,\"input\"],[14,0,\"radio-inline small-form-control\"],[15,\"checked\",[30,2,[\"isAgent\"]]],[14,2,\"no\"],[15,\"onclick\",[28,[37,1],[[30,0],[28,[37,2],[[30,2,[\"isAgent\"]]],null],true],null]],[14,4,\"radio\"],[12],[13],[1,\"No\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@model\"],false,[\"not\",\"action\",\"mut\"]]",
    "moduleName": "mldp/components/site-property-create/ownership-question-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});