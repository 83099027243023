define("mldp/account/property/permit/amd-recommendations/view/controller", ["exports", "@ember/object", "@ember/service", "@ember/controller"], function (_exports, _object, _service, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ViewController = (_dec = (0, _service.inject)('notifications'), _dec2 = (0, _service.inject)('general-ajax'), _dec3 = (0, _object.computed)('model.permits'), classic(_class = (_class2 = class ViewController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _defineProperty(this, "declineComment", '');
    }

    get managementUnits() {
      let list = [];
      this.model.permit.amdPartitions.forEach(partition => {
        let foundSite = list.find(site => site.id === partition.siteId);

        if (foundSite) {
          if (foundSite.amdRecommendations) foundSite.amdRecommendations = foundSite.amdRecommendations.concat(partition.amdRecommendations);
        } else {
          let newSite = partition.site;
          newSite.amdRecommendations = partition.amdRecommendations || [];
          newSite.totalAcreage = partition.totalAcreage || 0;
          list.push(newSite);
        }
      }); // condense recommendations that are in the same group into one recommendation for the user to see.

      list.forEach(site => {
        site.recommendationGroups = [];
        site.amdRecommendations.forEach(rec => {
          let existingGroupRec = site.recommendationGroups.find(recommendationGroup => recommendationGroup.groupId === rec.groupId);

          if (existingGroupRec) {
            existingGroupRec.recommendedCount += rec.recommendedCount; // Because 0 recs are automatically owner accepted, we need to make sure that if ANY of the recs are not accepted or declined,
            // then they should appear to the user as not accepted or declined

            if (!rec.ownerAccepted) {
              existingGroupRec.ownerAccepted = false;
            }

            if (!rec.ownerDeclined) {
              existingGroupRec.ownerDeclined = false;
            }
          } else {
            site.recommendationGroups.push(Object.assign({}, rec));
          }
        });
      });
      return list;
    }

    async generateTagsUrl(recommendation) {
      let notifications = this.notifications;
      this.set('loadingTags', true);

      try {
        const response = await this.ajax.request(`/amdtags/${recommendation.groupId}/validate`);

        if (response.valid) {
          this.set('tagsUrl', `/amdtags/${recommendation.groupId}?auth=${response.token}`);
          this.set('recommendationToViewTags', recommendation);
        } else {
          notifications.error('Unable to Print Tags');
        }
      } catch (e) {
        notifications.addApiErrors(e);
      }

      this.set('loadingTags', false);
    }

    async accept() {
      let notifications = this.notifications;
      let adapter = this.store.adapterFor('amd-recommendation');
      return adapter.accept({
        params: {
          groupId: this.recommendationToAccept.groupId
        }
      }).then(() => {
        (0, _object.set)(this, 'recommendationToAccept.ownerAccepted', true);
        this.set('recommendationToAccept', undefined);
      }).catch(e => {
        notifications.addApiErrors(e);
      });
    }

    async decline() {
      let notifications = this.notifications;

      if (!this.declineComment.length) {
        return notifications.error('decline comment is required');
      }

      const record = await this.store.find('amd-recommendation', this.recommendationToDecline.id);
      record.set('ownerAgentComment', this.declineComment);
      let adapter = this.store.adapterFor('amd-recommendation');
      let response;

      try {
        response = await adapter.decline({
          params: {
            groupId: this.recommendationToDecline.groupId
          }
        });
      } catch (e) {
        notifications.addApiErrors(e);
      }

      (0, _object.set)(this, 'recommendationToDecline.ownerDeclined', true);
      this.set('recommendationToDecline', undefined);
      this.set('declineComment', '');
      return response;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "managementUnits", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "managementUnits"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "generateTagsUrl", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "generateTagsUrl"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "accept", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "accept"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "decline", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "decline"), _class2.prototype)), _class2)) || _class);
  _exports.default = ViewController;
});