define("mldp/components/harvest-calculator/recommended-harvest/component", ["exports", "@ember/component", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _component, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let HarvestCalculatorRecommendedHarvest = classic(_class = (_class2 = class HarvestCalculatorRecommendedHarvest extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "adultBucksRec", _descriptor3, this);

      _initializerDefineProperty(this, "fawnBucksRec", _descriptor4, this);

      _initializerDefineProperty(this, "adultDoesRec", _descriptor5, this);

      _initializerDefineProperty(this, "fawnDoesRec", _descriptor6, this);
    }

    init() {
      super.init(...arguments);
    }

    createHarvestRec() {
      if (!this.currentEnrollment || !this.currentEnrollment.isConservation) {
        this.notifications.error('No Current Conservation Option Enrollment Found');
      }

      if (this.unit) {
        const options = {
          queryParams: {
            antlerless: this.totalDoesRec,
            buck: this.totalBucksRec,
            unit: this.unit.id
          }
        };
        this.router.transitionTo('account.property.enrollment.recommendations', this.parentSiteType, this.unit.parentSiteId, this.currentEnrollment.id, options);
      }

      if (this.site) {
        const options = {
          queryParams: {
            antlerless: this.totalDoesRec,
            buck: this.totalBucksRec,
            unit: this.site.id
          }
        };
        this.router.transitionTo('account.property.enrollment.recommendations', this.site.siteType, this.site.id, this.currentEnrollment.id, options);
      }
    }

    get totalBucksRec() {
      return this.adultBucksRec + this.fawnBucksRec;
    }

    get totalBucksPcnt() {
      return Math.round(this.totalBucksRec / this.currentPopulation.totalBucks * 100) || 0;
    }

    get totalDoesRec() {
      return this.adultDoesRec + this.fawnDoesRec;
    }

    get totalDoesPcnt() {
      return Math.round(this.totalDoesRec / this.currentPopulation.totalDoes * 100) || 0;
    }

    get totalAdultRec() {
      return this.adultBucksRec + this.adultDoesRec;
    }

    get totalAdultPcnt() {
      return Math.round(this.totalAdultRec / this.currentPopulation.totalAdults * 100);
    }

    get totalFawnRec() {
      return this.fawnBucksRec + this.fawnDoesRec;
    }

    get totalFawnPcnt() {
      return Math.round(this.totalFawnRec / this.currentPopulation.fawns * 100) || 0;
    }

    get totalRec() {
      return this.totalAdultRec + this.totalFawnRec;
    }

    get totalPcnt() {
      return Math.round(this.totalRec / this.currentPopulation.totalDeer * 100) || 0;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "adultBucksRec", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "fawnBucksRec", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "adultDoesRec", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "fawnDoesRec", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "createHarvestRec", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "createHarvestRec"), _class2.prototype)), _class2)) || _class;

  _exports.default = HarvestCalculatorRecommendedHarvest;
});