define("mldp/components/payment-nav-link/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KWaU80ga",
    "block": "[[[11,\"li\"],[4,[38,0],[[30,0,[\"updateFeeInvoices\"]]],null],[12],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"navbar-link flex items-center text-white no-underline hover:no-underline focus:no-underline\"]],[[\"@route\"],[\"account.payment\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[14,0,\"inline-block relative\"],[12],[1,\"\\n        \"],[10,\"i\"],[14,0,\"material-icons mr-2\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"shopping_cart\"],[13],[1,\"\\n\"],[41,[28,[37,3],[[30,1],0],null],[[[1,\"          \"],[10,1],[14,0,\"absolute top-0 right-2 block h-4 w-4 ring-1 ring-white rounded-full bg-red-500\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n      My Payment Page\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@feeInvoices\"],false,[\"did-insert\",\"link-to\",\"if\",\"gt\"]]",
    "moduleName": "mldp/components/payment-nav-link/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});