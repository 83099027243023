define("mldp/account/property/unit/harvest/edit/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ocAvqtNk",
    "block": "[[[8,[39,0],null,[[\"@harvest\",\"@harvests\",\"@seasons\",\"@site\",\"@currentSeasonId\",\"@onSave\",\"@onDelete\",\"@onReset\"],[[30,0,[\"harvest\"]],[30,0,[\"harvests\"]],[30,0,[\"seasons\"]],[30,0,[\"site\"]],[30,0,[\"currentSeasonId\"]],[28,[37,1],[\"save\"],null],[28,[37,1],[\"delete\"],null],[28,[37,1],[\"reset\"],null]]],null]],[],false,[\"harvest-edit\",\"route-action\"]]",
    "moduleName": "mldp/account/property/unit/harvest/edit/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});