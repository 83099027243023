define("mldp/adapters/account", ["exports", "mldp/adapters/application", "mldp/utils/class-op"], function (_exports, _application, _classOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelName: 'account',
    findDetails: (0, _classOp.default)({
      path: 'details/{id}',
      single: true,
      returnModel: false
    })
  });

  _exports.default = _default;
});