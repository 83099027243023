define("mldp/utils/filter-enrollment-units", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = filterEnrollmentUnits;

  function filterEnrollmentUnits(enrollment, units) {
    let ids = enrollment.get('mUs') || [];
    ids = ids.map(id => String(id));
    return units.filter(unit => {
      let included = ids.includes(unit.get('id'));

      if (included) {
        if (unit.get('isPartOfPool')) {
          return unit.get('poolActive');
        }

        return true;
      }

      return false;
    });
  }
});