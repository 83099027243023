define("mldp/components/loading-spinner/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "aq+x9rCe",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"lds-\",[52,[30,0,[\"isSmall\"]],\"small\",\"default\"]]]],[12],[10,0],[12],[13],[10,0],[12],[13],[10,0],[12],[13],[10,0],[12],[13],[10,0],[12],[13],[10,0],[12],[13],[10,0],[12],[13],[10,0],[12],[13],[10,0],[12],[13],[10,0],[12],[13],[10,0],[12],[13],[10,0],[12],[13],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"if\"]]",
    "moduleName": "mldp/components/loading-spinner/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});