define("mldp/mirage/routes/fee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    server.get('http://localhost:5000/api/fee', (schema, _request) => {
      return schema.fees.all();
    });
  }
});