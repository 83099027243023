define("mldp/account/property/project/route", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AccountPropertyProjectRoute extends _route.default {}

  _exports.default = AccountPropertyProjectRoute;

  window.__CLASSIC_OWN_CLASSES__.set(AccountPropertyProjectRoute, true);
});