define("mldp/account/properties/route", ["exports", "@ember/routing/route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _route, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let PropertiesRoute = classic(_class = class PropertiesRoute extends _route.default.extend(_authenticatedRouteMixin.default) {}) || _class;

  _exports.default = PropertiesRoute;
});