define("mldp/components/info-tooltip/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let InfoTooltip = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class InfoTooltip extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "side", 'right');
    }

    init() {
      super.init(...arguments);

      if (Array.isArray(this.text)) {
        this.set('textAsArray', true);
      }
    }

  }) || _class) || _class);
  _exports.default = InfoTooltip;
});