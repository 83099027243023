define("mldp/serializers/application", ["exports", "@ember-data/serializer/json-api", "ember-inflector", "@ember/string"], function (_exports, _jsonApi, _emberInflector, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Application = classic(_class = class Application extends _jsonApi.default {
    // convert server types to singular and dasherized
    normalizeArrayResponse(store, primaryModelClass, payload) {
      payload.data = payload.data.map(item => {
        item.type = normalizeType(item.type);

        if (item.attributes.siteType) {
          item.attributes.siteType = item.attributes.siteType.toLowerCase();
        }

        if (item.relationships) {
          Object.keys(item.relationships).forEach(key => {
            let rel = item.relationships[key];

            if (!rel.data) {
              return;
            }

            rel.data.forEach(relItem => {
              if (relItem.type) {
                relItem.type = normalizeType(relItem.type);
              }
            });
          });
        }

        return item;
      });

      if (payload.included) {
        payload.included = payload.included.map(item => {
          item.type = normalizeType(item.type);
          return item;
        });
      }

      return payload;
    }

    normalizeSingleResponse(store, primaryModelClass, payload) {
      if (payload.data.type) {
        payload.data.type = normalizeType(payload.data.type);
      }

      if (payload.data.attributes.siteType) {
        payload.data.attributes.siteType = payload.data.attributes.siteType.toLowerCase();
      }

      if (payload.data.relationships) {
        Object.keys(payload.data.relationships).forEach(key => {
          let item = payload.data.relationships[key];

          if (!item.data) {
            return;
          }

          if (Array.isArray(item.data)) {
            item.data.forEach(datum => {
              if (datum.type) {
                datum.type = normalizeType(datum.type);
              }
            });
          } else if (item.data.type) {
            item.data.type = normalizeType(item.data.type);
          }
        });
      }

      if (payload.included) {
        payload.included = payload.included.map(item => {
          item.type = normalizeType(item.type);
          return item;
        });
      }

      return payload;
    } // from server: singularize


    modelNameFromPayloadKey(payloadKey) {
      let type = String((0, _emberInflector.singularize)(payloadKey));
      type = fixModelType(type);
      return (0, _string.dasherize)(type);
    } // To server: pluralize


    payloadKeyFromModelName(modelName) {
      return String((0, _emberInflector.pluralize)(modelName));
    } // don't dasherize the attribute keys


    keyForAttribute(attr) {
      return attr;
    }

  }) || _class;

  _exports.default = Application;

  function normalizeType(type) {
    return (0, _string.dasherize)(fixModelType((0, _emberInflector.singularize)(type)));
  }

  function fixModelType(type) {
    // Hack for this one, because multiple camelcase doesn't work right in dotnet
    // json api plugin
    switch (type) {
      // singularize fails
      case 'unassignedRtum':
        {
          return 'unassigned-rta';
        }

      case 'tlkFenceType':
        {
          return 'tlk-fencetype';
        }

      case 'coOp':
        {
          return 'coop';
        }

      case 'tlkSpecies':
        {
          return 'tlk-specie';
        }
    }

    return type;
  }
});