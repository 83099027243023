define("mldp/services/state", ["exports", "@ember/object", "@ember/service", "mldp/utils/date-quarter", "rsvp"], function (_exports, _object, _service, _dateQuarter, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let StateService = (_dec = (0, _service.inject)('store'), _dec2 = (0, _object.computed)('seasons'), _dec3 = (0, _object.computed)('seasons'), _dec4 = (0, _object.computed)('permitSeasons'), classic(_class = (_class2 = class StateService extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    get enrollmentSeasons() {
      let seasons = this.seasons;
      let date = new Date();
      let currentYear = date.getUTCFullYear();
      return seasons.filter(s => {
        return s.id <= String(currentYear + 1);
      });
    }

    get reportingSeasons() {
      let seasons = this.seasons;
      let date = new Date();
      let currentYear = date.getUTCFullYear();
      let currentQuarter = (0, _dateQuarter.default)(date);
      let maxYear = currentQuarter > 1 ? currentYear + 1 : currentYear;
      return seasons.filter(s => {
        return s.id <= String(maxYear);
      });
    }

    get permitSeasonOptions() {
      let seasons = this.permitSeasons;
      let date = new Date();
      let currentYear = date.getUTCFullYear();
      return seasons.filter(s => {
        return s.id <= String(currentYear + 2);
      });
    }

    async loadOutreachTlks() {
      if (this.outreachTlksLoaded) {
        return this.getProperties('outreachEventTypes', 'outreachFundings', 'outreachGroups', 'wmas');
      }

      let store = this.store;
      let {
        outreachEventTypes,
        outreachFundings,
        outreachGroups,
        wmas
      } = await (0, _rsvp.hash)({
        outreachEventTypes: store.findAll('tlk-outreach-event-type'),
        outreachFundings: store.findAll('tlk-outreach-funding'),
        outreachGroups: store.findAll('tlk-outreach-group'),
        wmas: store.findAll('tlk-wma')
      }); // Sort the values

      outreachEventTypes = outreachEventTypes.sortBy('displayValue');
      outreachFundings = outreachFundings.sortBy('displayValue');
      outreachGroups = outreachGroups.sortBy('displayValue');
      wmas = wmas.sortBy('displayValue');
      this.setProperties({
        outreachEventTypes,
        outreachFundings,
        outreachGroups,
        wmas,
        outreachTlksLoaded: true
      });
      return {
        outreachEventTypes,
        outreachFundings,
        outreachGroups,
        wmas
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "enrollmentSeasons", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "enrollmentSeasons"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "reportingSeasons", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "reportingSeasons"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "permitSeasonOptions", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "permitSeasonOptions"), _class2.prototype)), _class2)) || _class);
  _exports.default = StateService;
});