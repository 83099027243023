define("mldp/components/enrollment/enrolled-details/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "mldp/utils/filter-enrollment-units", "leaflet", "moment", "ember-concurrency-decorators", "mldp/utils/site-fee-invoices"], function (_exports, _component, _tracking, _object, _service, _filterEnrollmentUnits, _leaflet, _moment, _emberConcurrencyDecorators, _siteFeeInvoices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EnrolledDetails = (_dec = (0, _service.inject)('general-ajax'), (_class = class EnrolledDetails extends _component.default {
    get showApproval() {
      return this.biologistApproved === null;
    }

    get filteredUnits() {
      let filteredUnits = (0, _filterEnrollmentUnits.default)(this.args.enrollment, this.args.units);
      return filteredUnits.map(unit => {
        let feeInvoices = this.args.enrollment.feeInvoices.filter(invoice => invoice.primarySiteId === unit.parentSiteId);
        unit.paymentStatus = (0, _siteFeeInvoices.processSitePaymentStatus)(feeInvoices);
        return {
          id: unit.id,
          paymentStatus: unit.paymentStatus,
          siteName: unit.siteName,
          geoJSON: unit.geoJSON,
          model: unit
        };
      });
    }

    get showEditEnrollmentMessage() {
      return (!this.roleService.isUserInTpwd() && this.args.enrollment.primarySite.siteType === 'coOp' || !this.roleService.isUserInTpwd()) && this.args.enrollment.enrollmentTypeId === 2 && parseInt(this.state.currentSeason.id) === this.args.enrollment.deerSeason.id;
    }

    get sitePaymentStatus() {
      return (0, _siteFeeInvoices.processSitePaymentStatus)((0, _siteFeeInvoices.siteFeeInvoices)(this.args.enrollment, this.args.site));
    }

    get formattedFeeTotalAmount() {
      let feesTotal = this.args.enrollment.feeInvoices.filter(feeInvoice => feeInvoice.primarySiteId === Number(this.args.site.id)).reduce(function (accumulator, currentFeeInvoice) {
        return accumulator + currentFeeInvoice.totalAmount;
      }, 0);
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(feesTotal);
    }

    get displayPrintDetails() {
      return parseInt(this.args.enrollment.enrollmentTypeId) === 2 && this.args.enrollment.biologistApproved || parseInt(this.args.enrollment.enrollmentTypeId) === 1;
    }

    get displayPayFee() {
      return !this.roleService.isUserInTpwd() && this.args.enrollment.paymentStatus === 'Unpaid' && (this.args.enrollment.primarySite.siteType === 'coOp' && !this.isPoolManager || this.args.enrollment.primarySite.siteType !== 'coOp') && this.args.enrollment.biologistApproved;
    }

    get canWaiveFee() {
      return this.roleService.isAdmin && (this.args.enrollment.paymentStatus === 'Pending' || this.args.enrollment.paymentStatus === 'Unpaid' || this.filteredUnits.some(unit => unit.paymentStatus === 'Unpaid'));
    }

    get canReinstateFee() {
      return this.roleService.isAdmin && (this.args.enrollment.paymentStatus === 'Waived' || this.filteredUnits.some(unit => unit.paymentStatus === 'Waived'));
    }

    get canReinstateEnrollment() {
      return this.roleService.isAdmin;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajaxService", _descriptor, this);

      _initializerDefineProperty(this, "state", _descriptor2, this);

      _initializerDefineProperty(this, "roleService", _descriptor3, this);

      _initializerDefineProperty(this, "notifications", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "router", _descriptor6, this);

      _initializerDefineProperty(this, "isPoolManager", _descriptor7, this);

      _initializerDefineProperty(this, "feeInvoices", _descriptor8, this);

      _initializerDefineProperty(this, "mUs", _descriptor9, this);

      _initializerDefineProperty(this, "biologistApproved", _descriptor10, this);

      _initializerDefineProperty(this, "isConfirmWaive", _descriptor11, this);

      _initializerDefineProperty(this, "isConfirmReinstate", _descriptor12, this);

      _initializerDefineProperty(this, "selectedUnit", _descriptor13, this);

      _initializerDefineProperty(this, "isConfirmReinstateEnrollment", _descriptor14, this);

      _initializerDefineProperty(this, "isConfirmReinstateEnrollmentSuccess", _descriptor15, this);

      _initializerDefineProperty(this, "isViewCancelledEnrollments", _descriptor16, this);

      _initializerDefineProperty(this, "cancelledEnrollments", _descriptor17, this);

      _initializerDefineProperty(this, "isConfirmReinstateCoopMu", _descriptor18, this);

      _initializerDefineProperty(this, "isConfirmReinstateCoopMuSuccess", _descriptor19, this);

      _initializerDefineProperty(this, "leaveEnrollmentWarning", _descriptor20, this);

      _initializerDefineProperty(this, "leaveEnrollmentModel", _descriptor21, this);

      _initializerDefineProperty(this, "leaveEnrollmentid", _descriptor22, this);

      _initializerDefineProperty(this, "leaveEnrollmentType", _descriptor23, this);

      let currentSeason = this.state.currentSeason;
      let cancelationDate = this.args.enrollment.isConservation ? currentSeason.get('coEnrollmentWithdrawalDate') : currentSeason.get('hoEnrollmentWithdrawalDate'); // cancelation is only valid between May 1st and September 15th

      this.showCancelation = (0, _moment.default)(new Date()).isBefore(cancelationDate);
      this.bounds = _leaflet.default.latLngBounds();
      let users = this.args.site.get('associatedUsers');
      let poolManager = users.findBy('type', 'PoolManager');

      if (poolManager) {
        this.isPoolManager = this.roleService.isSameUser(poolManager.userId);
      }

      if (this.args.enrollment.primarySite.siteType === 'coOp' && this.roleService.isAdmin && !this.args.enrollment.archived) {
        this.fetchCancelledEnrollments.perform();
      }
    }

    geoJsonLayerAdd(e) {
      let map = e.target._map;
      let bounds = this.bounds;
      bounds.extend(e.target.getBounds());
      map.fitBounds(bounds);
    }

    setupReinstateCoopMu(unit) {
      this.selectedUnit = unit;
      this.isViewCancelledEnrollments = false;
      this.isConfirmReinstateCoopMu = true;
    }

    *openDetails() {
      const ajax = this.ajaxService;
      const enrollmentId = this.args.enrollment.id;
      const {
        token,
        valid
      } = yield ajax.request(`/enrollmentdetails/${enrollmentId}/validate`);

      if (token && valid) {
        let url = '/enrollmentdetails/' + enrollmentId + '?auth=' + token;
        window.open(url, '_blank');
      } else {
        this.notifications.error('You are not authorized to print enrollment details.');
      }
    }

    *waiveFee() {
      try {
        var _this$selectedUnit;

        const adapter = this.store.adapterFor('enrollment');
        yield adapter.waiveFee(this.args.enrollment.id, (_this$selectedUnit = this.selectedUnit) === null || _this$selectedUnit === void 0 ? void 0 : _this$selectedUnit.id);
        yield this.args.enrollment.reload();
        this.notifications.add('Successfully waived fees.');
        this.selectedUnit = null;
        this.isConfirmWaive = false;
      } catch (error) {
        var _error$payload, _error$payload$errors;

        this.selectedUnit = null;
        this.isConfirmWaive = false;

        if (error !== null && error !== void 0 && (_error$payload = error.payload) !== null && _error$payload !== void 0 && (_error$payload$errors = _error$payload.errors) !== null && _error$payload$errors !== void 0 && _error$payload$errors.length) {
          this.notifications.error(error.payload.errors[0].detail);
        } else {
          this.notifications.error('An error has occurred');
        }
      }
    }

    *reinstateFee() {
      try {
        var _this$selectedUnit2;

        const adapter = this.store.adapterFor('enrollment');
        yield adapter.reinstateFee(this.args.enrollment.id, (_this$selectedUnit2 = this.selectedUnit) === null || _this$selectedUnit2 === void 0 ? void 0 : _this$selectedUnit2.id);
        yield this.args.enrollment.reload();
        this.notifications.add('Successfully reinstated fees.');
        this.selectedUnit = null;
        this.isConfirmReinstate = false;
      } catch (error) {
        var _error$payload2, _error$payload2$error;

        this.selectedUnit = null;
        this.isConfirmReinstate = false;

        if (error !== null && error !== void 0 && (_error$payload2 = error.payload) !== null && _error$payload2 !== void 0 && (_error$payload2$error = _error$payload2.errors) !== null && _error$payload2$error !== void 0 && _error$payload2$error.length) {
          this.notifications.error(error.payload.errors[0].detail);
        } else {
          this.notifications.error('An error has occurred');
        }
      }
    }

    *reinstateEnrollment() {
      try {
        const adapter = this.store.adapterFor('enrollment');
        yield adapter.reinstateEnrollment(this.args.enrollment.id);
        yield this.args.enrollment.reload();
        this.selectedUnit = null;
        this.isConfirmReinstateEnrollment = false;
        this.isConfirmReinstateEnrollmentSuccess = true;
      } catch (error) {
        var _error$payload3, _error$payload3$error;

        this.selectedUnit = null;
        this.isConfirmReinstateEnrollment = false;
        this.isConfirmReinstateEnrollmentSuccess = true;

        if (error !== null && error !== void 0 && (_error$payload3 = error.payload) !== null && _error$payload3 !== void 0 && (_error$payload3$error = _error$payload3.errors) !== null && _error$payload3$error !== void 0 && _error$payload3$error.length) {
          this.notifications.error(error.payload.errors[0].detail);
        } else {
          this.notifications.error('An error has occurred');
        }
      }
    }

    *fetchCancelledEnrollments() {
      try {
        const adapter = this.store.adapterFor('enrollment');
        let cancelledEnrollments = yield adapter.cancelledEnrollments(this.args.enrollment.id);
        this.cancelledEnrollments = cancelledEnrollments;
      } catch (error) {
        var _error$payload4, _error$payload4$error;

        if (error !== null && error !== void 0 && (_error$payload4 = error.payload) !== null && _error$payload4 !== void 0 && (_error$payload4$error = _error$payload4.errors) !== null && _error$payload4$error !== void 0 && _error$payload4$error.length) {
          this.notifications.error(error.payload.errors[0].detail);
        } else {
          this.notifications.error('An error has occurred');
        }
      }
    }

    *restoreCoopMu() {
      try {
        const adapter = this.store.adapterFor('enrollment');
        yield adapter.restoreCoopMu(this.args.enrollment.id, this.selectedUnit);
        yield this.args.enrollment.reload();
        yield this.fetchCancelledEnrollments.perform();
        this.selectedUnit = null;
        this.isConfirmReinstateCoopMu = false;
        this.isConfirmReinstateCoopMuSuccess = true;
      } catch (error) {
        var _error$payload5, _error$payload5$error;

        this.selectedUnit = null;
        this.isConfirmReinstateCoopMu = false;

        if (error !== null && error !== void 0 && (_error$payload5 = error.payload) !== null && _error$payload5 !== void 0 && (_error$payload5$error = _error$payload5.errors) !== null && _error$payload5$error !== void 0 && _error$payload5$error.length) {
          this.notifications.error(error.payload.errors[0].detail);
        } else {
          this.notifications.error('An error has occurred');
        }
      }
    }

    openLeaveEnrollmentConfirmation(type, model, enrollment) {
      let warning = 'Are you sure this property should leave the enrollment? Cancellation of this enrollment will result in the invalidation of all tags issued and will withdraw this site from MLDP. Note that MLDP Fee payments are nonrefundable should you choose to withdraw from MLDP.<br><br>Click Yes to cancel the enrollment. Click No to exit this window without cancelling the enrollment.';
      this.leaveEnrollmentWarning = warning;
      this.leaveEnrollmentModel = model;
      this.leaveEnrollmentid = enrollment;
      this.leaveEnrollmentType = type;
    }

    *leaveEnrollment() {
      try {
        yield this.leaveEnrollmentModel.leaveEnrollment({
          params: {
            enrollmentid: this.args.enrollment.id
          }
        });

        if (this.leaveEnrollmentType === 'property') {
          this.leaveEnrollmentType = false;
          this.router.transitionTo('account.property.enrollments', this.leaveEnrollmentModel.id);
        } else {
          // type === 'management unit'
          yield this.args.enrollment.reload();

          if (this.roleService.isAdmin) {
            yield this.fetchCancelledEnrollments.perform();
          }

          this.leaveEnrollmentType = false;
        }

        this.leaveEnrollmentWarning = false;
        this.leaveEnrollmentModel = false;
        this.leaveEnrollmentid = false;
      } catch (error) {
        var _error$payload6, _error$payload6$error;

        this.leaveEnrollmentWarning = false;
        this.leaveEnrollmentModel = false;
        this.leaveEnrollmentid = false;

        if (error !== null && error !== void 0 && (_error$payload6 = error.payload) !== null && _error$payload6 !== void 0 && (_error$payload6$error = _error$payload6.errors) !== null && _error$payload6$error !== void 0 && _error$payload6$error.length) {
          this.notifications.error(error.payload.errors[0].detail);
        } else {
          this.notifications.error('An error has occurred');
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajaxService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "state", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "roleService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isPoolManager", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "feeInvoices", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.enrollment.feeInvoices;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "mUs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.enrollment.mUs;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "biologistApproved", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.enrollment.biologistApproved;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isConfirmWaive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isConfirmReinstate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "selectedUnit", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isConfirmReinstateEnrollment", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isConfirmReinstateEnrollmentSuccess", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "isViewCancelledEnrollments", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "cancelledEnrollments", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "isConfirmReinstateCoopMu", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "isConfirmReinstateCoopMuSuccess", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "leaveEnrollmentWarning", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "leaveEnrollmentModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "leaveEnrollmentid", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "leaveEnrollmentType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "geoJsonLayerAdd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "geoJsonLayerAdd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupReinstateCoopMu", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupReinstateCoopMu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openDetails", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "openDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "waiveFee", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "waiveFee"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reinstateFee", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "reinstateFee"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reinstateEnrollment", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "reinstateEnrollment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchCancelledEnrollments", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCancelledEnrollments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "restoreCoopMu", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "restoreCoopMu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openLeaveEnrollmentConfirmation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openLeaveEnrollmentConfirmation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "leaveEnrollment", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "leaveEnrollment"), _class.prototype)), _class));
  _exports.default = EnrolledDetails;
});