define("mldp/helpers/format-address", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatAddress = formatAddress;

  function formatAddress(_ref) {
    let [value] = _ref;
    var parts = [];
    parts = addToArrayIfTrue(parts, value.address1);
    parts = addToArrayIfTrue(parts, value.address2);
    parts = addToArrayIfTrue(parts, value.city);
    parts = addToArrayIfTrue(parts, value.state);
    parts = addToArrayIfTrue(parts, value.zipCode);
    return parts.join(', ');
  }

  function addToArrayIfTrue(array, value) {
    if (value) {
      array.push(value);
    }

    return array;
  }

  var _default = (0, _helper.helper)(formatAddress);

  _exports.default = _default;
});