define("mldp/account/property/permit/ph-recommendations/edit/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7+Dws9YQ",
    "block": "[[[1,[28,[35,0],[\"PH Recommendations\"],null]],[1,\"\\n\"],[1,[28,[35,1],null,[[\"permit\",\"permitType\",\"site\"],[[30,0,[\"model\",\"permit\"]],[30,0,[\"model\",\"permitType\"]],[30,0,[\"model\",\"site\"]]]]]],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"selectedLength\",\"season\",\"canEdit\",\"permit\",\"onSelectAll\",\"onUnselectAll\",\"applyLastSeasonRecs\",\"applyCalculatedAcreage\",\"submitRecs\"],[[30,0,[\"selectedLength\"]],[30,0,[\"model\",\"season\"]],[30,0,[\"model\",\"canEdit\"]],[30,0,[\"model\",\"permit\"]],[28,[37,3],[[30,0],\"selectAll\"],null],[28,[37,3],[[30,0],\"unselectAll\"],null],[28,[37,3],[[30,0],\"applyLastSeasonRecs\"],null],[28,[37,3],[[30,0],\"applyCalculatedAcreage\"],null],[28,[37,3],[[30,0],\"submitRecommendations\"],null]]]]],[1,\"\\n  \"],[10,0],[14,0,\"row-fluid clearfix form-group\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[[52,[30,0,[\"hideMap\"]],\"col-md-12\",\"col-md-7\"],\" padding-none\"]]],[12],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"permitApp\",\"partitions\",\"currentSeason\",\"site\",\"season\",\"canEdit\",\"herdUnitTally\",\"loadingTags\",\"onSelect\",\"onUnselect\"],[[30,0,[\"model\",\"permit\"]],[30,0,[\"partitions\"]],[30,0,[\"model\",\"currentSeason\"]],[30,0,[\"model\",\"site\"]],[30,0,[\"model\",\"season\"]],[30,0,[\"model\",\"canEdit\"]],[30,0,[\"model\",\"herdUnitTally\"]],[30,0,[\"loadingTags\"]],[28,[37,3],[[30,0],\"selectOne\"],null],[28,[37,3],[[30,0],\"unselectOne\"],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"col-md-5\"],[12],[1,\"\\n      \"],[1,[28,[35,6],null,[[\"units\"],[[30,0,[\"model\",\"units\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13]],[],false,[\"page-banner\",\"permit/ph-recommendations-edit/overview-card\",\"permit/ph-recommendations-edit/list-header\",\"action\",\"if\",\"permit/ph-recommendations-edit/list\",\"permit/ph-recommendation-view/map\"]]",
    "moduleName": "mldp/account/property/permit/ph-recommendations/edit/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});