define("mldp/components/recommendations/preview-column/component", ["exports", "@ember-decorators/component", "@ember/service", "@ember/component", "ember-concurrency", "@ember/object"], function (_exports, _component, _service, _component2, _emberConcurrency, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PreviewColumn = (_dec = (0, _component.tagName)(""), _dec2 = (0, _service.inject)('store'), _dec3 = (0, _emberConcurrency.task)(function* () {
    let {
      enrollment,
      siteId,
      type
    } = this;
    let isAggregate = type === 'aggregate';
    let recommendationOptions = {
      'filter[primarysite]': siteId,
      'filter[enrollment]': enrollment.id,
      'filter[season]': (0, _object.get)(enrollment, 'deerSeason.id')
    };
    let units = enrollment.mUs; // if (!isAggregate) {
    //   let adapter = this.storeService.adapterFor(type);
    //   units = yield adapter.getManagementUnits(siteId);
    // }

    let recommendations = yield this.storeService.query('recommendation', recommendationOptions);

    if (!isAggregate) {
      recommendations = recommendations.filter(recommendation => {
        return units.some(unit => {
          return unit === recommendation.siteId && recommendation.isValid;
        });
      });
    } else {
      recommendations = recommendations.filter(recommendation => {
        return siteId === recommendation.siteId.toString() && recommendation.isValid;
      });
    }

    let recommendationTotal = recommendations.reduce((result, recommendation) => {
      let buck = Number(recommendation.get('buckTagsAny'));
      let unbranchedBuck = Number(recommendation.get('buckTagsUnbranched'));
      let doe = Number(recommendation.get('doeTags'));
      result.buckTagsAny += isNaN(buck) ? 0 : buck;
      result.buckTagsUnbranched += isNaN(unbranchedBuck) ? 0 : unbranchedBuck;
      result.doeTags += isNaN(doe) ? 0 : doe;
      return result;
    }, {
      buckTagsAny: 0,
      buckTagsUnbranched: 0,
      doeTags: 0
    });
    recommendationTotal.grandTotal = recommendationTotal.buckTagsAny + recommendationTotal.buckTagsUnbranched + recommendationTotal.doeTags;
    this.set('grandTotal', recommendationTotal.grandTotal);
    this.set('recommendationTotalToolTip', [`Sum total of ${recommendations.length} recommendation(s)`, `for a total of ${units.length || 1} unit(s):`, `Any Buck: ${recommendationTotal.buckTagsAny}`, `Unbranched Buck: ${recommendationTotal.buckTagsUnbranched}`, `Doe: ${recommendationTotal.doeTags}`, `Total: ${recommendationTotal.grandTotal}`]);
  }), _dec(_class = classic(_class = (_class2 = class PreviewColumn extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "storeService", _descriptor, this);

      _initializerDefineProperty(this, "fetchRecommendations", _descriptor2, this);
    }

    init() {
      super.init(...arguments);
      this.fetchRecommendations.perform();
      this.set('enrollmentStatusToolTip', ['Number of enrolled MUs', 'grouped by recommendation status.', `Missing: ${this.enrollment.recommendationsMissing}`, `Pending: ${this.enrollment.recommendationsPending}`, `Completed: ${this.enrollment.recommendationsCompleted}`]);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "storeService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "fetchRecommendations", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = PreviewColumn;
});