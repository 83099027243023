define("mldp/account/outreaches/index/controller", ["exports", "@ember/service", "@ember/controller", "@ember/object", "mldp/account/outreaches/index/query-params"], function (_exports, _service, _controller, _object, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let IndexController = (_dec = (0, _service.inject)('state'), _dec2 = (0, _object.computed)(..._queryParams.keys), _dec3 = (0, _object.computed)('formData'), classic(_class = (_class2 = class IndexController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stateService", _descriptor, this);

      _defineProperty(this, "queryParams", ['page', ..._queryParams.keys]);

      _defineProperty(this, "name", undefined);

      _defineProperty(this, "tpwd", undefined);

      _defineProperty(this, "location", undefined);

      _defineProperty(this, "funding", undefined);

      _defineProperty(this, "group", undefined);

      _defineProperty(this, "wma", undefined);

      _defineProperty(this, "type", undefined);

      _defineProperty(this, "startDate", undefined);

      _defineProperty(this, "endDate", undefined);

      _defineProperty(this, "comment", undefined);

      _defineProperty(this, "county", undefined);

      _defineProperty(this, "biologistIdOther", undefined);

      _defineProperty(this, "userId", undefined);

      _defineProperty(this, "contactId", undefined);

      _defineProperty(this, "biologistId", undefined);

      _defineProperty(this, "page", 1);
    }

    get formData() {
      var obj = {};

      for (let param of _queryParams.keys) {
        obj[param] = this.get(param);
      }

      return _object.default.create(obj);
    }

    get showClearSearch() {
      let formData = this.formData;

      for (let param of _queryParams.keys) {
        let value = formData.get(param);

        if (value !== undefined) {
          return true;
        }
      }
    }

    updateForm(data) {
      this.set('searching', true);

      _queryParams.keys.forEach(param => {
        if (!data[param]) {
          data.set(param, undefined);
        }
      }); // Reset the page to 1


      data.set('page', 1); // Apply the data to QPs to update the model in the route

      this.setProperties(data);
    }

    clearSearch() {
      this.set('searching', true);

      _queryParams.keys.forEach(param => {
        this.set(param, undefined);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "formData", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "formData"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showClearSearch", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "showClearSearch"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateForm", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateForm"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clearSearch", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "clearSearch"), _class2.prototype)), _class2)) || _class);
  _exports.default = IndexController;
});