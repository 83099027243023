define("mldp/account/users/index/controller", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  const params = ['county', 'role', 'firstName', 'lastName', 'email'];
  let IndexController = (_dec = (0, _object.computed)(...params), _dec2 = (0, _object.computed)('formData'), classic(_class = (_class2 = class IndexController extends _controller.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ['page', 'county', 'role', 'firstName', 'lastName', 'email']);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "county", null);

      _defineProperty(this, "role", null);

      _defineProperty(this, "firstName", null);

      _defineProperty(this, "lastName", null);

      _defineProperty(this, "email", null);
    }

    get formData() {
      var obj = {};

      for (let param of params) {
        obj[param] = this.get(param);
      }

      return _object.default.create(obj);
    }

    get showClearSearch() {
      let formData = this.formData;

      for (let param of params) {
        let value = formData.get(param);

        if (value !== null) {
          return true;
        }
      }
    }

    updateForm(data) {
      for (let param of params) {
        if (!data[param]) {
          data.set(param, null);
        }
      }

      data.page = 1;
      this.setProperties(data);
    }

    continueMerge(from, into) {
      return this.transitionToRoute('account.users.merge', from.id, into.id);
    }

    closeMerge() {
      this.setProperties({
        // closes the modal
        mergeUser: false,
        mergeFrom: undefined,
        mergeInto: undefined
      });
    }

    clearSearch() {
      params.forEach(param => {
        this.set(param, null);
      });
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "formData", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "formData"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showClearSearch", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "showClearSearch"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateForm", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateForm"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "continueMerge", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "continueMerge"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "closeMerge", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "closeMerge"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clearSearch", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "clearSearch"), _class2.prototype)), _class2)) || _class);
  _exports.default = IndexController;
});