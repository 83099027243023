define("mldp/components/stepper/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OnSEOjcU",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"stepper-container\"],[12],[1,\"\\t\\n      \"],[10,0],[14,0,\"stepper-wrapper\"],[12],[1,\"\\t\\n        \"],[10,0],[14,0,\"arrow-steps clearfix\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"steps\"]]],null]],null],null,[[[1,\"            \"],[10,0],[15,0,[29,[\"step \",[52,[28,[37,3],[[30,0,[\"currentStep\"]],[30,3]],null],\"current\"]]]],[12],[1,\" \"],[10,1],[12],[1,\" \"],[1,[30,2]],[13],[1,\" \"],[13],[1,\"\\n\"]],[2,3]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"step\",\"index\"],false,[\"each\",\"-track-array\",\"if\",\"eq\"]]",
    "moduleName": "mldp/components/stepper/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});