define("mldp/services/session", ["exports", "@ember/object", "@ember/object/computed", "ember-simple-auth/services/session"], function (_exports, _object, _computed, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let _SessionService = (_dec = (0, _computed.alias)('data.authenticated.roles'), _dec2 = (0, _computed.alias)('tokenData.sub'), _dec3 = (0, _object.computed)('tokenData.IsSuperAdmin'), _dec4 = (0, _object.computed)('tokenData.UserId'), _dec5 = (0, _object.computed)('isAuthenticated'), _dec6 = (0, _object.computed)('isAuthenticated'), _dec7 = (0, _object.computed)('isAuthenticated'), _dec8 = (0, _object.computed)('isAuthenticated'), _dec9 = (0, _object.computed)('isAuthenticated'), _dec10 = (0, _object.computed)('data.authenticated.accessToken'), classic(_class = (_class2 = class _SessionService extends _session.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "roles", _descriptor, this);

      _initializerDefineProperty(this, "email", _descriptor2, this);
    }

    get isSuperAdmin() {
      const string = this.get('tokenData.IsSuperAdmin');
      return string === 'true' || false;
    }

    get userId() {
      let id = this.get('tokenData.UserId');
      return id && Number(id);
    }

    get isAdmin() {
      if (!this.isAuthenticated) {
        return false;
      }

      let roles = this.roles;
      return roles && roles.indexOf('Admin') > -1;
    }

    get isBiologist() {
      if (!this.isAuthenticated) {
        return false;
      }

      let roles = this.roles;
      return roles && roles.indexOf('Biologist') > -1;
    }

    get isLawEnforcement() {
      if (!this.isAuthenticated) {
        return false;
      }

      let roles = this.roles;
      return roles && roles.indexOf('Law Enforcement') > -1;
    }

    get isBiologistWithoutAdminOrSuper() {
      return this.isBiologist && !this.isAdmin && !this.isSupervisor;
    }

    get isSupervisor() {
      if (!this.isAuthenticated) {
        return false;
      }

      let roles = this.roles;
      return roles && roles.indexOf('Supervisor') > -1;
    }

    get tokenData() {
      let token = this.get('data.authenticated.accessToken');

      if (token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "roles", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "email", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isSuperAdmin", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "isSuperAdmin"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "userId", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "userId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isAdmin", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "isAdmin"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isBiologist", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "isBiologist"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isLawEnforcement", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "isLawEnforcement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isBiologistWithoutAdminOrSuper", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "isBiologistWithoutAdminOrSuper"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isSupervisor", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "isSupervisor"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "tokenData", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "tokenData"), _class2.prototype)), _class2)) || _class);

  _exports.default = _SessionService;
});