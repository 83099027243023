define("mldp/account/property/project/edit/route", ["exports", "@ember/routing/route", "rsvp", "@ember/service", "mldp/utils/area-in-acres"], function (_exports, _route, _rsvp, _service, _areaInAcres) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountPropertyProjectEditRoute = (_dec = (0, _service.inject)('state'), (_class = class AccountPropertyProjectEditRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);
    }

    async model() {
      let property = this.paramsFor('account.property');
      let {
        projectId
      } = this.paramsFor('account.property.project');
      return _rsvp.default.hash({
        project: this.store.find('habitat-project', projectId),
        property: this.store.findRecord('property', property.siteId)
      });
    }

    setupController(controller, resolved) {
      // why is the habitat project type different on the edit and index route
      resolved.project.habitatProjectTypeId = resolved.project.habitatProjectTypeId.toString();
      controller.setProperties(resolved);
      let geoJSON = resolved.project.get('geoJSON');

      if (geoJSON) {
        controller.set('editProjectArea', true);
        let area = (0, _areaInAcres.default)(geoJSON);
        controller.set('areaMapped', area);
      } else {
        controller.set('areaMapped', 0);
      }
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        //TODO: This doesn't see like the best way to solve this.
        controller.set('project', null);
        controller.set('property', null);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AccountPropertyProjectEditRoute;
});