define("mldp/adapters/document", ["exports", "mldp/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    async getDocTypes() {
      let ajax = this.ajaxService;
      let docTypes = await ajax.request('tlkdocumenttype', {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
      return docTypes.data.map(doc => {
        return {
          id: doc.id,
          displayValue: doc.attributes.displayValue,
          obsolete: doc.attributes.obsolete,
          sortOrder: doc.attributes.sortOrder
        };
      });
    }

  });

  _exports.default = _default;
});