define("mldp/account/consultation/index/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iubNx7da",
    "block": "[[[1,[28,[35,0],[\"Viewing Consultation\"],null]],[1,\"\\n\\n\"],[41,[30,0,[\"site\"]],[[[1,\"  \"],[46,[28,[37,3],[\"site-\",[30,0,[\"site\",\"siteType\"]],\"-card\"],null],null,[[\"site\"],[[30,0,[\"site\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,[28,[35,4],null,[[\"site\",\"counties\",\"otherStaff\",\"consultation\"],[[30,0,[\"site\"]],[30,0,[\"counties\"]],[30,0,[\"consultation\",\"selectedUsers\"]],[30,0,[\"consultation\"]]]]]],[1,\"\\n\"]],[],false,[\"page-banner\",\"if\",\"component\",\"concat\",\"consultation-view\"]]",
    "moduleName": "mldp/account/consultation/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});