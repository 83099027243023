define("mldp/account/property/invite/route", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadCrumb: {
      title: 'Invite',
      linkable: false
    }
  });

  _exports.default = _default;
});