define("mldp/mirage/factories/fee", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const feeDescriptions = ['MLDP Conservation Option', 'MLDP Harvest Option'];

  var _default = _emberCliMirage.Factory.extend({
    siteName() {
      return _faker.default.company.companyName();
    },

    siteCounty() {
      return _faker.default.address.county();
    },

    feeDescription() {
      return feeDescriptions[Math.floor(Math.random() * feeDescriptions.length)];
    },

    dueDate() {
      return _faker.default.date.future();
    },

    amount() {
      return _faker.default.finance.amount();
    },

    enrollment() {
      return {
        deerSeason: '2021-22',
        mUs: [1, 2],
        id: _faker.default.random.hexaDecimal()
      };
    },

    siteType: 'Property'
  });

  _exports.default = _default;
});