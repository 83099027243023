define("mldp/components/simple-notification/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "z92pOCfi",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,0,[\"model\",\"notificationTitle\"]]],null]],[1,\"\\n\\n  \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n\"],[41,[30,0,[\"siteName\"]],[[[1,\"      \"],[10,\"header\"],[14,0,\"agreement-header\"],[12],[1,\"\\n        \"],[10,\"h3\"],[12],[1,[30,0,[\"siteName\"]]],[13],[1,\"\\n        \"],[10,2],[12],[1,\"County: \"],[1,[30,0,[\"countyName\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"header\"],[14,0,\"agreement-header\"],[12],[1,\"\\n        \"],[10,\"h3\"],[12],[1,[30,0,[\"model\",\"notificationTitle\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[30,0,[\"model\",\"notificationDetail\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"enrollmentNotification\"]],[[[1,\"      \"],[8,[39,2],[[24,0,\"btn btn-success\"]],[[\"@route\",\"@models\"],[\"account.property.enrollments\",[28,[37,3],[[30,0,[\"siteType\"]],[30,0,[\"siteId\"]]],null]]],[[\"default\"],[[[[1,\"View Enrollment\"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"siteName\"]],[[[1,\"      \"],[8,[39,2],[[24,0,\"btn btn-success\"]],[[\"@route\",\"@models\"],[\"account.property\",[28,[37,3],[[30,0,[\"siteType\"]],[30,0,[\"siteId\"]]],null]]],[[\"default\"],[[[[1,[30,0,[\"siteName\"]]]],[]]]]],[1,\"\\n    \"]],[]],null]],[]]],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"default\",\"pending\",\"class\",\"action\"],[\"Acknowledge Alert\",\"Saving...\",\"btn btn-primary pull-right\",[28,[37,5],[[30,0],\"complete\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"page-banner\",\"if\",\"link-to\",\"array\",\"async-button\",\"action\"]]",
    "moduleName": "mldp/components/simple-notification/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});