define("mldp/components/reporting-wizard/ineligible-pronghorn/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let IneligiblePronghorn = (_dec = (0, _component.tagName)(''), classic(_class = _dec(_class = class IneligiblePronghorn extends _component2.default {}) || _class) || _class);
  _exports.default = IneligiblePronghorn;
});