define("mldp/templates/head", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mRjrn4Q+",
    "block": "[[[10,\"title\"],[12],[1,[30,0,[\"model\",\"title\"]]],[13]],[],false,[]]",
    "moduleName": "mldp/templates/head.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});