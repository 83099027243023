define("mldp/account/property/unit/practices/index/route", ["exports", "rsvp", "@ember/routing/route"], function (_exports, _rsvp, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let IndexRoute = classic(_class = class IndexRoute extends _route.default {
    model() {
      let unit = this.modelFor('account.property.unit');
      let property = this.modelFor('account.property');
      return (0, _rsvp.hash)({
        unit,
        property,
        practices: unit.findPractices()
      });
    }

    setupController(controller, resolved) {
      controller.set('unit', resolved.unit);
      controller.set('property', resolved.property.property);
      resolved.practices = resolved.practices.sortBy('deerSeasonId').reverse();
      controller.set('practices', resolved.practices);
    }

  }) || _class;

  _exports.default = IndexRoute;
});