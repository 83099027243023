define("mldp/account/property/permit/ph-recommendations/edit/controller", ["exports", "@ember/service", "@ember/controller", "@ember/object"], function (_exports, _service, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EditController = (_dec = (0, _service.inject)('notifications'), _dec2 = (0, _service.inject)('store'), _dec3 = (0, _object.computed)('partitions.@each.selected'), _dec4 = (0, _object.computed)('model.permit.phPartitions'), classic(_class = (_class2 = class EditController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);
    }

    get selectedLength() {
      let partitions = this.partitions;
      return partitions.reduce((acc, partition) => {
        if (partition.selected) return acc + 1;
        return acc;
      }, 0);
    }

    get partitions() {
      let phPartitions = this.model.permit.phPartitions;
      phPartitions = phPartitions.map(part => _object.default.create(part));
      phPartitions.forEach(part => {
        part.newPhRecommendations = [];
        part.totalBuckThisHu = part.phRecommendations.reduce((acc, cur) => acc + cur.recommendedBucks, 0);
        part.totalDoeThisHu = part.phRecommendations.reduce((acc, cur) => acc + cur.recommendedDoes, 0);
        part.phRecommendations = part.phRecommendations.map(part => _object.default.create(part));
      });
      return phPartitions;
    }

    selectAll() {
      this.partitions.forEach(partition => {
        partition.set('selected', true);
      });
    }

    unselectAll() {
      this.partitions.forEach(partition => {
        partition.set('selected', false);
      });
    }

    selectOne(partition) {
      partition.set('selected', true);
    }

    unselectOne(partition) {
      partition.set('selected', false);
    }

    applyCalculatedAcreage() {
      const store = this.storeService;
      this.partitions.forEach(partition => {
        if (partition.selected) {
          let recommendedBuck;

          if (partition.calculatedBucks > 1) {
            recommendedBuck = Math.round(partition.calculatedBucks);
          } else {
            recommendedBuck = Math.floor(partition.calculatedBucks);
          }

          const newRec = store.createRecord('ph-recommendation', {
            phPartitionId: partition.id,
            seasonId: this.currentSeason.id,
            recommendedBucks: recommendedBuck,
            recommendedDoes: Number(partition.calculatedDoes)
          });
          partition.newPhRecommendations.pushObject(newRec);
        }
      });
    }

    async applyLastSeasonRecs() {
      this.set('loading', true);
      const lastSeasonQuery = await this.store.query('ph-permit-app', {
        'filter[siteid]': this.model.permit.primarySiteId,
        'filter[seasonid]': this.model.permit.seasonId - 1
      });
      const lastSeasonPermit = lastSeasonQuery.get('firstObject');

      if (!lastSeasonPermit) {
        this.set('loading', false);
        return this.notifications.error('Could not locate previous season permit');
      }

      this.partitions.forEach(partition => {
        const matchedPartition = lastSeasonPermit.phPartitions.find(part => part.herdUnit === partition.herdUnit);

        if (matchedPartition) {
          matchedPartition.phRecommendations.forEach(lastSeasonRec => {
            if (!lastSeasonRec.invalidated && !lastSeasonRec.archived) {
              const newRec = this.store.createRecord('ph-recommendation', {
                phPartitionId: partition.id,
                seasonId: this.model.permit.seasonId,
                recommendedBucks: lastSeasonRec.recommendedBucks,
                recommendedDoes: lastSeasonRec.recommendedDoes
              });
              partition.newPhRecommendations.pushObject(newRec);
            }
          });
        }
      });
      this.set('loading', false);
    }

    async submitRecommendations() {
      let adapter = this.store.adapterFor('ph-recommendation');
      let data = [];
      this.partitions.forEach(partition => {
        if (partition.selected) {
          return partition.newPhRecommendations.forEach(rec => data.push(rec));
        }

        return;
      });
      await adapter.postCollection({
        data
      });
      this.send('refreshModel');
      return this.notifications.add('Submitted recommendations');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "selectedLength", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "selectedLength"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "partitions", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "partitions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectAll", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "selectAll"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "unselectAll", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "unselectAll"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectOne", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "selectOne"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "unselectOne", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "unselectOne"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "applyCalculatedAcreage", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "applyCalculatedAcreage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "applyLastSeasonRecs", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "applyLastSeasonRecs"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "submitRecommendations", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "submitRecommendations"), _class2.prototype)), _class2)) || _class);
  _exports.default = EditController;
});