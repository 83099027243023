define("mldp/components/enrollment/enrolled-details/confirm-waive-reinstate/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FzNvgxxG",
    "block": "[[[41,[28,[37,1],[[30,1],\"waive\"],null],[[[1,\"  \"],[10,0],[12],[1,\"\\n    Are you sure you want to waive these fees?\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"reinstate\"],null],[[[1,\"  \"],[10,0],[12],[1,\"\\n    Are you sure you want to reinstate these fees?\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"reinstate-enrollment\"],null],[[[1,\"  \"],[10,2],[12],[1,\"\\n    Note the following information before proceeding with reinstatement of this enrollment:\\n  \"],[13],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"px-4\"],[12],[1,\"\\n    \"],[10,\"li\"],[14,0,\"list-disc mb-4\"],[12],[1,\"\\n      Unpaid fees must be remitted before the site can accept harvest recommendations or print tags.\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[14,0,\"list-disc mb-4\"],[12],[1,\"\\n      You must contact the member to inform them the enrollment has been reinstated. No notification is being sent by the LMA system.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"reinstate-enrollment-success\"],null],[[[1,\"  Success. The enrollment has been reinstated. Provide notice to the cooperator and include a reminder to make payment if the fee is unpaid.\\n\"]],[]],[[[1,\"  \"],[10,0],[12],[1,\"\\n    Are you sure?\\n  \"],[13],[1,\"\\n\"]],[]]]],[]]]],[]]]],[]]]],[\"@actionTaken\"],false,[\"if\",\"eq\"]]",
    "moduleName": "mldp/components/enrollment/enrolled-details/confirm-waive-reinstate/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});