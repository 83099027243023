define("mldp/account/property/units/create/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QgXKpNVZ",
    "block": "[[[41,[30,0,[\"property\",\"newProperty\"]],[[[1,\"  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@currentStep\",\"@steps\"],[2,[30,0,[\"steps\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,2],[[30,0,[\"property\",\"newProperty\"]]],null],[[[1,\"  \"],[46,[28,[37,4],[\"site-\",[30,0,[\"property\",\"siteType\"]],\"-card\"],null],null,[[\"site\"],[[30,0,[\"property\"]]]],null],[1,\"\\n  \"],[1,[28,[35,5],null,[[\"model\",\"fenceTypes\",\"property\",\"units\",\"allowAddMore\",\"onAddMore\",\"onNext\",\"onClear\"],[[30,0,[\"unit\"]],[30,0,[\"fenceTypes\"]],[30,0,[\"property\"]],[30,0,[\"units\"]],[28,[37,2],[[30,0,[\"property\",\"singleMgtUnit\"]]],null],[28,[37,6],[\"next\",true],null],[28,[37,6],[\"next\",false],null],[28,[37,6],[\"reset\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,7],null,[[\"model\",\"fenceTypes\",\"property\",\"units\",\"allowAddMore\",\"onAddMore\",\"onNext\",\"onFinish\",\"onClear\"],[[30,0,[\"unit\"]],[30,0,[\"fenceTypes\"]],[30,0,[\"property\"]],[30,0,[\"units\"]],[28,[37,2],[[30,0,[\"property\",\"singleMgtUnit\"]]],null],[28,[37,6],[\"next\",true],null],[28,[37,6],[\"next\",false],null],[28,[37,6],[\"finish\"],null],[28,[37,6],[\"reset\"],null]]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"stepper\",\"not\",\"component\",\"concat\",\"managementunit-edit\",\"route-action\",\"managementunit-new-property\"]]",
    "moduleName": "mldp/account/property/units/create/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});