define("mldp/components/permit/lease-list/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3EDqBghn",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[6,[39,0],[[30,0,[\"data\"]]],null,[[\"default\"],[[[[1,\"    \"],[10,2],[12],[1,\"If you receive a harvest recommendation, would you like to be included on TPWD’s Lease List? If you choose Yes, TPWD will post your name, phone number, and the primary county of your property on our public website to help potential hunters contact you. Choosing No will not impact your application.\"],[13],[1,\"\\n\"],[6,[30,2,[\"radio-group\"]],[\"leaseListOptIn\"],[[\"options\",\"label\"],[[30,0,[\"leaseListOptions\"]],\"Would you like to opt in?\"]],[[\"default\"],[[[[1,\"      \"],[8,[30,3,[\"radio-field\"]],null,[[\"@fieldClasses\",\"@labelClasses\",\"@inputClasses\"],[\"radio-inline\",\"radio-inline\",\"small-form-control\"]],null],[1,\"\\n\"]],[3]]]]]],[2]]]]],[13]],[\"&attrs\",\"f\",\"controls\"],false,[\"form-for\"]]",
    "moduleName": "mldp/components/permit/lease-list/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});