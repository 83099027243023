define("mldp/application/route", ["exports", "@ember/service", "@ember/routing/route", "@ember/object", "rsvp", "mldp/config/environment", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _service, _route, _object, _rsvp, _environment, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const env = _environment.default.environment;
  const STATUS_URL = `https://storage.googleapis.com/lma-webdata-${env}/status.json?_bust=`;

  var _default = _route.default.extend(_applicationRouteMixin.default, {
    router: (0, _service.inject)('router'),
    session: (0, _service.inject)('session'),
    role: (0, _service.inject)('role-service'),
    userIdle: (0, _service.inject)('user-idle'),
    state: (0, _service.inject)('state'),
    ajax: (0, _service.inject)('ajax'),
    notifications: (0, _service.inject)('notifications'),
    moment: (0, _service.inject)('moment'),
    routeAfterAuthentication: 'account',

    beforeModel() {
      this.moment.setTimeZone('US/Central');
      return this._super(...arguments);
    },

    model() {
      let ajax = this.ajax;
      let isAuthenticated = this.get('session.isAuthenticated');
      let statusUrl = STATUS_URL + Date.now();
      let hash = {
        status: ajax.request(statusUrl, {
          contentType: 'application/json',
          accept: 'application/json',
          dataType: 'json'
        }).catch(() => _rsvp.default.resolve())
      };

      if (isAuthenticated) {
        let adapter = this.store.adapterFor('user');
        hash.profile = adapter.getAccountProfile().catch(() => _rsvp.default.resolve());
      }

      return _rsvp.default.hash(hash);
    },

    afterModel(resolved) {
      this.set('session.profile', resolved.profile);
      this.fetchFeeInvoices();
    },

    setupController(controller, resolved) {
      this._super(...arguments);

      let ajax = this.ajax;
      let state = this.state;
      let maintenance = (0, _object.get)(resolved, 'status.maintenance');
      let {
        skipMaintenance
      } = this.paramsFor(this.routeName); // Use queryparam `skipMaintenance=true` to avoid the check

      if (!skipMaintenance) {
        let timer = setInterval(() => {
          let statusUrl = STATUS_URL + Date.now();
          ajax.request(statusUrl, {
            contentType: 'application/json',
            accept: 'application/json',
            dataType: 'json'
          }).then(result => {
            let maintenance = result === null || result === void 0 ? void 0 : result.maintenance;
            let maintenanceEnabled = maintenance === null || maintenance === void 0 ? void 0 : maintenance.enabled;

            if (state.isDestroyed || state.isDestroying) {
              clearInterval(timer);
              return;
            }

            controller.set('maintenance', maintenance);

            if (this.router.currentRouteName === 'maintenance' && !maintenanceEnabled) {
              this.transitionTo('index');
            } else if (maintenanceEnabled) {
              this.transitionTo('maintenance');
            }
          });
        }, 1000 * 60);
        controller.set('timer', timer);

        if (maintenance !== null && maintenance !== void 0 && maintenance.enabled) {
          controller.set('maintenance', maintenance);
          this.transitionTo('maintenance');
        }
      }
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        let timer = controller.timer;
        clearInterval(timer);
      }
    },

    init() {
      this._super(...arguments); // trigger service init, since services are lazy


      this.userIdle;
    },

    async fetchFeeInvoices() {
      let adapter = this.store.adapterFor('feecart');
      let {
        pending,
        inCart
      } = await adapter.invoiceCounts();
      this.controller.set('feeInvoices', pending + inCart);
    },

    actions: {
      logout() {
        let session = this.session;
        return session.invalidate();
      },

      openUserDetails(user, site) {
        this.controller.set('selectedUser', user); //TODO: refactor this so logic is clearer

        let canEditSelectedUserType = user.type === 'Agent' || user.type === 'Owner';
        let canEditSelectedUser;

        if (!site) {
          canEditSelectedUser = false;
        } else if (canEditSelectedUserType && (this.role.isAdmin || this.role.isWPO)) {
          canEditSelectedUser = true;
        } else if (canEditSelectedUserType && site) {
          let associated = this.role.associated(site);
          const isAssociated = associated.any(user => user.email === this.session.email);
          const roleIsAllowed = ['Admin', 'Biologist'].any(role => this.role.isInRole(role));
          canEditSelectedUser = isAssociated && roleIsAllowed;

          if (!canEditSelectedUser && this.role.isInRole('Supervisor')) {
            let subordinates = this.state.subordinates;

            if (subordinates) {
              canEditSelectedUser = site.associatedUsers.any(user => subordinates.includes(user.userId));
            }
          }
        }

        this.controller.set('canEditSelectedUser', canEditSelectedUser);
        this.controller.set('selectedSite', site);
      },

      transitionTo() {
        return this.transitionTo(...arguments);
      },

      error(e, transition) {
        console.log('application-error', e);

        if (e.errors) {
          let error = e.errors[0];

          if (error.status === 0) {//showErrorDialog('Sorry, but we\'re having trouble connecting to the server. This problem is usually the result of a broken Internet connection. You can try refreshing this page.');
          } else if (error.status === 403) {//go to some default route
          } else if (error.status === 401) {
            return this.transitionTo('login'); //TODO: find a better way to handle this exception
          } else if (error.status === 422 && error.detail === 'Shape area exceeds maximum allowed') {
            this.notifications.error(error.detail);
            return false;
          } else if (error.status === 404) {
            this.transitionTo('four-oh-four', {
              path: transition.intent.url
            });
            return false;
          }
        }

        if (e.message) {
          // Remove public reference to ED
          if (typeof e.message === 'string') {
            e.message = e.message.replace('Ember Data', 'API');
          }

          this.notifications.error(e.message);
          return false;
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});