define("mldp/components/survey-season/header/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PiOUgtMN",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,\"header\"],[14,0,\"clearfix\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"inline\"],[12],[1,\"\\n      Deer Survey Details\\n      \"],[10,0],[14,0,\"lower-details\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"season\"],[12],[1,[30,2]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"pull-right\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"list-toolbar clearfix pull-right\"],[12],[1,\"\\n\"],[41,[28,[37,1],[\"edit deer population data in properties\",[30,3]],null],[[[41,[30,4],[[[1,\"            \"],[8,[39,2],[[24,0,\"btn btn-primary\"]],[[\"@route\",\"@models\"],[\"account.property.unit.populationestimate\",[28,[37,3],[[30,5],[30,6],[30,4],[30,7]],null]]],[[\"default\"],[[[[1,\"\\n              Population Estimator\\n            \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[39,2],[[24,0,\"btn btn-primary\"]],[[\"@route\",\"@models\"],[\"account.property.populationestimate\",[28,[37,3],[[30,5],[30,6],[30,7]],null]]],[[\"default\"],[[[[1,\"\\n              Population Estimator\\n            \"]],[]]]]],[1,\"\\n\"]],[]]]],[]],null],[41,[28,[37,1],[\"enter deer population survey in properties\",[30,3]],null],[[[1,\"          \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,4],[\"click\",[30,8]],null],[12],[1,\"\\n            Export to Excel\\n          \"],[13],[1,\"\\n          \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,4],[\"click\",[30,9]],null],[12],[1,\"\\n            Enter Survey Data\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@seasonName\",\"@site\",\"@unitId\",\"@siteType\",\"@siteId\",\"@seasonId\",\"@downLoadExcelReport\",\"@openModal\"],false,[\"if\",\"can\",\"link-to\",\"array\",\"on\"]]",
    "moduleName": "mldp/components/survey-season/header/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});