define("mldp/components/permit/reporting-status/amd/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Amd = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class Amd extends _component2.default {
    init() {
      super.init(...arguments);
      let submitted,
          onTime = false;

      if (this.harvests.length) {
        submitted = this.harvests[0].submitted;
        onTime = this.harvests[0].onTime;
      }

      this.setProperties({
        submitted,
        onTime
      });
    }

  }) || _class) || _class);
  _exports.default = Amd;
});