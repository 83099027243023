define("mldp/services/general-ajax", ["exports", "mldp/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let GeneralAjaxService = classic(_class = class GeneralAjaxService extends _ajax.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "namespace", '/');
    }

  }) || _class;

  _exports.default = GeneralAjaxService;
});