define("mldp/components/app-map-editable-pm/component", ["exports", "@ember-decorators/object", "leaflet", "@ember/object", "ember-leaflet/components/base-layer", "@ember/runloop"], function (_exports, _object, _leaflet, _object2, _baseLayer, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let AppMapEditablePm = (_dec = (0, _object.observes)('geoJSON'), classic(_class = (_class2 = class AppMapEditablePm extends _baseLayer.default {
    // didReceiveAttrs(){
    //   var map = this.parentComponent._layer;
    //   var geoJSON = this.geoJSON;
    //   var editableLayers = this.set('editableLayers', new L.FeatureGroup());
    //   editableLayers.addLayer(L.geoJSON(geoJSON))
    //   map.addLayer(editableLayers);
    // },
    didInsertParent() {
      _runloop.run.scheduleOnce('afterRender', () => {
        let map = this.parentComponent._layer;
        const geoJSON = this.geoJSON;
        const staticGeoJSON = this.staticGeoJSON;
        this._layer = this.createLayer(geoJSON);

        this._addObservers();

        this._addEventListeners();

        if (map) {
          map.addLayer(this._layer);
        }

        if (geoJSON) {
          map.pm.toggleGlobalEditMode();

          this._layer.pm.enable({
            allowSelfIntersection: false
          });

          this._layer.on('pm:edit', () => this.drawEdited.call(this));

          map.fitBounds(this._layer.getBounds());
        }

        if (staticGeoJSON) {
          const staticLayer = _leaflet.default.geoJson(staticGeoJSON, {
            style: () => {
              return {
                color: 'orange'
              };
            }
          });

          delete staticLayer.pm;
          Object.keys(staticLayer._layers).forEach(key => {
            delete staticLayer._layers[key].pm;
          });
          map.addLayer(staticLayer);

          if (!geoJSON) {
            map.fitBounds(staticLayer.getBounds());
          }
        }

        this.didCreateLayer();
      });
    } // draw Edited is defined here sothat if can be called in didInsertParent


    drawEdited() {
      let onGeoJSONUpdate = this.onGeoJSONUpdate;
      let editableLayers = this.editableLayers;
      onGeoJSONUpdate(editableLayers.toGeoJSON());
    }

    shapeObserver() {
      var map = this.parentComponent._layer;
      var editableLayers = this.editableLayers;
      var geoJSON = this.geoJSON;

      if (JSON.stringify(editableLayers.toGeoJSON()) === JSON.stringify(geoJSON)) {
        return;
      }

      editableLayers.clearLayers();

      if (geoJSON) {
        let valLayer = _leaflet.default.geoJSON(geoJSON, {
          style() {
            return {
              color: '#ff0000'
            };
          }

        });

        valLayer.eachLayer(function (layer) {
          if (layer.eachLayer) {
            layer.eachLayer(function (alayer) {
              editableLayers.addLayer(alayer);
            });
          } else {
            editableLayers.addLayer(layer);
          }
        });
        map.fitBounds(editableLayers.getBounds());
      }
    }

    willDestroyParent() {
      let map = this.parentComponent._layer;
      let {
        drawCreated,
        drawEdited,
        polygonCut,
        drawDeleted,
        drawBoundary,
        editBoundary,
        deleteBoundary
      } = this;
      map.off('pm:create', drawCreated);
      map.off('pm:edit', drawEdited);
      map.off('pm:cut', polygonCut);
      map.off('pm:remove', drawDeleted);
      this.eventsBus.off('appMapEditable:drawBoundary', this, drawBoundary);
      this.eventsBus.off('appMapEditable:editBoundary', this, editBoundary);
      this.eventsBus.off('appMapEditable:deleteBoundary', this, deleteBoundary);
    }

    createLayer(geoJSON) {
      var component = this;
      var map = this.parentComponent._layer;
      let editableLayers = this.set('editableLayers', new _leaflet.default.FeatureGroup());

      if (geoJSON && geoJSON.type !== 'MultiPolygon') {
        editableLayers = this.set('editableLayers', _leaflet.default.geoJSON(geoJSON));
      } else {
        editableLayers = this.set('editableLayers', new _leaflet.default.FeatureGroup());
      }

      map.addLayer(editableLayers);
      map.pm.toggleGlobalEditMode();
      editableLayers.bringToFront();
      let onGeoJSONUpdate = component.get('onGeoJSONUpdate');

      let drawCreated = function drawCreatedHandler(e) {
        editableLayers.addLayer(e.layer);
        e.layer.pm.enable({
          allowSelfIntersection: false
        });
        e.layer.on('pm:edit', () => component.drawEdited.call(component));
        onGeoJSONUpdate(editableLayers.toGeoJSON());
      };

      let polygonCut = function polygonCutHandler(e) {
        let layers = e.layer.getLayers ? e.layer.getLayers() : [e.layer];
        layers.forEach(layer => {
          editableLayers.addLayer(layer);
          layer.on('pm:edit', () => component.drawEdited.call(component));
        });
        editableLayers.removeLayer(e.originalLayer);
        onGeoJSONUpdate(editableLayers.toGeoJSON());
      }; // If last layer, sets geoJSON to null so that sidebar can react 


      let drawDeleted = function drawDeletedHandler(e) {
        editableLayers.removeLayer(e.layer);
        let lastLayer = true;
        editableLayers.eachLayer(() => {
          lastLayer = false;
        });

        if (lastLayer) {
          return onGeoJSONUpdate(null);
        }

        onGeoJSONUpdate(editableLayers.toGeoJSON());
      };

      let drawBoundary = function drawBoundaryHandler() {
        map.pm.enableDraw('Poly');
      };

      let editBoundary = function editBoundaryHandler() {
        map.pm.toggleGlobalEditMode();
      }; // Removes Everything


      let deleteBoundary = function deleteBoundaryHandler() {
        editableLayers.eachLayer(layer => {
          editableLayers.removeLayer(layer);
        });
        onGeoJSONUpdate(null);
      };

      map.pm.addControls({
        position: 'topright',
        drawCircle: false,
        drawMarker: false,
        drawPolyline: false,
        drawRectangle: false
      });
      editableLayers.pm.enable({
        allowSelfIntersection: false
      });
      map.on('pm:create', drawCreated);
      map.on('pm:edit', this.drawEdited);
      map.on('pm:cut', polygonCut);
      map.on('pm:remove', drawDeleted);
      this.eventsBus.on('appMapEditable:drawBoundary', this, drawBoundary);
      this.eventsBus.on('appMapEditable:editBoundary', this, editBoundary);
      this.eventsBus.on('appMapEditable:deleteBoundary', this, deleteBoundary);
      this.setProperties({
        drawCreated,
        drawDeleted,
        drawBoundary,
        editBoundary,
        deleteBoundary
      });

      if (geoJSON && geoJSON.type === 'MultiPolygon') {
        geoJSON.coordinates.forEach(coords => {
          // L.geoJSON makes a layer inside of a layer for some reason, so we have to grab that inside one for the 
          // map events to work
          _leaflet.default.geoJSON({
            'type': 'Polygon',
            'coordinates': coords
          }, {
            onEachFeature: (_, layer) => {
              editableLayers.addLayer(layer);
              layer.on('pm:edit', () => component.drawEdited.call(component));
            }
          });
        });
      }

      return editableLayers;
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "shapeObserver", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "shapeObserver"), _class2.prototype)), _class2)) || _class);
  _exports.default = AppMapEditablePm;
});