define("mldp/account/payment/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "96PheaQd",
    "block": "[[[1,[28,[35,0],[\"Payment\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"w-full bg-tan-200 h-20 px-4\"],[12],[1,\"\\n  \"],[10,\"nav\"],[14,0,\"-mb-px flex space-x-8\"],[14,\"aria-label\",\"Tabs\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@linkText\",\"@routeName\"],[\"Payment Due\",\"account.payment.index\"]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@linkText\",\"@routeName\"],[\"Payment History\",\"account.payment.history\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"paymentStatus\"]],[[[1,\"  \"],[8,[39,3],null,[[\"@named\",\"@send\"],[\"modal\",[50,\"modal-window\",0,null,[[\"title\",\"closeLabel\",\"body\",\"close\"],[[28,[37,5],[[33,6],[30,0,[\"paymentStatus\"]]],null],\"Close\",[50,\"payment-status-modal\",0,null,[[\"finishCheckout\",\"paymentStatus\"],[[30,0,[\"finishCheckout\"]],[30,0,[\"paymentStatus\"]]]]],[28,[37,7],[[30,0],[30,0,[\"closePaymentStatusModal\"]]],null]]]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[46,[28,[37,8],null,null],null,null,null]],[],false,[\"page-banner\",\"nav-link\",\"if\",\"to-elsewhere\",\"component\",\"concat\",\"Payment\",\"action\",\"-outlet\"]]",
    "moduleName": "mldp/account/payment/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});