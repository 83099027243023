define("mldp/components/payment-history-table/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Erjd3zfs",
    "block": "[[[10,\"table\"],[14,0,\"min-w-full divide-y divide-gray-20 mt-6\"],[12],[1,\"\\n  \"],[10,\"thead\"],[14,0,\"bg-shadow-100\"],[12],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[14,\"scope\",\"col\"],[14,0,\"px-6 py-3 text-center font-medium text-gray-500 tracking-wider\"],[12],[1,\"\\n        Payment Date\\n      \"],[13],[1,\"\\n      \"],[10,\"th\"],[14,\"scope\",\"col\"],[14,0,\"px-6 py-3 text-center font-medium text-gray-500 tracking-wider\"],[12],[1,\"\\n        Payment Total\\n      \"],[13],[1,\"\\n      \"],[10,\"th\"],[14,\"scope\",\"col\"],[14,0,\"px-6 py-3 text-center font-medium text-gray-500 tracking-wider\"],[12],[1,\"\\n        Itemized Receipt\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"      \"],[8,[39,2],null,[[\"@fee\",\"@index\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[2,3]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@fees\",\"fee\",\"index\"],false,[\"each\",\"-track-array\",\"payment-history-table/row\"]]",
    "moduleName": "mldp/components/payment-history-table/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});