define("mldp/serializers/supervisor", ["exports", "mldp/serializers/application", "@ember/string", "ember-inflector"], function (_exports, _application, _string, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Supervisor = classic(_class = class Supervisor extends _application.default {
    modelNameFromPayloadKey() {
      return 'supervisor';
    } // convert server types to singular and dasherized


    normalizeArrayResponse(store, primaryModelClass, payload) {
      payload.data = payload.data.map(item => {
        item.type = 'supervisor';

        if (item.relationships) {
          Object.keys(item.relationships).forEach(key => {
            let rel = item.relationships[key];

            if (!rel.data) {
              return;
            }

            rel.data.forEach(relItem => {
              if (relItem.type) {
                relItem.type = normalizeType(relItem.type);
              }
            });
          });
        }

        return item;
      });

      if (payload.included) {
        payload.included = payload.included.map(item => {
          item.type = normalizeType(item.type);
          return item;
        });
      }

      return payload;
    }

    normalizeSingleResponse(store, primaryModelClass, payload) {
      if (payload.data.type) {
        payload.data.type = 'supervisor';
      }

      if (payload.data.relationships) {
        Object.keys(payload.data.relationships).forEach(key => {
          let item = payload.data.relationships[key];

          if (!item.data) {
            return;
          }

          item.data.forEach(item => {
            if (item.type) {
              item.type = normalizeType(item.type);
            }
          });
        });
      }

      if (payload.included) {
        payload.included = payload.included.map(item => {
          item.type = normalizeType(item.type);
          return item;
        });
      }

      return payload;
    }

  }) || _class;

  _exports.default = Supervisor;

  function normalizeType(type) {
    return (0, _string.dasherize)((0, _emberInflector.singularize)(type));
  }
});