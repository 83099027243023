define("mldp/abilities/recommendation", ["exports", "@ember/object", "@ember/service", "ember-can"], function (_exports, _object, _service, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Recommendation = (_dec = (0, _service.inject)('session'), _dec2 = (0, _service.inject)('app-values'), _dec3 = (0, _service.inject)('state'), _dec4 = (0, _service.inject)('role-service'), _dec5 = (0, _object.computed)('session.isAuthenticated', 'model'), _dec6 = (0, _object.computed)('session.isAuthenticated', 'model'), _dec7 = (0, _object.computed)('session.isAuthenticated', 'model'), classic(_class = (_class2 = class Recommendation extends _emberCan.Ability {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "appValues", _descriptor2, this);

      _initializerDefineProperty(this, "stateService", _descriptor3, this);

      _initializerDefineProperty(this, "roleService", _descriptor4, this);
    }

    get canCreate() {
      let appValues = this.appValues;
      let roleService = this.roleService;
      let site = this.model;
      let isAdmin = roleService.get('isAdmin');

      if (isAdmin) {
        return true;
      }

      let isSupervisor = this.session.isSupervisor;

      if (isSupervisor) {
        let subordinates = this.stateService.subordinates;

        if (subordinates) {
          let any = site.associatedUsers.any(user => subordinates.includes(user.userId));

          if (any) {
            return true;
          }
        }
      }

      let associated = roleService.associated(site);

      if (!associated.length) {
        return false;
      }

      let validRoles = [appValues.get('biologistUserType')];
      let hasRole = associated.any(user => {
        return validRoles.includes(user.type);
      });

      if (hasRole) {
        return true;
      }

      return false;
    }

    get canAccept() {
      let appValues = this.appValues;
      let roleService = this.roleService;
      let site = this.model;
      let isAdmin = roleService.get('isAdmin');

      if (isAdmin) {
        return true;
      }

      let isSupervisor = this.session.isSupervisor;

      if (isSupervisor) {
        let subordinates = this.stateService.subordinates;

        if (subordinates) {
          let any = site.associatedUsers.any(user => subordinates.includes(user.userId));
          return any;
        }
      }

      let associated = roleService.associated(site);

      if (!associated.length) {
        return false;
      }

      let validRoles;

      if (site.get('siteType') === 'aggregate') {
        validRoles = [appValues.get('poolManagerUserType')];
      } else {
        validRoles = [appValues.get('ownerUserType'), appValues.get('agentUserType')];
      }

      let hasRole = associated.any(user => {
        return validRoles.includes(user.type);
      });

      if (hasRole) {
        return true;
      }

      return false;
    }

    get canPrint() {
      let appValues = this.appValues;
      let roleService = this.roleService;
      let site = this.model;
      let isAdmin = roleService.get('isAdmin');

      if (isAdmin || roleService.isUserInTpwd()) {
        return true;
      }

      let isSupervisor = this.session.isSupervisor;

      if (isSupervisor) {
        let subordinates = this.stateService.subordinates;

        if (subordinates) {
          let any = site.associatedUsers.any(user => subordinates.includes(user.userId));
          return any;
        }
      }

      let associated = roleService.associated(site);

      if (!associated.length) {
        return false;
      }

      let validRoles;

      if (site.get('siteType') === 'aggregate') {
        validRoles = [appValues.get('poolManagerUserType')];
      } else {
        validRoles = [appValues.get('ownerUserType'), appValues.get('agentUserType')];
      }

      let hasRole = associated.any(user => {
        return validRoles.includes(user.type);
      });

      if (hasRole) {
        return true;
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "appValues", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "roleService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "canCreate", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "canCreate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canAccept", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "canAccept"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canPrint", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "canPrint"), _class2.prototype)), _class2)) || _class);
  _exports.default = Recommendation;
});