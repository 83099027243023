define("mldp/models/ph-permit-app", ["exports", "@ember-data/model", "ember-cp-validations", "@ember/object", "mldp/utils/instance-op"], function (_exports, _model, _emberCpValidations, _object, _instanceOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    wheatAcreage: {
      description: 'Wheat Acres',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: false
      }), (0, _emberCpValidations.validator)('presence', true)]
    },
    alfalfaAcreage: {
      description: 'Alfalfa Acres',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: false
      }), (0, _emberCpValidations.validator)('presence', true)]
    },
    otherCropAcreage: {
      description: 'Other Crop Acres',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: false
      }), (0, _emberCpValidations.validator)('presence', true)]
    },
    otherCropType: {
      description: 'Other Crop Type',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Description of crop type is required for Other Crop Acres above 0',
        disabled: (0, _object.computed)('model.otherCropAcreage', {
          get() {
            let otherCropAcreage = this.get('model.otherCropAcreage');
            if (otherCropAcreage === undefined || otherCropAcreage === null) return true;
            return otherCropAcreage === undefined || null || parseInt(otherCropAcreage) <= 0;
          }

        }).volatile()
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    archived: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    primarySiteId: (0, _model.attr)('number'),
    primarySite: (0, _model.attr)(),
    seasonId: (0, _model.attr)(),
    season: (0, _model.attr)(),
    mUs: (0, _model.attr)(),
    applicationStatusId: (0, _model.attr)('number'),
    wheatAcreage: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    alfalfaAcreage: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    otherCropAcreage: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    otherCropType: (0, _model.attr)(),
    //?
    leaseListOptIn: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    phPartitions: (0, _model.attr)(),
    //"phPartitions":[],
    siteXPhPermitApps: (0, _model.attr)(),
    //[],
    rangelandAcreage: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    eligibility: (0, _instanceOp.default)({
      path: 'eligibility/{siteId}',
      type: 'get'
    }),
    fetchReporting: (0, _instanceOp.default)({
      path: 'reporting',
      type: 'get'
    })
  });

  _exports.default = _default;
});