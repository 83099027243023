define("mldp/account/permits/index/route", ["exports", "@ember/routing/route", "rsvp", "@ember/service", "@ember/object"], function (_exports, _route, _rsvp, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const params = ['sitename', 'primarysite', 'countyname', 'seasonname', 'biologistid'];

  var _default = _route.default.extend({
    session: (0, _service.inject)('session'),
    breadCrumb: {
      title: 'Permits'
    },
    queryParams: {
      page: {
        refreshModel: true
      },
      sitename: {
        refreshModel: true
      },
      primarysite: {
        refreshModel: true
      },
      countyname: {
        refreshModel: true
      },
      seasonname: {
        refreshModel: true
      },
      biologist: {
        refreshModel: true
      },
      permitCreated: {
        refreshModel: false
      },
      propertyQp: {
        refreshModel: true
      }
    },

    async model(opts) {
      const {
        type
      } = this.paramsFor('account.permits');
      let {
        page
      } = opts;
      const options = {
        //TODO; handle multiple pages
        page: {
          number: page,
          size: 25
        },
        reload: true
      };
      const permitAppOptions = Object.assign({}, options);

      for (let param of params) {
        if (opts[param]) {
          permitAppOptions[`filter[${param}]`] = opts[param];
        }
      }

      const {
        roles,
        userId
      } = this.session;

      if (!roles.includes('Admin')) {
        if (roles.includes('Supervisor')) {
          options['filter[biologistid]'] = userId;
        } else if (roles.includes('Biologist')) {
          options['filter[biologistid]'] = userId;
        }
      }

      const seasonAdapter = this.store.adapterFor('tlk-season');
      let currentSeasonPH, currentSeasonAMD; //TODO: this can't be right, see if there is a way to do this with adapters, or get Peter to change response from 404

      try {
        currentSeasonPH = await seasonAdapter['phFindCurrentApplication']();
      } catch {
        currentSeasonPH = false;
      }

      try {
        currentSeasonAMD = await seasonAdapter['amdFindCurrentApplication']();
      } catch {
        currentSeasonAMD = false;
      }

      const phSiteOptions = Object.assign({
        'filter[pheligible]': 1
      }, options);
      const amdSiteOptions = Object.assign({
        'filter[amdeligible]': 1
      }, options);
      const hash = {
        phEligibleSites: this.store.query('site', phSiteOptions),
        amdEligibleSites: this.store.query('site', amdSiteOptions),
        amdApplications: this.store.query('amd-permit-app', permitAppOptions),
        phApplications: this.store.query('ph-permit-app', permitAppOptions),
        tlkApplicationStatus: this.store.findAll('tlk-application-status'),
        opts,
        currentSeasonPH,
        currentSeasonAMD,
        isAdmin: roles.includes('Admin')
      };
      return _rsvp.default.hash(hash);
    },

    setupController(controller, resolved) {
      resolved.applicationStatus = {};
      resolved.tlkApplicationStatus.forEach(item => {
        resolved.applicationStatus[item.sortOrder] = item.displayValue;
      });
      resolved.phEligibleSitesCount = resolved.phEligibleSites.meta.results.filteredCount;
      resolved.amdEligibleSitesCount = resolved.amdEligibleSites.meta.results.filteredCount;
      resolved.amdCount = resolved.amdApplications.meta.results.filteredCount;
      resolved.phCount = resolved.phApplications.meta.results.filteredCount;
      const {
        type
      } = this.paramsFor('account.permits');
      const activePermitType = type;
      resolved.activePermitType = activePermitType;
      resolved.searchFields = _object.default.create({});

      for (let param of params) {
        if (resolved.opts[param]) {
          resolved.searchFields[param] = resolved.opts[param];
        } else {
          resolved.searchFields[param] = null;
        }
      }

      delete resolved.opts;
      resolved.paging = resolved[`${type}Applications`].meta.paging;
      resolved.applications = resolved[`${type}Applications`];
      controller.setProperties(resolved);
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        //TODO: This doesn't see like the best way to solve this.
        controller.set('showHerdUnitModal', false);
        controller.set('showCountyModal', false);
      }
    }

  });

  _exports.default = _default;
});