define("mldp/components/loading-button/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ION4f82Q",
    "block": "[[[41,[30,0,[\"loading\"]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,2,[[30,0,[\"actionResult\"]],[30,0,[\"errored\"]]]],[1,\"\\n\"]],[]]]],[\"&else\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "mldp/components/loading-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});