define("mldp/components/toggle-archived/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yK5+qmuY",
    "block": "[[[41,[28,[37,1],[\"see archived in properties\"],null],[[[1,\"  \"],[10,0],[14,0,\"btn-group\"],[14,\"aria-label\",\"Active/Archived Mode Toggle\"],[12],[1,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-default dropdown-toggle\"],[14,\"data-toggle\",\"dropdown\"],[14,\"aria-haspopup\",\"true\"],[14,\"aria-expanded\",\"false\"],[14,4,\"button\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        Showing \"],[10,\"i\"],[14,0,\"material-icons text-muted\"],[12],[1,\"archive\"],[13],[1,\" Archived\\n\"]],[]],[[[1,\"        Showing Active\\n\"]],[]]],[1,\"      \"],[10,1],[14,0,\"caret\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"ul\"],[14,0,\"dropdown-menu\"],[12],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n        \"],[10,3],[14,\"role\",\"button\"],[15,\"onclick\",[28,[37,2],[[30,0],[30,2],[28,[37,3],[[30,1]],null]],null]],[12],[1,\"\\n          Show \"],[1,[52,[30,1],\"Active\",\"Archived\"]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@archived\",\"@onToggle\"],false,[\"if\",\"can\",\"action\",\"not\"]]",
    "moduleName": "mldp/components/toggle-archived/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});