define("mldp/expired-password/route", ["exports", "@ember/object", "@ember/service", "jquery", "@ember/routing/route", "@ember/application", "@ember/string", "ember-simple-auth/mixins/unauthenticated-route-mixin", "mldp/models/expired-password"], function (_exports, _object, _service, _jquery, _route, _application, _string, _unauthenticatedRouteMixin, _expiredPassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ExpiredPasswordRoute = (_dec = (0, _service.inject)('state'), _dec2 = (0, _service.inject)('notifications'), _dec3 = (0, _service.inject)('session'), classic(_class = (_class2 = class ExpiredPasswordRoute extends _route.default.extend(_unauthenticatedRouteMixin.default) {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);
    }

    model() {
      let model = _expiredPassword.default.create((0, _application.getOwner)(this).ownerInjection());

      return model;
    }

    afterModel(model) {
      let state = this.state;
      let code = state.get('expiredPasswordCode');
      this.set('emailAddress', state.get('expiredEmail'));

      if (!code) {
        this.transitionTo('login');
      }

      model.set('code', code);
    }

    setupController(controller, model) {
      let state = this.state;
      controller.setProperties({
        model,
        emailAddress: state.get('expiredEmail')
      });
    } // TODO: don't handle DOM in the route, use a component


    activate() {
      let cssClass = this.toCssClass(); // you probably don't need the application class
      // to be added to the body

      if (cssClass !== 'application') {
        (0, _jquery.default)('body').addClass(cssClass);
      }
    }

    deactivate() {
      (0, _jquery.default)('body').removeClass(this.toCssClass());
    }

    resetPassword(data) {
      return data.validate().then(() => {
        if (data.get('validations.isValid')) {
          let adapter = this.store.adapterFor('user');
          let notifications = this.notifications;
          return adapter.changeExpiredPassword(data).then(() => {
            //Password reset, log them in
            let session = this.session;
            session.authenticate('authenticator:jwt', {
              identification: this.controller.get('emailAddress'),
              password: data.get('newPassword')
            }).then(() => this.transitionTo('account')).then(() => notifications.add('Your password has been reset.')).catch(e => {
              return this.transitionTo('login').then(() => notifications.error(e.errors[0].detail));
            });
          }).catch(error => {
            if (error.jqXHR) {
              if (error.jqXHR.status === 403 || error.jqXHR.status === 404 || error.jqXHR.status === 409) {
                notifications.error(error.payload.errors[0].message);
              }
            } else if (error.errors) {
              notifications.error(error.errors[0].detail);
            } else {
              notifications.error('An error has occurred');
            }
          });
        }
      });
    }

    toCssClass() {
      return (0, _string.dasherize)(this.routeName.replace(/\./g, '-'));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "resetPassword", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "resetPassword"), _class2.prototype)), _class2)) || _class);
  _exports.default = ExpiredPasswordRoute;
});