define("mldp/services/user-idle", ["exports", "@ember/service", "ember-user-activity/services/user-idle", "mldp/config/environment"], function (_exports, _service, _userIdle, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let _UserIdleService = (_dec = (0, _service.inject)('session'), _dec2 = (0, _service.inject)('user-activity'), classic(_class = (_class2 = class _UserIdleService extends _userIdle.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _defineProperty(this, "IDLE_TIMEOUT", _environment.default.inactiveLogoutTimeout || 1800000);

      _initializerDefineProperty(this, "userActivity", _descriptor2, this);
    }

    // logout user if idle for 30 minutes or more
    setIdle() {
      super.setIdle(...arguments);
      let disabled = _environment.default.disableInactiveLogout;
      let isIdle = this.isIdle;
      let session = this.session;

      if (!disabled && isIdle && session.get('isAuthenticated')) {
        setTimeout(() => {
          if (this.isIdle) session.invalidate();
        }, 30000); //30 seconds
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "userActivity", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);

  _exports.default = _UserIdleService;
});