define("mldp/components/archive-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KTu2CWEz",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Warning, you are about to archive \"],[10,\"em\"],[12],[1,[30,0,[\"siteTypeName\"]]],[13],[1,\" \"],[10,\"b\"],[12],[1,\"\\\"\"],[1,[30,2,[\"siteName\"]]],[1,\"\\\"\"],[13],[1,\".\"],[10,\"br\"],[12],[13],[1,\"\\n    Neither you nor the landowner(s)/agent(s) will have access to the record once done.\\n  \"],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,\"\\n    Click \"],[10,\"em\"],[12],[1,\"Ok\"],[13],[1,\" to continue or \"],[10,\"em\"],[12],[1,\"Cancel\"],[13],[1,\" to stop this action.\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@site\"],false,[]]",
    "moduleName": "mldp/components/archive-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});