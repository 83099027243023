define("mldp/account/property/units/index/route", ["exports", "@ember/object", "@ember/routing/route", "rsvp"], function (_exports, _object, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  const queryParams = {
    archivedMus: {
      refreshModel: true
    }
  };

  let IndexRoute = classic(_class = (_class2 = class IndexRoute extends _route.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", queryParams);
    }

    model(_ref) {
      let {
        archivedMus
      } = _ref;
      let {
        type,
        siteId
      } = this.paramsFor('account.property');
      let {
        property
      } = this.modelFor('account.property');
      let adapter = this.store.adapterFor(type);
      return _rsvp.default.hash({
        property,
        units: adapter.getManagementUnits(siteId, archivedMus),
        isProperty: type === 'property'
      });
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }

    changePoolStatus(unit) {
      let poolActive = unit.get('poolActive');
      let poolStatusNotes = '';

      if (poolActive) {
        poolStatusNotes = window.prompt('Please explain why this is being deactivated');

        if (poolStatusNotes === null) {
          return;
        }
      }

      return unit.changePoolStatus({
        data: {
          isActive: !poolActive,
          poolStatusNotes
        }
      }).then(() => {
        unit.set('poolActive', !poolActive);
      });
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "changePoolStatus", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "changePoolStatus"), _class2.prototype)), _class2)) || _class;

  _exports.default = IndexRoute;
});