define("mldp/components/reports/permit-summary-filters/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "sAuGudyL",
    "block": "[[[11,0],[24,0,\"row-fluid clearfix no-gutter\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-xs-4\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"block\"],[12],[1,\"\\n      Year\\n      \"],[8,[39,0],null,[[\"@options\",\"@selected\",\"@searchEnabled\",\"@placeholder\",\"@triggerClass\",\"@onChange\"],[[30,0,[\"stateService\",\"permitSeasonOptions\"]],[30,0,[\"year\"]],false,\"-- Select Year --\",\"form-control\",[28,[37,1],[[30,0],\"updateYear\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"id\"]]],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"col-xs-4\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"block\"],[12],[1,\"\\n      Reporting Unit\\n      \"],[8,[39,0],null,[[\"@options\",\"@selected\",\"@searchEnabled\",\"@placeholder\",\"@triggerClass\",\"@onChange\"],[[30,0,[\"reportingUnits\"]],[30,0,[\"reportingUnit\"]],false,\"-- Select Reporting Unit --\",\"form-control\",[28,[37,1],[[30,0],\"updateReportingUnit\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3]],[1,\"\\n      \"]],[3]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"col-xs-4\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"block\"],[12],[1,\"\\n      Status\\n      \"],[8,[39,0],null,[[\"@options\",\"@selected\",\"@placeholder\",\"@allowClear\",\"@triggerClass\",\"@dropdownClass\",\"@onChange\"],[[30,0,[\"statuses\"]],[30,0,[\"appstatus\"]],\"All Statuses\",true,\"form-control\",\"biologist-select-dropdown\",[28,[37,1],[[30,0],\"updateStatus\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4,[\"displayValue\"]]],[1,\"\\n      \"]],[4]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"year\",\"reportingUnit\",\"status\"],false,[\"power-select\",\"action\"]]",
    "moduleName": "mldp/components/reports/permit-summary-filters/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});