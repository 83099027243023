define("mldp/components/admin/process-modal/component", ["exports", "@glimmer/component", "@glimmer/tracking", "ember-concurrency-decorators", "ember-concurrency", "@ember/service"], function (_exports, _component, _tracking, _emberConcurrencyDecorators, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProcessModal = (_class = class ProcessModal extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "canStartObj", _descriptor3, this);

      _initializerDefineProperty(this, "areYouSure", _descriptor4, this);

      _initializerDefineProperty(this, "process", _descriptor5, this);
    }

    get percentComplete() {
      var _this$process, _this$process2;

      const percent = `${Math.floor(((_this$process = this.process) === null || _this$process === void 0 ? void 0 : _this$process.totalProcessed) / ((_this$process2 = this.process) === null || _this$process2 === void 0 ? void 0 : _this$process2.totalQueued) * 100 || 0)}%`;
      return percent;
    }

    *checkCanStart() {
      const processAdapter = this.store.adapterFor('process');
      const canStartStatus = yield processAdapter.checkCanStart(this.args.process.id);

      if (!canStartStatus.canStart && canStartStatus.reason === 'Processing job for this season has already been started') {
        try {
          this.pollLatest.perform();
        } catch (error) {
          var _error$payload, _error$payload$errors;

          if (error !== null && error !== void 0 && (_error$payload = error.payload) !== null && _error$payload !== void 0 && (_error$payload$errors = _error$payload.errors) !== null && _error$payload$errors !== void 0 && _error$payload$errors.length) {
            this.notifications.error(error.payload.errors[0].detail);
          } else {
            this.notifications.error('An error has occurred');
          }
        }
      }

      this.canStartObj = canStartStatus;
    }

    *pollLatest() {
      while (true) {
        const processAdapter = this.store.adapterFor('process');

        try {
          const latestProcess = yield processAdapter.checkLatest(this.args.process.id);
          yield (0, _emberConcurrency.timeout)(3000);
          if (latestProcess) this.process = latestProcess;
          const progressBarFill = document.querySelector('.progress-bar-fill');

          if (progressBarFill !== null && progressBarFill !== void 0 && progressBarFill.style) {
            progressBarFill.style.width = this.percentComplete;
          }
        } catch (error) {
          var _error$payload2, _error$payload2$error;

          if (error !== null && error !== void 0 && (_error$payload2 = error.payload) !== null && _error$payload2 !== void 0 && (_error$payload2$error = _error$payload2.errors) !== null && _error$payload2$error !== void 0 && _error$payload2$error.length) {
            this.notifications.error(error.payload.errors[0].detail);
          } else {
            this.notifications.error('An error has occurred');
          }
        }
      }
    }

    *startProcess() {
      const processAdapter = this.store.adapterFor('process');

      if (!this.areYouSure) {
        this.areYouSure = true;
      } else {
        try {
          this.process = true;
          yield processAdapter.startProcess(this.args.process.id); // this.process = processStarted;

          this.pollLatest.perform();
        } catch (error) {
          var _error$payload3, _error$payload3$error;

          if (error !== null && error !== void 0 && (_error$payload3 = error.payload) !== null && _error$payload3 !== void 0 && (_error$payload3$error = _error$payload3.errors) !== null && _error$payload3$error !== void 0 && _error$payload3$error.length) {
            this.notifications.error(error.payload.errors[0].detail);
          } else {
            this.notifications.error('An error has occurred');
          }
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "canStartObj", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "areYouSure", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "process", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "checkCanStart", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "checkCanStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pollLatest", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "pollLatest"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startProcess", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "startProcess"), _class.prototype)), _class);
  _exports.default = ProcessModal;
});