define("mldp/components/confirm-action/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "j6cjv8D/",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"Would you like to save this message to view later?\"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/confirm-action/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});