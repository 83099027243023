define("mldp/account/consultations/index/query-params", ["exports", "mldp/utils/setup-query-params"], function (_exports, _setupQueryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.routeQueryParams = _exports.keys = void 0;
  const keys = ['type', 'site', 'biologistid', 'startDate', 'endDate', 'comment', 'county', 'biologistIdOther'];
  _exports.keys = keys;
  const routeQueryParams = (0, _setupQueryParams.default)(keys);
  _exports.routeQueryParams = routeQueryParams;
});