define("mldp/components/notices/reporting/missing-reports/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pkIA62r5",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"This site has one or more management units that are missing deer harvest reports for this season. Would you like to continue reporting harvest?\"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/notices/reporting/missing-reports/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});