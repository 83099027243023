define("mldp/models/estimate", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Estimate = (_dec = (0, _object.computed)('shape'), _dec2 = (0, _object.computed)('bucks', 'antlerless'), classic(_class = (_class2 = class Estimate extends _object.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "highFence", false);
    }

    get geoJSON() {
      let shape = this.shape;

      if (!shape) {
        return;
      }

      try {
        return JSON.parse(shape);
      } catch (e) {// noop
      }
    }

    get totalTags() {
      let bucks = this.bucks || 0;
      let antlerless = this.antlerless || 0;
      return bucks + antlerless;
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "geoJSON", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "geoJSON"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "totalTags", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "totalTags"), _class2.prototype)), _class2)) || _class);
  _exports.default = Estimate;
});