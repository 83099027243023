define("mldp/abilities/permit", ["exports", "@ember/object", "@ember/service", "ember-can", "moment"], function (_exports, _object, _service, _emberCan, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Permit = (_dec = (0, _service.inject)('role-service'), _dec2 = (0, _service.inject)('app-values'), _dec3 = (0, _service.inject)('session'), _dec4 = (0, _object.computed)('roleService.isAdmin'), _dec5 = (0, _object.computed)('session.isAuthenticated', 'model'), _dec6 = (0, _object.computed)('session.isAuthenticated'), _dec7 = (0, _object.computed)('session.isAuthenticated', 'model'), _dec8 = (0, _object.computed)('session.isAuthenticated', 'model', 'currentSeason', 'permitType'), _dec9 = (0, _object.computed)('session.isAuthenticated'), classic(_class = (_class2 = class Permit extends _emberCan.Ability {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "roleService", _descriptor, this);

      _initializerDefineProperty(this, "appValues", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);
    }

    get canAccess() {
      return true;
    }

    get canAcceptRecommendations() {
      let appValues = this.appValues;
      let roleService = this.roleService;
      let site = this.model;
      let isAdmin = roleService.get('isAdmin');

      if (isAdmin) {
        return true;
      }

      let isSupervisor = this.session.isSupervisor;

      if (isSupervisor) {
        let subordinates = this.stateService.subordinates;

        if (subordinates) {
          let any = site.associatedUsers.any(user => subordinates.includes(user.userId));
          return any;
        }
      }

      let associated = roleService.associated(site);

      if (!associated.length) {
        return false;
      }

      let validRoles = [appValues.get('poolManagerUserType'), appValues.get('ownerUserType'), appValues.get('agentUserType')];
      let hasRole = associated.any(user => {
        return validRoles.includes(user.type);
      });

      if (hasRole) {
        return true;
      }

      return false;
    }

    get canApplyForPermit() {
      let lawEnforcementRole = this.appValues.lawEnforcementRole;
      let isLawEnforcement = this.roleService.isInRole(lawEnforcementRole);

      if (isLawEnforcement) {
        return false;
      }

      return true;
    }

    get canEditCropAcreage() {
      let appValues = this.appValues;
      let roleService = this.roleService;
      let site = this.model;
      let isAdmin = roleService.get('isAdmin');
      const isWPO = this.session.roles.includes('Wildlife Permit Office');

      if (isAdmin || isWPO) {
        return true;
      }

      let isSupervisor = this.session.isSupervisor;

      if (isSupervisor) {
        let subordinates = this.stateService.subordinates;

        if (subordinates) {
          let any = site.associatedUsers.any(user => subordinates.includes(user.userId));
          return any;
        }
      }

      let associated = roleService.associated(site);

      if (!associated.length) {
        return false;
      }

      let validRoles = [appValues.get('poolManagerUserType'), appValues.get('ownerUserType'), appValues.get('agentUserType'), appValues.get('biologistRole')];
      let hasRole = associated.any(user => {
        return validRoles.includes(user.type);
      });

      if (hasRole) {
        return true;
      }

      return false;
    }

    get canCancelApplication() {
      let appValues = this.appValues;
      let roleService = this.roleService;
      let site = this.model;
      let {
        currentSeason,
        permitType
      } = this;
      let isAdmin = roleService.get('isAdmin');
      const isWPO = this.session.roles.includes('Wildlife Permit Office');

      if (isAdmin || isWPO) {
        return true;
      }

      if (!currentSeason || !permitType || !['amd', 'ph'].includes(permitType)) {
        return false;
      }

      const withdrawDate = currentSeason[permitType.concat('ApplicationWithdrawDate')];

      if ((0, _moment.default)(new Date()).isAfter(withdrawDate)) {
        return false;
      }

      let isSupervisor = this.session.isSupervisor;

      if (isSupervisor) {
        let subordinates = this.stateService.subordinates;

        if (subordinates) {
          let any = site.associatedUsers.any(user => subordinates.includes(user.userId));
          return any;
        }
      }

      let associated = roleService.associated(site);

      if (!associated.length) {
        return false;
      }

      let validRoles = [appValues.get('poolManagerUserType'), appValues.get('ownerUserType'), appValues.get('agentUserType'), appValues.get('biologistRole')];
      let hasRole = associated.any(user => {
        return validRoles.includes(user.type);
      });

      if (hasRole) {
        return true;
      }

      return false;
    }

    get canViewBulkRecommendationButton() {
      let isAdmin = this.roleService.get('isAdmin');

      if (isAdmin) {
        return true;
      }

      const isBio = this.session.roles.includes('Biologist');
      const isWPO = this.session.roles.includes('Wildlife Permit Office');
      const isSup = this.session.roles.includes('Supervisor');
      return isBio || isWPO || isSup;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "roleService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "appValues", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "canAccess", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "canAccess"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canAcceptRecommendations", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "canAcceptRecommendations"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canApplyForPermit", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "canApplyForPermit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canEditCropAcreage", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "canEditCropAcreage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canCancelApplication", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "canCancelApplication"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canViewBulkRecommendationButton", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "canViewBulkRecommendationButton"), _class2.prototype)), _class2)) || _class);
  _exports.default = Permit;
});