define("mldp/adapters/ph-recommendation", ["exports", "mldp/adapters/application", "mldp/utils/class-op"], function (_exports, _application, _classOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelName: 'ph-recommendation',
    findAllForSeason: (0, _classOp.default)({
      path: 'forrecommending/{seasonId}'
    }),
    postCollection: (0, _classOp.default)({
      path: 'collection',
      type: 'post',
      returnModel: false
    })
  });

  _exports.default = _default;
});