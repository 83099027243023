define("mldp/utils/has-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hasManager;

  function hasManager(users) {
    return users.any(function (item) {
      return item.type === 'PoolManager';
    });
  }
});