define("mldp/forgot-password/index/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "6z8wwI8u",
    "block": "[[[1,[28,[35,0],[\"Forgot Password\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"login-bg\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-md-5 center-block login-form\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n            \"],[10,\"h3\"],[12],[1,\"Forgot Password\"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"col-md-10 col-md-offset-1\"],[12],[1,\"\\n\"],[6,[39,1],[[30,0,[\"forgotPasswordModel\"]]],[[\"class\",\"submit\"],[\"container-fluid forgot-password\",[28,[37,2],[\"submit\",[30,0,[\"forgotPasswordModel\"]]],null]]],[[\"default\"],[[[[1,\"              \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n                  \"],[10,0],[12],[1,\"\\n                    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n                      \"],[10,2],[12],[1,\"Please enter the email address for which you have an account.\"],[13],[1,\"\\n                    \"],[13],[1,\"\\n\\n                    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n                      \"],[1,[28,[30,1,[\"email-field\"]],[\"email\"],null]],[1,\"\\n                    \"],[13],[1,\"\\n\\n                    \"],[10,0],[14,0,\"form-group clearfix\"],[12],[1,\"\\n                      \"],[1,[28,[30,1,[\"submit\"]],[\"Request Change\"],[[\"pending\",\"class\"],[\"Submitting..\",\"pull-right\"]]]],[1,\"\\n                    \"],[13],[1,\"\\n                  \"],[13],[1,\"\\n                \"],[13],[1,\"\\n              \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"f\"],false,[\"page-title\",\"form-for\",\"route-action\"]]",
    "moduleName": "mldp/forgot-password/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});