define("mldp/account/reports/lbb-details/route", ["exports", "@ember/routing/route", "@ember/service", "mldp/utils/get-report-auth", "mldp/account/reports/current-year"], function (_exports, _route, _service, _getReportAuth, _currentYear) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // /report/lbbsummary?year=2018
  var _default = _route.default.extend({
    session: (0, _service.inject)('session'),
    ajaxService: (0, _service.inject)('general-ajax'),
    breadCrumb: {
      title: 'LBB Details'
    },

    async model() {
      let report = {
        name: 'LBB Details',
        url: '/report/lbbdetail'
      };
      let currentYear = (0, _currentYear.default)();
      let ajax = this.ajaxService;
      let auth = await (0, _getReportAuth.default)(report, ajax);
      let query = {
        auth,
        year: currentYear,
        quarter: 1,
        measure: 1
      };
      return {
        report,
        query
      };
    },

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    },

    resetController(controller) {
      controller.set('query', {});
    }

  });

  _exports.default = _default;
});