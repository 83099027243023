define("mldp/components/reporting-wizard/header/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "blNuiBG0",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[6,[39,0],null,[[\"text\",\"side\"],[[30,0,[\"toolTipText\"]],\"bottom\"]],[[\"default\"],[[[[1,\"Report Harvest or Habitat:  \"],[1,[30,0,[\"currentSeason\",\"displayValue\"]]],[1,\" \"]],[]]]]],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"info-tooltip\"]]",
    "moduleName": "mldp/components/reporting-wizard/header/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});