define("mldp/components/permit/ph-reporting/list-header/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vHb+DrFS",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,\"header\"],[14,0,\"clearfix\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"inline push-up\"],[12],[1,\"\\n      Harvest\\n      \"],[10,0],[12],[1,[30,0,[\"permit\",\"season\",\"displayValue\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"pull-right\"],[12],[1,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[14,\"role\",\"button\"],[15,\"onclick\",[28,[37,0],[[30,0],[30,0,[\"submitRecs\"]]],null]],[12],[1,\"Submit\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"alert alert-info\"],[14,\"role\",\"alert\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"push-down-sm\"],[12],[1,\"1) Enter your pronghorn harvest for the mapped area in each herd unit listed below.\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"2) Click Submit when done.\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"action\"]]",
    "moduleName": "mldp/components/permit/ph-reporting/list-header/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});