define("mldp/account/users/edit/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _object, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EditRoute = (_dec = (0, _service.inject)('notifications'), classic(_class = (_class2 = class EditRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);
    }

    model(_ref) {
      let {
        user_id: userId
      } = _ref;
      return _rsvp.default.hash({
        user: this.store.findRecord('user', userId, {
          include: 'roles,counties',
          reload: true
        }),
        roles: this.store.findAll('tlkRole'),
        counties: this.store.findAll('tlkCounty'),
        supervisors: this.store.findAll('supervisor')
      });
    }

    setupController(controller, resolved) {
      let xRoles = resolved.user.get('roles');
      let roleIds = xRoles.map(xRole => String(xRole.get('roleId')));
      let roles = resolved.roles.filter(role => roleIds.includes(role.id));
      let xCounties = resolved.user.get('counties');
      let countyIds = xCounties.map(xCounty => String(xCounty.get('countyId')));
      let counties = resolved.counties.filter(county => countyIds.includes(county.id));
      resolved.user.set('displayRoles', roles);
      resolved.user.set('displayCounties', counties);
      resolved.user.set('requirePassword', false);
      controller.setProperties(resolved);
    }

    save(user) {
      let notifications = this.notifications;
      return user.validate().then(() => {
        if (!user.get('validations.isValid')) {
          return _rsvp.default.reject('Validations invalid');
        }

        return user.save();
      }).then(() => {
        let userAdapter = this.store.adapterFor('user');
        return _rsvp.default.all([userAdapter.updateRoles(user), userAdapter.updateCounties(user)]);
      }).then(() => this.transitionTo('account.users')).then(() => notifications.add('Updated user successfully')).catch(e => {
        if (e === 'Validations invalid') {
          let errors = user.get('validations.errors');
          let message = '';
          errors.forEach(error => {
            message += `${error.attribute}: ${error.message}\n`;
          });
          return _rsvp.default.reject(notifications.error(message));
        } else {
          return _rsvp.default.reject(notifications.addApiErrors(e));
        }
      });
    }

    reset(model) {
      model.rollbackAttributes();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "reset"), _class2.prototype)), _class2)) || _class);
  _exports.default = EditRoute;
});