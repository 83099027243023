define("mldp/adapters/property", ["exports", "mldp/adapters/site"], function (_exports, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Property = classic(_class = class Property extends _site.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "modelName", 'property');
    }

    createRecord(store, type, snapshot) {
      var _snapshot$adapterOpti;

      let data = {};
      let serializer = store.serializerFor(type.modelName);
      let url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });

      if ((_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.contactbiologist) {
        url += '?contactbiologist=1';
      }

      return this.ajax(url, 'POST', {
        data: data
      });
    }

    acceptAgreement(siteId) {
      let ajax = this.ajaxService;
      let siteType = this.modelName;
      return ajax.post(`/${siteType}/SetAgreementDate/${siteId}`, {
        contentType: 'application/json',
        dataType: 'json'
      });
    }

    inviteAgent(propertyId, inviteModel) {
      let ajax = this.ajaxService;
      let siteType = this.modelName;
      return ajax.post(`/${siteType}/${propertyId}/inviteAgent/`, {
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify(inviteModel.deserialize())
      });
    }

    inviteLandowner(propertyId, inviteModel) {
      let ajax = this.ajaxService;
      let siteType = this.modelName;
      return ajax.post(`/${siteType}/${propertyId}/inviteLandowner/`, {
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify(inviteModel.deserialize())
      });
    }

    getForMU(muId) {
      let ajax = this.ajaxService;
      return ajax.request(`/property/forManagementUnit/${muId}`, {
        contentType: 'application/json',
        dataType: 'json'
      });
    }

  }) || _class;

  _exports.default = Property;
});