define("mldp/components/reporting-status-item/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VR4x6xxW",
    "block": "[[[10,0],[14,0,\"reporting-status-item\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@open\",\"@ontoggle\"],[[29,[[30,1,[\"site\",\"siteName\"]],\" (\",[28,[37,1],[[30,1,[\"site\",\"siteType\"]]],null],\")\"]],[30,0,[\"open\"]],[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"open\"]]],null],[28,[37,4],[[30,0,[\"open\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"padded\"],[12],[1,\"\\n\"],[41,[30,1,[\"harvest\"]],[[[1,\"        \"],[10,\"strong\"],[12],[1,\"\\n          Harvest Report:\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"dl\"],[14,0,\"dl-horizontal push-down\"],[12],[1,\"\\n          \"],[10,\"dt\"],[12],[1,\"Submitted\"],[13],[1,\"\\n          \"],[10,\"dd\"],[12],[1,[52,[30,1,[\"harvest\",\"submitted\"]],\"Yes\",\"No\"]],[13],[1,\"\\n\\n\"],[41,[30,1,[\"harvest\",\"submitted\"]],[[[1,\"            \"],[10,\"dt\"],[12],[1,\"On Time\"],[13],[1,\"\\n            \"],[10,\"dd\"],[12],[1,[52,[30,1,[\"harvest\",\"onTime\"]],\"Yes\",\"No\"]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,6],[[30,1,[\"practice\"]],[28,[37,7],[[30,1,[\"site\",\"siteType\"]],\"aggregate\"],null]],null],[[[1,\"        \"],[10,\"strong\"],[12],[1,\"\\n          Habitat Practice Report:\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"dl\"],[14,0,\"dl-horizontal push-down\"],[12],[1,\"\\n          \"],[10,\"dt\"],[12],[1,\"On Time Count\"],[13],[1,\"\\n          \"],[10,\"dd\"],[12],[1,[30,1,[\"practice\",\"onTimeCount\"]]],[13],[1,\"\\n          \"],[10,\"dt\"],[12],[1,\"Late Count\"],[13],[1,\"\\n          \"],[10,\"dd\"],[12],[1,[30,1,[\"practice\",\"lateCount\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@reporting\"],false,[\"collapse-panel\",\"titleize\",\"action\",\"mut\",\"not\",\"if\",\"and\",\"not-eq\"]]",
    "moduleName": "mldp/components/reporting-status-item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});