define("mldp/components/merge-user-preview/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/QYoIctU",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    User \"],[10,\"b\"],[12],[1,[30,0,[\"from\",\"name\"]]],[1,\" (\"],[1,[30,0,[\"from\",\"id\"]]],[1,\")\"],[13],[1,\" will be merged into \"],[10,\"b\"],[12],[1,[30,0,[\"to\",\"name\"]]],[1,\" (\"],[1,[30,0,[\"to\",\"id\"]]],[1,\")\"],[13],[1,\" and then deleted!\\n  \"],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,\"\\n    Review the resulting attributes of user \"],[10,\"b\"],[12],[1,[30,0,[\"to\",\"id\"]]],[13],[1,\", before clicking \\\"Merge\\\".\\n    If you see anything out of place, press \\\"Cancel\\\" and update the merge details.\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"table\"],[14,0,\"table table-striped table-hover table-bordered\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Attribute\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Value\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"fields\"]]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n            \"],[46,[28,[37,3],[\"merge-\",[52,[30,2,[\"hasMany\"]],\"relationship\",\"attribute\"],\"-preview\"],null],null,[[\"data\",\"field\"],[[30,0,[\"data\"]],[30,2]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"field\"],false,[\"each\",\"-track-array\",\"component\",\"concat\",\"if\"]]",
    "moduleName": "mldp/components/merge-user-preview/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});