define("mldp/account/property/unit/edit/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bR5Y/Fdu",
    "block": "[[[46,[28,[37,1],[\"site-\",[30,0,[\"property\",\"property\",\"siteType\"]],\"-card\"],null],null,[[\"site\"],[[30,0,[\"property\",\"property\"]]]],null],[1,\"\\n\\n\"],[1,[28,[35,2],null,[[\"model\",\"fenceTypes\",\"units\",\"property\",\"allowAddMore\",\"submitLabel\",\"clearLabel\",\"onNext\",\"onClear\"],[[30,0,[\"unit\"]],[30,0,[\"fenceTypes\"]],[30,0,[\"units\"]],[30,0,[\"property\",\"property\"]],false,\"Save\",\"Reset\",[28,[37,3],[\"checkGeoJsonChanges\"],null],[28,[37,3],[\"reset\"],null]]]]],[1,\"\\n\"],[41,[30,0,[\"boundaryChange\"]],[[[1,\"  \"],[8,[39,5],null,[[\"@named\",\"@send\"],[\"modal\",[50,\"modal-window\",0,null,[[\"title\",\"allowEnterSubmit\",\"body\",\"onsubmit\",\"close\",\"submitLabel\",\"closeLabel\"],[\"Warning\",true,[50,\"notices/mu-shape-warning\",0,null,null],[28,[37,6],[[30,0],[28,[37,7],[[28,[37,3],[\"save\",[30,0,[\"unit\"]]],null],[28,[37,6],[[30,0],[28,[37,8],[[30,0,[\"boundaryChange\"]]],null],false],null]],null]],null],[28,[37,6],[[30,0],[28,[37,8],[[30,0,[\"boundaryChange\"]]],null],false],null],\"Save\",\"Cancel\"]]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"component\",\"concat\",\"managementunit-edit\",\"route-action\",\"if\",\"to-elsewhere\",\"action\",\"pipe\",\"mut\"]]",
    "moduleName": "mldp/account/property/unit/edit/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});