define("mldp/imported-user/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "SRHmGPDN",
    "block": "[[[1,[28,[35,0],[\"TWIMS Account Imported\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"login-bg\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-md-5 center-block login-form\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n            \"],[10,\"h3\"],[12],[1,\"TWIMS Account Imported\"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"col-md-10 col-md-offset-1\"],[12],[1,\"\\n            \"],[10,2],[14,0,\"login-desc\"],[12],[1,\"Your TWIMS user account has been imported to the Land Management Assistance (LMA) application.\"],[13],[1,\"\\n            \"],[10,2],[14,0,\"login-desc\"],[12],[1,\"For security purposes your password must be reset.\"],[13],[1,\"\\n            \"],[10,2],[14,0,\"login-desc\"],[12],[1,\"An email has been sent to you with instructions to reset your password for use in LMA.\"],[13],[1,\"\\n            \"],[10,2],[14,0,\"login-desc\"],[12],[1,\"Please review the email and follow the instructions. Once you have completed the password reset process, your LMA account will be ready to use.\"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"page-title\"]]",
    "moduleName": "mldp/imported-user/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});