define("mldp/components/report-container/component", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    hasExcel: (0, _object.computed)('report.formats', {
      get() {
        let formats = this.get('report.formats');
        return formats ? formats.includes('excel') : true;
      }

    }),
    hasPdf: (0, _object.computed)('report.formats', {
      get() {
        let formats = this.get('report.formats');
        return formats ? formats.includes('pdf') : true;
      }

    }),
    hasHtml: (0, _object.computed)('report.formats', {
      get() {
        let formats = this.get('report.formats');
        return formats ? formats.includes('html') : true;
      }

    }),
    actions: {
      getReport(format) {
        return this.getReport({
          format
        });
      }

    }
  });

  _exports.default = _default;
});