define("mldp/components/reports/transaction-list/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "//wvlKRN",
    "block": "[[[11,0],[24,0,\"row-fluid clearfix no-gutter\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-xs-6\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"block\"],[12],[1,\"\\n      Start Date\\n      \"],[10,0],[14,0,\"form-control\"],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@date\",\"@onChange\",\"@class\"],[[30,2,[\"startDate\"]],[28,[37,1],[[30,3],\"start\"],null],\"form-control\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"col-xs-6\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"block\"],[12],[1,\"\\n      End Date\\n      \"],[10,0],[14,0,\"form-control\"],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@date\",\"@onChange\",\"@altInputClass\"],[[30,2,[\"endDate\"]],[28,[37,1],[[30,3],\"end\"],null],\"form-control\"]],null],[1,\"\\n      \"],[13],[1,\"  \\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@query\",\"@onChange\"],false,[\"ember-flatpickr\",\"fn\"]]",
    "moduleName": "mldp/components/reports/transaction-list/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});