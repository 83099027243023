define("mldp/components/practice/crop-select/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zMpXMRre",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n    \"],[1,[28,[33,0,[\"select-field\"]],[\"cropGrownId\"],[[\"options\",\"label\",\"optionTargetPath\",\"optionLabelPath\",\"prompt\",\"promptIsSelectable\",\"required\"],[[30,0,[\"state\",\"crops\"]],\"Crop grown\",\"identifier\",\"displayValue\",\"Please select one:\",true,true]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"f\"]]",
    "moduleName": "mldp/components/practice/crop-select/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});