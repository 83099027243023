define("mldp/components/recommendations/tags-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "IZZQ+Zbb",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    The following webpage is a single time use page, and will be unusable after\\n    a refresh. To generate another link visit the recommendations and click \\\"View Tags\\\" again.\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-primary btn-block\"],[15,\"onclick\",[28,[37,0],[[30,0],\"print\"],null]],[14,4,\"button\"],[12],[1,\"Print Tags\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n    Note: You browser might block the tag window that gets opened. Make sure to allow popups for the LMA website.\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"text-center form-group push-down\"],[12],[1,\"\\n    OR\\n    \"],[10,\"hr\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,\"\\n    Copy the link to save for use later or for emailing. To print now, use the button above.\\n    Use \"],[10,\"kbd\"],[12],[1,\"Ctrl + V\"],[13],[1,\" if on Windows (or \"],[10,\"kbd\"],[12],[1,\"Cmd + V\"],[13],[1,\" on macOS) to paste the URL after clicking the button.\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@clipboardText\",\"@title\",\"@class\"],[[30,0,[\"fullUrl\"]],\"copy to clipboard\",\"btn btn-default btn-block\"]],[[\"default\"],[[[[1,\"\\n    Copy Print Tags URL\\n    \"],[10,\"i\"],[14,0,\"material-icons\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"content_copy\"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"action\",\"copy-button\"]]",
    "moduleName": "mldp/components/recommendations/tags-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});