define("mldp/components/deer-population/pick-report-season/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jLQICdss",
    "block": "[[[1,\"\\n\"],[10,\"label\"],[14,0,\"block\"],[12],[1,\"\\n  Year\\n  \"],[8,[39,0],null,[[\"@options\",\"@selected\",\"@searchEnabled\",\"@dropdownClass\",\"@triggerClass\",\"@onChange\"],[[30,0,[\"seasons\"]],[30,0,[\"selectedReportSeason\"]],false,\"biologist-select-dropdown\",\"form-control\",[30,0,[\"selectReportSeason\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,1,[\"id\"]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"year\"],false,[\"power-select\"]]",
    "moduleName": "mldp/components/deer-population/pick-report-season/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});