define("mldp/components/remove-user/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "F6rIARjk",
    "block": "[[[11,1],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"canUserRemove\"]],[[[1,\"    \"],[10,\"button\"],[14,0,\"btn btn-link btn-xs\"],[15,\"onclick\",[28,[37,1],[[30,0],\"remove\"],null]],[12],[1,\"(remove)\"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\"],false,[\"if\",\"action\"]]",
    "moduleName": "mldp/components/remove-user/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});