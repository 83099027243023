define("mldp/components/my-subordinate/component", ["exports", "@ember/component", "@ember/service", "@ember/object", "ember-concurrency"], function (_exports, _component, _service, _object, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    storeService: (0, _service.inject)('store'),
    stateService: (0, _service.inject)('state'),
    routerService: (0, _service.inject)('router'),
    notificationService: (0, _service.inject)('notifications'),
    loadSites: (0, _emberConcurrency.task)(function* () {
      let userId = this.get('user.id');

      if (!userId) {
        return;
      }

      let store = this.storeService;
      let sites = yield store.query('site', {
        'filter[bioid]': userId
      });
      return sites.sortBy('siteName');
    }).on('init').restartable(),
    loadCounties: (0, _emberConcurrency.task)(function* () {
      let userId = this.get('user.id');
      let allCounties = this.get('stateService.counties');
      let user = yield this.storeService.findRecord('user', userId, {
        include: 'roles,counties',
        reload: true
      });

      if (!user) {
        return;
      }

      let countyResults = user.counties;
      let counties = countyResults.map(county => allCounties.findBy('id', String(county.countyId)));
      return counties.sortBy('displayValue');
    }).on('init').restartable(),
    actions: {
      visitSite(site) {
        let siteType = (0, _object.get)(site, 'siteType');
        let id = (0, _object.get)(site, 'id');
        this.onTransition();
        return this.routerService.transitionTo('account.property', siteType, id);
      },

      startCountyEdit(val) {
        this.set('editCounties', true);
        this.user.set('displayCounties', this.loadCounties.lastSuccessful.value);
      },

      async saveCounties() {
        let userAdapter = this.storeService.adapterFor('user');
        let user = await this.storeService.findRecord('user', this.user.id, {
          include: 'roles,counties',
          reload: true
        });

        try {
          await userAdapter.updateCounties(user);
        } catch (e) {
          console.log('error', e);
          this.notificationService.error('Something went wrong saving counties');
        }

        this.loadCounties.perform();
        this.set('editCounties', false);
      }

    }
  });

  _exports.default = _default;
});