define("mldp/tag-estimator/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "nwPjgwMm",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"MLDP Harvest Option Tag Estimator\"]],null],[1,\"\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@model\",\"@createMode\"],[[30,0,[\"model\"]],true]],null]],[],false,[\"page-banner\",\"show-page-banner\",\"tag-map\"]]",
    "moduleName": "mldp/tag-estimator/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});