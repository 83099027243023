define("mldp/components/deer-population/list/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let DeerPopulationList = classic(_class = class DeerPopulationList extends _component.default {
    init() {
      super.init(...arguments);
    }

  }) || _class;

  _exports.default = DeerPopulationList;
});