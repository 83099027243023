define("mldp/utils/area-in-acres", ["exports", "@turf/turf"], function (_exports, _turf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = unscaledArea;
  const areaMultiplier = 0.000247105; // sqmeters to area

  function unscaledArea(geojson) {
    let rawArea = _turf.default.area(geojson);
    /*
    let centroid = turf.centroid(geojson);
    let lat = centroid.geometry.coordinates[1];
    let scaleFactor = Math.cos(lat * Math.PI / 180);
    let areaSquareMeters = rawArea * scaleFactor * scaleFactor;
    */


    let areaAcres = (rawArea * areaMultiplier).toFixed(2);
    return areaAcres;
  }
});