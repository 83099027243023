define("mldp/adapters/feeschedule", ["exports", "mldp/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let FeeScheduleAdapter = classic(_class = class FeeScheduleAdapter extends _application.default {
    async getForSeason(seasonId) {
      let ajax = this.ajaxService;
      let feeSchedules = await ajax.request(`feeSchedule/forseason/${seasonId}`, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
      let feeMap = feeSchedules.map(schedule => {
        return {
          id: schedule.id,
          type: 'fee-schedule',
          attributes: {
            feeAmountForDisplay: schedule.feeAmountForDisplay,
            feeType: schedule.feeType.displayValue,
            seasonId
          },
          relationships: {
            season: {
              data: {
                type: 'tlk-deer-season',
                id: schedule.seasonId
              }
            }
          }
        };
      });
      return this.store.push({
        data: feeMap
      });
    }

  }) || _class;

  _exports.default = FeeScheduleAdapter;
});