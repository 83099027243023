define("mldp/components/permit/unit-aggregate-item/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eSJ6ZJni",
    "block": "[[[10,\"td\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"top-bar form-group clearfix\"],[12],[1,\"\\n    \"],[10,\"b\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@route\",\"@models\"],[\"account.property.unit\",[28,[37,1],[[30,0,[\"site\",\"siteType\"]],[30,0,[\"site\",\"id\"]],[30,0,[\"unit\",\"id\"]]],null]]],[[\"default\"],[[[[1,[30,0,[\"unit\",\"siteName\"]]]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[28,[37,3],[[30,0,[\"unit\",\"eligibility\",\"isEligible\"]]],null],[[[1,\"    \"],[1,[28,[35,4],null,[[\"errors\"],[[30,0,[\"unit\",\"eligibility\",\"errors\"]]]]]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"link-to\",\"array\",\"if\",\"not\",\"eligibility-errors\"]]",
    "moduleName": "mldp/components/permit/unit-aggregate-item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});