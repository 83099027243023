define("mldp/helpers/almost-eq", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.almostEq = almostEq;
  _exports.default = void 0;

  function almostEq(_ref) {
    let [a, b] = _ref;
    return a == b;
  }

  var _default = (0, _helper.helper)(almostEq);

  _exports.default = _default;
});