define("mldp/models/user-xrole", ["exports", "@ember-data/model", "@ember/object", "mldp/utils/instance-op"], function (_exports, _model, _object, _instanceOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    userId: (0, _model.attr)('number'),
    roleId: (0, _model.attr)('number'),
    roleIdentifier: (0, _object.computed)('roleId', {
      get() {
        let id = this.roleId;
        return String(id);
      }

    }),
    deleteForMerge: (0, _instanceOp.default)({
      path: 'formerge',
      type: 'delete'
    })
  });

  _exports.default = _default;
});