define("mldp/maintenance/route", ["exports", "@ember/service", "@ember/routing/route", "mldp/config/environment"], function (_exports, _service, _route, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  const env = _environment.default.environment;
  const STATUS_URL = `https://storage.googleapis.com/lma-webdata-${env}/status.json?_bust=`;
  let MaintenanceRoute = (_dec = (0, _service.inject)('ajax'), _dec2 = (0, _service.inject)('state'), classic(_class = (_class2 = class MaintenanceRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "state", _descriptor2, this);
    }

    model() {
      let ajax = this.ajax;
      let url = STATUS_URL + Date.now();
      return ajax.request(url, {
        contentType: 'application/json',
        accept: 'application/json',
        dataType: 'json'
      });
    } // Update maintenance status every minute once on this page


    setupController(controller, model) {
      if (!model || !model.maintenance || !model.maintenance.enabled) {
        return this.transitionTo('index');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = MaintenanceRoute;
});