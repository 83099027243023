define("mldp/helpers/is-eligible", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEligible = isEligible;

  function isEligible(_ref, _ref2) {
    let [enrollment, eligibility] = _ref;
    let {
      typeId
    } = _ref2;
    return enrollment.isEligible(eligibility, typeId);
  }

  var _default = (0, _helper.helper)(isEligible);

  _exports.default = _default;
});