define("mldp/components/permit/permit-details/component", ["exports", "@ember-decorators/component", "@ember/component", "mldp/utils/permit-type-dictionary"], function (_exports, _component, _component2, _permitTypeDictionary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PermitDetails = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class PermitDetails extends _component2.default {
    init() {
      super.init(...arguments);
      let permitType = this.permitType;
      this.set('permitTypeDisplay', (0, _permitTypeDictionary.default)(permitType));
    }

  }) || _class) || _class);
  _exports.default = PermitDetails;
});