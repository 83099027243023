define("mldp/account/outreach/edit/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "IyOW/uGv",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"Editing Outreach: \",[30,0,[\"outreach\",\"eventName\"]]],null]],null]],[1,\"\\n\\n\"],[1,[28,[35,2],null,[[\"outreach\",\"eventTypes\",\"fundings\",\"groups\",\"wmas\",\"counties\",\"onUpdate\",\"onReset\"],[[30,0,[\"outreach\"]],[30,0,[\"outreachEventTypes\"]],[30,0,[\"outreachFundings\"]],[30,0,[\"outreachGroups\"]],[30,0,[\"wmas\"]],[30,0,[\"counties\"]],[28,[37,3],[\"update\"],null],[28,[37,3],[\"cancel\"],null]]]]]],[],false,[\"page-banner\",\"concat\",\"outreach-edit\",\"route-action\"]]",
    "moduleName": "mldp/account/outreach/edit/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});