define("mldp/components/loading-iframe/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ly93t6MD",
    "block": "[[[41,[30,0,[\"loading\"]],[[[41,[48,[30,1]],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"loading\"],[12],[1,\"\\n      Loading..\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"\\n\"],[10,\"iframe\"],[15,\"src\",[30,0,[\"src\"]]],[12],[13]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "mldp/components/loading-iframe/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});