define("mldp/account/outreaches/create/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _object, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CreateRoute = (_dec = (0, _service.inject)('state'), _dec2 = (0, _service.inject)('notifications'), classic(_class = (_class2 = class CreateRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stateService", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);
    }

    async model() {
      let stateService = this.stateService;
      let {
        outreachEventTypes,
        outreachFundings,
        outreachGroups,
        wmas
      } = await stateService.loadOutreachTlks();
      let data = stateService.get('outreachCloneData') || {};
      let otherStaff = data.otherStaff;
      delete data.otherStaff;
      let outreach = this.store.createRecord('outreach', data);

      if (otherStaff && otherStaff.length !== 0) {
        let users = await (0, _rsvp.all)(otherStaff.map(staffId => {
          return this.store.findRecord('user-xoutreach', staffId).then(inter => {
            return this.store.findRecord('user', inter.get('userId'));
          }).catch(e => {
            console.warn(e);
          });
        }));
        outreach.set('selectedUsers', users);
      }

      return (0, _rsvp.hash)({
        outreach,
        outreachEventTypes,
        outreachFundings,
        outreachGroups,
        wmas
      });
    }

    setupController(controller, resolved) {
      let counties = this.get('stateService.counties');
      resolved.counties = counties; // Reset clone data if any

      this.set('stateService.outreachCloneData', undefined);
      controller.setProperties(resolved);
    }

    async create(otherStaff, outreach) {
      let notifications = this.notifications;

      try {
        let intersections;

        if (otherStaff !== null && otherStaff !== void 0 && otherStaff.length) {
          intersections = otherStaff.map(staff => {
            return this.store.createRecord('user-xoutreach', {
              userId: staff.id
            });
          });
        }

        await outreach.save();

        if (intersections) {
          for (let item of intersections) {
            item.set('outreachId', outreach.id);
            await item.save();
          }
        }

        await outreach.reload();
        await this.transitionTo('account.outreaches');
        notifications.add('Created a new outreach');
      } catch (err) {
        notifications.addApiErrors(err);
      }
    }

    cancel() {
      if (window.confirm('Are you sure?')) {
        this.transitionTo('account.outreaches');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "create", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "create"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "cancel"), _class2.prototype)), _class2)) || _class);
  _exports.default = CreateRoute;
});