define("mldp/services/events-bus", ["exports", "@ember/object/evented", "@ember/service"], function (_exports, _evented, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let EventsBusService = classic(_class = class EventsBusService extends _service.default.extend(_evented.default) {}) || _class;

  _exports.default = EventsBusService;
});