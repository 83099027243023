define("mldp/models/aggregate", ["exports", "mldp/utils/instance-op", "mldp/models/site"], function (_exports, _instanceOp, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _site.default.extend({
    findHarvests: (0, _instanceOp.default)({
      path: 'harvests',
      type: 'get',

      transform(payload) {
        if (payload !== null && payload !== void 0 && payload.data && payload.data.length) {
          payload.data = payload.data.map(datum => {
            datum.type = 'harvest';
            return datum;
          });
        }

        return this.store.push(payload);
      }

    })
  });

  _exports.default = _default;
});