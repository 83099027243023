define("mldp/account/property/permit/index/route", ["exports", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let IndexRoute = (_dec = (0, _service.inject)('session'), classic(_class = (_class2 = class IndexRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _defineProperty(this, "queryParams", {
        permit: {
          refreshModel: true
        }
      });
    }

    async model(opts) {
      let {
        page
      } = opts;
      let {
        siteId,
        type
      } = this.paramsFor('account.property');
      const {
        roles
      } = this.session;
      const options = {
        'filter[siteid]': siteId
      };
      const eligibilityOptions = {
        page: {
          number: page,
          size: 25
        },
        reload: true
      };
      const phPermits = await this.store.query('ph-permit-app', options);
      const amdPermits = await this.store.query('amd-permit-app', options);
      let eligibleSites = await this.store.query('site', eligibilityOptions);
      let selectedSite;
      const seasonAdapter = this.store.adapterFor('tlk-season');
      let currentSeasonPH, currentSeasonAMD; //TODO: this can't be right, see if there is a way to do this with adapters, or get Peter to change response from 404

      try {
        currentSeasonPH = await seasonAdapter['phFindCurrentApplication']();
      } catch {
        currentSeasonPH = false;
      }

      try {
        currentSeasonAMD = await seasonAdapter['amdFindCurrentApplication']();
      } catch {
        currentSeasonAMD = false;
      }

      if (siteId) {
        selectedSite = this.store.find(type, siteId);
      }

      return _rsvp.default.hash({
        currentSeasonPH,
        currentSeasonAMD,
        phPermits,
        amdPermits,
        eligibleSites,
        selectedSite,
        tlkApplicationStatus: this.store.findAll('tlk-application-status'),
        isAdmin: roles.includes('Admin')
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = IndexRoute;
});