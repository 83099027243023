define("mldp/models/expired-password", ["exports", "mldp/models/custom-model", "ember-cp-validations"], function (_exports, _customModel, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  const Validations = (0, _emberCpValidations.buildValidations)({
    currentPassword: (0, _emberCpValidations.validator)('presence', true),
    newPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('different', {
      on: 'currentPassword',
      message: 'New password must be different than current password'
    })],
    confirmNewPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
      on: 'newPassword',
      message: 'Password does not match new password'
    })],
    code: (0, _emberCpValidations.validator)('presence', true)
  });

  let Model = classic(_class = class Model extends _customModel.default.extend(Validations) {}) || _class;

  window.__CLASSIC_OWN_CLASSES__.set(Model, true);

  Model.reopenClass({
    attrs: ['currentPassword', 'newPassword', 'confirmNewPassword', 'code']
  });
  var _default = Model;
  _exports.default = _default;
});