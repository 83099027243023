define("mldp/components/permit/ph-recommendation-view/list-header/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ListHeader = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class ListHeader extends _component2.default {
    didReceiveAttrs() {
      let totalBuckAllHus = 0,
          totalDoeAllHus = 0;
      this.permit.phPartitions.forEach(partion => {
        partion.phRecommendations.forEach(rec => {
          if (!rec.invalidated) {
            totalBuckAllHus += Number(rec.recommendedBucks);
            totalDoeAllHus += Number(rec.recommendedDoes);
          }
        });
      });
      this.setProperties({
        totalBuckAllHus,
        totalDoeAllHus
      });
    }

  }) || _class) || _class);
  _exports.default = ListHeader;
});