define("mldp/components/permit/add-acreage/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7RYHO1yX",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[6,[39,0],[[30,2]],[[\"submit\"],[[28,[37,1],[[30,0],[30,3]],null]]],[[\"default\"],[[[[1,\"    \"],[10,2],[12],[1,\" Please enter the total acreage on this application to be planted for the Fall of the \"],[1,[30,0,[\"currentSeason\",\"displayValue\"]]],[1,\" (i.e., if multiple tracts are included, sum all planted acreage together).\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"row-fluid\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n          \"],[1,[28,[30,4,[\"number-field\"]],[\"wheatAcreage\"],[[\"label\"],[\"Total Acres in Wheat\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n          \"],[1,[28,[30,4,[\"number-field\"]],[\"alfalfaAcreage\"],[[\"label\"],[\"Total Acres in Alfalfa\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"row-fluid\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n          \"],[1,[28,[30,4,[\"number-field\"]],[\"otherCropAcreage\"],[[\"label\"],[\"Total Acres in Other Crops\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n          \"],[1,[28,[30,4,[\"text-field\"]],[\"otherCropType\"],[[\"label\"],[\"Describe Other Crops\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[4]]]]],[13]],[\"&attrs\",\"@data\",\"@onsubmit\",\"f\"],false,[\"form-for\",\"action\"]]",
    "moduleName": "mldp/components/permit/add-acreage/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});