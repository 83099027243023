define("mldp/components/my-form/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let MyForm = (_dec = (0, _component.tagName)('form'), classic(_class = _dec(_class = class MyForm extends _component2.default {
    submit(event) {
      event.preventDefault();
      var promise = this['on-submit']();
      this.set('promise', promise);
    }

  }) || _class) || _class);
  _exports.default = MyForm;
});