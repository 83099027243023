define("mldp/account/property/unit/practices/create/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "rsvp", "mldp/utils/calculate-reporting-year", "mldp/utils/area-in-acres"], function (_exports, _object, _service, _route, _rsvp, _calculateReportingYear, _areaInAcres) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  const defaultProperties = {
    primaryTargetSpeciesAsheRedBerryJuniper: void 0,
    primaryTargetSpeciesChineseTallow: void 0,
    primaryTargetSpeciesCreosoteTarBush: void 0,
    primaryTargetSpeciesEasternRedCedar: void 0,
    primaryTargetSpeciesHardwoods: void 0,
    primaryTargetSpeciesHuisache: void 0,
    primaryTargetSpeciesMcCartneyRose: void 0,
    primaryTargetSpeciesMesquite: void 0,
    primaryTargetSpeciesMixedBrush: void 0,
    primaryTargetSpeciesPricklyPear: void 0,
    primaryTargetSpeciesSaltCedar: void 0,
    primaryTargetSpeciesShinoak: void 0,
    primaryTargetSpeciesOther: void 0,
    acres: void 0,
    number: void 0,
    description: void 0,
    date: void 0,
    date2: void 0,
    miscFloat: void 0,
    length: void 0,
    method: void 0,
    liveStockTypesCattle: void 0,
    liveStockTypesCowCalf: void 0,
    liveStockTypesGoats: void 0,
    liveStockTypesHorses: void 0,
    liveStockTypesSheep: void 0,
    liveStockTypesOther: void 0,
    treatmentId: void 0,
    treatmentTypeId: void 0,
    cropGrownId: void 0
  };
  let CreateRoute = (_dec = (0, _service.inject)('notifications'), _dec2 = (0, _service.inject)('state'), classic(_class = (_class2 = class CreateRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "state", _descriptor2, this);
    }

    model() {
      // let currentSeasonId = this.get('state.currentSeason.identifier');
      let unit = this.paramsFor('account.property.unit'); // let reportingYear = calculateReportingYear(currentSeasonId);

      return _rsvp.default.hash({
        practice: this.store.createRecord('habitat-practice', {
          // deerSeasonId: reportingYear,
          siteId: unit.unitId
        }),
        unit: this.store.findRecord('management-unit', unit.unitId)
      });
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }

    async save(practice) {
      let notifications = this.notifications;
      let {
        type,
        siteId
      } = this.paramsFor('account.property');

      try {
        await practice.save();
        await this.transitionTo('account.property', type, siteId);
        notifications.add('Habitat Practice successfully submitted');
      } catch (error) {
        notifications.addApiErrors(error);
      }
    }

    updateGeom(value) {
      let {
        practice
      } = this.currentModel;
      practice.set('geoJSON', value);

      if (value) {
        let area = (0, _areaInAcres.default)(value);
        practice.set('areaMapped', area);
      } else {
        practice.set('areaMapped', 0);
      }
    }

    async reset() {
      this.refresh();
    }

    update(object, property, value) {
      object.set(property, value);

      switch (property) {
        case 'habitatPracticeTypeId':
          object.set('habitatPracticeSubTypeId', undefined);

        /* falls through */

        case 'habitatPracticeSubTypeId':
          object.setProperties(defaultProperties);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateGeom", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateGeom"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "reset"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "update"), _class2.prototype)), _class2)) || _class);
  _exports.default = CreateRoute;
});