define("mldp/components/wizard-modal-footer/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "H+ezASiu",
    "block": "[[[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[10,\"button\"],[14,0,\"btn btn-transparent pull-left\"],[15,\"onclick\",[30,1]],[12],[1,\"\\n      \"],[10,\"i\"],[14,0,\"material-icons\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"arrow_back\"],[13],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"button\"],[14,0,\"btn btn-default\"],[15,\"onclick\",[30,2]],[14,4,\"button\"],[12],[1,\"Close\"],[13],[1,\"\\n\"],[13]],[\"@goBack\",\"@close\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "mldp/components/wizard-modal-footer/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});