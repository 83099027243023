define("mldp/abilities/outreach", ["exports", "@ember/object", "@ember/service", "ember-can", "mldp/utils/date-quarter", "mldp/utils/get-quarters", "moment"], function (_exports, _object, _service, _emberCan, _dateQuarter, _getQuarters, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Outreach = (_dec = (0, _service.inject)('role-service'), _dec2 = (0, _object.computed)('roleService.isAdmin'), _dec3 = (0, _object.computed)('roleService.isAdmin', 'model'), _dec4 = (0, _object.computed)('roleService.isAdmin', 'model'), classic(_class = (_class2 = class Outreach extends _emberCan.Ability {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "roleService", _descriptor, this);
    }

    get canAccess() {
      let roleService = this.roleService;
      let isAdmin = roleService.get('isAdmin');
      let isBiologist = roleService.isInRole(roleService.get('appValues.biologistRole'));
      let isSupervisor = roleService.isInRole(roleService.get('appValues.supervisorRole'));
      return isAdmin || isBiologist || isSupervisor;
    }

    get canEdit() {
      let roleService = this.roleService;
      let isAdmin = roleService.get('isAdmin'); // Admins can edit at any time

      if (isAdmin) {
        return true;
      }

      let entryBy = this.get('model.entryBy'); // If not created by the current user, you cannot edit

      if (!roleService.isSameUser(entryBy)) {
        return false;
      }

      let entryDateString = this.get('model.entryDate');
      let currentDate = new Date();
      let entryDate = new Date(entryDateString);
      let currentQuarter = (0, _dateQuarter.default)(currentDate);
      let entryQuarter = (0, _dateQuarter.default)(entryDate);
      let sameQuarter = entryQuarter && currentQuarter === entryQuarter;

      if (sameQuarter) {
        return true;
      }

      let quarters = (0, _getQuarters.default)();

      if (entryQuarter && currentQuarter === entryQuarter + 1) {
        let quarterObject = quarters[entryQuarter];
        let endOfQuarterPlus10 = (0, _moment.default)(quarterObject.end).add(10, 'days'); // Is no more then 10 days after the end of the quarter

        return (0, _moment.default)(entryDate).isSameOrBefore(endOfQuarterPlus10);
      } else if (!entryQuarter) {
        let quarterObject = quarters[1];
        let startOfNextYearsQuarterPlus10 = (0, _moment.default)(quarterObject.start).add(1, 'years').add(10, 'days'); // Current quarter is in the next year, so the quarters must be rotated

        return (0, _moment.default)(entryDate).isSameOrBefore(startOfNextYearsQuarterPlus10);
      }

      return false;
    }

    get canDuplicate() {
      let roleService = this.roleService;
      let isAdmin = roleService.get('isAdmin'); // Admins can edit at any time

      if (isAdmin) {
        return true;
      }

      let entryBy = this.get('model.entryBy'); // If not created by the current user, you cannot edit

      if (roleService.isSameUser(entryBy)) {
        return true;
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "roleService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "canAccess", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "canAccess"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canEdit", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "canEdit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canDuplicate", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "canDuplicate"), _class2.prototype)), _class2)) || _class);
  _exports.default = Outreach;
});