define("mldp/account/property/unit/practice/index/route", ["exports", "@ember/routing/route", "rsvp", "@ember/object"], function (_exports, _route, _rsvp, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let IndexRoute = classic(_class = (_class2 = class IndexRoute extends _route.default {
    model() {
      let {
        siteId
      } = this.paramsFor('account.property');
      let queryOptions = {
        'filter[siteid]': siteId
      };
      let property = this.modelFor('account.property').property;
      let unit = this.modelFor('account.property.unit');
      let {
        practiceId
      } = this.paramsFor('account.property.unit.practice');
      let model = this.store.findRecord('habitat-practice', practiceId, {
        reload: true
      });
      return _rsvp.default.hash({
        model,
        property,
        projects: this.store.query('habitat-project', queryOptions),
        unit
      });
    }

    setupController(controller, resolved) {
      resolved.projects = resolved.projects.toArray();
      resolved.unselectedProjects = resolved.projects.filter(cur => {
        return !resolved.model.habitatProjects.find(proj => {
          return parseInt(proj.id) === parseInt(cur.id);
        });
      });
      controller.setProperties(resolved);
    }

    refreshModel() {
      this.refresh();
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "refreshModel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "refreshModel"), _class2.prototype)), _class2)) || _class;

  _exports.default = IndexRoute;
});