define("mldp/components/permit/amd-bulk-recommendation/edit-recommendation-card/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3RsyI9wh",
    "block": "[[[11,0],[24,0,\"recommendation-card container-fluid form-group\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-xs-8\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2,[\"partitions\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"row form-group\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"col-xs-6\"],[12],[1,\"\\n            \"],[10,\"label\"],[14,0,\"light-label\"],[15,\"for\",[29,[[30,3,[\"partitionId\"]],\"-\",[30,0,[\"elementId\"]]]]],[12],[1,\"\\n              Compartment \"],[1,[30,3,[\"compartment\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[10,0],[14,0,\"col-xs-6\"],[12],[1,\"\\n            \"],[10,\"input\"],[14,0,\"form-control\"],[14,\"min\",\"0\"],[15,2,[30,3,[\"count\"]]],[15,\"oninput\",[28,[37,2],[[30,0],[28,[37,2],[[30,0],\"updatePartition\",[30,3,[\"partitionId\"]]],null]],[[\"value\"],[\"target.value\"]]]],[14,4,\"number\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"col-xs-2 text-center\"],[12],[1,\"\\n      Total\\n      \"],[10,0],[14,0,\"total-count\"],[12],[1,[30,0,[\"total\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"col-xs-2 text-center\"],[12],[1,\"\\n\"],[1,\"      \"],[11,\"button\"],[24,0,\"btn btn-link\"],[24,4,\"button\"],[4,[38,2],[[30,0],[30,4],[30,2]],null],[12],[1,\"\\n        \"],[10,\"i\"],[14,0,\"material-icons md-36\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"delete\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"sr-only\"],[12],[1,\"Delete Recommendation\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@rec\",\"partition\",\"@onRemove\"],false,[\"each\",\"-track-array\",\"action\"]]",
    "moduleName": "mldp/components/permit/amd-bulk-recommendation/edit-recommendation-card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});