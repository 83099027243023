define("mldp/adapters/feetransaction", ["exports", "mldp/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let FeeTransactionAdapter = classic(_class = class FeeTransactionAdapter extends _application.default {
    async getTransaction(localRefId) {
      let ajax = this.ajaxService;
      let response = await ajax.request(`feetransaction/localrefid/${localRefId}`, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
      response.data.type = 'feeTransaction';
      return this.store.push({
        data: response.data
      });
    }

    async submitRefund(body) {
      let ajax = this.ajaxService;
      return await ajax.post('/feerefund', {
        contentType: 'application/vnd.api+json',
        headers: {
          'Accept': 'application/vnd.api+json'
        },
        data: JSON.stringify(body)
      });
    }

    async getRefundMethods() {
      let ajax = this.ajaxService;
      let response = await ajax.request('tlkrefundstatus', {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
      return response.data;
    }

    async enterChargeBack(localRefId, body) {
      let ajax = this.ajaxService;
      return await ajax.post(`/feetransaction/localrefid/${localRefId}/chargeback`, {
        contentType: 'application/vnd.api+json',
        headers: {
          'Accept': 'application/vnd.api+json'
        },
        data: JSON.stringify(body)
      });
    }

  }) || _class;

  _exports.default = FeeTransactionAdapter;
});