define("mldp/components/notices/mu-shape-warning/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rYYVuzoP",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"You are about to make changes to the managment unit boundary. If this edit also involves changes to the property boundary, please navigate to the property page and edit there as well.\"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/notices/mu-shape-warning/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});