define("mldp/helpers/user-action-title", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.userActionTitle = userActionTitle;

  function userActionTitle(_ref) {
    let [value] = _ref;

    switch (value.get('actionType')) {
      case 'AgentAccept':
        {
          return `Property Agent Invitation: ${value.get('property.siteName')}`;
        }

      case 'PropertyAcceptPoolInvite':
        {
          let type = value.get('coOp.siteName') ? 'Co-Op' : 'Aggregate';
          let name = type === 'Co-Op' ? value.get('coOp.siteName') : value.get('aggregate.siteName');
          return `${type} Invitation: ${name}`;
        }

      case 'CoOpManagerInvite':
        {
          let name = value.get('coOp.siteName');
          return `Co-Op Manager Invitation: ${name}`;
        }

      case 'AggregateManagerInvite':
        {
          let name = value.get('aggregate.siteName');
          return `Aggregate Manager Invitation: ${name}`;
        }

      case 'VerifyLandowner':
      case 'VerifyTrackResearchAgreement':
      case 'NavigatePath':
      case 'ShowFeeReceipt':
      case 'SimpleNotification':
        {
          return value.get('notificationTitle');
        }

      default:
        return `action type not found (${value.get('actionType')})`;
    }
  }

  var _default = (0, _helper.helper)(userActionTitle);

  _exports.default = _default;
});