define("mldp/helpers/number-commas", ["exports", "@ember/component/helper", "mldp/utils/number-commas"], function (_exports, _helper, _numberCommas) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.numberCommas = numberCommas;

  function numberCommas(_ref
  /*, hash*/
  ) {
    let [val] = _ref;
    if (!val) return '0';
    return (0, _numberCommas.default)(val);
  }

  var _default = (0, _helper.helper)(numberCommas);

  _exports.default = _default;
});