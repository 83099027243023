define("mldp/helpers/titleize", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.titleize = titleize;

  function titleize(params
  /*, hash*/
  ) {
    return params.map(param => param ? param.capitalize() : '').join('');
  }

  var _default = (0, _helper.helper)(titleize);

  _exports.default = _default;
});