define("mldp/helpers/str", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.str = str;

  function str(_ref
  /*, hash*/
  ) {
    let [val] = _ref;
    return String(val);
  }

  var _default = (0, _helper.helper)(str);

  _exports.default = _default;
});