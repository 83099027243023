define("mldp/components/harvest-calculator/post-harvest/component", ["exports", "@ember/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let HarvestCalculatorPostHarvest = classic(_class = (_class2 = class HarvestCalculatorPostHarvest extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "adultBucksRec", _descriptor, this);

      _initializerDefineProperty(this, "fawnBucksRec", _descriptor2, this);

      _initializerDefineProperty(this, "adultDoesRec", _descriptor3, this);

      _initializerDefineProperty(this, "fawnDoesRec", _descriptor4, this);

      _initializerDefineProperty(this, "acreage", _descriptor5, this);

      _initializerDefineProperty(this, "fawnsOverDoes", _descriptor6, this);

      _defineProperty(this, "s", void 0);
    }

    init() {
      super.init(...arguments);
    }

    update(event) {
      this.fawnsOverDoes = parseFloat(event.target.value || '0'); // console.log('e', e, f )
    }

    get winterAdultBucks() {
      return this.currentPopulation.bucks - this.adultBucksRec;
    }

    get winterAdultDoes() {
      return this.currentPopulation.does - this.adultDoesRec;
      ;
    }

    get winterAdultTotal() {
      return this.winterAdultBucks + this.winterAdultDoes;
      ;
    }

    get winterDoesOverBuck() {
      return this.winterAdultDoes / this.winterAdultBucks || 0;
    }

    get winterFawnBucks() {
      return this.currentPopulation.buckFawns - this.fawnBucksRec;
    }

    get winterFawnDoes() {
      return this.currentPopulation.doeFawns - this.fawnDoesRec;
    }

    get winterFawnTotal() {
      return this.winterFawnBucks + this.winterFawnDoes;
      ;
    }

    get winterFawnTotal() {
      return this.winterFawnBucks + this.winterFawnDoes;
      ;
    }

    get winterAcresOverAdultDeer() {
      return this.acreage / this.winterAdultTotal || 0;
    }

    get winterAcresOverDeer() {
      return this.acreage / (this.winterAdultTotal + this.winterFawnTotal) || 0;
    }

    get winterAdultDeerPerThousand() {
      return Math.round(1000 / this.winterAcresOverAdultDeer, 1) || 0;
    }

    get winterDeerPerThousand() {
      return Math.round(1000 / this.winterAcresOverDeer, 1) || 0;
    }

    get fallAdultBucks() {
      return this.winterAdultBucks + this.winterFawnBucks;
    }

    get fallAdultDoes() {
      return this.winterAdultDoes + this.winterFawnDoes;
    }

    get fallAdultTotal() {
      return this.fallAdultBucks + this.fallAdultDoes;
    }

    get fallFawnTotal() {
      return Math.round(this.fawnsOverDoes * this.fallAdultDoes);
    }

    get fallFawnBucks() {
      return Math.ceil(this.fallFawnTotal / 2);
    }

    get fallFawnDoes() {
      return this.fallFawnTotal - this.fallFawnBucks;
    }

    get fallAcresOverDeer() {
      return Math.round(this.acreage / (this.fallAdultTotal + this.fallFawnTotal), 1) || 0;
    }

    get fallDeerPerThousand() {
      return Math.round(1000 / this.fallAcresOverDeer, 1) || 0;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "adultBucksRec", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "fawnBucksRec", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "adultDoesRec", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "fawnDoesRec", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "acreage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "fawnsOverDoes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "update"), _class2.prototype)), _class2)) || _class;

  _exports.default = HarvestCalculatorPostHarvest;
});