define("mldp/account/property/harvestcalculator/controller", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object"], function (_exports, _controller, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let HarvestCalculatorController = classic(_class = (_class2 = class HarvestCalculatorController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "adultBucksPcnt", _descriptor, this);

      _initializerDefineProperty(this, "fawnBucksPcnt", _descriptor2, this);

      _initializerDefineProperty(this, "adultDoesPcnt", _descriptor3, this);

      _initializerDefineProperty(this, "fawnDoesPcnt", _descriptor4, this);
    }

    get adultBucksRec() {
      return Math.round(this.currentPopulation.bucks * (this.adultBucksPcnt / 100));
    }

    get fawnBucksRec() {
      return Math.round(this.currentPopulation.buckFawns * (this.fawnBucksPcnt / 100));
    }

    get adultDoesRec() {
      return Math.round(this.currentPopulation.does * (this.adultDoesPcnt / 100));
    }

    get fawnDoesRec() {
      return Math.round(this.currentPopulation.doeFawns * (this.fawnDoesPcnt / 100));
    }

    updatePcnt(field, event) {
      this[field] = parseFloat(event.target.value || '0');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "adultBucksPcnt", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "fawnBucksPcnt", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "adultDoesPcnt", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "fawnDoesPcnt", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "updatePcnt", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updatePcnt"), _class2.prototype)), _class2)) || _class;

  _exports.default = HarvestCalculatorController;
});