define("mldp/account/properties/create/controller", ["exports", "@ember/object", "@ember/service", "@ember/controller", "rsvp"], function (_exports, _object, _service, _controller, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CreateController = (_dec = (0, _service.inject)('state'), _dec2 = (0, _service.inject)('notifications'), _dec3 = (0, _service.inject)('store'), _dec4 = (0, _service.inject)('can'), _dec5 = (0, _service.inject)('ajax'), classic(_class = (_class2 = class CreateController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stateService", _descriptor, this);

      _initializerDefineProperty(this, "notificationService", _descriptor2, this);

      _initializerDefineProperty(this, "storeService", _descriptor3, this);

      _initializerDefineProperty(this, "canService", _descriptor4, this);

      _initializerDefineProperty(this, "ajaxService", _descriptor5, this);

      _defineProperty(this, "contactBio", false);
    }

    onPropertySave(property) {
      let ajax = this.ajaxService;
      let isOwner = this.canService.can('be property owner in properties') && property.get('isAgent') !== true;
      this.stateService.set('propertyIsOwner', isOwner);
      return property.validate().then(() => {
        if (property.get('validations.isValid')) {
          return ajax.post('/api/property/HasOverlap', {
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(property)
          }).then(results => {
            if (results.value) {
              return new _rsvp.Promise((resolve, reject) => {
                this.setProperties({
                  npolModalPromiseControls: {
                    resolve,
                    reject
                  },
                  'showNPOLModal': true
                });
              });
            } else if (this.contactBio) {
              return property.save({
                adapterOptions: {
                  contactbiologist: '1'
                }
              });
            } else {
              return property.save();
            }
          });
        }

        return (0, _rsvp.reject)('Validations invalid');
      }).catch(e => {
        let notifications = this.notificationService;

        if (e === 'Validations invalid') {
          return (0, _rsvp.reject)(notifications.addValidationErrors(property));
        } else {
          return (0, _rsvp.reject)(notifications.addApiErrors(e));
        }
      });
    }

    onSave(property) {
      let isOwner = this.canService.can('be property owner in properties') && property.get('isAgent') !== true;
      this.stateService.set('propertyIsOwner', isOwner);
      return property.validate().then(() => {
        if (property.get('validations.isValid')) {
          return property.save();
        }

        return (0, _rsvp.reject)('Validations invalid');
      }).catch(e => {
        let notifications = this.notificationService;

        if (e === 'Validations invalid') {
          return (0, _rsvp.reject)(notifications.addValidationErrors(property));
        } else {
          return (0, _rsvp.reject)(notifications.addApiErrors(e));
        }
      });
    }

    onReset(type) {
      let model = this.data;
      model.rollbackAttributes();
      this.set('data', this.storeService.createRecord(type, {
        isCreateMode: true
      }));
    }

    submitNPOLModal(property) {
      property.save().then(() => {
        return;
      }, results => {
        return this.storeService.query('userAction', {
          'sort': '-ModifiedDate',
          reload: true
        });
      }).then(results => {
        this.setProperties({
          npolModalPromiseControls: null,
          'showNPOLModal': false
        });

        if (results.content[0]) {
          this.transitionToRoute('account.action', results.content[0].id);
        } else {
          this.transitionToRoute('account');
        }
      });
    }

    closeNPOLModal() {
      this.npolModalPromiseControls.resolve('NPOL Cancel');
      this.setProperties({
        npolModalPromiseControls: null,
        'showNPOLModal': false
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notificationService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "storeService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "canService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "ajaxService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "onPropertySave", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onPropertySave"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onSave", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onSave"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onReset", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onReset"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "submitNPOLModal", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "submitNPOLModal"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "closeNPOLModal", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "closeNPOLModal"), _class2.prototype)), _class2)) || _class);
  _exports.default = CreateController;
});