define("mldp/account/property/edit/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Do9Gr2vf",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"Edit \",[30,0,[\"data\",\"property\",\"siteType\"]]],null]],null]],[1,\"\\n\\n\"],[46,[28,[37,3],[\"site-\",[30,0,[\"data\",\"property\",\"siteType\"]],\"-edit\"],null],null,[[\"class\",\"model\",\"counties\",\"units\",\"transitionTo\",\"onSave\",\"onReset\"],[\"container\",[30,0,[\"data\",\"property\"]],[30,0,[\"counties\"]],[30,0,[\"units\"]],[28,[37,4],[\"transitionTo\"],null],[28,[37,4],[\"onSave\"],null],[28,[37,4],[\"onReset\"],null]]],null],[1,\"\\n\"]],[],false,[\"page-banner\",\"titleize\",\"component\",\"concat\",\"route-action\"]]",
    "moduleName": "mldp/account/property/edit/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});