define("mldp/helpers/sum", ["exports", "ember-math-helpers/helpers/sum"], function (_exports, _sum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _sum.default;
    }
  });
  Object.defineProperty(_exports, "sum", {
    enumerable: true,
    get: function () {
      return _sum.sum;
    }
  });
});