define("mldp/components/permit/reporting-status/ph/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AUx3GEvd",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"reporting-status-item\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"collapse-panel\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"header push-up\"],[12],[1,\"\\n        \"],[10,\"strong\"],[12],[1,\"\\n          \"],[1,[30,0,[\"permit\",\"primarySite\",\"siteName\"]]],[1,\" (\"],[1,[28,[35,0],[[30,0,[\"permit\",\"primarySite\",\"siteType\"]]],null]],[1,\")\\n        \"],[13],[1,\"\\n        \"],[10,\"i\"],[15,0,[29,[\"fa \",[52,[30,2],\"fa-angle-up\",\"fa-angle-down\"],\" pull-right text-muted\"]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"herdUnits\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"padded push-right\"],[12],[1,\"\\n          \"],[10,\"strong\"],[12],[1,\"\\n            Herd Unit \"],[1,[30,3,[\"id\"]]],[1,\" Harvest Report:\\n          \"],[13],[1,\"\\n\\n          \"],[10,\"dl\"],[14,0,\"dl-horizontal push-down\"],[12],[1,\"\\n            \"],[10,\"dt\"],[12],[1,\"Submitted\"],[13],[1,\"\\n            \"],[10,\"dd\"],[12],[1,[52,[30,3,[\"submitted\"]],\"Yes\",\"No\"]],[13],[1,\"\\n            \"],[10,\"dt\"],[12],[1,\"On Time\"],[13],[1,\"\\n            \"],[10,\"dd\"],[12],[1,[52,[30,3,[\"onTime\"]],\"Yes\",\"No\"]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],[[[1,\"        \"],[10,0],[14,0,\"padded push-right\"],[12],[1,\"\\n          \"],[10,\"strong\"],[12],[1,\"\\n            No recommendations submitted\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@open\",\"hu\"],false,[\"titleize\",\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "mldp/components/permit/reporting-status/ph/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});