define("mldp/components/simple-notification/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/component"], function (_exports, _component, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SimpleNotification = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = (_class2 = class SimpleNotification extends _component2.default {
    init() {
      super.init(...arguments);
      let data = this.model;
      let siteType = null;
      let siteId = null;
      let siteName = null;
      let countyName = null;

      if (data.get('property')) {
        siteType = 'property';
        siteId = data.get('propertyId');
        siteName = data.get('property.siteName');
        countyName = data.get('property.countyName');
      } else if (data.get('coOp')) {
        siteType = 'coop';
        siteId = data.get('coOpId');
        siteName = data.get('coOp.siteName');
        countyName = data.get('coOp.countyName');
      } else if (data.get('aggregate')) {
        siteType = 'aggregate';
        siteId = data.get('aggregateId');
        siteName = data.get('aggregate.siteName');
        countyName = data.get('aggregate.countyName');
      }

      let enrollmentNotification;

      if (this.model.notificationTitle.includes('New CO Enrollment')) {
        enrollmentNotification = true;
      }

      this.setProperties({
        enrollmentNotification,
        siteType,
        siteId,
        siteName,
        countyName
      });
    }

    complete() {
      let data = this.model;
      return this.onComplete({
        guid: data.get('actionGuid'),
        message: 'You have acknowledged the alert'
      });
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "complete", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "complete"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = SimpleNotification;
});