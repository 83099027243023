define("mldp/account/properties/create/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pLS7wHrh",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"Add a New \",[30,0,[\"type\"]]],null]],null]],[1,\"\\n\\n\"],[41,[28,[37,3],[[30,0,[\"type\"]],\"property\"],null],[[[1,\"  \"],[46,[28,[37,5],[\"site-property-create\"],null],null,[[\"class\",\"model\",\"counties\",\"onSave\",\"onReset\",\"contactBio\"],[\"container\",[30,0,[\"data\"]],[30,0,[\"counties\"]],[52,[28,[37,3],[[30,0,[\"type\"]],\"property\"],null],[28,[37,6],[[30,0],\"onPropertySave\"],null],[28,[37,6],[[30,0],\"onSave\"],null]],[28,[37,6],[[30,0],\"onReset\",[30,0,[\"type\"]]],null],[30,0,[\"contactBio\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[46,[28,[37,5],[\"site-\",[30,0,[\"type\"]],\"-edit\"],null],null,[[\"class\",\"model\",\"counties\",\"onSave\",\"onReset\",\"contactBio\"],[\"container\",[30,0,[\"data\"]],[30,0,[\"counties\"]],[52,[28,[37,3],[[30,0,[\"type\"]],\"property\"],null],[28,[37,6],[[30,0],\"onPropertySave\"],null],[28,[37,6],[[30,0],\"onSave\"],null]],[28,[37,6],[[30,0],\"onReset\",[30,0,[\"type\"]]],null],[30,0,[\"contactBio\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n\\n\"],[41,[30,0,[\"showNPOLModal\"]],[[[1,\"  \"],[8,[39,7],null,[[\"@named\",\"@send\"],[\"modal\",[50,\"modal-window\",0,null,[[\"title\",\"closeLabel\",\"submitLabel\",\"body\",\"close\",\"onsubmit\"],[\"NPOL Error\",\"Cancel\",\"I Understand\",[50,\"npol-warn-modal\",0,null,null],[28,[37,6],[[30,0],\"closeNPOLModal\"],null],[28,[37,6],[[30,0],\"submitNPOLModal\",[30,0,[\"data\"]]],null]]]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"page-banner\",\"titleize\",\"if\",\"eq\",\"component\",\"concat\",\"action\",\"to-elsewhere\"]]",
    "moduleName": "mldp/account/properties/create/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});