define("mldp/components/practice/pretty-print/component", ["exports", "@ember-decorators/component", "@ember/service", "@ember/component"], function (_exports, _component, _service, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PrettyPrint = (_dec = (0, _component.tagName)(''), _dec2 = (0, _service.inject)('state'), classic(_class = _dec(_class = (_class2 = class PrettyPrint extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);
    }

    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      const type = this.type;
      const subType = this.subType;
      const treatment = this.treatment;
      const crop = this.crop;
      const treatmentType = this.treatmentType;
      const deerSeason = this.deerSeason;

      if (type) {
        this.set('display', this.getTypeName(type));
      } else if (subType) {
        this.set('display', this.getSubTypeName(subType));
      } else if (treatment) {
        this.set('display', this.getTreatmentName(treatment));
      } else if (crop) {
        this.set('display', this.getCropName(crop));
      } else if (treatmentType) {
        this.set('display', this.getTreatmentTypeName(treatmentType));
      } else if (deerSeason) {
        this.set('display', this.getDeerSeasoneName(deerSeason));
      } else {
        this.set('display', false);
      }
    }

    getTypeName(type) {
      const types = this.get('state.habitatPracticeTypes');
      const filtered = types.filter(item => type === item.get('identifier'));
      return filtered.get('firstObject.displayValue');
    }

    getTreatmentName(treatment) {
      const treatments = this.get('state.treatments');
      const filtered = treatments.filter(item => treatment === item.get('identifier'));
      return filtered.get('firstObject.displayValue');
    }

    getSubTypeName(subType) {
      const subtypes = this.get('state.subtypes');
      const filtered = subtypes.filter(item => subType === item.get('identifier'));

      if (filtered.length) {
        return filtered.get('0.displayValue');
      }
    }

    getCropName(crop) {
      const crops = this.get('state.crops');
      const filtered = crops.filter(item => crop === item.get('identifier'));

      if (filtered.length) {
        return filtered.get('0.displayValue');
      }
    }

    getTreatmentTypeName(treatmentType) {
      const treatmentTypes = this.get('state.treatmentTypes');
      const filtered = treatmentTypes.filter(item => treatmentType === item.get('identifier'));

      if (filtered.length) {
        return filtered.get('0.displayValue');
      }
    }

    getDeerSeasoneName(deerSeason) {
      const deerSeasons = this.get('state.seasons');
      const filtered = deerSeasons.filter(item => deerSeason === item.get('identifier'));

      if (filtered.length) {
        return filtered.get('0.displayValue');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = PrettyPrint;
});