define("mldp/account/reports/staff-log/route", ["exports", "@ember/routing/route", "@ember/service", "mldp/utils/get-report-auth", "mldp/account/reports/current-year"], function (_exports, _route, _service, _getReportAuth, _currentYear) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // /report/stafflog?biologistId=59329&year=2018
  var _default = _route.default.extend({
    session: (0, _service.inject)('session'),
    ajaxService: (0, _service.inject)('general-ajax'),
    breadCrumb: {
      title: 'Staff Log'
    },

    async model() {
      let report = {
        name: 'Staff Log',
        url: '/report/stafflog',
        formats: ['html', 'excel']
      };
      let currentYear = (0, _currentYear.default)();
      let ajax = this.ajaxService;
      let auth = await (0, _getReportAuth.default)(report, ajax);
      let query = {
        auth,
        year: currentYear,
        log: 'both',
        quarter: 1
      }; // Assign current user as the biologist if they are a biologist

      if (this.get('session.isBiologist')) {
        query.biologistId = this.get('session.userId');
      }

      return {
        report,
        query
      };
    },

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    },

    resetController(controller) {
      controller.set('query', {});
    }

  });

  _exports.default = _default;
});