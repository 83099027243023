define("mldp/components/permit/select-herd-unit/component", ["exports", "@ember/component", "@ember/service", "@ember/object", "ember-concurrency"], function (_exports, _component, _service, _object, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    storeService: (0, _service.inject)('store'),
    stateService: (0, _service.inject)('state'),
    session: (0, _service.inject)('session'),
    routerService: (0, _service.inject)('router'),
    loadHerdUnits: (0, _emberConcurrency.task)(function* () {
      let user = yield this.storeService.findRecord('user', this.session.userId);

      if (!user) {
        return;
      }

      const isAdmin = this.session.roles.includes('Admin');
      const isWPO = this.session.roles.includes('Wildlife Permit Office');
      let herdUnits;

      if (isAdmin || isWPO) {
        let phTallyAdapter = this.storeService.adapterFor('ph-tally');
        let currentseaonId;

        if (this.currentSeasonApplication) {
          currentseaonId = this.currentSeasonApplication.identifier;
        } else {
          // herdUnits = yield this.storeService.findAll('ph-tally');
          let seasonAdapter = this.storeService.adapterFor('tlk-season');
          let reportingSeason = yield seasonAdapter.phFindCurrentReporting();
          currentseaonId = Number(reportingSeason.id) + 1;
        }

        herdUnits = yield phTallyAdapter.findAllLatest();
        return herdUnits.map(hu => hu.herdUnit).sort((a, b) => a - b);
      }

      let adapter = this.storeService.adapterFor('user-xphherdunit');
      herdUnits = yield adapter.getBiologistHerdUnits({
        params: {
          biologistId: user.id
        }
      });
      return herdUnits.sort();
    }).on('init').restartable()
  });

  _exports.default = _default;
});