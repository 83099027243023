define("mldp/account/property/unit/route", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  const queryParams = {
    archivedMu: {
      refreshModel: true
    }
  };

  let UnitRoute = classic(_class = class UnitRoute extends _route.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", queryParams);
    }

    model(_ref) {
      let {
        unitId,
        archivedMu
      } = _ref;
      return this.store.findRecord('management-unit', unitId, {
        reload: true,
        adapterOptions: {
          query: {
            archived: archivedMu
          }
        }
      });
    }

    afterModel(unit) {
      let siteName = unit.get('siteName');
      this.set('breadCrumb', {
        title: `Unit: ${siteName}`
      });
    }

  }) || _class;

  _exports.default = UnitRoute;
});