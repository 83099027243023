define("mldp/components/outreach-view/component", ["exports", "@ember/component", "@ember/service", "ember-concurrency"], function (_exports, _component, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    storeService: (0, _service.inject)('store'),
    stateService: (0, _service.inject)('state'),
    router: (0, _service.inject)('router'),
    entryBy: (0, _emberConcurrency.task)(function* () {
      let store = this.storeService;
      let entryBy = this.get('outreach.entryBy');
      let user = yield store.findRecord('user', entryBy);
      return user;
    }).on('init'),
    actions: {
      duplicate() {
        let router = this.router;
        let outreach = this.outreach;
        let data = outreach.toJSON();
        delete data.eventUserId;
        delete data.eventContactId;
        delete data.outreachDate;
        this.set('stateService.outreachCloneData', data);
        router.transitionTo('account.outreaches.create');
      }

    }
  });

  _exports.default = _default;
});