define("mldp/abilities/enrollment", ["exports", "@ember/object", "@ember/service", "ember-can"], function (_exports, _object, _service, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Enrollment = (_dec = (0, _service.inject)('session'), _dec2 = (0, _service.inject)('app-values'), _dec3 = (0, _service.inject)('role-service'), _dec4 = (0, _service.inject)('state'), _dec5 = (0, _object.computed)('session.isAuthenticated'), _dec6 = (0, _object.computed)('session.isAuthenticated'), _dec7 = (0, _object.computed)('session.isAuthenticated', 'model'), _dec8 = (0, _object.computed)('session.isAuthenticated', 'model'), _dec9 = (0, _object.computed)('session.isAuthenticated', 'model'), _dec10 = (0, _object.computed)('appValues.{agentUserType,biologistUserType,ownerUserType,poolManagerUserType}', 'indirectEnrollment', 'isCurrentSeason', 'model', 'session.isAuthenticated', 'showCancelation', 'site', 'stateService.subordinates'), _dec11 = (0, _object.computed)('appValues.{agentUserType,biologistUserType,ownerUserType,poolManagerUserType}', 'indirectEnrollment', 'isCurrentSeason', 'model', 'session.isAuthenticated', 'showCancelation', 'site', 'stateService.subordinates'), _dec12 = (0, _object.computed)('appValues.biologistUserType', 'isCurrentSeason', 'model', 'session.isAuthenticated', 'stateService.subordinates'), classic(_class = (_class2 = class Enrollment extends _emberCan.Ability {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "appValues", _descriptor2, this);

      _initializerDefineProperty(this, "roleService", _descriptor3, this);

      _initializerDefineProperty(this, "stateService", _descriptor4, this);
    }

    get canSeeAll() {
      let roleService = this.roleService;
      let isAdmin = roleService.get('isAdmin');
      let isTpwd = roleService.isUserInTpwd();

      if (isAdmin || isTpwd) {
        return true;
      }

      return false;
    }

    get canToggleArchived() {
      let roleService = this.roleService;
      let isAdmin = roleService.get('isAdmin');
      return isAdmin;
    }

    get canAccessCoops() {
      let appValues = this.appValues;
      let primarySite = this.model;
      let roleService = this.roleService;
      let isAdmin = roleService.get('isAdmin');
      let isTpwd = roleService.isUserInTpwd();

      if (isAdmin || isTpwd) {
        return true;
      }

      let associated = roleService.associated(primarySite, {
        usersKey: 'associatedUsers'
      });

      if (!associated.length) {
        return false;
      }

      let validRoles;

      if (primarySite.get('SiteType') === 'coOp') {
        validRoles = [appValues.get('poolManagerUserType')];
      } else {
        validRoles = [appValues.get('ownerUserType'), appValues.get('agentUserType')];
      }

      let hasRole = associated.any(user => {
        return validRoles.includes(user.type);
      });

      if (hasRole) {
        return true;
      }

      return false;
    }

    get canAccessTagSearch() {
      let roleService = this.roleService;
      return roleService.isUserInTpwd();
    }

    get canAccessRecommendationStatus() {
      let roleService = this.roleService;
      return roleService.isUserInTpwd();
    } //TODO Simplify these two functions


    get canLeave() {
      let roleService = this.roleService;
      let sessionService = this.session;
      let enrollment = this.model;
      let site = this.site;
      let primarySite = enrollment.primarySite;
      let isAdmin = roleService.get('isAdmin');
      let indirect = this.indirectEnrollment;

      if (!indirect || primarySite.siteType !== 'coOp') {
        return false;
      } else if (isAdmin) {
        return true;
      }

      let validTypes = [this.get('appValues.poolManagerUserType'), this.get('appValues.ownerUserType'), this.get('appValues.agentUserType'), this.get('appValues.biologistUserType')];
      let associatedExternal = roleService.associated(site);
      let associatedInternal = roleService.associated(primarySite);
      let isValidType = associatedExternal.any(user => validTypes.includes(user.type)) || associatedInternal.any(user => validTypes.includes(user.type));
      let userAllowed = isValidType;
      let isSupervisor = sessionService.isSupervisor;

      if (isSupervisor && !userAllowed) {
        let subordinates = this.stateService.subordinates;

        if (subordinates) {
          //TODO: track down which sites have this field capitalized and which don't
          let associatedUsers = site.associatedUsers;
          let any = associatedUsers.any(user => subordinates.includes(user.userId));
          userAllowed = any;
        }
      }

      let isCurrentSeason = this.isCurrentSeason;
      let showCancelation = this.showCancelation;
      return userAllowed && isCurrentSeason && showCancelation;
    }

    get canCancel() {
      let sessionService = this.session;
      let roleService = this.roleService;
      let isAdmin = roleService.get('isAdmin');
      let indirect = this.indirectEnrollment;

      if (indirect) {
        return false;
      } else if (isAdmin) {
        return true;
      }

      let validPoolTypes = [this.get('appValues.poolManagerUserType'), this.get('appValues.biologistUserType')];
      let validPropertyTypes = [this.get('appValues.ownerUserType'), this.get('appValues.agentUserType'), this.get('appValues.biologistUserType')];
      let site = this.site;
      let associated = roleService.associated(site);
      let isValidType = site.isPool ? associated.any(user => validPoolTypes.includes(user.type)) : associated.any(user => validPropertyTypes.includes(user.type));
      let userAllowed = isValidType;
      let isSupervisor = sessionService.isSupervisor;

      if (isSupervisor && !userAllowed) {
        let subordinates = this.stateService.subordinates;

        if (subordinates) {
          let any = site.associatedUsers.any(user => subordinates.includes(user.userId));
          userAllowed = any;
        }
      }

      let isCurrentSeason = this.isCurrentSeason;
      let showCancelation = this.showCancelation;
      return userAllowed && isCurrentSeason && showCancelation;
    }

    get canEdit() {
      let roleService = this.roleService;
      let isCurrentSeason = this.isCurrentSeason;
      let isAdmin = roleService.get('isAdmin');

      if (isCurrentSeason) {
        let enrollment = this.model;

        if (parseInt(enrollment.enrollmentTypeId) !== 2) {
          return false;
        }

        if (enrollment.primarySite.siteType === 'aggregate') {
          return false;
        }

        if (isAdmin) {
          return true;
        }

        let validTypes = [this.get('appValues.biologistUserType')];
        let site = enrollment.primarySite;
        let isTpwd = roleService.isUserInTpwd();
        let associated = roleService.associated(site, {
          usersKey: 'associatedUsers'
        });
        let associatedTpwd = associated.length !== 0 && isTpwd;
        let isValidType = associated.any(user => validTypes.includes(user.type));
        let sessionService = this.session;
        let isSupervisor = sessionService.isSupervisor;
        let userAllowed = associatedTpwd || isValidType;

        if (isSupervisor && !userAllowed) {
          let subordinates = this.stateService.subordinates;

          if (subordinates) {
            let any = site.associatedUsers.any(user => subordinates.includes(user.userId));
            userAllowed = any;
          }
        }

        return userAllowed;
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "appValues", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "roleService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "canSeeAll", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "canSeeAll"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canToggleArchived", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "canToggleArchived"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canAccessCoops", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "canAccessCoops"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canAccessTagSearch", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "canAccessTagSearch"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canAccessRecommendationStatus", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "canAccessRecommendationStatus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canLeave", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "canLeave"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canCancel", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "canCancel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canEdit", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "canEdit"), _class2.prototype)), _class2)) || _class);
  _exports.default = Enrollment;
});