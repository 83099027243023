define("mldp/utils/within-date-range", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = withinDateRange;

  function withinDateRange(startDate, endDate) {
    let currentDate = new Date();
    let compareDate = (0, _moment.default)(currentDate, 'DD/MM/YYYY');
    let startDateFormatted = (0, _moment.default)(startDate, 'YYYY-MM-DD');
    let endDateFormatted = (0, _moment.default)(endDate, 'YYYY-MM-DD');
    return compareDate.isBetween(startDateFormatted, endDateFormatted);
  }
});