define("mldp/account/property/invite/manager/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "@ember/application", "mldp/models/invite-model"], function (_exports, _object, _service, _route, _application, _inviteModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ManagerRoute = (_dec = (0, _service.inject)('notifications'), classic(_class = (_class2 = class ManagerRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);
    }

    setupController(controller, model) {
      let invite = _inviteModel.default.create((0, _application.getOwner)(this).ownerInjection());

      controller.setProperties({
        model,
        invite
      });
    }

    onSave(data) {
      return data.validate().then(() => {
        if (data.get('validations.isValid')) {
          let site = this.get('controller.model.property');
          let siteId = this.get('controller.model.property.id');
          let siteType = this.get('controller.model.property.siteType');
          let notifications = this.notifications;
          let adapter = this.store.adapterFor(siteType);
          return adapter.inviteManager(siteId, data).then(() => site.reload()).then(() => this.transitionTo('account.property', siteType, siteId)).then(() => notifications.add('You have invited a new manager')).catch(error => {
            if (error.jqXHR) {
              if (error.jqXHR.status === 403 || error.jqXHR.status === 404 || error.jqXHR.status === 409) {
                return this.transitionTo('account.properties.index').then(() => notifications.error(error.payload.errors[0].message));
              }
            } else if (error.errors) {
              return this.transitionTo('account.property', siteType, siteId).then(() => notifications.error(error.errors[0].detail));
            } else {
              console.error('invite landowner error', {
                error
              });
              notifications.error('An error has occurred');
            }
          });
        }
      });
    }

    onCancel() {
      let id = this.get('controller.model.property.id');
      let type = this.get('controller.model.property.siteType');
      this.transitionTo('account.property', type, id);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "onSave", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onSave"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onCancel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onCancel"), _class2.prototype)), _class2)) || _class);
  _exports.default = ManagerRoute;
});