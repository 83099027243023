define("mldp/components/survey-season/header/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  // import { tagName } from "@ember-decorators/component";
  const classic = __EMBER_CLASSIC_DECORATOR; // import { inject as service } from '@ember/service';

  let SurveySeasonHeader = // @tagName("")
  classic(_class = class SurveySeasonHeader extends _component.default {
    // @service('role-service')
    // roleService;
    init() {
      super.init(...arguments); // let isUserInTpwd = this.roleService.isUserInTpwd();
      // this.set('showPropertyLink', (isUserInTpwd && this.get('site.siteType') !== 'property'));
    }

  }) || _class;

  _exports.default = SurveySeasonHeader;
});