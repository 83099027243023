define("mldp/components/reports/permit-site-specific/component", ["exports", "@ember/object", "@ember-decorators/component", "@ember/service", "@ember/component"], function (_exports, _object, _component, _service, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PermitSiteSpecific = (_dec = (0, _component.tagName)(""), _dec2 = (0, _service.inject)('session'), _dec3 = (0, _service.inject)('state'), _dec(_class = classic(_class = (_class2 = class PermitSiteSpecific extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionService", _descriptor, this);

      _initializerDefineProperty(this, "stateService", _descriptor2, this);
    }

    init() {
      super.init(...arguments);
      let query = this.query;
      let sessionService = this.sessionService;
      let isAdmin = sessionService.isAdmin;
      let isSupervisor = sessionService.isSupervisor;
      let years = this.get('stateService.permitSeasonOptions');
      let year = years.findBy('id', String(query.year));
      let biologists = this.get('stateService.biologists');
      let biologist = biologists.findBy('id', String(query.biologistId));

      if (!isAdmin && isSupervisor) {
        let subordinates = this.stateService.subordinates;

        if (subordinates) {
          biologists = subordinates.map(id => biologists.findBy('id', String(id))).filter(bio => !!bio);
        }
      }

      this.setProperties({
        biologist,
        biologists,
        year,
        county: query.countyid
      });
    }

    updateBiologist(biologist) {
      if (biologist) {
        this.onChange('biologistId', biologist.id);
        this.set('biologist', biologist);
      } else {
        this.onChange('biologistId', null);
        this.set('biologist', null);
      }
    }

    updateYear(season) {
      this.onChange('year', season.id);
      this.set('year', season);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "sessionService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "updateBiologist", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateBiologist"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateYear", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateYear"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = PermitSiteSpecific;
});