define("mldp/components/permit/ph-reporting/reapply-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7WIg61rD",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"Harvest report Submitted successfully for the \"],[1,[30,0,[\"currentSeason\",\"displayValue\"]]],[13],[1,\"\\n  \"],[10,2],[12],[1,\"Would you like to submit your application for pronghorn permits for next season now?\"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/permit/ph-reporting/reapply-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});