define("mldp/components/permit/ph-reporting/change-property-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "M8Rroj9X",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"Do you need to make any changes to the property map shown on this screen for your next application (e.g., add acreage, remove acreage, etc.)?\"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/permit/ph-reporting/change-property-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});