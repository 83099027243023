define("mldp/components/verify-landowner/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    actions: {
      complete() {
        let data = this.model;
        return this.onComplete({
          guid: data.get('actionGuid'),
          message: 'You have accepted the landowner agreement'
        });
      },

      decline() {
        return this.decline();
      }

    }
  });

  _exports.default = _default;
});