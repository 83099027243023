define("mldp/confirm-email/route", ["exports", "@ember/service", "rsvp", "@ember/routing/route"], function (_exports, _service, _rsvp, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ConfirmEmailRoute = (_dec = (0, _service.inject)('notifications'), classic(_class = (_class2 = class ConfirmEmailRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);
    }

    model(_ref) {
      let {
        confirmationCode
      } = _ref;
      let adapter = this.store.adapterFor('user');
      let notifications = this.notifications;
      return adapter.confirmEmailAddress(confirmationCode).then(() => this.transitionTo('login')).then(() => notifications.add('Your email address has been confirmed. You may now log in.')).catch(e => {
        console.log(e);

        try {
          let error = e.errors[0];

          if (error.status === '404') {
            return (0, _rsvp.reject)({
              errors: [{
                Status: 404
              }]
            });
          } else {
            return (0, _rsvp.reject)({
              message: error.title
            });
          }
        } catch (ex) {// noop
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = ConfirmEmailRoute;
});