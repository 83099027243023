define("mldp/components/reporting-status-tabs/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yZvkMXds",
    "block": "[[[10,\"ul\"],[14,0,\"nav nav-tabs\"],[14,\"role\",\"tablist\"],[12],[1,\"\\n  \"],[10,\"li\"],[15,0,[52,[28,[37,1],[[30,1],\"units\"],null],\"active\"]],[12],[1,\"\\n    \"],[10,3],[14,\"role\",\"button\"],[15,\"onclick\",[28,[37,2],[[30,0],[30,2],\"units\"],null]],[12],[1,\"Reporting Status\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"li\"],[15,0,[52,[28,[37,1],[[30,1],\"reporting-details\"],null],\"active\"]],[12],[1,\"\\n    \"],[10,3],[14,\"role\",\"button\"],[15,\"onclick\",[28,[37,2],[[30,0],[30,2],\"reporting-details\"],null]],[12],[1,\"Reporting Requirements\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@currentTab\",\"@navigate\"],false,[\"if\",\"eq\",\"action\"]]",
    "moduleName": "mldp/components/reporting-status-tabs/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});