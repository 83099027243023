define("mldp/account/reports/current-year", ["exports", "mldp/utils/date-quarter"], function (_exports, _dateQuarter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    let date = new Date();
    let currentYear = date.getUTCFullYear();
    let currentQuarter = (0, _dateQuarter.default)(date);
    let maxYear = currentQuarter > 1 ? currentYear + 1 : currentYear;
    return maxYear;
  }
});