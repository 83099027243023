define("mldp/components/app-map-editable/component", ["exports", "@ember-decorators/object", "@ember/object", "@ember/runloop", "leaflet", "@turf/turf", "@turf/intersect", "ember-leaflet/components/base-layer", "mldp/utils/remove-slivers"], function (_exports, _object, _object2, _runloop, _leaflet, _turf, _intersect, _baseLayer, _removeSlivers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  const drawOptions = {
    polyline: false,
    polygon: {
      allowIntersection: false,
      // Restricts shapes to simple polygons
      drawError: {
        color: '#e1e100',
        // Color the shape will turn when intersects
        message: '<strong>Sorry, the property drawn is not acceptable due to intersecting boundary lines. Please redraw.</strong>' // Message that will show when intersect

      },
      shapeOptions: {
        color: '#ff0000'
      }
    },
    // rectangle: {
    //   shapeOptions: {
    //     color: '#ff0000'
    //   }
    // },
    rectangle: false,
    circle: false,
    // Turns off this drawing tool
    marker: false
  };
  let AppMapEditable = (_dec = (0, _object.observes)('geoJSON'), classic(_class = (_class2 = class AppMapEditable extends _baseLayer.default {
    /* eslint-disable ember/no-observers */
    shapeObserver() {
      var map = this.parentComponent._layer;
      var editableLayers = this.editableLayers;
      var geoJSON = this.geoJSON;

      if (JSON.stringify(editableLayers.toGeoJSON()) === JSON.stringify(geoJSON)) {
        return;
      }

      editableLayers.clearLayers();

      if (geoJSON) {
        let valLayer = _leaflet.default.geoJSON(geoJSON, {
          style() {
            return {
              color: '#ff0000'
            };
          }

        });

        valLayer.eachLayer(function (layer) {
          if (layer.eachLayer) {
            layer.eachLayer(function (alayer) {
              editableLayers.addLayer(alayer);
            });
          } else {
            editableLayers.addLayer(layer);
          }
        });
        map.fitBounds(editableLayers.getBounds());

        if (this.forceSinglePoly) {
          this.disableDrawTools();
        }
      }
    }
    /* eslint-enable ember/no-observers */


    didReceiveAttrs() {
      var geoJSON = this.geoJSON;

      if (this.forceSinglePoly && geoJSON) {
        this.disableDrawTools();
      } else {
        this.enableDrawTools();
      }
    }

    didInsertParent() {
      _runloop.run.scheduleOnce('afterRender', () => {
        let map = this.parentComponent._layer;
        this._layer = this.createLayer();

        this._addObservers();

        this._addEventListeners();

        if (map) {
          this._layer.addTo(map); // TODO: add measure tool

        }

        this.didCreateLayer();
      });
    } // cleanup eventbus handlers


    willDestroyParent() {
      let map = this.parentComponent._layer;
      let {
        drawCreated,
        drawEdited,
        drawDeleted,
        drawBoundary,
        editBoundary,
        deleteBoundary
      } = this;
      map.off('draw:created', drawCreated);
      map.off('draw:edited', drawEdited);
      map.off('draw:deleted', drawDeleted);
      this.eventsBus.off('appMapEditable:drawBoundary', this, drawBoundary);
      this.eventsBus.off('appMapEditable:editBoundary', this, editBoundary);
      this.eventsBus.off('appMapEditable:deleteBoundary', this, deleteBoundary);
    }

    createLayer() {
      var component = this;
      var map = this.parentComponent._layer;
      var editableLayers = this.set('editableLayers', new _leaflet.default.FeatureGroup());
      var geoJSON = this.geoJSON;
      map.addLayer(editableLayers);
      editableLayers.bringToFront();

      if (geoJSON) {
        let valLayer = _leaflet.default.geoJSON(geoJSON, {
          style() {
            return {
              color: '#ff0000'
            };
          }

        });

        valLayer.eachLayer(function (layer) {
          if (layer.eachLayer) {
            layer.eachLayer(function (alayer) {
              editableLayers.addLayer(alayer);
            });
          } else {
            editableLayers.addLayer(layer);
          }
        });
        map.fitBounds(editableLayers.getBounds());
      }

      let options = {
        position: 'topright',
        draw: drawOptions,
        edit: {
          featureGroup: editableLayers //REQUIRED!!

        }
      };
      let onGeoJSONUpdate = component.get('onGeoJSONUpdate');

      let drawCreated = function drawCreatedHandler(e) {
        let propertyGeoJson = component.get('propertyGeoJson');
        let units = component.get('units');
        let unitsGeoJson = units && units.filter(unit => unit.get('geoJSON')).mapBy('geoJSON'); // Check if all the space is occupied first, only if units available

        if (unitsGeoJson && unitsGeoJson.length) {
          let propertyFeature = propertyGeoJson.features ? propertyGeoJson.features[0] : _turf.default.feature(propertyGeoJson);
          let unitFeatures = unitsGeoJson.map(all => {
            if (all.features) {
              return all.features.length && all.features[0];
            } else {
              return _turf.default.feature(all);
            }
          });

          let unitsUnion = _turf.default.union(...unitFeatures);

          let intersection = (0, _intersect.default)(unitsUnion, propertyFeature);

          let difference = _turf.default.difference(propertyFeature, intersection);

          if (!difference) {
            return alert('Existing management units already occupy all available space, please edit those first before adding a new management unit');
          }
        }

        let layer = e.layer;
        let clippedLayer;

        try {
          clippedLayer = component.get('clipToParent').call(component, editableLayers, layer);
        } catch {
          return alert('Existing management units already occupy all available space, please edit those first before adding a new management unit');
        }

        if (clippedLayer) {
          if (component.get('forceSinglePoly')) {
            editableLayers.clearLayers();
            component.disableDrawTools();
          }

          editableLayers.addLayer(clippedLayer);

          if (onGeoJSONUpdate) {
            let layerGeoJson = editableLayers.toGeoJSON();
            onGeoJSONUpdate(layerGeoJson);
          }
        }
      };

      let drawEdited = function drawEditedHandler(e) {
        component.get('clipToParent').call(component, editableLayers, e.layers);

        if (onGeoJSONUpdate) {
          if (editableLayers.toGeoJSON().features.length === 0) {
            onGeoJSONUpdate(null);
          } else {
            onGeoJSONUpdate(editableLayers.toGeoJSON());
          }
        }
      };

      let drawDeleted = function drawDeletedHandler() {
        if (onGeoJSONUpdate) {
          if (editableLayers.toGeoJSON().features.length === 0) {
            onGeoJSONUpdate(null);

            if (component.get('forceSinglePoly')) {
              component.enableDrawTools();
            }
          } else {
            onGeoJSONUpdate(editableLayers.toGeoJSON());
          }
        }
      };

      let drawBoundary = function drawBoundaryHandler() {
        this.drawControl._toolbars.draw._modes.polygon.handler.enable();
      };

      let editBoundary = function editBoundaryHandler() {
        this.drawControl._toolbars.edit._modes.edit.handler.enable();
      };

      let deleteBoundary = function deleteBoundaryHandler() {
        editableLayers.clearLayers();

        if (onGeoJSONUpdate) {
          onGeoJSONUpdate(null);
        }
      };

      map.on('draw:created', drawCreated);
      map.on('draw:edited', drawEdited);
      map.on('draw:deleted', drawDeleted);
      this.eventsBus.on('appMapEditable:drawBoundary', this, drawBoundary);
      this.eventsBus.on('appMapEditable:editBoundary', this, editBoundary);
      this.eventsBus.on('appMapEditable:deleteBoundary', this, deleteBoundary);

      if (this.drawSearch) {
        _leaflet.default.drawLocal.draw.toolbar.buttons.polygon = 'Click to draw a search area!';
      } else {
        _leaflet.default.drawLocal.draw.toolbar.buttons.polygon = 'Draw a polygon';
      }

      let drawControl = new _leaflet.default.Control.Draw(options);
      this.setProperties({
        drawControl,
        drawCreated,
        drawEdited,
        drawDeleted,
        drawBoundary,
        editBoundary,
        deleteBoundary
      });

      if (geoJSON && this.forceSinglePoly) {
        this.disableDrawTools();
      }

      return drawControl;
    }

    clipToParent(editableLayers, layer) {
      let layers = layer.getLayers ? layer.getLayers() : [layer];
      let propertyGeoJson = this.propertyGeoJson;
      let units = this.units;

      try {
        if (units && units.length > 0) {
          let unitsGeoJson = units.filter(unit => {
            let geojson = unit.get('geoJSON');

            if (!geojson || !geojson.coordinates) {
              return false;
            }

            return geojson.coordinates.length > 0;
          }).map(unit => {
            let json = unit.get('geoJSON');

            if (json.type === 'FeatureCollection') {
              json = _turf.default.combine(json).features[0];
            } else if (json.type !== 'Feature') {
              json = _turf.default.feature(json);
            }

            json.geometry.coordinates = json.geometry.coordinates.map(coords => (0, _removeSlivers.default)(coords, 1, 0.00001));
            return json;
          }).filter(geojson => {
            let length = (0, _object2.get)(geojson, 'geometry.coordinates.length');
            return length > 0;
          });

          if (unitsGeoJson && unitsGeoJson.length) {
            let unitsUnioned = unitsGeoJson.reduce((result, item) => {
              if (result) {
                result = _turf.default.union(result, item);
              } else {
                result = item;
              }

              return result;
            }, null);
            units = unitsUnioned;
          } else {
            units = null;
          }
        } else {
          units = null;
        }
      } catch (err) {
        console.warn(err);
        units = null;
      }

      window.MUs = units;
      layers.forEach(drawnLayer => {
        let drawnGeoJson = drawnLayer.toGeoJSON();

        let newFeature = _turf.default.truncate(drawnGeoJson);

        if (newFeature) {
          // Only keep the portion of our geometry that intersects with the property shape
          if (propertyGeoJson) {
            let parentFeature;

            if (propertyGeoJson.type === 'FeatureCollection') {
              parentFeature = _turf.default.combine(propertyGeoJson).features[0];
            } else {
              parentFeature = propertyGeoJson;
            }

            newFeature = (0, _intersect.default)(parentFeature, newFeature);
          } // cut out units from our geom


          if (units) {
            newFeature = _turf.default.difference(newFeature, units);

            if (!newFeature) {
              throw new Error('cannot create MU, no area after clipping');
            }
          }

          newFeature = _turf.default.truncate(newFeature);
          let processed; // prevents edge case where multipolygon consists of seperate sliver polygons which would fail remove slivers

          if (newFeature.geometry.type === 'MultiPolygon') {
            let i = 0,
                found = false;

            while (i < newFeature.geometry.coordinates.length && !found) {
              let coordinates = newFeature.geometry.coordinates[i];

              try {
                processed = (0, _removeSlivers.default)(coordinates[0], 1, 0.00001);
                found = true;
              } catch {
                i++;
              }
            }
          } else {
            processed = (0, _removeSlivers.default)(newFeature.geometry.coordinates[0], 1, 0.00001);
          } // let coordinates = newFeature.geometry.type === 'MultiPolygon' ? newFeature.geometry.coordinates[0] : newFeature.geometry.coordinates;


          let latLngs = this.arrayToLatLngArray(processed);

          if (!latLngs.length) {
            throw new Error('cannot create MU, no area after clipping');
          }

          drawnLayer.setLatLngs(latLngs);

          if (drawnLayer.editing._updateLatLngs) {
            drawnLayer.editing._updateLatLngs({
              layer: drawnLayer
            });
          }
        } else {
          if (editableLayers.hasLayer(drawnLayer)) {
            editableLayers.removeLayer(drawnLayer);
          }

          drawnLayer.remove();

          if (layers.length === 1) {
            return null;
          }
        }

        window.newFeature = newFeature;
      }); //window.testLayers = {layer, propertyGeoJson, layers};

      return layer;
    }

    arrayToLatLngArray(a) {
      let output = [];
      let arrayToLatLngArray = this.arrayToLatLngArray;

      for (var i = 0; i < a.length; i++) {
        let c = a[i];

        if (Array.isArray(c) && Array.isArray(c[0])) {
          output.push(arrayToLatLngArray.call(this, c));
        } else {
          output.push(_leaflet.default.latLng(c[1], c[0]));
        }
      }

      return output;
    }

    addToContainer() {
      let map = this.parentComponent._layer;
      map.addControl(this._layer);
    } // Leaflet draw doesn't have proper hooks to disable
    // the toolbars dynamically, at least none that work.


    disableDrawTools() {
      let control = this.drawControl;
      (0, _runloop.scheduleOnce)('afterRender', this, () => {
        if (!control) {
          return;
        }

        control._toolbars.draw._toolbarContainer.style.display = 'none';
        control._toolbars.edit._toolbarContainer.style['margin-top'] = 0;
      });
    }

    enableDrawTools() {
      let control = this.drawControl;
      (0, _runloop.scheduleOnce)('afterRender', this, () => {
        if (!control) {
          return;
        }

        control._toolbars.draw._toolbarContainer.style.display = 'block';
        control._toolbars.edit._toolbarContainer.style['margin-top'] = '12px';
      });
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "shapeObserver", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "shapeObserver"), _class2.prototype)), _class2)) || _class);
  _exports.default = AppMapEditable;
});