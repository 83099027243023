define("mldp/components/deer-population/season-summary-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8OjLnBmq",
    "block": "[[[1,\"\\n\"],[6,[39,0],[[30,0,[\"summary\"]]],null,[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"row form-group\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[30,1,[\"select-field\"]],[\"deerSeasonId\"],[[\"options\",\"label\",\"optionTargetPath\",\"optionLabelPath\",\"prompt\",\"promptIsSelectable\",\"hideAsterisk\",\"required\"],[[30,0,[\"seasons\"]],\"Hunting Season\",\"identifier\",\"displayValue\",\"Please Select Reporting Year\",false,true,true]]]],[1,\"\\n        \"],[1,[28,[30,1,[\"select-field\"]],[\"speciesId\"],[[\"options\",\"label\",\"optionTargetPath\",\"optionLabelPath\",\"prompt\",\"promptIsSelectable\",\"hideAsterisk\",\"required\"],[[30,0,[\"species\"]],\"Species Type\",\"identifier\",\"displayValue\",\"Please Select a Species\",false,true,true]]]],[1,\"\\n        \"],[1,[28,[30,1,[\"number-field\"]],[\"bucks\"],[[\"label\"],[\"Bucks\"]]]],[1,\"\\n        \"],[1,[28,[30,1,[\"number-field\"]],[\"does\"],[[\"label\"],[\"Does\"]]]],[1,\"\\n        \"],[1,[28,[30,1,[\"number-field\"]],[\"fawns\"],[[\"label\"],[\"Fawns\"]]]],[1,\"\\n        \"],[1,[28,[30,1,[\"textarea-field\"]],[\"notes\"],[[\"label\"],[\"Notes (optional)\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]]],[\"f\"],false,[\"form-for\"]]",
    "moduleName": "mldp/components/deer-population/season-summary-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});