define("mldp/components/calendar-field/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const CalendarFieldComponent = _component.default.extend({
    init() {
      this._super(...arguments);

      this.today = new Date();
      this.set('months', ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);
      this.centerMonth = this.months[this.today.getMonth()];
      this.centerYear = this.today.getYear() + 1900 + '';
    },

    years: Array(...Array(85)).map((_, i) => `${i + 1940}`),
    actions: {
      changeCenter(unit, calendar, e) {
        let newCenter = new Date(calendar.center);

        if (unit === 'month') {
          newCenter.setMonth(this.months.indexOf(e.target.value));
        } else if (unit === 'year') {
          newCenter.setFullYear(e.target.value);
        }

        calendar.actions.changeCenter(newCenter);
      }

    }
  });

  CalendarFieldComponent.reopenClass({
    positionalParams: ['positionalParamValue']
  });
  var _default = CalendarFieldComponent;
  _exports.default = _default;
});