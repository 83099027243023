define("mldp/components/permit/reporting-status/ph/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Ph = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class Ph extends _component2.default {
    init() {
      super.init(...arguments);
      let herdUnits = [];
      this.permit.phPartitions.forEach(partition => {
        let huHasRec = partition.phRecommendations.some(rec => rec.recommendedBucks > 0);
        if (!huHasRec) return;
        let hu = {
          id: partition.herdUnit,
          submitted: false,
          onTime: false
        };
        let foundHarvest = this.harvests.find(harvest => harvest.herdUnit === hu.id);

        if (foundHarvest) {
          Object.assign(hu, foundHarvest);
        }

        herdUnits.push(hu);
      });
      this.set('herdUnits', herdUnits);
    }

  }) || _class) || _class);
  _exports.default = Ph;
});