define("mldp/components/loading-iframe/component", ["exports", "@ember/component", "@ember/runloop"], function (_exports, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let LoadingIframe = classic(_class = class LoadingIframe extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "loading", true);
    }

    init() {
      super.init(...arguments);
      this.onFinishedLoading = this.finishedLoading.bind(this);
    }

    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.set('loading', true); // Clean up last event

      if (this.element) {
        this.$('iframe').off('load', this.onFinishedLoading);
      }

      (0, _runloop.scheduleOnce)('afterRender', this, () => {
        if (!this.element) {
          return;
        }

        this.$('iframe').on('load', this.onFinishedLoading);
      });
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.$('iframe').off('load', this.onFinishedLoading);
    }

    finishedLoading() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      this.set('loading', false);
    }

  }) || _class;

  _exports.default = LoadingIframe;
});