define("mldp/components/merge-attribute-select/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Dj5Qf7Fy",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,\"input\"],[15,3,[30,0,[\"field\",\"attribute\"]]],[15,2,[28,[37,0],[[30,0,[\"data\"]],[30,0,[\"field\",\"attribute\"]]],null]],[15,\"onchange\",[28,[37,1],[[30,0],[30,0,[\"onSelect\"]],[30,0,[\"field\",\"attribute\"]],[28,[37,0],[[30,0,[\"data\"]],[30,0,[\"field\",\"attribute\"]]],null]],null]],[15,\"checked\",[30,0,[\"preselected\"]]],[14,4,\"radio\"],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"field\",\"displayAttr\"]],[[[1,\"    \"],[1,[28,[35,0],[[30,0,[\"data\"]],[28,[37,3],[[30,0,[\"field\",\"attribute\"]],\".\",[30,0,[\"field\",\"displayAttr\"]]],null]],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,0],[[30,0,[\"data\"]],[30,0,[\"field\",\"attribute\"]]],null]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\"],false,[\"get\",\"action\",\"if\",\"concat\"]]",
    "moduleName": "mldp/components/merge-attribute-select/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});