define("mldp/account/property/project/edit/controller", ["exports", "@ember/controller", "@ember/object", "@ember/service", "mldp/utils/area-in-acres"], function (_exports, _controller, _object, _service, _areaInAcres) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountPropertyProjectEditController = (_dec = (0, _service.inject)('notifications'), _dec2 = (0, _service.inject)('router'), (_class = class AccountPropertyProjectEditController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "routerService", _descriptor2, this);
    }

    async save() {
      let notifications = this.notifications;

      try {
        const cleanRegex = /[\s$,]/g;
        const nonInt = /[^0-9]/g;

        if (typeof this.project.totalCost === 'string') {
          this.project.totalCost = this.project.totalCost.toString().replaceAll(cleanRegex, '');

          if (this.project.totalCost.match(nonInt)) {
            notifications.error('Total Cost must be an integer');
            return;
          }
        }

        if (typeof this.project.statedProjectAcreage === 'string') {
          this.project.statedProjectAcreage = this.project.statedProjectAcreage.replaceAll(cleanRegex, '');

          if (this.project.statedProjectAcreage.match(nonInt)) {
            notifications.error('Project Acreage must be an integer');
            return;
          }
        }

        await this.project.save();
        let {
          id,
          siteType
        } = this.property;
        await this.transitionToRoute('account.property.project', siteType, id, this.project.id);
        notifications.add('Habitat Project changes submitted');
      } catch (error) {
        notifications.addApiErrors(error);
      }
    }

    updateGeom(value) {
      let {
        project
      } = this;
      project.set('geoJSON', value);

      if (value) {
        let area = (0, _areaInAcres.default)(value);
        this.set('areaMapped', area);
      } else {
        this.set('areaMapped', 0);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "routerService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateGeom", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateGeom"), _class.prototype)), _class));
  _exports.default = AccountPropertyProjectEditController;
});