define("mldp/components/async-button/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Rf+7VVsb",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0],[30,0,[\"_stateObject\"]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[34,3]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"text\"]]",
    "moduleName": "mldp/components/async-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});