define("mldp/account/permit/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GbntQMwj",
    "block": "[[[1,[28,[35,0],[\"Viewing Permit\"],null]],[1,\"\\n\"],[46,[28,[37,2],[\"site-\",[30,0,[\"site\",\"siteType\"]],\"-card\"],null],null,[[\"site\"],[[30,0,[\"site\"]]]],null],[1,\"\\n\"],[1,[28,[35,3],null,[[\"permit\",\"site\",\"editAcreage\",\"cancelApplication\",\"currentSeason\",\"units\",\"permitType\"],[[30,0,[\"application\"]],[30,0,[\"site\"]],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"editAcreage\"]]],null],true],null],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"showDeleteModal\"]]],null],true],null],[30,0,[\"currentSeason\"]],[30,0,[\"units\"]],[30,0,[\"permitType\"]]]]]],[1,\"\\n\"],[41,[30,0,[\"editAcreage\"]],[[[1,\"  \"],[8,[39,7],null,[[\"@named\",\"@send\"],[\"modal\",[50,\"modal-window\",0,null,[[\"title\",\"body\",\"onsubmit\",\"close\",\"submitLabel\"],[\"Edit Crop Acreage\",[50,\"permit/add-acreage\",0,null,[[\"data\",\"currentSeason\"],[[30,0,[\"application\"]],[30,0,[\"currentSeason\"]]]]],[28,[37,4],[[30,0],\"saveApplication\",[30,0,[\"application\"]]],null],[28,[37,4],[[30,0],[28,[37,8],[[28,[37,4],[[30,0],\"cancleEdit\",[30,0,[\"application\"]]],null],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"editAcreage\"]]],null],false],null]],null]],null],\"Submit\"]]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showDeleteModal\"]],[[[1,\"  \"],[8,[39,7],null,[[\"@named\",\"@send\"],[\"modal\",[50,\"modal-window\",0,null,[[\"title\",\"body\",\"submitLabel\",\"onsubmit\",\"close\"],[\"Delete Confirmation\",[50,\"delete-confirmation\",0,null,[[\"itemLabel\"],[\"permit application\"]]],\"Confirm\",[28,[37,4],[[30,0],\"onDelete\",[30,0,[\"application\"]]],null],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"showDeleteModal\"]]],null],false],null]]]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"page-banner\",\"component\",\"concat\",\"permit/permit-details\",\"action\",\"mut\",\"if\",\"to-elsewhere\",\"pipe\"]]",
    "moduleName": "mldp/account/permit/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});