define("mldp/utils/calculate-reporting-year", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = calculateReportingYear;

  function calculateReportingYear(currentSeason) {
    let date = new Date();
    let month = date.getMonth();
    let isMarchOrApril = month === 2 || month === 3;
    return isMarchOrApril ? currentSeason - 1 : currentSeason;
  }
});