define("mldp/components/permit/select-county/component", ["exports", "@ember/component", "@ember/service", "ember-concurrency"], function (_exports, _component, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    storeService: (0, _service.inject)('store'),
    stateService: (0, _service.inject)('state'),
    roleService: (0, _service.inject)('role-service'),
    session: (0, _service.inject)('session'),
    routerService: (0, _service.inject)('router'),
    loadCounties: (0, _emberConcurrency.task)(function* () {
      let user = yield this.storeService.findRecord('user', this.session.userId);
      let allCounties = this.get('stateService.counties');

      if (!user) {
        return;
      }

      let counties = [];

      if (this.roleService.isAdmin) {
        counties = [...allCounties];
      } else {
        let countyNames = this.get('session.profile.counties');
        counties = allCounties.filter(county => countyNames.includes(county.get('displayValue')));
      }

      return counties.filterBy('amdSeasonOpen', true).sortBy('displayValue');
    }).on('init').restartable()
  });

  _exports.default = _default;
});