define("mldp/components/deer-population/note-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "CPfMPXpW",
    "block": "[[[10,2],[12],[1,\"\\n  \"],[1,[30,0,[\"model\"]]],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "mldp/components/deer-population/note-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});