define("mldp/helpers/permit-display-value", ["exports", "@ember/component/helper", "mldp/utils/permit-type-dictionary"], function (_exports, _helper, _permitTypeDictionary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.permitDisplayValue = permitDisplayValue;

  function permitDisplayValue(params
  /*, hash*/
  ) {
    const [type] = params;
    return (0, _permitTypeDictionary.default)(type);
  }

  var _default = (0, _helper.helper)(permitDisplayValue);

  _exports.default = _default;
});