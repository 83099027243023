define("mldp/components/guidance/management-units/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "fI1iLlnj",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  The LMA application requires that every property define the number of\\n  Management Units associated with the property. Each property registered\\n  in the LMA application will be required by to have at least one\\n  Management Unit. A Management Unit is simply the area of the property\\n  that will be receiving technical guidance from TPWD or will be enrolled\\n  in MLDP. Some landowners may choose to receive technical guidance on\\n  only a portion of their property, so the management unit allows\\n  customers to define that specific land area.\\n  If a property is low­fenced, or partially high­fenced, and the entire\\n  property will be placed under technical guidance or involved in MLDP,\\n  it should be considered a single Management Unit.\\n  For the purposes of technical guidance, TPWD considers each completely\\n  high­fenced tract of land or non-contiguous tracts of a property to be a\\n  distinct Management Unit.\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/guidance/management-units/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});