define("mldp/components/form-fields/select-field/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/eHZD2gd",
    "block": "[[[6,[39,0],[[30,0,[\"propertyName\"]]],[[\"class\",\"classNames\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"object\",\"hint\",\"form\",\"label\",\"control\",\"update\"],[\"select-field\",[30,0,[\"fieldClasses\"]],[30,0,[\"errorClasses\"]],[30,0,[\"hintClasses\"]],[30,0,[\"inputClasses\"]],[30,0,[\"labelClasses\"]],[30,0,[\"object\"]],[30,0,[\"hint\"]],[30,0,[\"form\"]],[30,0,[\"label\"]],[30,0,[\"control\"]],[28,[37,1],[[30,0],[30,0,[\"update\"]]],null]]],[[\"default\"],[[[[1,\"  \"],[8,[30,1,[\"label\"]],null,[[\"@required\"],[[28,[37,2],[[30,0,[\"required\"]],[28,[37,3],[[30,0,[\"hideAsterisk\"]]],null]],null]]],null],[1,\"\\n  \"],[8,[30,1,[\"control\"]],null,[[\"@accesskey\",\"@autofocus\",\"@dir\",\"@disabled\",\"@groupLabelPath\",\"@hidden\",\"@includeBlank\",\"@prompt\",\"@promptIsSelectable\",\"@lang\",\"@multiple\",\"@options\",\"@optionValuePath\",\"@optionTargetPath\",\"@optionLabelPath\",\"@required\",\"@search\",\"@tabindex\"],[[30,0,[\"accesskey\"]],[30,0,[\"autofocus\"]],[30,0,[\"dir\"]],[30,0,[\"disabled\"]],[30,0,[\"groupLabelPath\"]],[30,0,[\"hidden\"]],[30,0,[\"includeBlank\"]],[30,0,[\"prompt\"]],[30,0,[\"promptIsSelectable\"]],[30,0,[\"lang\"]],[30,0,[\"multiple\"]],[30,0,[\"options\"]],[30,0,[\"optionValuePath\"]],[30,0,[\"optionTargetPath\"]],[30,0,[\"optionLabelPath\"]],[30,0,[\"required\"]],[30,0,[\"search\"]],[30,0,[\"tabindex\"]]]],null],[1,\"\\n  \"],[1,[30,1,[\"errors\"]]],[1,\"\\n  \"],[1,[30,1,[\"hint\"]]],[1,\"\\n\"]],[1]]]]]],[\"f\"],false,[\"form-field\",\"action\",\"and\",\"not\"]]",
    "moduleName": "mldp/components/form-fields/select-field/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});