define("mldp/components/enrollment/payment-status/component", ["exports", "@glimmer/component", "@ember/service", "mldp/utils/site-fee-invoices"], function (_exports, _component, _service, _siteFeeInvoices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PaymentStatus = (_class = class PaymentStatus extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "roleService", _descriptor, this);
    }

    get sitePaymentStatus() {
      return (0, _siteFeeInvoices.processSitePaymentStatus)((0, _siteFeeInvoices.siteFeeInvoices)(this.args.enrollment, this.args.site));
    }

    get displayOverallPaymentStatus() {
      let isCoop = this.args.enrollment.primarySite.siteType === 'coOp' && this.args.enrollment.primarySite.id === Number(this.args.site.id);
      return this.isPoolManager || isCoop || this.args.enrollment.primarySite.siteType === 'aggregate';
    }

    get isPoolManager() {
      let users = this.args.site.associatedUsers;
      let poolManager = users.findBy('type', 'PoolManager');
      return this.roleService.isSameUser(poolManager === null || poolManager === void 0 ? void 0 : poolManager.userId);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "roleService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = PaymentStatus;
});