define("mldp/account/rtas/index/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _object, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let IndexRoute = (_dec = (0, _service.inject)('notifications'), classic(_class = (_class2 = class IndexRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _defineProperty(this, "queryParams", {
        countyid: {
          refreshModel: true
        },
        sitetype: {
          refreshModel: true
        },
        sitename: {
          refreshModel: true
        },
        page: {
          refreshModel: true
        }
      });
    }

    model(_ref) {
      let {
        page,
        countyid,
        sitetype,
        sitename
      } = _ref;
      // Don't reuse cached records, since the cache doesnt differentiate between "queries"
      // that is, all and county based use the same cache
      this.store.unloadAll('unassigned-rta');
      let options = {
        page: {
          number: page,
          size: 50
        },
        reload: true
      };

      if (countyid) {
        options['filter[countyid]'] = countyid;
      }

      if (sitetype) {
        options['filter[sitetype]'] = sitetype;
      }

      if (sitename) {
        options['filter[sitename]'] = sitename;
      }

      let adapter = this.store.adapterFor('user');
      return _rsvp.default.hash({
        rtas: this.store.query('unassigned-rta', options),
        counties: this.store.findAll('tlkCounty'),
        biologists: adapter.findBiologists()
      }).then(res => {
        return {
          paging: res.rtas.meta.paging,
          rtas: res.rtas.sortBy('siteName'),
          counties: res.counties,
          biologists: res.biologists
        };
      });
    }

    setupController(controller, resolved) {
      let {
        countyid
      } = this.paramsFor(this.routeName);

      if (countyid) {
        let county = resolved.counties.findBy('id', countyid);
        resolved.selectedCounty = county;
      }

      controller.setProperties(resolved);
    }

    assignSelf(rta) {
      let store = this.store;
      let notifications = this.notifications;
      let siteId = rta.get('siteId');
      let siteType = rta.get('site.siteType').toLowerCase();
      let adapter = store.adapterFor(siteType);
      return adapter.assignBiologistSelf(siteId).then(() => rta.destroyRecord()).then(() => {
        this.controller.set('selectBiologistRta', false);
        return this.refresh();
      }).then(() => {
        notifications.add('Assigned self as Biologist', {
          type: 'success'
        });
      }).catch(e => {
        console.warn(e);
        notifications.error(jsonApiErrorToMessage(e), {
          title: 'Error assigning self as Biologist'
        });
      });
    }

    assignOther(rta, biologist) {
      let store = this.store;
      let notifications = this.notifications;
      let siteType = rta.get('siteType');
      let siteId = rta.get('siteId');
      return store.findRecord(siteType, siteId).then(site => site.addBiologist({
        params: {
          biologistId: biologist.id
        }
      })).then(() => rta.destroyRecord()).then(() => {
        this.controller.set('selectBiologistRta', false);
        return this.refresh();
      }).then(() => {
        notifications.add('Assigned a Biologist', {
          type: 'success'
        });
      }).catch(e => {
        console.warn(e);
        notifications.error(jsonApiErrorToMessage(e), {
          title: 'Error assigning a Biologist'
        });
      });
    }

    ignoreRTA(rta) {
      let notifications = this.notifications;
      let siteName = rta.get('siteName');
      rta.destroyRecord().then(() => {
        this.controller.set('selectIgnoreRta', false);
        return this.refresh();
      }).then(() => {
        notifications.add(siteName, {
          title: 'RTA has been removed from the system'
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "assignSelf", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "assignSelf"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "assignOther", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "assignOther"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "ignoreRTA", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "ignoreRTA"), _class2.prototype)), _class2)) || _class);
  _exports.default = IndexRoute;

  function jsonApiErrorToMessage(err) {
    let messages = err.errors.mapBy('detail');
    return messages.join('\n');
  }
});