define("mldp/components/report-details-container/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    actions: {
      getReport(format) {
        return this.getReport({
          format
        });
      }

    }
  });

  _exports.default = _default;
});