define("mldp/components/permit/amd-recommendation-view/list/component", ["exports", "@ember/object", "@ember-decorators/component", "@ember/component"], function (_exports, _object, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let List = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = (_class2 = class List extends _component2.default {
    antlerlessTotalCallback(acc, rec) {
      if (!rec.invalidated) {
        return acc += Number(rec.recommendedCount);
      }

      return acc;
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "antlerlessTotalCallback", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "antlerlessTotalCallback"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = List;
});