define("mldp/account/permits/create/route", ["exports", "@ember/service", "@ember/routing/route", "rsvp", "@ember/object", "moment"], function (_exports, _service, _route, _rsvp, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  const params = ['siteName', 'county', 'landFname', 'landLname'];
  let CreateRoute = (_dec = (0, _service.inject)('session'), classic(_class = (_class2 = class CreateRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        primarySiteId: {
          refreshModel: true
        },
        primarySiteType: {
          refreshModel: true
        },
        siteName: {
          refreshModel: true
        },
        county: {
          refreshModel: true
        },
        landFname: {
          refreshModel: true
        },
        landLname: {
          refreshModel: true
        }
      });
    }

    async model(opts) {
      const {
        type
      } = this.paramsFor('account.permits');
      const {
        page,
        primarySiteId,
        primarySiteType
      } = opts;
      const options = {
        //TODO; handle multiple pages 
        page: {
          number: page,
          size: 25
        },
        reload: true
      };
      const {
        roles,
        userId
      } = this.session;

      if (!roles.includes('Admin')) {
        if (roles.includes('Supervisor')) {
          options['filter[biosupid]'] = userId;
        } else if (roles.includes('Biologist')) {
          options['filter[bioid]'] = userId;
        }
      }

      options[`filter[${type}eligible]`] = 1;

      for (let param of params) {
        if (opts[param]) {
          options[`filter[${param}]`] = opts[param];
        }
      }

      const seasonAdapter = this.store.adapterFor('tlk-season');
      let currentSeason, nextSeason;

      try {
        currentSeason = await seasonAdapter[`${type}FindCurrentApplication`]();
      } catch {
        currentSeason = false;
      }

      if (!currentSeason && roles.includes('Admin')) {
        currentSeason = await seasonAdapter[`${type}FindCurrentReporting`]();
        nextSeason = await this.store.find('tlk-season', Number(currentSeason.id) + 1);
        let today = (0, _moment.default)();

        if (today.isAfter(nextSeason[`${type}ApplicationWithdrawDate`])) {
          currentSeason = nextSeason;
        }
      }

      let eligibleSites = await this.store.query('site', options);
      let units = null;
      let application = await this.store.createRecord(`${type}-permit-app`);
      let site = null;

      if (eligibleSites.content.length === 1) {
        site = eligibleSites.toArray()[0];
        const siteAdapter = this.store.adapterFor(site.siteType);
        units = await siteAdapter.getManagementUnits(site.id);
      } else if (primarySiteId) {
        const siteAdapter = this.store.adapterFor(primarySiteType);
        site = await this.store.findRecord(primarySiteType, primarySiteId);
        units = await siteAdapter.getManagementUnits(primarySiteId);
        application.set('site', site);
      }

      let selectedUnits = null;

      if (units) {
        const eligibility = await application.eligibility({
          params: {
            siteId: site.id
          }
        });
        application.set('siteEligibility', eligibility);
        selectedUnits = [];
        units.forEach(unit => {
          unit.set('eligibility', eligibility.children.find(muEligible => muEligible.siteId === unit.identifier));
          if (eligibility.isEligible && unit.eligibility && unit.eligibility.isEligible) selectedUnits.push(unit);
        });
      }

      const hash = {
        eligibleSites,
        application,
        currentSeason,
        type,
        units,
        opts,
        selectedUnits
      };
      return _rsvp.default.hash(hash);
    }

    setupController(controller, resolved) {
      const {
        eligibleSites,
        units,
        currentSeason,
        application,
        selectedUnits
      } = resolved;
      const {
        meta,
        content
      } = eligibleSites;

      if (content.length === 1 && controller.showSelectSiteModal || application.site) {
        // && typeof controller.showSelectSiteModal !== "boolean"){
        const site = application.site || resolved.eligibleSites.toArray()[0];
        controller.set('showSelectSiteModal', false);
        application.setProperties({
          site,
          units,
          primarySiteId: site.id,
          seasonId: currentSeason.id,
          applicationStatusId: 1,
          selectedUnits
        });
      }

      resolved.searchFields = _object.default.create({});

      for (let param of params) {
        if (resolved.opts[param]) {
          resolved.searchFields[param] = resolved.opts[param];
        } else {
          resolved.searchFields[param] = null;
        }
      }

      resolved.paging = meta.paging;
      controller.setProperties(resolved);
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('primarySiteId', null);
        controller.set('primarySiteType', null);
        controller.set('showSelectSiteModal', true);
        controller.set('application', null);
        controller.set('eligibleSites', null);
        controller.set('siteName', null);
        controller.set('county', null);
        controller.set('landFname', null);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = CreateRoute;
});