define("mldp/models/user-action", ["exports", "@ember/object", "@ember-data/model"], function (_exports, _object, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ActionModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('number'), _dec10 = (0, _model.attr)('number'), _dec11 = (0, _model.attr)('number'), _dec12 = (0, _model.attr)('number'), _dec13 = (0, _model.attr)('number'), _dec14 = (0, _model.attr)('number'), _dec15 = (0, _model.attr)('boolean'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('date'), _dec18 = (0, _model.attr)('boolean'), _dec19 = (0, _model.attr)('number'), _dec20 = (0, _model.attr)('date'), _dec21 = (0, _model.attr)('number'), _dec22 = (0, _model.attr)('date'), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('string'), _dec26 = (0, _model.attr)(), _dec27 = (0, _model.attr)(), _dec28 = (0, _model.attr)(), _dec29 = (0, _model.attr)(), _dec30 = (0, _model.attr)(), _dec31 = (0, _model.attr)(), _dec32 = (0, _object.computed)('coOpId', 'aggregateId'), classic(_class = (_class2 = class ActionModel extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "actionGuid", _descriptor, this);

      _initializerDefineProperty(this, "actionEntityType", _descriptor2, this);

      _initializerDefineProperty(this, "actionEntityId", _descriptor3, this);

      _initializerDefineProperty(this, "ownerEmail", _descriptor4, this);

      _initializerDefineProperty(this, "ownerUserId", _descriptor5, this);

      _initializerDefineProperty(this, "ownerFirstName", _descriptor6, this);

      _initializerDefineProperty(this, "ownerLastName", _descriptor7, this);

      _initializerDefineProperty(this, "actionType", _descriptor8, this);

      _initializerDefineProperty(this, "userId", _descriptor9, this);

      _initializerDefineProperty(this, "propertyId", _descriptor10, this);

      _initializerDefineProperty(this, "managementUnitId", _descriptor11, this);

      _initializerDefineProperty(this, "coOpId", _descriptor12, this);

      _initializerDefineProperty(this, "aggregateId", _descriptor13, this);

      _initializerDefineProperty(this, "recommendationId", _descriptor14, this);

      _initializerDefineProperty(this, "completed", _descriptor15, this);

      _initializerDefineProperty(this, "completedBy", _descriptor16, this);

      _initializerDefineProperty(this, "completedDateTime", _descriptor17, this);

      _initializerDefineProperty(this, "obsolete", _descriptor18, this);

      _initializerDefineProperty(this, "entryBy", _descriptor19, this);

      _initializerDefineProperty(this, "entryDate", _descriptor20, this);

      _initializerDefineProperty(this, "modifiedBy", _descriptor21, this);

      _initializerDefineProperty(this, "modifiedDate", _descriptor22, this);

      _initializerDefineProperty(this, "notificationDetail", _descriptor23, this);

      _initializerDefineProperty(this, "notificationTitle", _descriptor24, this);

      _initializerDefineProperty(this, "navigationPath", _descriptor25, this);

      _initializerDefineProperty(this, "user", _descriptor26, this);

      _initializerDefineProperty(this, "property", _descriptor27, this);

      _initializerDefineProperty(this, "managementUnit", _descriptor28, this);

      _initializerDefineProperty(this, "coOp", _descriptor29, this);

      _initializerDefineProperty(this, "aggregate", _descriptor30, this);

      _initializerDefineProperty(this, "entryUser", _descriptor31, this);
    }

    get poolType() {
      let coopId = this.coOpId;
      let aggId = this.aggregateId;

      if (coopId !== null) {
        return 'coop';
      } else if (aggId !== null) {
        return 'aggregate';
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "actionGuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "actionEntityType", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "actionEntityId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "ownerEmail", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "ownerUserId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "ownerFirstName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "ownerLastName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "actionType", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "userId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "propertyId", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "managementUnitId", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "coOpId", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "aggregateId", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "recommendationId", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "completed", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "completedBy", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class2.prototype, "completedDateTime", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class2.prototype, "obsolete", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class2.prototype, "entryBy", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class2.prototype, "entryDate", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class2.prototype, "modifiedBy", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class2.prototype, "modifiedDate", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class2.prototype, "notificationDetail", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class2.prototype, "notificationTitle", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class2.prototype, "navigationPath", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class2.prototype, "user", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class2.prototype, "property", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class2.prototype, "managementUnit", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class2.prototype, "coOp", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class2.prototype, "aggregate", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class2.prototype, "entryUser", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "poolType", [_dec32], Object.getOwnPropertyDescriptor(_class2.prototype, "poolType"), _class2.prototype)), _class2)) || _class);

  window.__CLASSIC_OWN_CLASSES__.set(ActionModel, true);

  _model.default.reopenClass({
    attrs: ['actionGuid', 'ownerEmail', 'ownerUserId', 'ownerFirstName', 'ownerLastName', 'actionType', 'userId', 'propertyId', 'managementUnitId', 'coOpId', 'aggregateId', 'recommendationId', 'completed', 'completedBy', 'completedDateTime', 'obsolete', 'entryBy', 'entryUser', 'entryDate', 'modifiedBy', 'modifiedDate'],

    // Coming from server
    serialize(data) {
      let obj = {};
      Object.keys(data).forEach(attr => {
        obj[attr.camelize()] = data[attr];
      });
      return obj;
    },

    // Going to server
    deserialize(data) {
      let obj = {}; // eslint-disable-next-line ember/no-attrs-in-components

      let attrs = this.attrs;
      Object.keys(data).forEach(attr => {
        if (attrs.includes(attr)) {
          obj[attr.classify()] = data[attr];
        }
      });
      return obj;
    }

  });

  var _default = ActionModel;
  _exports.default = _default;
});