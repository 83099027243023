define("mldp/models/site", ["exports", "@ember-data/model", "@ember/object/computed", "@ember/object", "@turf/turf", "ember-cp-validations", "mldp/utils/instance-op", "mldp/utils/area-in-acres"], function (_exports, _model, _computed, _object, _turf, _emberCpValidations, _instanceOp, _areaInAcres) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    siteName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Site Name'
    }),
    primaryCountyId: {
      description: 'County',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true,
        gt: 0
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    siteName: (0, _model.attr)('string'),
    siteType: (0, _model.attr)('type'),
    primaryCountyId: (0, _model.attr)('number'),
    primaryCounty: (0, _model.attr)(),
    shape: (0, _model.attr)('string'),
    unassignedRtaId: (0, _model.attr)('number'),
    archived: (0, _model.attr)('boolean'),
    wmpIsActive: (0, _model.attr)('boolean'),
    wmpActiveDate: (0, _model.attr)('date'),
    wmpActiveUser: (0, _model.attr)(),
    wmpEditHistory: (0, _model.attr)(),
    // EditDate, EditComment
    associatedUsers: (0, _model.attr)(),
    users: (0, _model.hasMany)('user-xsite'),
    isProperty: (0, _computed.equal)('siteType', 'property'),
    isCoop: (0, _computed.equal)('siteType', 'coop'),
    isAggregate: (0, _computed.equal)('siteType', 'aggregate'),
    findAllEnrollments: (0, _instanceOp.default)({
      path: 'enrollments',
      type: 'get',

      modifyData() {
        let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        data.sort = 'deerSeasonId';
        return data;
      },

      transform(payload) {
        let store = this.store;
        payload.data = payload.data.map(item => {
          item.type = 'enrollment';
          return item;
        });
        return store.push(payload);
      }

    }),
    findAllArchivedEnrollments: (0, _instanceOp.default)({
      path: 'archivedenrollments',
      type: 'get',

      modifyData() {
        let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        data.sort = 'deerSeasonId';
        return data;
      },

      transform(payload) {
        let store = this.store;
        payload.data = payload.data.map(item => {
          item.type = 'enrollment';
          return item;
        });
        return store.push(payload);
      }

    }),
    archive: (0, _instanceOp.default)({
      type: 'post',
      path: 'archive'
    }),
    unarchive: (0, _instanceOp.default)({
      type: 'post',
      path: 'unarchive'
    }),
    activateWmp: (0, _instanceOp.default)({
      path: 'activateWmp',
      type: 'post'
    }),
    deactivateWmp: (0, _instanceOp.default)({
      path: 'deactivateWmp',
      type: 'post'
    }),
    enrollmentEligibility: (0, _instanceOp.default)({
      path: 'enrollmentEligibility',
      type: 'get'
    }),
    addBiologist: (0, _instanceOp.default)({
      type: 'post',
      path: 'addbiologist/{biologistId}'
    }),
    isPool: (0, _object.computed)('siteType', {
      get() {
        let siteType = this.siteType;
        return siteType === 'coop' || siteType === 'aggregate';
      }

    }),
    geoJSON: (0, _object.computed)('shape', {
      get() {
        let shape = this.shape;

        if (!shape) {
          return;
        }

        try {
          return JSON.parse(shape);
        } catch (e) {// pass
        }
      },

      set(key, value) {
        if (value) {
          this.set('shape', JSON.stringify(value));
        } else {
          this.set('shape', null);
        }

        return value;
      }

    }),
    centroid: (0, _object.computed)('geoJSON', {
      get() {
        if (!this.geoJSON) {
          return JSON.stringify({
            'type': 'Point',
            'coordinates': []
          });
        }

        return _turf.default.centroid(this.geoJSON);
      }

    }),
    geoJSONArea: (0, _object.computed)('geoJSON', {
      get() {
        let geojson = this.geoJSON;

        if (!geojson) {
          return 0;
        }

        let area = (0, _areaInAcres.default)(geojson);
        return area;
      }

    })
  });

  _exports.default = _default;
});