define("mldp/components/tag-map/component", ["exports", "@ember/object", "@ember-decorators/component", "@ember/service", "@ember/component", "rsvp", "mldp/utils/area-in-acres"], function (_exports, _object, _component, _service, _component2, _rsvp, _areaInAcres) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let TagMap = (_dec = (0, _component.tagName)(""), _dec2 = (0, _service.inject)('notifications'), _dec3 = (0, _service.inject)('anon-ajax'), _dec(_class = classic(_class = (_class2 = class TagMap extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _defineProperty(this, "hasEstimate", false);

      _defineProperty(this, "loadingEstimate", false);
    }

    willRender() {
      let geoJson = this.get('model.geoJSON');

      if (geoJson) {
        let area = (0, _areaInAcres.default)(geoJson);
        this.set('areaMapped', area);
      }
    }

    onGeoJSONUpdate(geoJSON) {
      if (geoJSON) {
        let json = JSON.stringify(geoJSON);
        this.set('model.shape', json);
        let area = (0, _areaInAcres.default)(geoJSON);
        this.set('areaMapped', area);
      } else {
        this.set('model.shape', null);
        this.set('areaMapped', 0);
      }
    }

    drawBoundary() {
      this.eventsBus.trigger('appMapEditable:drawBoundary');
    }

    editBoundary() {
      this.eventsBus.trigger('appMapEditable:editBoundary');
    }

    deleteBoundary() {
      this.eventsBus.trigger('appMapEditable:deleteBoundary');
      this.set('hasEstimate', false);
    }

    getEstimate() {
      let notifications = this.notifications;
      this.set('loadingEstimate', true);
      return this.ajaxEstimate().then(resp => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        this.set('model.anyBucks', resp.buckTagsAny);
        this.set('model.unbranchedBucks', resp.buckTagsUnbranched);
        this.set('model.antlerless', resp.doeTags);
        this.set('model.totalTags', resp.totalTags);
        this.set('hasEstimate', true);
        this.set('loadingEstimate', false);
      }).catch(e => {
        this.set('loadingEstimate', false);

        if (e.payload && e.payload.includes && e.payload.includes('Shape area exceeds maximum allowed')) {
          return _rsvp.default.reject(notifications.error('The mapped area is too large. Please map a smaller area.', {
            title: 'API Error'
          }));
        }

        return _rsvp.default.reject(notifications.addApiErrors(e));
      });
    }

    ajaxEstimate() {
      let ajax = this.ajax;
      const url = '/TagEstimator/Calculate';
      const data = {
        HighFence: this.get('model.highFence'),
        GeoJson: JSON.stringify(this.get('model.geoJSON'))
      };
      return ajax.post(url, {
        data: JSON.stringify(data),
        dataType: 'json',
        contentType: 'application/json'
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "onGeoJSONUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onGeoJSONUpdate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "drawBoundary", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "drawBoundary"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "editBoundary", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "editBoundary"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteBoundary", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteBoundary"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getEstimate", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "getEstimate"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = TagMap;
});