define("mldp/adapters/mldp-static-table", ["exports", "mldp/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let ProcessAdapter = classic(_class = class ProcessAdapter extends _application.default {
    // season values: 'fall' or 'spring'
    getCurrentSeasonYear(season) {
      let ajax = this.ajaxService;
      return ajax.request(`/statictable/snapshot/${season}/season`, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
    }

    generateTable(season) {
      let ajax = this.ajaxService;
      return ajax.post(`/statictable/snapshot/${season}`);
    }

  }) || _class;

  _exports.default = ProcessAdapter;
});