define("mldp/account/property/enrollment/recommendations/route", ["exports", "@ember/service", "@ember/routing/route", "@ember/object", "rsvp", "ember-state-services/state-for"], function (_exports, _service, _route, _object, _rsvp, _stateFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const queryParams = {
    archivedMu: {
      buck: null,
      antlerless: null,
      unit: null
    }
  };

  var _default = _route.default.extend({
    stateService: (0, _service.inject)('state'),
    notifications: (0, _service.inject)('notifications'),
    // used by the recommendations-sform state
    canService: (0, _service.inject)('can'),
    state: (0, _stateFor.default)('recommendations-form'),
    queryParams: queryParams,

    model() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let {
        type,
        siteId
      } = this.paramsFor('account.property');
      let {
        property
      } = this.modelFor('account.property');
      let enrollment = this.modelFor('account.property.enrollment');
      let stateService = this.stateService;
      let isAggregate = type === 'aggregate';
      let recommendationOptions = {
        'filter[primarysite]': siteId,
        'filter[enrollment]': enrollment.id,
        'filter[season]': (0, _object.get)(enrollment, 'deerSeason.id')
      };
      let units = [];
      type = type.toLowerCase(); // Only load units for non aggregates, since recs are for the aggregate directly

      if (!isAggregate) {
        let adapter = this.store.adapterFor(type);
        units = adapter.getManagementUnits(siteId);
      }

      return _rsvp.default.hash({
        enrollment,
        site: property,
        species: stateService.get('species'),
        types: stateService.get('enrollmentTypes'),
        currentSeason: stateService.get('currentSeason'),
        genders: this.store.findAll('tlk-enrollment-gender'),
        newRecommendation: opts,
        recommendations: this.store.query('recommendation', recommendationOptions).then(results => {
          results.forEach(recommendation => {
            let ownerAgentID = recommendation.ownerAgentAcceptedBy || recommendation.ownerAgentDeclinedBy;

            if (ownerAgentID) {
              property.associatedUsers.forEach(user => {
                if (user.userId === ownerAgentID) {
                  recommendation.ownerAgentName = user.firstName + ' ' + user.lastName;
                }
              });
            }

            return recommendation;
          });
          return results;
        }),
        units
      });
    },

    setupController(controller, resolved) {
      let muIds = resolved.enrollment.get('mUs');
      let speciesId = resolved.enrollment.get('speciesId') || 3;
      let isAggregate = resolved.site.get('isAggregate');
      let enrollmentGenderId = resolved.enrollment.get('enrollmentGenderId');
      let gender = resolved.genders.findBy('identifier', enrollmentGenderId);

      if (!gender) {
        // set to both by default
        resolved.gender = resolved.genders.findBy('id', '3');
      } else {
        resolved.gender = gender;
      }

      resolved.units = muIds.map(id => resolved.units.findBy('id', String(id))).filter(item => !!item).sortBy('siteName');
      resolved.allSpeciesOptions = resolved.species.rejectBy('id', '3');
      resolved.currentSpecies = resolved.species.findBy('identifier', speciesId);
      resolved.recommendations = resolved.recommendations.sortBy('notificationDate').reverse();
      resolved.selected = isAggregate ? [resolved.site] : resolved.units.slice();
      controller.setProperties(resolved);
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        let states = this.getStates();
        controller.setProperties({
          buck: undefined,
          antlerless: undefined,
          unit: undefined
        }); // Clear out any unsubmitted recommendations

        states.forEach(state => {
          let recommendations = state.recommendations;
          recommendations.clear();
        });
      }
    },

    actions: {
      save() {
        let gender = this.controller.get('gender');
        let notifications = this.notifications;
        let recommendationsState = this.getRecommendationsState();
        let recommendations = recommendationsState.map(state => {
          let record = this.store.createRecord('recommendation', state);
          record.set('gender', gender);
          return record;
        });
        let validated = recommendations.map(model => model.validateSync());
        let anyInvalid = validated.filter(validated => {
          return validated.validations.get('isInvalid');
        }).length !== 0;

        if (!anyInvalid) {
          let tasks = recommendations.map(recommendations => {
            return () => {
              return recommendations.save();
            };
          });
          let allSavedSerially = tasks.reduce(function (cur, next) {
            return cur.then(next);
          }, _rsvp.default.resolve());
          return allSavedSerially.then(() => {
            let enrollment = this.currentModel.enrollment;
            let recommendationOptions = {
              'filter[enrollment]': enrollment.id,
              'filter[season]': (0, _object.get)(enrollment, 'deerSeason.id')
            };
            return this.store.query('recommendation', recommendationOptions);
          }).then(recommendations => {
            let states = this.getStates({
              filterBySelected: true
            });
            this.controller.set('recommendations', recommendations);
            states.forEach(state => {
              let recommendations = state.recommendations;
              recommendations.clear();
            });
            this.controller.set('submitRecommendations', false);
            notifications.add('Successfully submitted recommendations');
          });
        } else {
          validated.forEach(validated => {
            if (validated.validations.get('isInvalid')) {
              notifications.addValidationErrors(validated.model);
            }
          });
          this.controller.set('submitRecommendations', false);
        }
      },

      loadLastSeason() {
        let notifications = this.notifications;
        let enrollment = (0, _object.get)(this, 'controller.enrollment');
        let lastSeason = Number((0, _object.get)(enrollment, 'deerSeason.id')) - 1;
        let primarySiteId = enrollment.primarySiteId;
        let recommendationOptions = {
          'filter[season]': lastSeason,
          'filter[primarysite]': primarySiteId
        };
        this.store.query('recommendation', recommendationOptions).then(results => {
          if (results.get('length') === 0) {
            return notifications.add('No previous recommendations found');
          }

          let states = this.getStates({
            filterBySelected: true
          });
          let filtered = results.reduce((all, item) => {
            let siteId = String(item.get('siteId'));
            let state = states.findBy('siteId', siteId);

            if (state) {
              all.push({
                state,
                item
              });
            }

            return all;
          }, []);

          if (filtered.get('length') === 0) {
            return notifications.add('No previous recommendations found', {
              type: 'info'
            });
          }

          filtered.forEach(_ref => {
            let {
              state,
              item
            } = _ref;
            let siteId = String(item.get('siteId'));
            this.send('addManualRecommendation', state.recommendations, siteId, item);
          });
        });
      },

      addManualRecommendation(recommendations, siteId, item) {
        let speciesId = Number(item.get('speciesId'));
        let currentSpeciesId = this.get('controller.currentSpecies.identifier');
        let checkSpecies = currentSpeciesId !== 3;
        let invalidSpecies = checkSpecies && currentSpeciesId !== speciesId; // species is not enrolled, so skip adding this rec

        if (invalidSpecies) {
          return;
        }

        let enrollmentId = this.get('controller.enrollment.id');
        let seasonId = this.get('controller.enrollment.deerSeason.id');
        let genderKeys = this.get('controller.gender.keys');
        let datum = {
          siteId,
          enrollmentId,
          speciesId,
          seasonId
        }; // Only add values taht are valid for the selected gender

        genderKeys.forEach(key => {
          datum[key] = item.get(key);
        });
        recommendations.unshiftObject(datum);
      },

      select(unit) {
        let selected = (0, _object.get)(this, 'controller.selected') || [];

        if (selected.includes(unit)) {
          selected.removeObject(unit);
        } else {
          selected.pushObject(unit);
        }

        (0, _object.set)(this, 'controller.selected', selected);
      },

      selectAll() {
        let isAggregate = (0, _object.get)(this, 'currentModel.site.siteType') === 'aggregate';
        let items;

        if (isAggregate) {
          items = [(0, _object.get)(this, 'currentModel.site')];
        } else {
          items = (0, _object.get)(this, 'controller.units');
        }

        (0, _object.set)(this, 'controller.selected', [...items]);
      },

      clearSelected() {
        this.set('controller.selected', []);
      },

      invalidate(recommendation, comment) {
        let notifications = this.notifications;
        return recommendation.invalidate({
          data: {
            comment
          }
        }).then(() => recommendation.reload()).then(() => {
          this.controller.set('recommendationToInvalidate', undefined);
        }).catch(e => {
          notifications.addApiErrors(e);
        });
      },

      accept(recommendation) {
        let notifications = this.notifications;
        return recommendation.accept().then(() => recommendation.reload()).then(() => {
          this.controller.set('recommendationToAccept', undefined);
        }).catch(e => {
          notifications.addApiErrors(e);
        });
      },

      decline(recommendation) {
        let notifications = this.notifications;
        return recommendation.decline().then(() => recommendation.reload()).then(() => {
          this.controller.set('recommendationToDecline', undefined);
        }).catch(e => {
          notifications.addApiErrors(e);
        });
      },

      generateTagsUrl(recommendation) {
        let notifications = this.notifications;
        this.controller.set('loadingTags', true);
        return recommendation.initTags().then(code => {
          let recommendationId = recommendation.id;
          this.controller.set('tagsUrl', `/tags/${recommendationId}?code=${code}`);
          this.controller.set('recommendationToViewTags', recommendation);
        }).catch(e => {
          notifications.addApiErrors(e);
        }).finally(() => this.controller.set('loadingTags', false));
      }

    },

    getRecommendationsState() {
      let states = this.getStates({
        filterBySelected: true
      });
      let recommendations = states.reduce((all, state) => {
        all = all.concat(state.recommendations);
        return all;
      }, []);
      return recommendations;
    },

    getStates() {
      let {
        filterBySelected
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let state = this.state;
      let resolved = this.currentModel;
      let isAggregate = (0, _object.get)(resolved, 'site.siteType') === 'aggregate';

      if (isAggregate) {
        let site = resolved.site;
        let item = state.get(site);
        item.siteId = site.id;
        return [item];
      }

      let units = (0, _object.get)(this, 'controller.units') || [];
      let selected = this.get('controller.selected');
      let hasSelected = selected && selected.get('length');

      if (filterBySelected && hasSelected) {
        units = selected;
      }

      units = units.filter(item => !!item);
      return units.map(unit => {
        let item = state.get(unit);

        if (item && unit) {
          item.siteId = unit.id;
        }

        return item;
      }).filter(item => !!item);
    }

  });

  _exports.default = _default;
});