define("mldp/components/enrollment/unit-aggregate-item/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Gfg4o+sY",
    "block": "[[[10,\"td\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"top-bar form-group clearfix\"],[12],[1,\"\\n    \"],[10,\"b\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@route\",\"@models\"],[\"account.property.unit\",[28,[37,1],[[30,0,[\"site\",\"siteType\"]],[30,0,[\"site\",\"id\"]],[30,0,[\"unit\",\"id\"]]],null]]],[[\"default\"],[[[[1,[30,0,[\"unit\",\"siteName\"]]]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"enrollment\",\"enrollmentTypeId\"]],[[[41,[28,[37,3],[[28,[37,4],[[30,0,[\"enrollment\"]],[30,0,[\"eligibility\"]]],[[\"typeId\"],[[30,0,[\"enrollment\",\"enrollmentTypeId\"]]]]]],null],[[[1,\"      \"],[1,[28,[35,5],null,[[\"errors\"],[[28,[37,6],[[30,0,[\"eligibility\"]]],[[\"enrollment\",\"typeId\"],[[30,0,[\"enrollment\"]],[30,0,[\"enrollment\",\"enrollmentTypeId\"]]]]]]]]],[1,\"\\n\"]],[]],null]],[]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-warning\"],[12],[1,\"\\n      Please select 'Conservation' or 'Harvest' option above to see eligibility for this item.\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[],false,[\"link-to\",\"array\",\"if\",\"not\",\"is-eligible\",\"eligibility-errors\",\"get-enrollment-errors\"]]",
    "moduleName": "mldp/components/enrollment/unit-aggregate-item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});