define("mldp/components/agreements/coop-invite/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2ipGmjq0",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,\"ol\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      I acknowledge that I have been invited to join the above named Wildlife Management\\n      Association/Cooperative (Co-Op) for the purpose of participation in the Managed Lands Deer Program (MLDP),\\n      and that I am voluntarily selecting the tract of land under my control that will become a member of\\n      this Co-Op.\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      I acknowledge and fully understand that by accepting this invitation, I hereby grant the manager\\n      of this Co-Op full authority to request that my property be enrolled for participation in\\n      the MLD Program.\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      I acknowledge that I will receive harvest recommendations specific to my property based\\n      on the type of tags requested for the Co-Op by the Co-Op Manager, and that I will be bound\\n      to the legal requirements of MLDP participation. More information on the MLD Program and its\\n      requirements is available\\n      \"],[10,3],[14,6,\"http://tpwd.texas.gov/publications/pwdforms/media/pwd_1047_w7000_mldp_program_information_white_tailed_deer.pdf\"],[12],[1,\"here\"],[13],[1,\".\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      I acknowledge that I am responsible for implementing any habitat management practices required\\n      for participation in MLDP as a member of this Co-Op, as well as for maintaining and submitting\\n      any required logs and reports. Failure to fulfill any program requirements could jeopardize my\\n      eligibility to continue participation in MLDP or other permit programs.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/agreements/coop-invite/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});