define("mldp/adapters/deer-population", ["exports", "mldp/adapters/application", "mldp/utils/class-op"], function (_exports, _application, _classOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelName: 'deer-population',
    findAllForSite: (0, _classOp.default)({
      path: 'forsite/{siteId}',

      transform(payload) {
        let data;

        if (payload) {
          data = payload.map(datum => {
            datum.attributes = datum;
            datum.type = 'deer-population';
            return datum;
          });
        }

        return this.store.push({
          data
        });
      }

    })
  });

  _exports.default = _default;
});