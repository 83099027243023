define("mldp/account/property/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pVnlmBa+",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"assignUserModel\"]],[[[1,\"  \"],[8,[39,3],null,[[\"@named\",\"@send\"],[\"modal\",[50,\"invite-modal\",0,null,[[\"biologists\",\"model\",\"onSave\",\"onCancel\"],[[30,0,[\"biologists\"]],[30,0,[\"assignUserModel\"]],[28,[37,4],[\"assignUser\"],null],[28,[37,5],[[30,0],[28,[37,6],[[30,0,[\"assignUserModel\"]]],null],false],null]]]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"unitToDeactivate\"]],[[[1,\"  \"],[8,[39,3],null,[[\"@named\",\"@send\"],[\"modal\",[50,\"modal-window\",0,null,[[\"title\",\"submitLabel\",\"closeLabel\",\"body\",\"onsubmit\",\"close\"],[\"Are you sure?\",\"YES\",\"NO\",[50,\"general-value\",0,null,[[\"value\"],[\"This cannot be undone. Click YES to continue deactivating.\"]]],[28,[37,4],[\"deactivateUnit\",[30,0,[\"unitToDeactivate\"]]],null],[28,[37,5],[[30,0],[28,[37,6],[[30,0,[\"unitToDeactivate\"]]],null],false],null]]]]]],null],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"component\",\"-outlet\",\"if\",\"to-elsewhere\",\"route-action\",\"action\",\"mut\"]]",
    "moduleName": "mldp/account/property/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});