define("mldp/account/property/invite/owner/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vTudVYnq",
    "block": "[[[1,[28,[35,0],[\"Invite Landowner\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-xs-12\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"Invite Landowner for \"],[1,[28,[35,1],[[30,0,[\"model\",\"property\",\"siteType\"]]],null]],[1,\" '\"],[1,[30,0,[\"model\",\"property\",\"siteName\"]]],[1,\"'\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-xs-12 col-md-6 col-md-push-6\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"well well-sm\"],[12],[1,\"\\n      All landowners associated with this property in the LMA system have full authority to submit technical\\n      guidance requests, apply for participation in TPWD deer permit programs,\\n      and submit enrollment request for the Managed Lands Deer Program (MLDP).\\n      Each landowner associated with this property in the LMA system must review and\\n      agree to the terms and conditions of the Request for Technical Guidance (RTG) policy\\n      in order to receive technical guidance from Texas Parks and Wildlife Department (TPWD),\\n      including participation in the MLD Program. An email will be sent to the named individual to\\n      notify them of this invitation and to provide additional instructions.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"col-xs-12 col-md-6 col-md-pull-6\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Enter the information requested below to invite a Landowner to be added to this\\n      property in the Land Management Assistance (LMA) system.\\n    \"],[13],[1,\"\\n\\n    \"],[1,[28,[35,2],null,[[\"invite\",\"inviteText\",\"onSave\",\"onCancel\"],[[30,0,[\"invite\"]],\"LANDOWNER\",[28,[37,3],[\"onSave\"],null],[28,[37,3],[\"onCancel\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"page-banner\",\"titleize\",\"invite-form\",\"route-action\"]]",
    "moduleName": "mldp/account/property/invite/owner/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});