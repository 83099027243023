define("mldp/components/practice-row/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Tqfj+sxZ",
    "block": "[[[11,0],[24,0,\"data-row row\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-sm-3 col-xs-12\"],[12],[1,\"\\n    \"],[10,\"strong\"],[14,0,\"visible-xs-inline\"],[12],[1,\"Type\"],[13],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"type\"],[[30,2,[\"habitatPracticeTypeId\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"col-sm-3 col-xs-12\"],[12],[1,\"\\n    \"],[10,\"strong\"],[14,0,\"visible-xs-inline\"],[12],[1,\"Sub Type\"],[13],[1,\"\\n\"],[41,[30,2,[\"habitatPracticeSubTypeId\"]],[[[1,\"      \"],[1,[28,[35,0],null,[[\"subType\"],[[30,2,[\"habitatPracticeSubTypeId\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"col-sm-3 col-xs-12\"],[12],[1,\"\\n    \"],[10,\"strong\"],[14,0,\"visible-xs-inline\"],[12],[1,\"Hunting Season\"],[13],[1,\"\\n    \"],[1,[30,2,[\"deerSeason\",\"displayValue\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"col-sm-3 col-xs-12\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn btn-primary pull-right\"]],[[\"@route\",\"@models\"],[\"account.property.unit.practice\",[28,[37,3],[[30,3,[\"siteType\"]],[30,3,[\"id\"]],[30,4,[\"id\"]],[30,2,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n      Details\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@practice\",\"@property\",\"@unit\"],false,[\"practice/pretty-print\",\"if\",\"link-to\",\"array\"]]",
    "moduleName": "mldp/components/practice-row/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});