define("mldp/components/permit/amd-recommendation-view/map/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "v7tiC6Cr",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[6,[39,0],null,[[\"permitType\"],[\"amd\"]],[[\"default\"],[[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"units\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,3],null,[[\"map\",\"unit\",\"hoveredUnit\",\"onHover\",\"onAdd\"],[[30,2],[30,4],[30,0,[\"hoveredUnit\"]],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"hoveredUnit\"]]],null]],null],[30,3,[\"fitBounds\"]]]]]],[1,\"\\n\"]],[4]],null]],[2,3]]]]],[13]],[\"&attrs\",\"map\",\"ops\",\"unit\"],false,[\"app-map-permit\",\"each\",\"-track-array\",\"selectable-unit-layer\",\"action\",\"mut\"]]",
    "moduleName": "mldp/components/permit/amd-recommendation-view/map/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});