define("mldp/helpers/subtract", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.subtract = subtract;

  function subtract(_ref
  /*, hash*/
  ) {
    let [val1, val2] = _ref;
    return val1 - val2;
  }

  var _default = (0, _helper.helper)(subtract);

  _exports.default = _default;
});