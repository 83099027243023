define("mldp/adapters/user-action", ["exports", "mldp/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let UserAction = classic(_class = class UserAction extends _application.default {
    init() {
      super.init(...arguments);
      let namespace = this.namespace;
      let url = `/${namespace}/userAction`;
      this.set('actionUrl', url);
      this.set('completeUrl', `${url}/Complete`);
    } // getAction(actionGuid) {
    //   let url = this.get('actionUrl') + '/' + actionGuid;
    //   let ajax = this.get('ajaxService');
    //   return ajax.request(url, {
    //     contentType: 'application/json',
    //     accept: 'application/json',
    //     dataType: 'json'
    //   })
    //   .then(res => {
    //     let data = UserAction.serialize(res);
    //     return UserAction.create(getOwner(this).ownerInjection(), data);
    //   });
    // },


    async getActions(startDate, endDate, size, page, actionType) {
      let url = `/api/useraction/inactive?page[size]=${size}&page[number]=${page}`;

      if (startDate) {
        url = url + `&start=${startDate}`;
      }

      if (endDate) {
        url = url + `&end=${endDate}`;
      }

      if (actionType) {
        url = url + `&filter[alerttype]=${actionType}`;
      }

      let ajax = this.ajaxService;
      let actions = await ajax.request(url, {
        contentType: 'application/json',
        accept: 'application/json',
        dataType: 'json'
      });
      let actionsMap = actions.data.map(action => {
        return {
          type: 'user-action',
          id: action.id,
          attributes: action
        };
      });
      let actionModels = await this.store.push({
        data: actionsMap
      });
      return {
        data: actionModels,
        meta: actions.meta
      };
    }

    completeAction(actionGuid, type, data) {
      type = type ? type + '/' : '';
      let base = this.completeUrl;
      let url = `${base}/${type}${actionGuid}`;
      let ajax = this.ajaxService;
      return ajax.post(url, {
        data: JSON.stringify(data),
        contentType: 'application/json',
        accept: 'application/json',
        dataType: 'json'
      });
    }

    ignoreAction(actionGuid) {
      let url = `${this.actionUrl}/ignore/${actionGuid}`;
      let ajax = this.ajaxService;
      return ajax.post(url, {
        contentType: 'application/json',
        accept: 'application/json'
      });
    }

  }) || _class;

  _exports.default = UserAction;
});