define("mldp/helpers/practice-layer-selected", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.practiceLayerSelected = practiceLayerSelected;

  function practiceLayerSelected(params
  /*, hash*/
  ) {
    const [array, layer] = params;
    return array.find(cur => cur[0] === layer[0]);
  }

  var _default = (0, _helper.helper)(practiceLayerSelected);

  _exports.default = _default;
});