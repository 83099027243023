define("mldp/routes/application", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Ensure the application route exists for ember-simple-auth's `setup-session-restoration` initializer
  var _default = _route.default.extend();

  _exports.default = _default;
});