define("mldp/components/remove-user/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/component"], function (_exports, _component, _object, _service, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let RemoveUser = (_dec = (0, _component.tagName)(""), _dec2 = (0, _service.inject)('state'), _dec3 = (0, _service.inject)('role-service'), _dec4 = (0, _service.inject)('session'), _dec5 = (0, _service.inject)('app-values'), _dec6 = (0, _service.inject)('store'), _dec7 = (0, _service.inject)('notifications'), _dec8 = (0, _object.computed)('session.isAuthenticated'), classic(_class = _dec(_class = (_class2 = class RemoveUser extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stateService", _descriptor, this);

      _initializerDefineProperty(this, "roleService", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "appValues", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "notifications", _descriptor6, this);
    }

    get canUserRemove() {
      let session = this.session;
      let roleService = this.roleService;
      let appValues = this.appValues;
      let users = this.users;
      let user = this.user;
      let site = this.site;
      let currentUserEmail = session.get('tokenData.sub');
      let currentUserType = this.getCurrentUserType(site, currentUserEmail);
      let userIsCurrentUser = user.email === currentUserEmail;

      if (roleService.isInRole(appValues.get('adminRole'))) {
        return true;
      }

      let associated = roleService.associated(site);
      let assigned = associated.findBy('type', currentUserType);
      let isSupervisor = session.isSupervisor; // Can remove all types (like bio can) if super of any associated users

      if (!assigned && isSupervisor) {
        let subordinates = this.stateService.subordinates;

        if (subordinates) {
          let found = site.associatedUsers.filter(user => subordinates.includes(user.userId));

          if (found !== null && found !== void 0 && found.length) {
            assigned = found[0];
          }
        }
      } // matches any assigned users


      if (assigned) {
        let assignedIsBioOrAgentOrOwner = assigned.type === appValues.biologistUserType || assigned.type === appValues.agentUserType || assigned.type === appValues.ownerUserType; // If user to be removed is bio:
        // - Can't remove if the user to be removed is the current user
        // - otherwise can remove if current user is assigned and is bio

        if (user.type === appValues.biologistUserType) {
          let biologist = associated.findBy('type', currentUserType); // biologist can't remove self

          if (userIsCurrentUser && biologist) {
            return false;
          }

          return assigned.type === appValues.biologistUserType;
        } // If user to be removed is owner:
        // - can't remove if only one owner is available
        // - otherwise can remove if current user is assigned and is bio, agent, or owner


        if (user.type === appValues.ownerUserType) {
          let owners = users.filterBy('type', appValues.ownerUserType);

          if (owners.length === 1) {
            return false;
          }

          return assignedIsBioOrAgentOrOwner;
        } // If user to be removed is poolmanager:
        // - can remove if current user is assigned and is bio or pool manager


        if (user.type === appValues.poolManagerUserType) {
          let assignedIsBioOrPoolManager = assigned.type === appValues.biologistUserType || assigned.type === appValues.poolManagerUserType;
          return assignedIsBioOrPoolManager;
        } // If user to be removed is agent:
        // - can remove if current user is assigned and is bio, agent, or owner


        if (user.type === appValues.agentUserType) {
          return assignedIsBioOrAgentOrOwner;
        }
      } // current user isn't assigned


      if (currentUserType == null) {
        return false;
      }

      return false;
    }

    remove() {
      let user = this.user;

      if (confirm(`Are you sure you want to remove ${user.firstName} ${user.lastName} from this site?`)) {
        let site = this.site;
        let adapter = this.store.adapterFor(site.get('siteType'));
        let notifications = this.notifications;
        return adapter.removeUser(site.id, user).then(() => this.onRemoveUser(user)).catch(error => {
          if (error.jqXHR) {
            if (error.jqXHR.status === 403 || error.jqXHR.status === 404 || error.jqXHR.status === 409) {
              notifications.error(error.payload.errors[0].message);
            }
          } else if (error.errors) {
            notifications.error(error.errors[0].detail);
          } else {
            notifications.error('An error has occurred');
          }
        });
      }
    }

    getCurrentUserType(site, email) {
      let associatedUsers = site.get('associatedUsers');

      for (var i = 0; i < associatedUsers.length; i++) {
        if (associatedUsers[i].email === email) {
          return associatedUsers[i].type;
        }
      }

      return null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "roleService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "appValues", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "canUserRemove", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "canUserRemove"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "remove"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = RemoveUser;
});