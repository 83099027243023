define("mldp/account/property/enrollment/index/controller", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let PropertyEnrollmentIndexController = classic(_class = (_class2 = class PropertyEnrollmentIndexController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);
    }

    async approve(enrollment) {
      let notifications = this.notifications;

      try {
        (0, _object.set)(this, 'loading', true);
        await enrollment.approve();
        notifications.add('Approved enrollment');
      } catch (err) {
        notifications.addApiErrors(err);
      } finally {
        await enrollment.reload();
        (0, _object.set)(this, 'loading', false);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "approve", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "approve"), _class2.prototype)), _class2)) || _class;

  _exports.default = PropertyEnrollmentIndexController;
});