define("mldp/mirage/factories/fee-schedule", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    conservationOption() {
      return {
        property: '$300 for enrollment of one management unit, $30 per each additional management unit',
        aggregate: '$300 flat fee',
        coopMember: '$30 per enrolled management unit'
      };
    },

    harvestOption() {
      return {
        property: '$30 per enrolled management unit',
        aggregate: '$30 flat fee'
      };
    }

  });

  _exports.default = _default;
});