define("mldp/account/property/unit/harvest/route", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let HarvestRoute = classic(_class = class HarvestRoute extends _route.default {
    model(_ref) {
      let {
        harvestId
      } = _ref;
      return this.store.findRecord('harvest', harvestId);
    }

  }) || _class;

  _exports.default = HarvestRoute;
});