define("mldp/models/profile", ["exports", "mldp/models/custom-model", "ember-cp-validations"], function (_exports, _customModel, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  const Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', true),
    lastName: (0, _emberCpValidations.validator)('presence', true),
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 8
    })],
    passwordConfirmation: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: '{description} do not match',
      description: 'Passwords'
    })],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });

  let Model = classic(_class = class Model extends _customModel.default.extend(Validations) {}) || _class;

  window.__CLASSIC_OWN_CLASSES__.set(Model, true);

  Model.reopenClass({
    attrs: ['firstName', 'lastName', 'middleName', 'primaryPhone', 'secondaryPhone', 'address1', 'address2', 'city', 'state', 'zipCode', 'roles', 'counties', 'email']
  });
  var _default = Model;
  _exports.default = _default;
});