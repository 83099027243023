define("mldp/models/management-unit", ["exports", "@ember-data/model", "@ember/object/computed", "@ember/runloop", "@ember/object", "ember-cp-validations", "@turf/turf", "mldp/utils/instance-op", "mldp/utils/area-in-acres"], function (_exports, _model, _computed, _runloop, _object, _emberCpValidations, _turf, _instanceOp, _areaInAcres) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    siteName: {
      description: 'Management Unit Name',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    fenceTypeId: {
      description: 'Fence Type',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    shape: {
      description: 'Management Unit Boundary',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    siteName: (0, _model.attr)('string'),
    siteType: (0, _model.attr)('string'),
    archived: (0, _model.attr)('boolean'),
    parentSiteId: (0, _model.attr)('number'),
    fenceTypeId: (0, _model.attr)('number'),
    deerBreederFacilityNumber: (0, _model.attr)('string'),
    shape: (0, _model.attr)('string'),
    calculatedAcreage: (0, _model.attr)('number'),
    coOpSiteId: (0, _model.attr)('number'),
    coOpSiteName: (0, _model.attr)('string'),
    aggregateSiteId: (0, _model.attr)('number'),
    aggregateSiteName: (0, _model.attr)('string'),
    parentSiteName: (0, _model.attr)('string'),
    poolActive: (0, _model.attr)('boolean'),
    poolStatus: (0, _model.attr)('string'),
    harvests: (0, _model.hasMany)('harvest'),
    practices: (0, _model.hasMany)('habitat-practice'),
    hasAReleaseSite: (0, _model.attr)('boolean'),
    isPartOfPool: (0, _computed.bool)('poolType'),
    poolName: (0, _computed.or)('coOpSiteName', 'aggregateSiteName'),
    poolId: (0, _computed.or)('coOpSiteId', 'aggregateSiteId'),
    highFence: (0, _computed.equal)('fenceTypeId', 2),
    // Custom Instance Queries
    findHarvests: (0, _instanceOp.default)({
      path: 'harvests',
      type: 'get',

      transform(payload) {
        if (payload !== null && payload !== void 0 && payload.data && payload.data.length) {
          payload.data = payload.data.map(datum => {
            datum.type = 'harvest';
            return datum;
          });
        }

        return this.store.push(payload);
      }

    }),
    findPractices: (0, _instanceOp.default)({
      path: 'HabitatPractices',
      type: 'get',

      transform(payload) {
        if (payload !== null && payload !== void 0 && payload.data && payload.data.length) {
          payload.data = payload.data.map(datum => {
            datum.type = 'habitat-practice';
            return datum;
          });
        }

        return this.store.push(payload);
      }

    }),
    leavePool: (0, _instanceOp.default)({
      path: 'LeavePooledProperty',
      type: 'post'
    }),

    /**
     * Takes 'isActive' and 'poolStatusNotes' in the body
     */
    changePoolStatus: (0, _instanceOp.default)({
      path: 'EditPoolStatus',
      type: 'post'
    }),
    leaveEnrollment: (0, _instanceOp.default)({
      path: 'leaveenrollment/{enrollmentid}',
      type: 'post'
    }),
    archive: (0, _instanceOp.default)({
      type: 'post',
      path: 'archive'
    }),
    unarchive: (0, _instanceOp.default)({
      type: 'post',
      path: 'unarchive'
    }),
    poolType: (0, _object.computed)('coOpSiteId', 'aggregateSiteId', {
      get() {
        let coopId = this.coOpSiteId;
        let aggId = this.aggregateSiteId;

        if (coopId !== null) {
          return 'coop';
        } else if (aggId !== null) {
          return 'aggregate';
        }
      }

    }),
    geoJSON: (0, _object.computed)('shape', {
      get() {
        let shape = this.shape;

        if (!shape) {
          return;
        }

        try {
          return JSON.parse(shape);
        } catch (e) {// noop
        }
      },

      set(key, value) {
        if (value) {
          this.set('shape', JSON.stringify(value));
        } else {
          this.set('shape', null);
        }

        return value;
      }

    }),
    geoJSONArea: (0, _object.computed)('geoJSON', {
      get() {
        let geojson = this.geoJSON;

        if (!geojson) {
          return 0;
        }

        let areaAcres = Number((0, _areaInAcres.default)(geojson));

        if (!isNaN(areaAcres)) {
          _runloop.run.scheduleOnce('afterRender', this, () => {
            // TODO: clean this up, bad practice
            // eslint-disable-next-line ember/no-side-effects
            this.set('calculatedAcreage', areaAcres);
          });
        }

        return areaAcres;
      }

    }),
    centroid: (0, _object.computed)('geoJSON', {
      get() {
        //TODO: find a better way to check for this
        if (!this.geoJSON || !this.geoJSON.coordinates || !this.geoJSON.coordinates[0] || !this.geoJSON.coordinates[0].length) {
          return 0;
        }

        return _turf.default.centroid(this.geoJSON);
      }

    }),
    identifier: (0, _object.computed)('id', {
      get() {
        return Number(this.id);
      }

    })
  });

  _exports.default = _default;
});