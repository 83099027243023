define("mldp/components/reporting-wizard/sites/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wxA5N/9C",
    "block": "[[[11,0],[24,0,\"reporting-wizard-sites\"],[17,1],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"fetchSites\",\"isRunning\"]],[30,0,[\"oneSite\"]]],null],[[[1,\"    \"],[10,2],[12],[1,\"\\n      Loading sites..\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,2],[12],[1,\"\\n      Please select a site for reporting\\n    \"],[13],[1,\"\\n\\n\"],[44,[[28,[37,1],[[30,0,[\"sites\"]],[30,2]],null]],[[[41,[30,3,[\"length\"]],[[[1,\"        \"],[10,\"ul\"],[14,0,\"sites-list list-unstyled\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,3]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[12],[1,\"\\n              \"],[10,\"button\"],[14,0,\"btn btn-link\"],[15,\"onclick\",[28,[37,5],[[30,0],[30,5],[30,4]],null]],[12],[1,\"\\n                \"],[1,[30,4,[\"siteName\"]]],[1,\" (\"],[1,[30,4,[\"siteType\"]]],[1,\")\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[4]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        You do not yet have any sites in LMA.\\n\"]],[]]]],[3]]]],[]]],[13]],[\"&attrs\",\"@sites\",\"sites\",\"site\",\"@selectSite\"],false,[\"if\",\"or\",\"let\",\"each\",\"-track-array\",\"action\"]]",
    "moduleName": "mldp/components/reporting-wizard/sites/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});