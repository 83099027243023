define("mldp/account/property/permit/amd-recommendations/edit/route", ["exports", "@ember/service", "@ember/routing/route", "rsvp", "@ember/array", "moment"], function (_exports, _service, _route, _rsvp, _array, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EditRoute = (_dec = (0, _service.inject)('store'), classic(_class = (_class2 = class EditRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    async model() {
      let {
        property: site
      } = this.modelFor('account.property');
      const {
        permitType,
        permitId
      } = this.paramsFor('account.property.permit');
      const permit = await this.store.findRecord('amd-permit-app', permitId);
      const seasonAdapter = this.store.adapterFor('tlk-season');
      const currentSeason = await seasonAdapter.amdFindCurrentReporting();
      const tallyAdapter = this.store.adapterFor('amd-tally');
      const tallys = await tallyAdapter.findAllForSeason({
        params: {
          seasonId: permit.seasonId
        }
      });
      const units = permit.mUs.map(id => this.store.findRecord('management-unit', id));
      let canEdit = false;

      if (permit.season.id > currentSeason.identifier) {
        canEdit = true;
      } else if (permit.season.id === currentSeason.identifier) {
        let today = (0, _moment.default)();
        canEdit = today.isBefore(currentSeason.amdTpSeasonEndDate);
      }

      return _rsvp.default.hash({
        currentSeason,
        canEdit,
        permit,
        permitType,
        permitId,
        site,
        units: _rsvp.default.all(units),
        tallys
      });
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        //TODO: This doesn't see like the best way to solve this.
        controller.set('selected', (0, _array.A)());
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = EditRoute;
});