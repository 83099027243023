define("mldp/components/site-property-create/show-mu-page-modal/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ShowMuPageModal = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class ShowMuPageModal extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "toolTipText", 'The LMA application requires that every property define the number of Management Units associated with the property. Each property registered in the LMA application will be required to have at least one Management Unit. A Management Unit is simply the area of the property that will be receiving technical guidance from TPWD or will be enrolled in MLDP. Some landowners may choose to receive technical guidance on only a portion of their property, so the Management Unit allows customers to define that specific land area. If a property is lowfenced, or partially highfenced, and the entire property will be placed under technical guidance or involved in MLDP, it should be considered a single Management Unit. For the purposed of technical guidance, TPWD considers each completely high-fenced tract of land or non-contiguous tracts of a property to be a distinct Management Unit.');
    }

  }) || _class) || _class);
  _exports.default = ShowMuPageModal;
});