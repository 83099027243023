define("mldp/components/admin/harvest-rate-value-modal/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "@ember/service"], function (_exports, _component, _emberConcurrencyDecorators, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let HarvestRateValueModal = (_class = class HarvestRateValueModal extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "generalAjax", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "uploadHarvestRates", _descriptor4, this);

      this.seasonIds = this.store.peekAll('tlk-season').map(season => season.id);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "generalAjax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uploadHarvestRates", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        if (!this.args.documentToUpload) {
          this.notifications.error('Document type must be set and a file must be selected before you can upload a file.');
          return;
        }

        let notifications = this.notifications;
        let ajax = this.generalAjax;
        let fileName = this.args.documentToUpload.blob.name;
        let dataUri = yield this.args.documentToUpload.readAsDataURL();
        let content = dataUri.split(',')[1];
        let url = `/api/${this.args.selectedHarvestRateType === 'ph' ? 'phharvestrate' : 'amdharvestrate'}/load?season=${this.args.selectedSeason}`;

        try {
          yield ajax.post(url, {
            contentType: 'application/json',
            data: {
              Content: content,
              FileName: fileName,
              EntityId: '1',
              Size: '1'
            }
          });
          notifications.add(`Document "${fileName}" was uploaded successfully!`);
        } catch (e) {
          var _e$payload;

          let message = e;

          if ((_e$payload = e.payload) !== null && _e$payload !== void 0 && _e$payload.errors && e.payload.errors.length) {
            message = e.payload.errors[0].detail;

            if (message === 'Request body too large.') {
              message = 'File exceeds maximum file size.';
            }
          }

          notifications.error(`Encountered an error while uploading your document: ${message}`);
        } finally {
          this.args.close();
        }
      };
    }
  })), _class);
  _exports.default = HarvestRateValueModal;
});