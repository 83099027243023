define("mldp/helpers/tag-estimate-display", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.tagEstimateDisplay = tagEstimateDisplay;

  function tagEstimateDisplay(_ref, _ref2) {
    let [tags] = _ref;
    let {
      gender
    } = _ref2;

    if (!tags || !gender) {
      return;
    }

    return [{
      type: 'Buck Only',
      label: `${tags.buckTagsAny} tags`
    }, {
      type: 'Buck Only',
      label: `${tags.buckTagsUnbranched} tags`
    }, {
      type: 'Antlerless Only',
      label: `${tags.doeTags} tags`
    }, {
      type: 'Buck & Antlerless',
      label: `${tags.totalTags} tags`
    }];
  }

  var _default = (0, _helper.helper)(tagEstimateDisplay);

  _exports.default = _default;
});