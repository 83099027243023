define("mldp/helpers/pluralize", ["exports", "@ember/component/helper", "ember-inflector"], function (_exports, _helper, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.pluralizeHelper = pluralizeHelper;

  function pluralizeHelper(_ref
  /*, hash*/
  ) {
    let [val] = _ref;

    if (typeof val === 'string') {
      return (0, _emberInflector.pluralize)(val);
    }

    return val;
  }

  var _default = (0, _helper.helper)(pluralizeHelper);

  _exports.default = _default;
});