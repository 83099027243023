define("mldp/account/property/route", ["exports", "@ember/service", "@ember/routing/route", "rsvp", "@ember/application", "mldp/models/invite-model"], function (_exports, _service, _route, _rsvp, _application, _inviteModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-classic-classes */
  const queryParams = {
    archived: {
      refreshModel: true
    }
  };

  var _default = _route.default.extend({
    state: (0, _service.inject)('state'),
    appValues: (0, _service.inject)('app-values'),
    notifications: (0, _service.inject)('notifications'),
    breadCrumb: {},
    queryParams,

    model(_ref) {
      let {
        type,
        siteId,
        archived
      } = _ref;
      type = type && type.toLowerCase().replace('-', '');
      let adapter = this.store.adapterFor(type);
      let season = this.get('state.currentSeason.displayValue');
      let enrollmentOptions = {
        'filter[primarySite]': siteId,
        'filter[season]': season,
        archived: archived
      };
      let query = {
        archived
      };
      return _rsvp.default.hash({
        biologists: this.get('state.biologists'),
        counties: this.get('state.counties'),
        property: this.store.findRecord(type, siteId, {
          reload: true,
          adapterOptions: {
            query
          }
        }),
        activeEnrollments: this.store.query('enrollment', enrollmentOptions),
        units: adapter.getManagementUnits(siteId, archived),
        isProperty: type === 'property'
      });
    },

    afterModel(resolved) {
      let siteType = resolved.property.get('siteType').capitalize();
      let siteName = resolved.property.get('siteName');
      this.set('breadCrumb', {
        title: `${siteType}: ${siteName}`
      });
    },

    setupController(controller, resolved) {
      let isAggregate = resolved.property.get('isAggregate');

      if (isAggregate) {
        resolved.hasActiveEnrollments = resolved.activeEnrollments.get('length') !== 0;
      }

      controller.setProperties(resolved);
    },

    actions: {
      openAssignModal(type) {
        let invite = _inviteModel.default.create((0, _application.getOwner)(this).ownerInjection());

        invite.set('type', type);
        this.controller.set('assignUserModel', invite);
      },

      assignUser(invite) {
        let appValues = this.appValues;
        let notifications = this.notifications;
        let site = this.get('currentModel.property');
        let {
          siteType,
          id: siteId
        } = site;
        let adapter = this.store.adapterFor(siteType);
        let inviteType = invite.get('type');
        let query;

        switch (inviteType) {
          case appValues.get('ownerUserType'):
            {
              query = adapter.inviteLandowner(siteId, invite);
              break;
            }

          case appValues.get('agentUserType'):
            {
              query = adapter.inviteAgent(siteId, invite);
              break;
            }

          case appValues.get('biologistUserType'):
            {
              query = adapter.addBiologist(siteId, invite.get('biologist.id'));
              break;
            }

          case appValues.get('poolManagerUserType'):
            {
              query = adapter.inviteManager(siteId, invite);
              break;
            }
        }

        return query.then(() => site.reload()).catch(error => notifications.addApiErrors(error));
      },

      openDeactivateConfirmation(unit) {
        return new _rsvp.default.Promise((resolve, reject) => {
          this.setProperties({
            deactivatePromiseControls: {
              resolve,
              reject
            },
            'controller.unitToDeactivate': unit
          });
        });
      },

      deactivateUnit(unit) {
        let deactivatePromiseControls = this.deactivatePromiseControls;
        return unit.changePoolStatus({
          data: {
            isActive: false,
            poolStatusNotes: 'Deactivated from enrollment'
          }
        }).then(() => {
          this.controller.set('unitToDeactivate', false);
          unit.set('poolActive', false);
          return deactivatePromiseControls.resolve();
        }).catch(err => deactivatePromiseControls.reject(err));
      }

    }
  });

  _exports.default = _default;
});