define("mldp/components/managementunit-details/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/component", "mldp/utils/within-date-range"], function (_exports, _component, _object, _service, _component2, _withinDateRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ManagementunitDetails = (_dec = (0, _component.tagName)(""), _dec2 = (0, _service.inject)('state'), _dec3 = (0, _service.inject)('session'), _dec4 = (0, _service.inject)('router'), _dec(_class = classic(_class = (_class2 = class ManagementunitDetails extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "routerService", _descriptor3, this);
    }

    init() {
      super.init(...arguments);
      let currentSeason = this.get('state.currentSeason');
      let startDate = currentSeason.get('enrollmentStartDate');
      let endDate = currentSeason.get('enrollmentEndDate');
      this.showLeavePool = (0, _withinDateRange.default)(startDate, endDate);
    }

    async archive() {
      let unit = this.unit;
      let site = this.site;
      let router = this.routerService;
      await unit.archive();
      let siteType = site.get('siteType');
      let siteArchived = site.get('archived');
      let siteId = site.id;
      let unitId = unit.id;
      let queryParams = {
        archivedMu: true,
        archived: siteArchived
      };
      await router.transitionTo('account.property.unit', siteType, siteId, unitId, {
        queryParams
      });
    }

    async unarchive(event) {
      let unit = this.unit;
      let site = this.site;
      let router = this.routerService;
      event.preventDefault();
      await unit.unarchive();
      let siteType = site.get('siteType');
      let siteArchived = site.get('archived');
      let siteId = site.id;
      let unitId = unit.id;
      let queryParams = {
        archivedMu: false,
        archived: siteArchived
      };
      await router.transitionTo('account.property.unit', siteType, siteId, unitId, {
        queryParams
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "routerService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "archive", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "archive"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "unarchive", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "unarchive"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = ManagementunitDetails;
});