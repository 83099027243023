define("mldp/components/reporting-wizard/pronghorn-app-select/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cc8bmrN2",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[11,0],[24,0,\"reporting-wizard-step\"],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"      Loading Permits...\\n\"]],[]],[[[1,\"      \"],[10,\"h4\"],[12],[1,\"\\n        Site: \"],[1,[30,2,[\"siteName\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"list-unstyled\"],[12],[1,\"\\n        \"],[10,\"li\"],[12],[1,\"\\n          \"],[10,\"label\"],[14,1,\"report-unit-parent\"],[12],[1,\"Parent Property\"],[13],[1,\"\\n          \"],[10,1],[14,\"aria-labelledby\",\"report-unit-parent\"],[12],[1,[30,3,[\"parentSiteName\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"panel panel-default container-fluid\"],[12],[1,\"\\n\"],[1,\"        \"],[10,0],[14,0,\"panel-body bg-primary row\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"col-xs-6\"],[12],[1,\"\\n            Applications (click one)\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"col-xs-6\"],[12],[1,\"\\n            Already Reported?\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"list-group\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"permitApps\"]]],null]],null],null,[[[1,\"            \"],[10,0],[14,0,\"list-group-item row\"],[12],[1,\"\\n              \"],[10,\"button\"],[14,0,\"btn btn-secondary col-xs-6 bg-gray-200 hover:bg-gray-100\"],[15,\"onclick\",[28,[37,3],[[30,0],\"checkPronghorn\",[30,4]],null]],[12],[1,\"\\n                \"],[1,[30,4,[\"appName\"]]],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,0],[14,0,\"col-xs-6\"],[12],[1,\"\\n                \"],[1,[52,[30,4,[\"phReportingStatus\"]],\"Yes\",\"No\"]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[4]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@site\",\"@unit\",\"permitApp\"],false,[\"if\",\"each\",\"-track-array\",\"action\"]]",
    "moduleName": "mldp/components/reporting-wizard/pronghorn-app-select/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});