define("mldp/utils/site-fee-invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.processSitePaymentStatus = processSitePaymentStatus;
  _exports.siteFeeInvoices = siteFeeInvoices;

  function siteFeeInvoices(enrollment, site) {
    let siteInvoices = enrollment.feeInvoices.filter(invoice => invoice.primarySiteId === Number(site.id));
    return siteInvoices;
  }

  function processSitePaymentStatus() {
    let feeInvoices = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    if (feeInvoices.length === 0) return 'Pending Approval';
    if (feeInvoices.every(feeInvoice => feeInvoice.paymentStatusId === 5)) return 'Not Applicable';
    if (feeInvoices.every(feeInvoice => feeInvoice.paymentStatusId === 6)) return 'Waived';
    if (feeInvoices.every(feeInvoice => feeInvoice.paymentStatusId === 4 || feeInvoice.paymentStatusId === 7)) return 'Unenrolled';
    let unpaidCount = feeInvoices.filter(feeInvoice => feeInvoice.paymentStatusId === 1 || feeInvoice.paymentStatusId === 2).length;
    let paidCount = feeInvoices.filter(feeInvoice => feeInvoice.paymentStatusId === 3).length;
    let totalCount = unpaidCount + paidCount;
    if (totalCount === 0) return 'Mixed';
    if (paidCount === 0) return 'Unpaid';
    if (unpaidCount === 0) return 'Paid';
    return `${paidCount} paid/${unpaidCount} unpaid`;
  }
});