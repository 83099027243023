define("mldp/adapters/management-unit", ["exports", "mldp/adapters/application", "mldp/mixins/survey-types-by-site", "qs"], function (_exports, _application, _surveyTypesBySite, _qs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let ManagementUnitAdapter = classic(_class = class ManagementUnitAdapter extends _application.default.extend(_surveyTypesBySite.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "modelName", 'managementunit');
    }

    getSiteStatistics(siteId, options) {
      let ajax = this.ajaxService;
      return ajax.request(`/site/${siteId}/statistics?${_qs.default.stringify(options)}`, {
        contentType: 'application/vnd.api+json',
        headers: {
          'Accept': 'application/vnd.api+json'
        }
      }).then(result => {
        if (result) {
          result = result.map(datum => {
            datum.type = 'site-statistics';
            return datum;
          });
          return result;
        }
      });
    }

  }) || _class;

  _exports.default = ManagementUnitAdapter;
});