define("mldp/components/agreements/landowner-pre-rta/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iIoWycaG",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    You have been named by \"],[10,\"b\"],[12],[1,[30,0,[\"model\",\"entryUser\",\"firstName\"]]],[1,\" \"],[1,[30,0,[\"model\",\"entryUser\",\"lastName\"]]],[1,\" (\"],[1,[30,0,[\"model\",\"entryUser\",\"email\"]]],[1,\")\"],[13],[1,\"\\n    as a landowner for a property named \"],[10,\"b\"],[12],[1,[30,0,[\"model\",\"property\",\"siteName\"]]],[13],[1,\" in \"],[10,\"b\"],[12],[1,[30,0,[\"model\",\"property\",\"countyName\"]]],[13],[1,\" County.\\n  \"],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,\"\\n    This site has been registered in TPWD’s Land Management Assistance application for the purposes of receiving technical guidance from TPWD staff or for participation\\n    in MLDP or another TPWD deer permit program. The following page displays TPWD’s policy related to providing technical guidance to private landowners and confidentiality\\n    agreements between TPWD and cooperating landowner(s). Please review this page carefully, and indicate whether you agree to the terms of the policy.\\n    If you do not agree, TPWD cannot provide you or your designated agent with technical guidance, and the property will not be eligible to participate in MLDP.\\n    If you do agree to the policy, please then review the property information for accuracy. Make any edits needed, including to the list of designated agents.\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/agreements/landowner-pre-rta/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});