define("mldp/account/payment/history/route", ["exports", "@ember/routing/route", "@ember/service", "rsvp"], function (_exports, _route, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AccountPaymentHistoryRoute extends _route.default {
    async model(params) {
      let transactions = await this.store.query('fee-transaction', {
        'filter[transactionoutcome]': 'Succeeded'
      });
      transactions = transactions.toArray();
      return (0, _rsvp.hash)({
        fees: transactions
      });
    }

  }

  _exports.default = AccountPaymentHistoryRoute;

  window.__CLASSIC_OWN_CLASSES__.set(AccountPaymentHistoryRoute, true);
});