define("mldp/account/property/rta/route", ["exports", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    notifications: (0, _service.inject)('notifications'),
    breadCrumb: {
      title: 'RTA'
    },

    model() {
      let {
        property
      } = this.modelFor('account.property');
      let rtaId = property.get('unassignedRtaId');
      let rta;

      if (rtaId) {
        rta = this.store.findRecord('unassigned-rta', rtaId);
      } else {
        rta = this.store.createRecord('unassigned-rta', {
          siteId: property.id,
          requestDate: new Date()
        });
      }

      return _rsvp.default.hash({
        rta,
        property
      });
    },

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    },

    actions: {
      submit(rta) {
        let notifications = this.notifications;
        return rta.save().then(() => {
          let {
            property
          } = this.modelFor('account.property');
          return property.reload();
        }).then(() => {
          let {
            siteId,
            type
          } = this.paramsFor('account.property');
          return this.transitionTo('account.property', type, siteId);
        }).then(() => notifications.add('Request for Technical Assistance submitted')).catch(e => {
          console.error(e);
          notifications.error('Encountered an error creating the RTA');
        });
      }

    }
  });

  _exports.default = _default;
});