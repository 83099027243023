define("mldp/components/npol-warn-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DoxntthA",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    An issue has been identified with the property information being submitted that will require TPWD assistance to resolve. \\n    Press 'I Understand' to proceed and save your property information\\n    or press Cancel to return to the information page so you can review and, if needed, edit your property information, including the map before resubmitting.\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/npol-warn-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});