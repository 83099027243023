define("mldp/account/outreaches/create/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Vopvoa79",
    "block": "[[[1,[28,[35,0],[\"Create Outreach\"],null]],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"outreach\",\"eventTypes\",\"fundings\",\"groups\",\"wmas\",\"counties\",\"onUpdate\",\"onReset\"],[[30,0,[\"outreach\"]],[30,0,[\"outreachEventTypes\"]],[30,0,[\"outreachFundings\"]],[30,0,[\"outreachGroups\"]],[30,0,[\"wmas\"]],[30,0,[\"counties\"]],[28,[37,2],[\"create\"],null],[28,[37,2],[\"cancel\"],null]]]]]],[],false,[\"page-banner\",\"outreach-edit\",\"route-action\"]]",
    "moduleName": "mldp/account/outreaches/create/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});