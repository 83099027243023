define("mldp/components/admin/chargeback-modal/enter-chargeback/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EnterChargeBack extends _component.default {
    get formattedFeesTotal() {
      var _this$args$transactio;

      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format((_this$args$transactio = this.args.transaction) === null || _this$args$transactio === void 0 ? void 0 : _this$args$transactio.totalAmount);
    }

  }

  _exports.default = EnterChargeBack;

  window.__CLASSIC_OWN_CLASSES__.set(EnterChargeBack, true);
});