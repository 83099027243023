define("mldp/utils/instance-op", ["exports", "@ember/polyfills", "@ember/debug", "mldp/utils/string-template", "qs"], function (_exports, _polyfills, _debug, _stringTemplate, _qs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = instanceOp;

  function instanceOp(options) {
    return function () {
      let payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let modelName = this._internalModel.modelName || this.constructor.modelName || this.constructor.typeKey;
      let requestType = (options.type || 'PUT').toUpperCase();
      let urlType = options.urlType || requestType;
      let adapter = this.store.adapterFor(modelName);
      let modelData = this.toJSON({
        includeId: true
      }); //data = assign(data, modelData);

      if (typeof options.modifyData === 'function') {
        payload = options.modifyData.call(this, payload, modelData);
      }

      adapter.skipSessionInvalidateOnUnauthorized = true;
      let data = Array.isArray(payload.data) ? [...payload.data] : (0, _polyfills.assign)({}, payload.data);
      let queryHash = (0, _polyfills.assign)({}, payload.query);
      let params = (0, _polyfills.assign)(modelData, payload.params);
      let fullUrl = buildOperationUrl(this, modelName, options.path, urlType, params, queryHash);
      let query = adapter.ajax(fullUrl, requestType, (0, _polyfills.assign)(options.ajaxOptions || {}, {
        data
      }));

      if (options.transform && typeof options.transform === 'function') {
        query = query.then(options.transform.bind(this));
      } else if (options.pushPayload) {
        query = query.then(result => this.store.push(result));
      }

      return query;
    };
  }

  function buildOperationUrl(record, modelName, opPath, urlType, params, queryHash) {
    let instance = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : true;
    (true && !(opPath) && (0, _debug.assert)('You must provide a path for instanceOp', opPath));
    let adapter = record.store.adapterFor(modelName);
    let path = (0, _stringTemplate.default)(opPath, params);

    let snapshot = record._createSnapshot();

    let baseUrl;

    if (urlType === 'base') {
      baseUrl = adapter.buildURL();
    } else {
      baseUrl = adapter.buildURL(modelName, instance ? record.get('id') : null, snapshot, urlType);
    } // Add qps


    if (Object.keys(queryHash).length) {
      let pathHasQps = path.includes('?');
      let prefix = pathHasQps ? '&' : '?'; // 'brackets' means an array is turned into myqp=1&myqp=2
      // like ember-data is modified to do in the application adapter

      path += prefix + _qs.default.stringify(queryHash, {
        arrayFormat: 'repeat'
      });
    }

    if (baseUrl.charAt(baseUrl.length - 1) === '/') {
      return `${baseUrl}${path}`;
    } else {
      return `${baseUrl}/${path}`;
    }
  }
});