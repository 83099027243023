define("mldp/components/leave-pool-confirm/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lutx0Lri",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"leavingPool\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@named\",\"@send\"],[\"modal\",[50,\"modal-window\",0,null,[[\"title\",\"body\",\"onsubmit\",\"close\",\"submitLabel\",\"closeLabel\"],[\"Leave Pool\",[50,\"leave-pool-modal\",0,null,null],[28,[37,3],[\"leavePool\",[30,0,[\"unit\"]],[30,0,[\"site\"]]],null],[28,[37,3],[\"cancelModel\"],null],\"Confirm\",\"Cancel\"]]]]],null],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\"],false,[\"if\",\"to-elsewhere\",\"component\",\"route-action\"]]",
    "moduleName": "mldp/components/leave-pool-confirm/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});