define("mldp/utils/update-intersections", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = updateIntersections;

  function updateIntersections(_ref) {
    let {
      current,
      updated,
      currentKey,
      updatedKey,
      createRecord
    } = _ref;
    let toAdd = [];
    let toRemove = []; // Nothing updated so don't touch anything

    if (!updated) {
      return {};
    }

    updated.forEach(item => {
      let id = (0, _object.get)(item, updatedKey);

      if (!current.findBy(currentKey, Number(id))) {
        toAdd.pushObject(item);
      }
    });
    current.forEach(item => {
      let id = (0, _object.get)(item, currentKey);

      if (!updated.findBy(updatedKey, String(id))) {
        toRemove.pushObject(item);
      }
    });
    toAdd = toAdd.map(add => {
      return createRecord(add);
    });
    current.addObjects(toAdd);
    current.removeObjects(toRemove);
    return {
      toAdd,
      toRemove
    };
  }
});