define("mldp/models/ph-recommendation", ["exports", "@ember-data/model", "mldp/utils/instance-op"], function (_exports, _model, _instanceOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    phPartitionId: (0, _model.attr)('number'),
    seasonId: (0, _model.attr)('number'),
    recommendedBucks: (0, _model.attr)('number'),
    recommendedDoes: (0, _model.attr)('number'),
    // can recommend does even though partition calculation is zero
    biologistComment: (0, _model.attr)('string'),
    // optional
    ownerAgentComment: (0, _model.attr)('string'),
    accept: (0, _instanceOp.default)({
      path: 'accept',
      type: 'post'
    }),
    decline: (0, _instanceOp.default)({
      path: 'decline',
      type: 'post'
    }),
    invalidate: (0, _instanceOp.default)({
      path: 'invalidate',
      type: 'post'
    })
  });

  _exports.default = _default;
});