define("mldp/components/outreach-edit/component", ["exports", "@ember/component", "@ember/service", "ember-concurrency"], function (_exports, _component, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    session: (0, _service.inject)('session'),
    storeService: (0, _service.inject)('store'),
    notifications: (0, _service.inject)('notifications'),

    init() {
      this._super(...arguments);

      this.maxDate = new Date();
    },

    searchUsers: (0, _emberConcurrency.task)(function* () {
      let store = this.storeService;
      let users = yield store.query('user', {
        'filter[otherstaff]': true
      });
      return users.sortBy('lastName');
    }).on('init'),
    searchContacts: (0, _emberConcurrency.task)(function* (query) {
      // debounce
      yield (0, _emberConcurrency.timeout)(400);
      let all = [];
      let store = this.storeService;
      let users = yield store.query('user', {
        'filter[identity]': query
      });
      let contacts = yield store.query('contact', {
        'filter[identity]': query
      });
      all = all.concat(users.toArray()).concat(contacts.toArray()); // Populate select so on subsequent open your last results are still there

      this.set('lastContactResults', all);
      return all;
    }).restartable(),
    assignSelfAsContact: (0, _emberConcurrency.task)(function* () {
      let store = this.storeService;
      let id = this.get('session.userId');
      let user = yield store.findRecord('user', id);
      this.set('selectedContact', user);
      this.set('outreach.eventUserId', user === null || user === void 0 ? void 0 : user.id);

      if (user) {
        this.set('outreach.eventContactId', undefined);
      }
    }).restartable(),
    actions: {
      updateCounty(county) {
        this.set('outreach.countyId', county.id);
        this.set('selectedCounty', county);
      },

      updateUsers(users) {
        this.set('selectedUsers', users);
      },

      updateContact(contact) {
        this.set('selectedContact', contact);

        if (contact) {
          if (contact.constructor.modelName === 'user') {
            this.set('outreach.eventUserId', contact.id);
            this.set('outreach.eventContactId', undefined);
          } else if (contact.constructor.modelName === 'contact') {
            this.set('outreach.eventContactId', contact.id);
            this.set('outreach.eventUserId', undefined);
          }
        } else {
          let eventUserId = this.get('outreach.eventUserId');
          let eventContactId = this.get('outreach.eventContactId');

          if (eventUserId) {
            this.set('outreach.eventUserId', undefined);
          } else if (eventContactId) {
            this.set('outreach.eventUserId', undefined);
          }
        }
      },

      updateEventType(eventType) {
        this.set('selectedEventType', eventType);
        this.set('outreach.eventTypeId', eventType.id);
      },

      updatePrimaryGroup(group) {
        this.set('selectedPrimaryGroup', group);
        this.set('outreach.primaryGroupId', group.id);
      },

      updateFunding(funding) {
        this.set('selectedFunding', funding);
        this.set('outreach.fundingId', funding.id);
      },

      updateWma(wma) {
        this.set('selectedWma', wma);
        this.set('outreach.wmaId', wma.id);
      },

      showCreateContact() {
        this.setProperties({
          showCreateContactModal: true,
          contact: {}
        });
      },

      async createContact(data) {
        let notifications = this.notifications;
        let store = this.storeService;

        if (!data.primaryPhone && !data.email) {
          notifications.error('A contact needs an \'Email\' or a \'Primary phone\' number', {
            title: 'Missing Fields'
          }); // Throw so that the modal doesn't close, since the submit wasn't successful

          throw 'Validation Error';
        }

        let contact = store.createRecord('contact', data);

        try {
          await contact.save();
          this.set('selectedContact', contact);
          this.set('outreach.eventContactId', contact === null || contact === void 0 ? void 0 : contact.id);

          if (contact) {
            this.set('outreach.eventUserId', undefined);
          }
        } catch (e) {
          notifications.addApiErrors(e);
          throw e;
        }
      },

      closeCreateContact() {
        this.set('showCreateContactModal', false);
        this.set('contact', {});
      }

    }
  });

  _exports.default = _default;
});