define("mldp/account/consultations/index/route", ["exports", "@ember/routing/route", "@ember/service", "mldp/account/consultations/index/query-params"], function (_exports, _route, _service, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    sessionService: (0, _service.inject)('session'),
    queryParams: (0, _queryParams.routeQueryParams)(['page']),

    model(params) {
      let options = {
        page: {
          number: params.page,
          size: 25
        }
      }; // Apply all the query params to the query as filters

      for (let key of _queryParams.keys) {
        let value = params[key];

        if (value !== undefined) {
          options[`filter[${key}]`] = value;
        }
      }

      let isAdmin = this.get('sessionService.isAdmin');
      let isBiologist = this.get('sessionService.isBiologist');
      let isSupervisor = this.get('sessionService.isSupervisor');
      let userId = this.get('sessionService.userId'); // make sure not admin since users can have multiple roles

      if (!isAdmin && (isBiologist || isSupervisor)) {
        options['filter[biologistIdAll]'] = userId;
      }

      return this.store.query('consultation', options).then(consultations => {
        return {
          paging: consultations.meta.paging,
          consultations
        };
      });
    },

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }

  });

  _exports.default = _default;
});