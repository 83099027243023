define("mldp/account/payment/index/route", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AccountPaymentIndexRoute extends _route.default {
    model() {
      return this.modelFor('account.payment');
    }

  }

  _exports.default = AccountPaymentIndexRoute;

  window.__CLASSIC_OWN_CLASSES__.set(AccountPaymentIndexRoute, true);
});