define("mldp/account/index/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hJvD8wtA",
    "block": "[[[1,[28,[35,0],[\"My Account\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-group\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n      \"],[8,[39,1],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n      \"],[8,[39,2],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"sessionService\",\"isSupervisor\"]],[[[1,\"        \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-banner\",\"my-alerts\",\"my-sites\",\"if\",\"my-subordinates\"]]",
    "moduleName": "mldp/account/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});