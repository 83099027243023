define("mldp/account/property/agreement/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xbLX0ovH",
    "block": "[[[1,[28,[35,0],[\"Agreement\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"container form-group\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"site\"],[[30,0,[\"site\"]]]]]],[1,\"\\n\\n  \"],[10,\"button\"],[15,\"onclick\",[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"showDisagreeModal\"]]],null],true],null]],[14,0,\"btn btn-link\"],[12],[1,\"\\n    I Disagree\\n  \"],[13],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"default\",\"pending\",\"class\",\"action\"],[\"I Agree\",\"Saving...\",\"btn btn-primary\",[28,[37,5],[\"onSave\"],null]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showDisagreeModal\"]],[[[1,\"  \"],[8,[39,7],null,[[\"@named\",\"@send\"],[\"modal\",[50,\"modal-window\",0,null,[[\"title\",\"allowEnterSubmit\",\"body\",\"onsubmit\",\"close\",\"submitLabel\",\"closeLabel\"],[\"Are you sure you want to decline?\",true,[50,\"notices/rta-disagree\",0,null,null],[28,[37,2],[[30,0],[28,[37,9],[[28,[37,5],[\"onDisagree\"],null],[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"showDisagreeModal\"]]],null],false],null]],null]],null],[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"showDisagreeModal\"]]],null],false],null],\"Yes\",\"No\"]]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"page-banner\",\"rta-agreement\",\"action\",\"mut\",\"async-button\",\"route-action\",\"if\",\"to-elsewhere\",\"component\",\"pipe\"]]",
    "moduleName": "mldp/account/property/agreement/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});