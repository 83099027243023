define("mldp/components/enrollment/tags-list/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "utuCCdzv",
    "block": "[[[11,0],[24,0,\"tags-display\"],[17,1],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n  \"],[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Any Buck\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Unbranched Buck\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Antlerless\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Issuance\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"normalizedTags\"]]],null]],null],null,[[[1,\"          \"],[10,\"td\"],[12],[1,\"\\n\"],[41,[28,[37,4],[[30,0,[\"gender\",\"displayValue\"]],[30,2,[\"type\"]]],null],[[[1,\"              \"],[10,\"b\"],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[30,2,[\"label\"]]],[1,\"\\n\"]],[]]],[1,\"          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[10,\"td\"],[12],[1,\"\\n          \"],[10,\"b\"],[12],[1,\"\\n            \"],[1,[30,0,[\"issuance\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"tag\",\"&default\"],false,[\"yield\",\"each\",\"-track-array\",\"if\",\"eq\"]]",
    "moduleName": "mldp/components/enrollment/tags-list/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});