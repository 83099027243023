define("mldp/models/habitat-practice", ["exports", "@ember-data/model", "@ember/object"], function (_exports, _model, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let HabitatPractice = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.attr)('boolean'), _dec5 = (0, _model.attr)('boolean'), _dec6 = (0, _model.attr)('boolean'), _dec7 = (0, _model.attr)('boolean'), _dec8 = (0, _model.attr)('boolean'), _dec9 = (0, _model.attr)('boolean'), _dec10 = (0, _model.attr)('boolean'), _dec11 = (0, _model.attr)('boolean'), _dec12 = (0, _model.attr)('boolean'), _dec13 = (0, _model.attr)('boolean'), _dec14 = (0, _model.attr)('boolean'), _dec15 = (0, _model.attr)('boolean'), _dec16 = (0, _model.attr)('boolean'), _dec17 = (0, _model.attr)('number'), _dec18 = (0, _model.attr)('number'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('number'), _dec23 = (0, _model.attr)('number'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('boolean'), _dec26 = (0, _model.attr)('boolean'), _dec27 = (0, _model.attr)('boolean'), _dec28 = (0, _model.attr)('boolean'), _dec29 = (0, _model.attr)('boolean'), _dec30 = (0, _model.attr)('boolean'), _dec31 = (0, _model.attr)('number'), _dec32 = (0, _model.attr)('number'), _dec33 = (0, _model.attr)('number'), _dec34 = (0, _model.attr)('number'), _dec35 = (0, _model.attr)(), _dec36 = (0, _model.attr)(), _dec37 = (0, _model.attr)('string'), _dec38 = (0, _object.computed)('shape'), classic(_class = (_class2 = class HabitatPractice extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "siteId", _descriptor, this);

      _initializerDefineProperty(this, "habitatPracticeTypeId", _descriptor2, this);

      _initializerDefineProperty(this, "habitatPracticeSubTypeId", _descriptor3, this);

      _initializerDefineProperty(this, "primaryTargetSpeciesAsheRedBerryJuniper", _descriptor4, this);

      _initializerDefineProperty(this, "primaryTargetSpeciesChineseTallow", _descriptor5, this);

      _initializerDefineProperty(this, "primaryTargetSpeciesCreosoteTarBush", _descriptor6, this);

      _initializerDefineProperty(this, "primaryTargetSpeciesEasternRedCedar", _descriptor7, this);

      _initializerDefineProperty(this, "primaryTargetSpeciesHardwoods", _descriptor8, this);

      _initializerDefineProperty(this, "primaryTargetSpeciesHuisache", _descriptor9, this);

      _initializerDefineProperty(this, "primaryTargetSpeciesMcCartneyRose", _descriptor10, this);

      _initializerDefineProperty(this, "primaryTargetSpeciesMesquite", _descriptor11, this);

      _initializerDefineProperty(this, "primaryTargetSpeciesMixedBrush", _descriptor12, this);

      _initializerDefineProperty(this, "primaryTargetSpeciesPricklyPear", _descriptor13, this);

      _initializerDefineProperty(this, "primaryTargetSpeciesSaltCedar", _descriptor14, this);

      _initializerDefineProperty(this, "primaryTargetSpeciesShinoak", _descriptor15, this);

      _initializerDefineProperty(this, "primaryTargetSpeciesOther", _descriptor16, this);

      _initializerDefineProperty(this, "acres", _descriptor17, this);

      _initializerDefineProperty(this, "number", _descriptor18, this);

      _initializerDefineProperty(this, "description", _descriptor19, this);

      _initializerDefineProperty(this, "date", _descriptor20, this);

      _initializerDefineProperty(this, "date2", _descriptor21, this);

      _initializerDefineProperty(this, "miscFloat", _descriptor22, this);

      _initializerDefineProperty(this, "length", _descriptor23, this);

      _initializerDefineProperty(this, "method", _descriptor24, this);

      _initializerDefineProperty(this, "liveStockTypesCattle", _descriptor25, this);

      _initializerDefineProperty(this, "liveStockTypesCowCalf", _descriptor26, this);

      _initializerDefineProperty(this, "liveStockTypesGoats", _descriptor27, this);

      _initializerDefineProperty(this, "liveStockTypesHorses", _descriptor28, this);

      _initializerDefineProperty(this, "liveStockTypesSheep", _descriptor29, this);

      _initializerDefineProperty(this, "liveStockTypesOther", _descriptor30, this);

      _initializerDefineProperty(this, "treatmentId", _descriptor31, this);

      _initializerDefineProperty(this, "treatmentTypeId", _descriptor32, this);

      _initializerDefineProperty(this, "cropGrownId", _descriptor33, this);

      _initializerDefineProperty(this, "deerSeasonId", _descriptor34, this);

      _initializerDefineProperty(this, "deerSeason", _descriptor35, this);

      _initializerDefineProperty(this, "habitatProjects", _descriptor36, this);

      _initializerDefineProperty(this, "shape", _descriptor37, this);
    }

    get geoJSON() {
      let shape = this.shape;

      if (!shape) {
        return;
      }

      try {
        return JSON.parse(shape);
      } catch (e) {// noop
      }
    }

    set geoJSON(value) {
      if (value) {
        this.set('shape', JSON.stringify(value));
      } else {
        this.set('shape', null);
      }

      return value;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "siteId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "habitatPracticeTypeId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "habitatPracticeSubTypeId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "primaryTargetSpeciesAsheRedBerryJuniper", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "primaryTargetSpeciesChineseTallow", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "primaryTargetSpeciesCreosoteTarBush", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "primaryTargetSpeciesEasternRedCedar", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "primaryTargetSpeciesHardwoods", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "primaryTargetSpeciesHuisache", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "primaryTargetSpeciesMcCartneyRose", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "primaryTargetSpeciesMesquite", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "primaryTargetSpeciesMixedBrush", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "primaryTargetSpeciesPricklyPear", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "primaryTargetSpeciesSaltCedar", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "primaryTargetSpeciesShinoak", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "primaryTargetSpeciesOther", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class2.prototype, "acres", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class2.prototype, "number", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class2.prototype, "description", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class2.prototype, "date", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class2.prototype, "date2", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class2.prototype, "miscFloat", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class2.prototype, "length", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class2.prototype, "method", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class2.prototype, "liveStockTypesCattle", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class2.prototype, "liveStockTypesCowCalf", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class2.prototype, "liveStockTypesGoats", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class2.prototype, "liveStockTypesHorses", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class2.prototype, "liveStockTypesSheep", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class2.prototype, "liveStockTypesOther", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class2.prototype, "treatmentId", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class2.prototype, "treatmentTypeId", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class2.prototype, "cropGrownId", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class2.prototype, "deerSeasonId", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class2.prototype, "deerSeason", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class2.prototype, "habitatProjects", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class2.prototype, "shape", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "geoJSON", [_dec38], Object.getOwnPropertyDescriptor(_class2.prototype, "geoJSON"), _class2.prototype)), _class2)) || _class);
  _exports.default = HabitatPractice;
});