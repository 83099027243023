define("mldp/components/nav-link/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4815zAEU",
    "block": "[[[41,[28,[37,1],[[30,0,[\"router\",\"currentRouteName\"]],[30,1]],null],[[[1,\"  \"],[8,[39,2],[[24,0,\"no-underline hover:no-underline focus:no-underline text-cerulean-700 whitespace-nowrap py-4 px-1 border-b-2 border-cerulean-700 font-medium\"],[24,\"aria-current\",\"page\"]],[[\"@route\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],[[24,0,\"border-transparent no-underline hover:no-underline focus:no-underline text-brown-700 hover:text-brown-600 hover:border-brown-400 whitespace-nowrap py-4 px-1 border-b-2 font-medium\"]],[[\"@route\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"@routeName\",\"@linkText\"],false,[\"if\",\"eq\",\"link-to\"]]",
    "moduleName": "mldp/components/nav-link/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});