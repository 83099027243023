define("mldp/components/enrollment/other-enrollment-warning/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lXJqEQW6",
    "block": "[[[10,2],[12],[1,\"\\n  Warning! This property already has an existing MLDP Conservation Option (CO) enrollment for this season for one or more management units. If you continue with this request to create another, separate CO enrollment for this site, it will be charged the full fee amount ($300 for the first management unit, plus $30 per additional unit).\\n\"],[13],[1,\"\\n\"],[10,2],[12],[1,\"\\n  Alternatively, your biologist can edit the existing CO enrollment already on file for this site to include the additional management unit(s) you are attempting to enroll here. The fee associated with the existing enrollment will be adjusted accordingly based on your edits ($30 per additional management unit). Click Cancel if you prefer to have your biologist edit the existing enrollment or Continue if you want to proceed and create another enrollment for this site.\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "mldp/components/enrollment/other-enrollment-warning/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});