define("mldp/components/permit/ph-recommendation-view/accept-modal/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let AcceptModal = (_dec = (0, _component.tagName)(''), _dec(_class = classic(_class = class AcceptModal extends _component2.default {
    didReceiveAttrs() {
      this.permit.phPartitions.forEach(partition => {
        partition.phRecommendations.forEach(rec => {
          if (this.recommendation && rec.id === this.recommendation.id) this.set('herdUnitId', partition.herdUnit);
        });
      });
    }

  }) || _class) || _class);
  _exports.default = AcceptModal;
});