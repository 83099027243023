define("mldp/components/form-fields/date-field/component", ["exports", "@ember-decorators/component", "@ember/component", "@ember/utils", "@ember/object", "ember-form-for/utils/date"], function (_exports, _component, _component2, _utils, _object, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let DateFieldComponent = (_dec = (0, _component.tagName)(''), classic(_class = _dec(_class = class DateFieldComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "control", 'calendar-field');

      _defineProperty(this, "configClasses", ['labelClasses', 'hintClasses', 'errorClasses']);
    }

    serializeValue(value) {
      if (!value) {
        return;
      }

      let isString = typeof value === 'string';
      let afterSet = isString && value.endsWith('.000Z');

      if (!afterSet && isString && this.local) {
        let date = new Date(value);
        return convertUTCDateToLocalDate(date);
      }

      if (!(value instanceof Date)) {
        return new Date(value);
      }

      return value;
    }

    deserializeValue(value) {
      if ((0, _utils.isEmpty)(value)) {
        return null;
      }

      if (value instanceof Date) {
        return value.toISOString();
      }

      return value;
    }

  }) || _class) || _class);

  window.__CLASSIC_OWN_CLASSES__.set(DateFieldComponent, true);

  DateFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });
  var _default = DateFieldComponent;
  _exports.default = _default;

  function convertUTCDateToLocalDate(date) {
    let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    let offset = date.getTimezoneOffset() / 60;
    let hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }
});