define("mldp/components/site-property-edit/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/object/computed", "@ember/component", "rsvp", "mldp/models/invite-model", "mldp/utils/handle-upload", "mldp/utils/acreage-tolerance"], function (_exports, _component, _object, _service, _computed, _component2, _rsvp, _inviteModel, _handleUpload, _acreageTolerance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SitePropertyEdit = (_dec = (0, _component.tagName)(""), _dec2 = (0, _service.inject)('role-service'), _dec3 = (0, _service.inject)('store'), _dec4 = (0, _service.inject)('notifications'), _dec5 = (0, _computed.gt)('units.length', 0), _dec(_class = classic(_class = (_class2 = class SitePropertyEdit extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "roleService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "hasUnits", _descriptor5, this);
    }

    init() {
      super.init(...arguments);
      let submitLabel = 'Update Property';
      let submitPending = 'Updating..';
      this.setProperties({
        submitLabel,
        submitPending,
        accept: ['application/zip', 'application/vnd.google-earth.kml+xml', 'application/gpx+xml'].join(','),
        isAgentOptions: [{
          label: 'Yes',
          value: false
        }, {
          label: 'No',
          value: true
        }],
        booleanOptions: [{
          label: 'Yes',
          value: true
        }, {
          label: 'No',
          value: false
        }]
      });
    }

    drawBoundary() {
      this.eventsBus.trigger('appMapEditable:drawBoundary');
    }

    editBoundary() {
      this.eventsBus.trigger('appMapEditable:editBoundary');
    }

    deleteBoundary() {
      this.eventsBus.trigger('appMapEditable:deleteBoundary');
    }

    updateGeom(value) {
      let property = this.model;
      let hasUnits = this.hasUnits;
      let hasMoreThenOneFeature = (value === null || value === void 0 ? void 0 : value.features) && value.features.length > 1;
      property.setProperties({
        singleMgtUnit: hasMoreThenOneFeature || hasUnits ? false : true,
        geoJSON: value
      });
    }

    uploadGeom(data) {
      return (0, _handleUpload.default)(data.blob).then(geojson => {
        this.set('model.geoJSON', geojson);
        data.set('loaded', true);
      });
    }

    continue(model) {
      let isUserInTpwd = this.roleService.isUserInTpwd();
      return model.validate().then(() => {
        if (model.get('validations.isValid')) {
          if (this.notifyAcreageTolerance(model)) {
            if (!isUserInTpwd && model.get('isAgent')) {
              this.set('showAgentAgreement', true);
            } else {
              return this.saveProperty(model);
            }
          }

          return;
        }

        return _rsvp.default.reject('Validations invalid');
      }).catch(e => {
        let notifications = this.notifications;

        if (e === 'Validations invalid') {
          return _rsvp.default.reject(notifications.addValidationErrors(model));
        } else {
          return _rsvp.default.reject(notifications.addApiErrors(e));
        }
      });
    }

    save(model) {
      return this.saveProperty(model);
    }

    saveProperty(model) {
      let hasUnits = this.hasUnits;
      let promise = this.onSave(model);
      let finishRoute = hasUnits ? 'account.property.units' : 'account.property.units.create';
      return promise.then(results => {
        let type = model.get('siteType');
        let id = model.id;

        if (results === 'NPOL Cancel') {
          return _rsvp.default.Promise.resolve(results);
        }

        return this.router.transitionTo('account.property', type, id);
      });
    }

    notifyAcreageTolerance(model) {
      let calculatedAcreage = Number(model.get('geoJSONArea'));
      let statedAcreage = Number(model.get('statedAcreage'));

      if (!(0, _acreageTolerance.default)(calculatedAcreage, statedAcreage)) {
        return confirm(`Notice: Stated (${statedAcreage}) and calculated (${calculatedAcreage}) acreage are not within tolerance of each other.\n
      Click Ok to continue or Cancel to fix.`);
      }

      return true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "roleService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "hasUnits", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "drawBoundary", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "drawBoundary"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "editBoundary", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "editBoundary"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteBoundary", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteBoundary"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateGeom", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateGeom"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "uploadGeom", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "uploadGeom"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "continue", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "continue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = SitePropertyEdit;
});