define("mldp/components/state-select/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let stateSelectComponent = classic(_class = class stateSelectComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "states", [// eslint-disable-line ember/avoid-leaking-state-in-ember-objects
      {
        name: 'Texas',
        value: 'TX'
      }, {
        name: 'Alabama',
        value: 'AL'
      }, {
        name: 'Alaska',
        value: 'AK'
      }, {
        name: 'Arizona',
        value: 'AZ'
      }, {
        name: 'Arkansas',
        value: 'AR'
      }, {
        name: 'California',
        value: 'CA'
      }, {
        name: 'Colorado',
        value: 'CO'
      }, {
        name: 'Connecticut',
        value: 'CT'
      }, {
        name: 'Delaware',
        value: 'DE'
      }, {
        name: 'District Of Columbia',
        value: 'DC'
      }, {
        name: 'Florida',
        value: 'FL'
      }, {
        name: 'Georgia',
        value: 'GA'
      }, {
        name: 'Hawaii',
        value: 'HI'
      }, {
        name: 'Idaho',
        value: 'ID'
      }, {
        name: 'Illinois',
        value: 'IL'
      }, {
        name: 'Indiana',
        value: 'IN'
      }, {
        name: 'Iowa',
        value: 'IA'
      }, {
        name: 'Kansas',
        value: 'KS'
      }, {
        name: 'Kentucky',
        value: 'KY'
      }, {
        name: 'Louisiana',
        value: 'LA'
      }, {
        name: 'Maine',
        value: 'ME'
      }, {
        name: 'Maryland',
        value: 'MD'
      }, {
        name: 'Massachusetts',
        value: 'MA'
      }, {
        name: 'Michigan',
        value: 'MI'
      }, {
        name: 'Minnesota',
        value: 'MN'
      }, {
        name: 'Mississippi',
        value: 'MS'
      }, {
        name: 'Missouri',
        value: 'MO'
      }, {
        name: 'Montana',
        value: 'MT'
      }, {
        name: 'Nebraska',
        value: 'NE'
      }, {
        name: 'Nevada',
        value: 'NV'
      }, {
        name: 'New Hampshire',
        value: 'NH'
      }, {
        name: 'New Jersey',
        value: 'NJ'
      }, {
        name: 'New Mexico',
        value: 'NM'
      }, {
        name: 'New York',
        value: 'NY'
      }, {
        name: 'North Carolina',
        value: 'NC'
      }, {
        name: 'North Dakota',
        value: 'ND'
      }, {
        name: 'Ohio',
        value: 'OH'
      }, {
        name: 'Oklahoma',
        value: 'OK'
      }, {
        name: 'Oregon',
        value: 'OR'
      }, {
        name: 'Pennsylvania',
        value: 'PA'
      }, {
        name: 'Rhode Island',
        value: 'RI'
      }, {
        name: 'South Carolina',
        value: 'SC'
      }, {
        name: 'South Dakota',
        value: 'SD'
      }, {
        name: 'Tennessee',
        value: 'TN'
      }, {
        name: 'Texas',
        value: 'TX'
      }, {
        name: 'Utah',
        value: 'UT'
      }, {
        name: 'Vermont',
        value: 'VT'
      }, {
        name: 'Virginia',
        value: 'VA'
      }, {
        name: 'Washington',
        value: 'WA'
      }, {
        name: 'West Virginia',
        value: 'WV'
      }, {
        name: 'Wisconsin',
        value: 'WI'
      }, {
        name: 'Wyoming',
        value: 'WY'
      }]);
    }

  }) || _class;

  window.__CLASSIC_OWN_CLASSES__.set(stateSelectComponent, true);

  stateSelectComponent.reopenClass({
    positionalParams: ['f', 'property']
  });
  var _default = stateSelectComponent;
  _exports.default = _default;
});