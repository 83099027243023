define("mldp/models/user-register", ["exports", "mldp/models/custom-model", "ember-cp-validations"], function (_exports, _customModel, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  const Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', true),
    lastName: (0, _emberCpValidations.validator)('presence', true),
    address1: (0, _emberCpValidations.validator)('presence', true),
    city: (0, _emberCpValidations.validator)('presence', true),
    state: (0, _emberCpValidations.validator)('presence', true),
    zipCode: (0, _emberCpValidations.validator)('presence', true),
    primaryPhone: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'phone'
    })],
    emailAddress: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 8
    })],
    confirmPassword: [(0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: '{description} do not match',
      description: 'Passwords'
    })]
  });

  let Model = classic(_class = class Model extends _customModel.default.extend(Validations) {}) || _class;

  window.__CLASSIC_OWN_CLASSES__.set(Model, true);

  Model.reopenClass({
    attrs: ['firstName', 'lastName', 'emailAddress', 'password', 'confirmPassword', 'address1', 'address2', 'city', 'state', 'zipCode', 'primaryPhone']
  });
  var _default = Model;
  _exports.default = _default;
});