define("mldp/components/practice/proscribed-burning/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "e2Zj8lTM",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"create\"]],[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[33,1,[\"number-field\"]],[\"acres\"],[[\"required\"],[true]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[33,1,[\"date-field\"]],[\"date\"],[[\"label\",\"required\"],[\"Date of Burn\",true]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"model\",\"type\"],[[30,0,[\"model\"]],\"acres\"]]]],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"model\",\"type\",\"label\"],[[30,0,[\"model\"]],\"date\",\"Date of Burn\"]]]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\"],false,[\"if\",\"f\",\"practice/display-field\"]]",
    "moduleName": "mldp/components/practice/proscribed-burning/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});