define("mldp/components/permit/amd-bulk-recommendation/compartment-details/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RJ1HW9XX",
    "block": "[[[10,\"dl\"],[14,0,\"dl-horizontal\"],[12],[1,\"\\n  \"],[10,\"dt\"],[12],[1,\"Total Permits Available\"],[13],[1,\"\\n  \"],[10,\"dd\"],[12],[1,[30,1,[\"totalPermits\"]]],[13],[1,\"\\n\\n  \"],[10,\"dt\"],[12],[1,\"Total Permits Issued\"],[13],[1,\"\\n  \"],[10,\"dd\"],[12],[1,[30,1,[\"issuedPermits\"]]],[13],[1,\"\\n\\n  \"],[10,\"dt\"],[12],[1,\"Total Permits Remaining\"],[13],[1,\"\\n  \"],[10,\"dd\"],[12],[1,[28,[35,0],[[30,1,[\"totalPermits\"]],[30,1,[\"issuedPermits\"]]],null]],[13],[1,\"\\n\"],[13]],[\"@tally\"],false,[\"subtract\"]]",
    "moduleName": "mldp/components/permit/amd-bulk-recommendation/compartment-details/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});