define("mldp/app", ["exports", "ember", "@ember/application", "rsvp", "ember-resolver", "ember-load-initializers", "mldp/config/environment"], function (_exports, _ember, _application, _rsvp, _emberResolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  _rsvp.default.on('error', function (error) {
    console.warn(error);
  });

  _ember.default.onerror = function (error) {
    console.warn(error);
  };

  class App extends _application.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);

      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);

      _defineProperty(this, "Resolver", _emberResolver.default);
    }

  }

  _exports.default = App;

  window.__CLASSIC_OWN_CLASSES__.set(App, true);

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});