define("mldp/account/property/index/route", ["exports", "@ember/object", "@ember/service", "@ember/object/computed", "@ember/routing/route", "rsvp", "mldp/utils/within-date-range", "mldp/utils/get-report-auth", "moment"], function (_exports, _object, _service, _computed, _route, _rsvp, _withinDateRange, _getReportAuth, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /* eslint-disable ember/no-controller-access-in-routes */
  const classic = __EMBER_CLASSIC_DECORATOR;
  let IndexRoute = (_dec = (0, _service.inject)('state'), _dec2 = (0, _service.inject)('role-service'), _dec3 = (0, _service.inject)('general-ajax'), _dec4 = (0, _service.inject)('notifications'), _dec5 = (0, _computed.alias)('state.postReportSubmit.completedReportsSeason'), classic(_class = (_class2 = class IndexRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);

      _initializerDefineProperty(this, "roleService", _descriptor2, this);

      _initializerDefineProperty(this, "ajaxService", _descriptor3, this);

      _initializerDefineProperty(this, "notifications", _descriptor4, this);

      _initializerDefineProperty(this, "completedReportsSeason", _descriptor5, this);
    }

    async model() {
      let data = this.modelFor('account.property');
      let {
        siteId,
        type,
        archived
      } = this.paramsFor('account.property');
      let adapter = this.store.adapterFor(type.toLowerCase());

      if (type === 'aggregate') {
        const results = await adapter.canInviteMember(siteId);
        data.canInvite = results.canInviteMember;
      }

      let queryOptions = {
        'filter[siteid]': siteId
      };

      try {
        let invites = await adapter.pendingInvitations(siteId);
        data.invites = invites;
      } catch {
        data.invites = [];
      }

      data.projects = this.store.query('habitat-project', queryOptions);
      const seasonAdapter = this.store.adapterFor('tlk-season'); //TODO: this can't be right, see if there is a way to do this with adapters, or get Peter to change response from 404

      try {
        data.currentSeasonPH = await seasonAdapter['phFindCurrentApplication']();
      } catch {
        data.currentSeasonPH = false;
      }

      try {
        data.currentSeasonAMD = await seasonAdapter['amdFindCurrentApplication']();
      } catch {
        data.currentSeasonAMD = false;
      }

      data.enrollments = data.property.findAllEnrollments({
        query: {
          archived
        }
      });
      data.amdApplications = this.store.query('amd-permit-app', queryOptions); // .then((res) => {
      //   const permits = [];
      //   res.content.map((permit) => {
      //     permits.push(permit.id);
      //   });
      //   return permits;
      // });

      data.phApplications = this.store.query('ph-permit-app', queryOptions); // .then((res) => {
      //   console.log('')
      //   const permits = [];
      //   res.content.map((permit) => {
      //     permits.push(permit.id);
      //   });
      //   return permits;
      // });

      data.units = await adapter.getManagementUnits(siteId, archived); // Disable practice map layer for pooled properties

      if (type !== 'aggregate' && type !== 'coop') {
        data.practices = _rsvp.default.all(data.units.map(unit => unit.findPractices()));
      } else {
        data.practices = [];
      }

      return _rsvp.default.hash(data);
    }

    setupController(controller, resolved) {
      resolved.practices = resolved.practices.flat().filter(practice => practice.shape);
      resolved.projectLayer = resolved.projects.toArray().filter(project => project.shape);
      resolved.projects.forEach(project => {
        if (project.habitatProjectTypeId) {
          project.habitatProjectType = this.state.habitatProjectTypes.find(el => parseInt(el.id) === parseInt(project.habitatProjectTypeId));
        }
      });
      resolved.practiceLayers = resolved.practices.reduce((acc, cur) => {
        let matchedSeason = acc.find(x => x[0] === cur.deerSeasonId);

        if (matchedSeason) {
          matchedSeason[1].push(cur);
        } else {
          acc.push([cur.deerSeasonId, [cur]]);
        }

        return acc;
      }, []);
      let state = this.state;
      let roleService = this.roleService;
      let currentSeason = this.get('state.currentSeason');
      let startDate = currentSeason.get('enrollmentStartDate');
      let endDate = currentSeason.get('enrollmentEndDate');
      let isPool = resolved.property.get('isPool');
      let showNotices = resolved.property.get('showNotices');
      let isPublic = resolved.property.get('isPublicLand');
      let trackResearchAgreement = resolved.property.get('trackResearchAgreement');
      let isTpwdUser = roleService.isUserInTpwd();
      let isCreatedByAgent = !isPool && state.get('propertyIsOwner') === false;
      let isCreatedByOwner = !isPool && state.get('propertyIsOwner') === true;
      let postCreateNoticeComponent;

      if (showNotices) {
        let isNewProperty = resolved.property.get('isCreateMode');
        let rtaIsSigned = resolved.property.get('agreementDate');

        if (isCreatedByOwner && rtaIsSigned) {
          resolved.showContactBio = true;
          resolved.property.set('showNotices', false);
        } else if (isCreatedByOwner) {
          postCreateNoticeComponent = 'notices/property-incomplete';
        } else if (isPublic) {
          postCreateNoticeComponent = 'notices/property-finished';
        } else if (trackResearchAgreement) {
          postCreateNoticeComponent = 'notices/property-research-agreement';
        } else if (isTpwdUser && isNewProperty) {
          postCreateNoticeComponent = 'notices/property-tpwd';
        } else if (isCreatedByAgent) {
          postCreateNoticeComponent = 'notices/property-created-by-agent';
        } else {
          resolved.property.set('showNotices', false);
        }
      }

      state.set('propertyIsOwner', undefined); // const parentController = this.controllerFor('account.property');
      // let canInvite;
      // let passedEnrollmentWithdrawalDeadline;
      // let passedAmdWithdrawalDeadline;
      // let passedPhWithdrawalDeadline;
      // let withdrawDate;
      // if (
      //   parentController.hasActiveEnrollments ||
      //   resolved.amdApplications.length > 0 ||
      //   resolved.phApplications.length > 0
      // ) {
      //   if (parentController.hasActiveEnrollments) {
      //     const activeEnrollments = parentController.activeEnrollments.content;
      //     passedEnrollmentWithdrawalDeadline = activeEnrollments
      //       .map((enrollment) => {
      //         if (enrollment.isConservation) {
      //           withdrawDate = currentSeason.coEnrollmentWithdrawalDate;
      //         } else {
      //           withdrawDate = currentSeason.hoEnrollmentWithdrawalDate;
      //         }
      //         if (moment(new Date()).isAfter(withdrawDate)) {
      //           return true;
      //         } else {
      //           return false;
      //         }
      //       })
      //       .some((el) => el === true);
      //   }
      //   if (resolved.amdApplications.length > 0) {
      //     passedAmdWithdrawalDeadline = resolved.amdApplications
      //       .map((application) => {
      //         withdrawDate = application.get('season.amdApplicationWithdrawDate');
      //         if (moment(new Date()).isAfter(withdrawDate)) {
      //           return true;
      //         } else {
      //           return false;
      //         }
      //       })
      //       .some((el) => el === true);
      //   }
      //   if (resolved.phApplications.length > 0) {
      //     passedPhWithdrawalDeadline = resolved.phApplications
      //       .map((application) => {
      //         withdrawDate = application.get('season.phApplicationWithdrawDate');
      //         if (moment(new Date()).isAfter(withdrawDate)) {
      //           return true;
      //         } else {
      //           return false;
      //         }
      //       })
      //       .some((el) => el === true);
      //   }
      // }
      // if (
      //   (passedEnrollmentWithdrawalDeadline ||
      //     passedAmdWithdrawalDeadline ||
      //     passedPhWithdrawalDeadline) &&
      //   resolved.property.siteType === 'aggregate'
      // ) {
      //   canInvite = false;
      // } else {
      //   canInvite = true;
      // }
      // resolved.canInvite = canInvite;
      //TODO: combine these two fields below?

      resolved.completedReportsSeason = state.postReportSubmit ? state.postReportSubmit.completedReportsSeason : false;
      resolved.property.set('isCreateMode', false);
      resolved.property.set('newProperty', false);
      resolved.permitsLength = resolved.phApplications.length + resolved.amdApplications.length;
      resolved.postCreateNoticeComponent = postCreateNoticeComponent;
      resolved.withinEnrollmentPeriod = (0, _withinDateRange.default)(startDate, endDate);
      resolved.showWMPConfirm = false;
      resolved.showPendingInvites = false;
      resolved.isAdmin = roleService.get('isAdmin');
      resolved.projectsLength = resolved.projects.toArray().length;
      controller.setProperties(resolved);
    }

    showWMPModal(show) {
      this.controller.set('showWMPConfirm', show);
    }

    showPendingInvitesModal(show) {
      this.controller.set('showPendingInvites', show);
    }

    closeCompletedReports() {
      this.state.set('postReportSubmit.completedReportsSeason', false);
      this.controller.set('completedReportsSeason', false);
    }

    async createRta(property) {
      let rta = this.store.createRecord('unassigned-rta', {
        siteId: property.id,
        requestDate: new Date()
      });
      await rta.save();
      property.set('showNotices', true);
      this.controller.set('postCreateNoticeComponent', 'notices/rta-created');
      this.controller.set('showContactBio', false);
    }

    noRta(property) {
      let state = this.state;
      let isPool = property.get('isPool');
      let isCreatedByOwner = !isPool && state.get('propertyIsOwner') === true;

      if (isCreatedByOwner) {
        this.controller.set('postCreateNoticeComponent', 'notices/property-finished');
      } else {
        this.controller.set('postCreateNoticeComponent', 'notices/property-incomplete');
      }

      property.set('showNotices', true);
      this.controller.set('showContactBio', false);
    }

    toggleWmp(site) {
      let query;

      if (site.get('wmpIsActive')) {
        query = site.deactivateWmp();
      } else {
        query = site.activateWmp();
      }

      return query.then(() => site.reload().then(() => this.controller.set('showWMPConfirm', false)));
    }

    async getPoolMembers() {
      let {
        siteId
      } = this.paramsFor('account.property');
      let report = {
        name: 'Pool Members',
        url: '/report/poolmembers',
        formats: ['excel']
      };
      let ajax = this.ajaxService;
      let auth = await (0, _getReportAuth.default)(report, ajax);
      window.open(`/report/poolmembers?format=excel&siteid=${siteId}&auth=${auth}`);
    }

    async clearPendingInvite(id) {
      let notifications = this.notifications;

      try {
        let {
          siteId,
          type
        } = this.paramsFor('account.property');
        let adapter = this.store.adapterFor(type.toLowerCase());
        await adapter.clearPendingInvitation(siteId, id);
        const newInvites = await adapter.pendingInvitations(siteId);
        this.controller.set('invites', newInvites);
        notifications.add('Pending Invite Removed');
      } catch {
        notifications.error('An error has occured');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "roleService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "ajaxService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "completedReportsSeason", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "showWMPModal", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "showWMPModal"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showPendingInvitesModal", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "showPendingInvitesModal"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "closeCompletedReports", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "closeCompletedReports"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createRta", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "createRta"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "noRta", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "noRta"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleWmp", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleWmp"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getPoolMembers", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "getPoolMembers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clearPendingInvite", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "clearPendingInvite"), _class2.prototype)), _class2)) || _class);
  _exports.default = IndexRoute;
});