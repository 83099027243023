define("mldp/account/permits/ph-recommendations/controller", ["exports", "@ember/controller", "@ember/object", "@ember/array", "@ember/service"], function (_exports, _controller, _object, _array, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    storeService: (0, _service.inject)('store'),
    notifications: (0, _service.inject)('notifications'),
    highlightedUnits: (0, _array.A)(),
    selectedLength: (0, _object.computed)('phPermits.@each.selected', function () {
      let phPermits = this.phPermits;
      return phPermits.reduce((acc, permit) => {
        if (permit.selected) return acc + 1;
        return acc;
      }, 0);
    }),
    totalAcreage: (0, _object.computed)('herdUnitId', 'phPermits', function () {
      return this.phPermits.reduce((total, permit) => {
        permit.phPartitions.forEach(partition => {
          if (Number(this.herdUnitId) === Number(partition.herdUnit)) {
            total += partition.totalAcreage;
          }
        });
        return total;
      }, 0);
    }),
    managementUnits: (0, _object.computed)('phPermits', function () {
      return this.phPermits.reduce((all, permit) => {
        let managementUnits = permit.mUs.map(muId => {
          let partitions = permit.phPartitions.reduce((partitions, partition) => {
            if (partition.siteId === muId) {
              partitions.push(partition);
            }

            return partitions;
          }, []);
          let siteX = permit.siteXPhPermitApps.findBy('siteId', muId);
          let site = siteX === null || siteX === void 0 ? void 0 : siteX.site;

          try {
            if (site !== null && site !== void 0 && site.shape) {
              site.geoJSON = JSON.parse(site.shape);
            }
          } catch (e) {// noop
          }

          return {
            id: muId,
            site,
            partitions,
            permit
          };
        });
        all = all.concat(managementUnits);
        return all;
      }, []);
    }),
    actions: {
      selectAll() {
        this.phPermits.forEach(permit => {
          permit.set('selected', true);
        });
        this.send('updateRecBuckTotal');
      },

      onHighlight(permitApp) {
        let newHighlightedUnits = (0, _array.A)();

        if (this.highlightedPermitApp === permitApp.id) {
          this.set('highlightedPermitApp', null);
          this.set('highlightedUnits', newHighlightedUnits);
          return;
        }

        permitApp.mUs.forEach(unitId => {
          const found = this.managementUnits.find(mu => mu.id === unitId);
          if (found) newHighlightedUnits.push(found);
        });
        this.set('highlightedPermitApp', permitApp.id);
        this.set('highlightedUnits', newHighlightedUnits);
      },

      updateRecBuckTotal() {
        let total = 0;
        this.phPermits.forEach(permit => {
          if (permit.selected) {
            permit.partition.newPhRecommendations.forEach(rec => total += rec.recommendedBucks);
          }

          permit.partition.phRecommendations.forEach(rec => {
            if (!rec.invalidated) total += rec.recommendedBucks;
          });
        });
        this.set('newTotalBuckIssued', total);
      },

      unselectAll() {
        this.phPermits.forEach(permit => {
          permit.set('selected', false);
        });
        this.send('updateRecBuckTotal');
      },

      selectOne(permit) {
        permit.set('selected', true);
        this.send('updateRecBuckTotal');
      },

      unselectOne(permit) {
        permit.set('selected', false);
        this.send('updateRecBuckTotal');
      },

      applyCalculatedAcreage() {
        const store = this.storeService;
        this.phPermits.forEach(permit => {
          if (permit.selected) {
            const {
              partition
            } = permit;
            let recommendedBuck;

            if (partition.calculatedBucks > 1) {
              recommendedBuck = Math.round(partition.calculatedBucks);
            } else {
              recommendedBuck = Math.floor(partition.calculatedBucks);
            }

            const newRec = store.createRecord('ph-recommendation', {
              phPartitionId: partition.id,
              seasonId: this.currentSeason.id,
              recommendedBucks: recommendedBuck,
              recommendedDoes: Number(partition.calculatedDoes)
            });
            partition.newPhRecommendations.pushObject(newRec);
          }
        });
        this.send('updateRecBuckTotal');
      },

      async applyLastSeasonRecs() {
        this.set('loading', true);
        const store = this.storeService;
        let lastSeason = Number(this.currentSeason.id) - 1;
        const lastSeasonPermits = await this.store.query('ph-permit-app', {
          'filter[herdunit]': this.herdUnitId,
          'filter[seasonid]': lastSeason
        });
        let lastSeasonPartitions = [];
        lastSeasonPermits.forEach(permit => {
          permit.phPartitions.forEach(part => {
            if (parseInt(part.herdUnit) === parseInt(this.herdUnitId) && part.phRecommendations.length) {
              lastSeasonPartitions.push(part);
            }
          });
        });
        this.phPermits.forEach(permit => {
          if (permit.selected) {
            const {
              partition
            } = permit;
            lastSeasonPartitions.forEach(lastSeaonRec => {
              if (lastSeaonRec.siteId === partition.siteId) {
                lastSeaonRec.phRecommendations.forEach(lastSeasonRec => {
                  if (!lastSeasonRec.invalidated && !lastSeasonRec.archived) {
                    const newRec = store.createRecord('ph-recommendation', {
                      phPartitionId: partition.id,
                      seasonId: this.currentSeason.id,
                      recommendedBucks: lastSeasonRec.recommendedBucks,
                      recommendedDoes: lastSeasonRec.recommendedDoes
                    });
                    partition.newPhRecommendations.pushObject(newRec);
                  }
                });
              }
            });
          }
        });
        this.send('updateRecBuckTotal');
        this.set('loading', false);
      },

      async submitRecommendations() {
        let adapter = this.store.adapterFor('ph-recommendation');
        let data = [];
        this.phPermits.forEach(permit => {
          if (permit.selected) {
            return permit.partition.newPhRecommendations.forEach(rec => data.push(rec));
          }

          return;
        });
        await adapter.postCollection({
          data
        });
        this.send('refreshModel');
        return this.notifications.add('Submitted recommendations');
      }

    } // submitRecommendations: task(function * (recommendations) {
    //   // TODO
    // })

  });

  _exports.default = _default;
});