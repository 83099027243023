define("mldp/account/property/enrollment/reporting-status/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7JI5kqJ+",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"Reporting Status\"]],null],[1,\"\\n\\n\"],[10,\"h2\"],[12],[1,\"\\n  \"],[1,[30,0,[\"enrollment\",\"deerSeason\",\"displayValue\"]]],[1,\": \"],[1,[30,0,[\"enrollment\",\"enrollmentType\",\"displayValue\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n  Primary Enrolled Site: \"],[1,[30,0,[\"enrollment\",\"primarySite\",\"siteName\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"panel panel-default\"],[12],[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"panel-heading\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@navigate\",\"@currentTab\"],[[30,1,[\"transition-to\"]],[30,1,[\"currentStep\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"panel-body\"],[12],[1,\"\\n      \"],[8,[30,1,[\"Step\"]],null,[[\"@name\"],[\"units\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n          \"],[10,\"strong\"],[12],[1,\"Click to collapse reporting status:\"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"sites\"]]],null]],null],null,[[[41,[30,2,[\"show\"]],[[[1,\"            \"],[8,[39,6],null,[[\"@reporting\"],[[30,2]]],null],[1,\"\\n\"]],[]],null]],[2]],null],[1,\"      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[30,1,[\"Step\"]],null,[[\"@name\"],[\"reporting-details\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,7],null,null,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"],[13]],[\"sm\",\"site\"],false,[\"page-banner\",\"step-manager\",\"reporting-status-tabs\",\"each\",\"-track-array\",\"if\",\"reporting-status-item\",\"reporting-requirements\"]]",
    "moduleName": "mldp/account/property/enrollment/reporting-status/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});