define("mldp/components/agreements/property-agent/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PcqrZ1+s",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,\"ol\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      I hereby acknowledge that I am authorized as a designated agent for this property\\n      and understand the landowner may rescind my designated agent status for this property at any time.\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      I understand that as a designated agent I have the authority to act on behalf of the\\n      landowner of this property to participate in, fulfill requirements of, and be responsible\\n      for conducting habitat management practices required for Texas Parks and Wildlife Department\\n      programs, permits, or assistance requests. In addition, I understand that failure to fulfill\\n      permit or program requirements could jeopardize participation in those programs for the\\n      property on which I am a designated agent.\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      I understand as the designated agent that I am responsible for informing the landowner of\\n      all correspondence and documents provide by Wildlife Division staff to me, unless\\n      otherwise directed by the landowner.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,\"\\n    Section 65.3 of the Parks and Wildlife Administrative Code defines an agent as\\n    “A person authorized by the landowner to act on behalf of the landowner.”\\n    This implies the agent shall have the same legal authority as the landowner to\\n    make decisions regarding participation in TPWD programs, permits, or assistance requests,\\n    and meeting program or permit requirements.\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/agreements/property-agent/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});