define("mldp/transforms/type", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Type = classic(_class = class Type extends _transform.default {
    deserialize(serialized) {
      return serialized && serialized.toLowerCase();
    }

    serialize(deserialized) {
      return deserialized && deserialized.toLowerCase();
    }

  }) || _class;

  _exports.default = Type;
});