define("mldp/utils/acreage-tolerance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = acreageWithinTolerance;

  function acreageWithinTolerance(calculated, stated) {
    let percentage;

    if (stated < 201) {
      percentage = stated * 0.10;
    } else if (stated > 200 && stated < 501) {
      percentage = stated * 0.05;
    } else if (stated > 500 && stated < 5001) {
      percentage = stated * 0.02;
    } else if (stated > 5000) {
      percentage = stated * 0.01;
    }

    if (percentage) {
      let belowHigh = stated + percentage > calculated;
      let aboveLow = stated - percentage < calculated;

      if (aboveLow && belowHigh) {
        return true;
      }
    }

    return false;
  }
});