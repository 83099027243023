define("mldp/account/permits/route", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let PermitsRoute = classic(_class = class PermitsRoute extends _route.default {
    beforeModel() {
      let params = this.paramsFor(this.routeName);
      this.set('breadCrumb', {
        title: `Permits: ${params.type === 'ph' ? 'Pronghorn' : 'Antlerless Mule Deer'}`
      });
    }

  }) || _class;

  _exports.default = PermitsRoute;
});