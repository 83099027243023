define("mldp/account/property/permit/ph-recommendations/view/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "rsvp", "moment"], function (_exports, _object, _service, _route, _rsvp, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ViewRoute = (_dec = (0, _service.inject)('store'), classic(_class = (_class2 = class ViewRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    async model() {
      let {
        property: site
      } = this.modelFor('account.property');
      const {
        permitType,
        permitId
      } = this.paramsFor('account.property.permit');
      const permit = await this.store.findRecord('ph-permit-app', permitId);
      const seasonAdapter = this.store.adapterFor('tlk-season');
      const currentSeason = await seasonAdapter.phFindCurrentReporting();
      const units = permit.mUs.map(id => this.store.findRecord('management-unit', id));
      let canAcceptRecs = false;

      if (permit.season.id > currentSeason.identifier) {
        canAcceptRecs = true;
      } else if (permit.season.id === currentSeason.identifier) {
        let today = (0, _moment.default)();
        canAcceptRecs = today.isBefore(currentSeason.phSeasonEndDate);
      }

      return _rsvp.default.hash({
        permit,
        permitType,
        canAcceptRecs,
        site,
        units: _rsvp.default.all(units)
      });
    }

    refreshRoute() {
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "refreshRoute", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "refreshRoute"), _class2.prototype)), _class2)) || _class);
  _exports.default = ViewRoute;
});