define("mldp/components/notices/rta-created/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "MwuCPgGr",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"Your request was submitted. A biologist will be in touch within 10 business days.\"],[13],[1,\" \\n\\n  \"],[10,2],[12],[1,\"You are done with the property registration process. You are welcome to enter harvest and/or habitat information, explore MLDP and/or other permit options, and upload documents at this time.\"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/notices/rta-created/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});