define("mldp/components/permit/unit-property-item/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let UnitPropertyItem = (_dec = (0, _component.tagName)('tr'), _dec2 = (0, _component.classNames)('enrollment-unit-item'), _dec3 = (0, _component.classNameBindings)('isHovered'), classic(_class = _dec(_class = _dec2(_class = _dec3(_class = class UnitPropertyItem extends _component2.default {
    // loadingEstimate: false,
    // didReceiveAttrs() {
    //   let unit = this.unit;
    //   let option = this.option;
    //   let gender = this.gender;
    //   let isHarvestOption = option && option.get('displayValue') === 'Harvest Option';
    //   let hasReleaseSite = Boolean(unit.deerBreederFacilityNumber);
    //   if (isHarvestOption && gender && unit && !this.tags) {
    //     this.tagEstimate(unit).then((res) => {
    //       this.setProperties({
    //         showTags: true,
    //         tags: res,
    //         canEnroll: hasReleaseSite || res.totalTags !== 0
    //       });
    //     });
    //   } else if (!isHarvestOption || !gender) {
    //     this.setProperties({
    //       showTags: false,
    //       canEnroll: true
    //     });
    //   } else {
    //     this.set('showTags', true);
    //   }
    //   unit.set('canEnroll', this.canEnroll);
    // },
    mouseEnter() {
      this.onHover(this.unit);
    }

    mouseLeave() {
      this.onHover();
    } // async tagEstimate(unit) {
    //   this.set('loadingEstimate', true)
    //   let ajax = this.ajax;
    //   const url = '/TagEstimator/Calculate';
    //   const data = {
    //     HighFence: unit.get('highFence'),
    //     GeoJson: unit.get('shape')
    //   };
    //   let result = await ajax.post(url, {
    //     data: JSON.stringify(data),
    //     dataType: 'json',
    //     contentType: 'application/json'
    //   });
    //   this.set('loadingEstimate', false)
    //   return result;
    // }


  }) || _class) || _class) || _class) || _class);
  _exports.default = UnitPropertyItem;
});