define("mldp/account/payment/index/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "99y5C1+9",
    "block": "[[[10,0],[14,0,\"flex mt-6 lg:justify-center\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"fees\",\"length\"]],0],null],[[[1,\"    \"],[8,[39,2],null,[[\"@fees\"],[[30,0,[\"fees\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"mx-auto font-bold\"],[12],[1,\"\\n      You do not have any fees due currently. Click Payment History to view your past payment information.\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[],false,[\"if\",\"gt\",\"fees-table\"]]",
    "moduleName": "mldp/account/payment/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});