define("mldp/components/enrollment/unit-property-item/component", ["exports", "@ember-decorators/component", "@ember/service", "@ember/component"], function (_exports, _component, _service, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let UnitPropertyItem = (_dec = (0, _component.tagName)('tr'), _dec2 = (0, _component.classNames)('enrollment-unit-item'), _dec3 = (0, _component.classNameBindings)('isHovered'), _dec4 = (0, _service.inject)('ajax'), classic(_class = _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class UnitPropertyItem extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _defineProperty(this, "loadingEstimate", false);
    }

    didReceiveAttrs() {
      let unit = this.unit;
      let option = this.option;
      let gender = this.gender;
      let isHarvestOption = option && option.get('displayValue') === 'Harvest Option';
      let hasReleaseSite = Boolean(unit.deerBreederFacilityNumber);

      if (isHarvestOption && gender && unit && !this.tags) {
        this.tagEstimate(unit).then(res => {
          this.setProperties({
            showTags: true,
            tags: res,
            canEnroll: hasReleaseSite || res.totalTags !== 0
          });
        });
      } else if (!isHarvestOption || !gender) {
        this.setProperties({
          showTags: false,
          canEnroll: true
        });
      } else {
        this.set('showTags', true);
      }

      unit.set('canEnroll', this.canEnroll);
    }

    mouseEnter() {
      this.onHover(this.unit);
    }

    mouseLeave() {
      this.onHover();
    }

    async tagEstimate(unit) {
      this.set('loadingEstimate', true);
      let ajax = this.ajax;
      const url = '/TagEstimator/Calculate';
      const data = {
        HighFence: unit.get('highFence'),
        GeoJson: unit.get('shape')
      };
      let result = await ajax.post(url, {
        data: JSON.stringify(data),
        dataType: 'json',
        contentType: 'application/json'
      });
      this.set('loadingEstimate', false);
      return result;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = UnitPropertyItem;
});