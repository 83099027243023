define("mldp/components/permit/amd-recommendation-view/list-header/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rcaG1v8y",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,\"header\"],[14,0,\"clearfix\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"inline push-up\"],[12],[1,\"\\n      Recommendations\\n      \"],[10,0],[12],[1,[30,0,[\"permit\",\"season\",\"displayValue\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"pull-right\"],[12],[1,\"\\n      \"],[10,3],[14,0,\"btn btn-primary\"],[14,\"role\",\"button\"],[14,6,\"https://storage.googleapis.com/lma-help-static/MLDP_Deer_Harvest_Log.pdf\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[1,\"Print Harvest Log PDF\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"h5\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"season\"],[12],[1,\"Total Permits Issued, All Managment Units: \"],[1,[30,0,[\"totalRecs\"]]],[1,\" Antlerless\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/permit/amd-recommendation-view/list-header/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});