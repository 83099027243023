define("mldp/account/reports/route", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let ReportsRoute = classic(_class = class ReportsRoute extends _route.default {}) || _class;

  _exports.default = ReportsRoute;
});