define("mldp/components/payment-history-table/row/component", ["exports", "@glimmer/component", "@glimmer/tracking", "ember-concurrency-decorators", "@ember/service"], function (_exports, _component, _tracking, _emberConcurrencyDecorators, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PaymentHistoryRowComponent = (_dec = (0, _service.inject)('general-ajax'), (_class = class PaymentHistoryRowComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajaxService", _descriptor, this);

      _initializerDefineProperty(this, "totalAmount", _descriptor2, this);
    }

    *openReceipt() {
      const ajax = this.ajaxService;
      const transactionId = this.args.fee.id;
      const {
        token
      } = yield ajax.request(`/feereceipt/${transactionId}/validate`);
      let url = '/feereceipt/' + transactionId + '?auth=' + token;
      window.open(url, '_blank');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajaxService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "totalAmount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(this.args.fee.totalAmount);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openReceipt", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "openReceipt"), _class.prototype)), _class));
  _exports.default = PaymentHistoryRowComponent;
});