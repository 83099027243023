define("mldp/account/property/unit/index/route", ["exports", "@ember/service", "@ember/object", "@ember/application", "@ember/routing/route", "rsvp", "mldp/utils/get-report-auth"], function (_exports, _service, _object, _application, _route, _rsvp, _getReportAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let IndexRoute = (_dec = (0, _service.inject)('notifications'), _dec2 = (0, _service.inject)('general-ajax'), classic(_class = (_class2 = class IndexRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "ajaxService", _descriptor2, this);
    }

    async model() {
      let unit = this.modelFor('account.property.unit');
      let {
        property: site
      } = this.modelFor('account.property');
      let fenceTypeId = String(unit.get('fenceTypeId'));
      let parentProperty, parentPropertyInfo;
      let adapter = this.store.adapterFor('property');

      try {
        parentPropertyInfo = await adapter.getForMU(unit.id);
        parentPropertyInfo = (0, _application.getOwner)(new _object.default(parentPropertyInfo));
      } catch {
        console.warn('Parent property info not found (or can\'t access)');
      }

      try {
        parentProperty = await this.store.findRecord('property', unit.get('parentSiteId'));
      } catch {
        console.warn('Parent property not found (or can\'t access)');
      }

      return _rsvp.default.hash({
        fenceType: this.store.findAll('tlkFencetype').then(all => all.findBy('id', fenceTypeId)),
        parentPropertyInfo,
        parentProperty,
        site,
        unit,
        leavingPool: false
      });
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }

    leavePoolConfirm() {
      this.controller.set('leavingPool', true);
    }

    cancelModel() {
      this.controller.set('leavingPool', false);
    }

    leavePool(unit, site) {
      let notifications = this.notifications;
      return unit.leavePool().then(() => {
        this.controller.set('leavingPool', false);
        return this.transitionTo('account.property', site.siteType, site.id);
      }).then(() => notifications.add('Successfully left the pool')).catch(err => {
        this.controller.set('leavingPool', false);
        throw notifications.addApiErrors(err);
      });
    }

    async getReport(muid) {
      let report = {
        name: 'Harvest Habitat',
        url: '/report/harvesthabitat'
      };
      let ajax = this.ajaxService;
      let auth = await (0, _getReportAuth.default)(report, ajax);
      window.open(`/report/harvesthabitat?muid=${muid}&auth=${auth}`);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "ajaxService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "leavePoolConfirm", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "leavePoolConfirm"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancelModel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "cancelModel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "leavePool", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "leavePool"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getReport", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "getReport"), _class2.prototype)), _class2)) || _class);
  _exports.default = IndexRoute;
});