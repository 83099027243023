define("mldp/components/site/document-edit/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "R6beR/RD",
    "block": "[[[11,0],[17,1],[4,[38,0],[[28,[37,1],[[30,0,[\"getDocTypes\"]]],null]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"row-fluid\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-xs-12\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"upload-doc-type\"],[12],[1,\"Type\"],[13],[1,\"\\n        \"],[8,[39,2],null,[[\"@triggerId\",\"@options\",\"@selected\",\"@triggerClass\",\"@dropdownClass\",\"@onChange\"],[\"upload-doc-type\",[30,0,[\"docTypes\"]],[30,2],\"form-control\",\"biologist-select-dropdown\",[28,[37,3],[[30,0],[30,3]],null]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,4,[\"displayValue\"]]],[1,\"\\n        \"]],[4]]]]],[1,\"\\n        \"],[10,\"small\"],[14,0,\"text-muted\"],[12],[1,\"\\n          Document type must be set and a file must be selected before you can continue.\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"row-fluid\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-xs-12\"],[12],[1,\"\\n      \"],[1,[30,5,[\"fileName\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@selectedDocType\",\"@updateDocType\",\"docType\",\"@document\"],false,[\"did-insert\",\"perform\",\"power-select\",\"action\"]]",
    "moduleName": "mldp/components/site/document-edit/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});