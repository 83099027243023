define("mldp/models/tlk-deer-season", ["exports", "@ember/object", "@ember-data/model", "moment"], function (_exports, _object, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let TlkDeerSeason = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.attr)('date'), _dec4 = (0, _model.attr)('date'), _dec5 = (0, _model.attr)('date'), _dec6 = (0, _model.attr)('date'), _dec7 = (0, _model.attr)('date'), _dec8 = (0, _model.attr)('date'), _dec9 = (0, _model.attr)('date'), _dec10 = (0, _model.attr)('date'), _dec11 = (0, _model.attr)('date'), _dec12 = (0, _model.attr)('date'), _dec13 = (0, _model.attr)('date'), _dec14 = (0, _object.computed)('id'), _dec15 = (0, _object.computed)('coEnrollmentStartDate', 'hoEnrollmentStartDate'), _dec16 = (0, _object.computed)('coEnrollmentEndDate', 'hoEnrollmentEndDate'), classic(_class = (_class2 = class TlkDeerSeason extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "displayValue", _descriptor, this);

      _initializerDefineProperty(this, "sortOrder", _descriptor2, this);

      _initializerDefineProperty(this, "harvestReportingStartDate", _descriptor3, this);

      _initializerDefineProperty(this, "harvestReportingEndDate", _descriptor4, this);

      _initializerDefineProperty(this, "habitatReportingStartDate", _descriptor5, this);

      _initializerDefineProperty(this, "habitatReportingDefaultDate", _descriptor6, this);

      _initializerDefineProperty(this, "habitatReportingEndDate", _descriptor7, this);

      _initializerDefineProperty(this, "coEnrollmentStartDate", _descriptor8, this);

      _initializerDefineProperty(this, "coEnrollmentEndDate", _descriptor9, this);

      _initializerDefineProperty(this, "coEnrollmentWithdrawalDate", _descriptor10, this);

      _initializerDefineProperty(this, "hoEnrollmentStartDate", _descriptor11, this);

      _initializerDefineProperty(this, "hoEnrollmentEndDate", _descriptor12, this);

      _initializerDefineProperty(this, "hoEnrollmentWithdrawalDate", _descriptor13, this);
    }

    get identifier() {
      let id = this.id;
      return Number(id);
    }

    get enrollmentStartDate() {
      let hoStartDate = this.hoEnrollmentStartDate;
      let coStartDate = this.coEnrollmentStartDate;

      if ((0, _moment.default)(hoStartDate).isBefore(coStartDate)) {
        return hoStartDate;
      }

      return coStartDate;
    }

    get enrollmentEndDate() {
      let hoEndDate = this.hoEnrollmentEndDate;
      let coEndDate = this.coEnrollmentEndDate;

      if ((0, _moment.default)(hoEndDate).isBefore(coEndDate)) {
        return coEndDate;
      }

      return hoEndDate;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "displayValue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "sortOrder", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "harvestReportingStartDate", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "harvestReportingEndDate", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "habitatReportingStartDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "habitatReportingDefaultDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "habitatReportingEndDate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "coEnrollmentStartDate", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "coEnrollmentEndDate", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "coEnrollmentWithdrawalDate", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "hoEnrollmentStartDate", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "hoEnrollmentEndDate", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "hoEnrollmentWithdrawalDate", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "identifier", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "identifier"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "enrollmentStartDate", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "enrollmentStartDate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "enrollmentEndDate", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "enrollmentEndDate"), _class2.prototype)), _class2)) || _class);
  _exports.default = TlkDeerSeason;
});