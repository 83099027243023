define("mldp/helpers/format-date-time", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDateTime = formatDateTime;

  /* global moment */
  function formatDateTime(_ref, _ref2) {
    let [value] = _ref;
    let {
      noTime,
      timezone,
      allowEmpty
    } = _ref2;

    if (allowEmpty && moment(value).format() === 'Invalid date') {
      return false;
    }

    let res;
    let format = noTime ? 'L' : 'L LT';

    if (timezone) {
      res = moment.utc(value).tz('America/Chicago');
      return res.format(format);
    } else {
      res = moment.utc(value);
      return res.format(format);
    }
  }

  var _default = (0, _helper.helper)(formatDateTime);

  _exports.default = _default;
});