define("mldp/account/property/enrollments/create/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iKp+07+A",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"Create Enrollment - \",[30,0,[\"site\",\"siteName\"]]],null]],null]],[1,\"\\n\\n\"],[46,[28,[37,1],[\"enrollment/enroll-\",[30,0,[\"site\",\"siteType\"]]],null],null,[[\"enrollment\",\"site\",\"eligibility\",\"units\",\"types\",\"genders\",\"species\",\"muleSeasonOpen\",\"whiteTailedSeasonOpen\",\"deactivateUnit\",\"onSubmit\",\"confirmEnrollment\",\"payEnrollmentNow\",\"payEnrollmentLater\",\"updateFeeInvoices\"],[[30,0,[\"enrollment\"]],[30,0,[\"site\"]],[30,0,[\"eligibility\"]],[30,0,[\"availableUnits\"]],[30,0,[\"types\"]],[30,0,[\"genders\"]],[30,0,[\"species\"]],[30,0,[\"site\",\"primaryCounty\",\"muleDeerSeasonOpen\"]],[30,0,[\"site\",\"primaryCounty\",\"wtDeerSeasonOpen\"]],[28,[37,3],[\"openDeactivateConfirmation\"],null],[28,[37,3],[\"create\"],null],[28,[37,3],[\"confirmEnrollment\"],null],[28,[37,3],[\"payEnrollmentNow\"],null],[28,[37,3],[\"payEnrollmentLater\"],null],[28,[37,3],[\"updateFeeInvoices\"],null]]],null]],[],false,[\"page-banner\",\"concat\",\"component\",\"route-action\"]]",
    "moduleName": "mldp/account/property/enrollments/create/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});