define("mldp/components/modal-window/component", ["exports", "@ember/object", "@ember-decorators/component", "jquery", "@ember/component"], function (_exports, _object, _component, _jquery, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ModalWindow = (_dec = (0, _component.classNames)('modal-window'), classic(_class = _dec(_class = (_class2 = class ModalWindow extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "closeOnOverlayClick", true);
    }

    close() {}

    keyPress(event) {
      if (event.key === 'Enter') {
        event.stopPropagation();

        if (this.allowEnterSubmit) {
          (0, _jquery.default)('.modal_submit_button').click();
        }

        return false;
      }
    }

    didInsertElement() {
      let element = this.element;
      let withScrollbar = this.withScrollbar;
      this.set('withScrollbar', withScrollbar || element.scrollHeight > element.clientHeight);
      (0, _jquery.default)('body').addClass('no-scroll');
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);
      (0, _jquery.default)('body').removeClass('no-scroll');
    }

    overlayClick() {
      var allow = this.closeOnOverlayClick;

      if (allow && this.close) {
        this.close(true);
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "overlayClick", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "overlayClick"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = ModalWindow;
});