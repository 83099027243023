define("mldp/components/practice-list/component", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object"], function (_exports, _component, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PracticeList = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = (_class2 = class PracticeList extends _component2.default {
    init() {
      super.init(...arguments);
      let practices = this.practices;
      let practiceSeasons = practices.reduce((all, practice) => {
        let seasonName = practice.get('deerSeason.displayValue');
        let found = all.findBy('seasonName', seasonName);

        if (found) {
          found.practices.push(practice);
        } else {
          all.push({
            seasonName,
            expanded: false,
            practices: [practice]
          });
        }

        return all;
      }, []);
      this.practiceSeasons = practiceSeasons;
    }

    expandPracticeSeason(practiceSeason) {
      (0, _object.set)(practiceSeason, 'expanded', !practiceSeason.expanded);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "expandPracticeSeason", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "expandPracticeSeason"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = PracticeList;
});