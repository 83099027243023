define("mldp/components/site-property-card/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SitePropertyCard = (_dec = (0, _component.tagName)(''), _dec(_class = classic(_class = class SitePropertyCard extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "canViewProperty", true);
    }

    didReceiveAttrs() {
      let site = this.site;
      let users, owner;

      if (!site) {
        this.set('canViewProperty', false);
        this.set('siteDetails', this.siteInfo);
        users = this.siteInfo.associatedUsers;
        owner = users.findBy('type', 'Owner');
        this.set('owner', owner);
        site = this.siteInfo;
      } else {
        this.set('siteDetails', this.site);
        users = site.get('associatedUsers');
        owner = users.findBy('type', 'Owner');
        this.set('owner', owner);
      }
    }

  }) || _class) || _class);
  _exports.default = SitePropertyCard;
});