define("mldp/account/property/enrollment/route", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let EnrollmentRoute = classic(_class = (_class2 = class EnrollmentRoute extends _route.default {
    async model(_ref) {
      let {
        enrollmentId
      } = _ref;
      let adapter = this.store.adapterFor('enrollment');
      let {
        archivedEnrollment
      } = this.paramsFor('account.property.enrollments.index');
      let enrollment;

      if (archivedEnrollment) {
        enrollment = await adapter.getArchived(enrollmentId);
      } else {
        enrollment = await this.store.findRecord('enrollment', enrollmentId);
      }

      return enrollment;
    }

    refreshModel() {
      this.refresh();
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "refreshModel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "refreshModel"), _class2.prototype)), _class2)) || _class;

  _exports.default = EnrollmentRoute;
});