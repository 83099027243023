define("mldp/account/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "rsvp", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _object, _service, _route, _rsvp, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;

  const defaultSort = list => list.sortBy('sortOrder');

  const displayValueSort = list => list.sortBy('displayValue');

  let AccountRoute = (_dec = (0, _service.inject)('state'), classic(_class = (_class2 = class AccountRoute extends _route.default.extend(_authenticatedRouteMixin.default) {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);

      _defineProperty(this, "breadCrumb", null);

      _defineProperty(this, "authenticationRoute", 'login');
    }

    model() {
      let isAuthenticated = this.get('session.isAuthenticated');

      if (!isAuthenticated) {
        return;
      }

      let isSupervisor = this.get('session.isSupervisor');
      let userAdapter = this.store.adapterFor('user');
      let seasonAdapter = this.store.adapterFor('tlk-deer-season');
      let hash = {
        roles: this.store.findAll('tlk-role').then(displayValueSort),
        counties: this.store.findAll('tlk-county').then(displayValueSort),
        species: this.store.findAll('tlk-specie').then(displayValueSort),
        enrollmentTypes: this.store.findAll('tlk-enrollment-type').then(displayValueSort),
        habitatProjectTypes: this.store.findAll('tlk-habitat-project-type').then(displayValueSort),
        habitatPracticeTypes: this.store.findAll('tlkHabitatPracticeType').then(displayValueSort),
        documentTypes: this.store.findAll('tlk-document-type').then(displayValueSort),
        consultationTypes: this.store.findAll('tlk-consultation-type').then(defaultSort),
        //TODO: Think about switching these, when we have time
        seasons: this.store.findAll('tlk-deer-season').then(defaultSort),
        applicationStatus: this.store.findAll('tlk-application-status').then(defaultSort),
        permitSeasons: this.store.findAll('tlk-season').then(defaultSort),
        currentSeason: seasonAdapter.findCurrent(),
        biologists: userAdapter.findBiologists(),
        // tpwdUsers: userAdapter.findTPWDUsers(),
        surveyTypes: this.store.findAll('tlk-survey-type').then(defaultSort),
        actionTypes: this.store.findAll('tlk-alert-type').then(defaultSort)
      };

      if (isSupervisor) {
        let userId = this.session.userId;
        hash.subordinates = userAdapter.getSubordinates(userId);
      }

      return _rsvp.default.hash(hash);
    } // set state on the state service


    afterModel(resolved) {
      let state = this.state;
      let reportingWizard = state.state;

      if (!reportingWizard) {
        reportingWizard = {};
        state.set('reportingWizard', reportingWizard);
      }

      state.setProperties(resolved);
    } // Pass through AuthenticatedRouteMixin#beforeModel if `account.action` route, otherwise
    // check the user via ESA. The account.action route does it's own checking.


    beforeModel(_ref) {
      let {
        targetName
      } = _ref;

      // Allow temporary access to action, where we decide if we go further.
      if (targetName !== 'account.action') {
        return super.beforeModel(...arguments);
      }
    }

    showUnitGuidance() {
      this.controller.set('showManagementUnitGuidance', true);
    }

    matchBiologist(item, term) {
      let {
        firstName,
        lastName,
        email
      } = item;
      let value = `${firstName} ${lastName} ${email}`;
      value = value.toLowerCase();
      return value.indexOf(term.toLowerCase());
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "showUnitGuidance", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "showUnitGuidance"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "matchBiologist", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "matchBiologist"), _class2.prototype)), _class2)) || _class);
  _exports.default = AccountRoute;
});