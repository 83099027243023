define("mldp/templates/four-oh-four", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XiQu0KYW",
    "block": "[[[1,[28,[35,0],[\"Not Found\"],null]],[1,\"\\n\"],[8,[39,1],null,[[\"@title\"],[\"Not Found\"]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/content/home-circle-img3.png\"],[14,0,\"four-oh-four\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"/\"],[1,[30,0,[\"model\",\"path\"]]],[1,\" not found\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"page-title\",\"page-banner\"]]",
    "moduleName": "mldp/templates/four-oh-four.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});