define("mldp/components/project/new-comment-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pcBDXCPz",
    "block": "[[[1,\"\\n\"],[6,[39,0],[[30,0,[\"data\"]]],null,[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n    \"],[1,[28,[30,1,[\"text-field\"]],[\"comment\"],[[\"label\"],[\"Comment\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]]],[\"f\"],false,[\"form-for\"]]",
    "moduleName": "mldp/components/project/new-comment-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});