define("mldp/components/recommendations/preview-column/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "H8tSyKsl",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-xs-1\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"text-center\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@side\",\"@text\"],[\"top\",[30,0,[\"enrollmentStatusToolTip\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"enrollment\",\"recommendationsMissing\"]]],[1,\" / \"],[1,[30,0,[\"enrollment\",\"recommendationsPending\"]]],[1,\" / \"],[1,[30,0,[\"enrollment\",\"recommendationsCompleted\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"fetchRecommendations\",\"isRunning\"]],[[[1,\"        \"],[10,0],[14,0,\"card-body\"],[12],[1,\"\\n          Loading Recommendations..\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"card-body\"],[12],[1,\"\\n          \"],[8,[39,0],null,[[\"@side\",\"@text\"],[\"bottom\",[30,0,[\"recommendationTotalToolTip\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,0,[\"grandTotal\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"info-tooltip\",\"if\"]]",
    "moduleName": "mldp/components/recommendations/preview-column/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});