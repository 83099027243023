define("mldp/account/users/merge/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tJ+Y4k1M",
    "block": "[[[1,[28,[35,0],null,[[\"from\",\"to\",\"fromOriginal\",\"toOriginal\",\"onMerge\"],[[30,0,[\"fromUserData\"]],[30,0,[\"intoUserData\"]],[30,0,[\"fromUser\"]],[30,0,[\"intoUser\"]],[28,[37,1],[\"merge\"],null]]]]]],[],false,[\"merge-users\",\"route-action\"]]",
    "moduleName": "mldp/account/users/merge/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});