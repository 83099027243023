define("mldp/components/merge-relationship-select/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lVc7REn6",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,\"input\"],[15,3,[30,0,[\"field\",\"attribute\"]]],[15,2,[28,[37,0],[[30,0,[\"data\"]],[30,0,[\"field\",\"attribute\"]]],null]],[15,\"onchange\",[28,[37,1],[[30,0],[30,0,[\"onSelect\"]],[30,0,[\"field\",\"attribute\"]],[28,[37,0],[[30,0,[\"data\"]],[30,0,[\"field\",\"attribute\"]]],null]],null]],[15,\"checked\",[30,0,[\"preselected\"]]],[14,4,\"radio\"],[12],[13],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[28,[37,0],[[30,0,[\"data\"]],[30,0,[\"field\",\"attribute\"]]],null]],null]],null],null,[[[1,[52,[30,3],\",\"]],[1,\" \"],[1,[30,2,[\"displayValue\"]]]],[2,3]],null],[13]],[\"&attrs\",\"model\",\"index\"],false,[\"get\",\"action\",\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "mldp/components/merge-relationship-select/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});