define("mldp/account/reports/available-vs-issued/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dll3JvDZ",
    "block": "[[[1,[28,[35,0],[\"Available Vs Issued\"],null]],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"report\",\"query\",\"getReport\",\"filterComponent\"],[[30,0,[\"report\"]],[30,0,[\"query\"]],[28,[37,2],[[30,0],\"getReport\"],null],[50,\"reports/permit-tally-filters\",0,null,[[\"onChange\"],[[28,[37,2],[[30,0],\"updateQuery\"],null]]]]]]]]],[],false,[\"page-banner\",\"report-container\",\"action\",\"component\"]]",
    "moduleName": "mldp/account/reports/available-vs-issued/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});