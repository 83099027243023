define("mldp/models/enrollment", ["exports", "@ember-data/model", "@ember/service", "@ember/object/computed", "@ember/object", "mldp/utils/instance-op", "ember-cp-validations"], function (_exports, _model, _service, _computed, _object, _instanceOp, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    enrollmentGenderId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Tag type is required',
      disabled: (0, _object.computed)('model.enrollmentTypeId', function () {
        let model = this.model;
        let enrollmentTypeId = String(model.get('enrollmentTypeId'));
        let harvestId = model.get('harvestId');
        let conservationId = model.get('conservationId');
        let isCoop = model.get('isCoop');
        let coopAndConservation = isCoop && enrollmentTypeId === conservationId;

        if (coopAndConservation || enrollmentTypeId === harvestId) {
          return false;
        }

        return true;
      }).volatile()
    }),
    speciesId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Species is required',
      disabled: (0, _object.computed)('model.enrollmentTypeId', function () {
        let model = this.model;
        let enrollmentTypeId = model.get('enrollmentTypeId');
        let conservationId = model.get('conservationId');

        if (String(enrollmentTypeId) === conservationId) {
          return false;
        }

        return true;
      }).volatile()
    }),
    mUs: (0, _emberCpValidations.validator)('length', {
      min: 1,
      message: 'At least one unit must be selected for the enrollment to be valid'
    })
  });

  var _default = _model.default.extend(Validations, {
    roleService: (0, _service.inject)('role-service'),
    canService: (0, _service.inject)('can'),
    primarySiteId: (0, _model.attr)('number'),
    enrollmentTypeId: (0, _model.attr)('number'),
    enrollmentGenderId: (0, _model.attr)('number'),
    speciesId: (0, _model.attr)('number'),
    enrollmentStartDate: (0, _model.attr)('date'),
    enrollmentEndDate: (0, _model.attr)('date'),
    biologistApproved: (0, _model.attr)(),
    // boolean, but can also be null
    biologistApprovedDate: (0, _model.attr)('date'),
    feeInvoices: (0, _model.attr)(),
    mUs: (0, _model.attr)(),
    primarySite: (0, _model.attr)(),
    species: (0, _model.attr)(),
    enrollmentType: (0, _model.attr)(),
    enrollmentGender: (0, _model.attr)(),
    deerSeason: (0, _model.attr)(),
    siteXEnrollments: (0, _model.attr)(),
    recommendationsMissing: (0, _model.attr)('number'),
    recommendationsPending: (0, _model.attr)('number'),
    recommendationsCompleted: (0, _model.attr)('number'),
    needsApproval: (0, _computed.not)('isHarvest'),
    paymentStatus: (0, _model.attr)('string'),
    feesTotal: (0, _model.attr)('number'),
    lastPaidDate: (0, _model.attr)(),
    archived: (0, _model.attr)('boolean'),
    refundStatus: (0, _model.attr)('string'),
    lastRefundDate: (0, _model.attr)('date'),
    // Custom operations: e.g. model.approve() => post /api/enrollments/{id}/approve
    approve: (0, _instanceOp.default)({
      path: 'approve',
      type: 'post'
    }),
    reject: (0, _instanceOp.default)({
      path: 'reject',
      type: 'post'
    }),
    cancel: (0, _instanceOp.default)({
      path: 'cancel',
      type: 'post'
    }),
    fetchReporting: (0, _instanceOp.default)({
      path: 'reporting',
      type: 'get'
    }),
    findAllRecommendations: (0, _instanceOp.default)({
      path: 'recommendations',
      type: 'get',
      pushPayload: true // return models

    }),
    accessibleSites: (0, _object.computed)('siteXEnrollments', 'primarySite', {
      get() {
        let primarySite = this.primarySite;

        if (!primarySite) {
          return false;
        }

        let canAccessCoops = this.canService.can('access coops in enrollments', _object.default.create(primarySite));

        if (canAccessCoops) {
          return false;
        }

        let sites = this.siteXEnrollments;

        if (!sites || (0, _object.get)(sites, 'length') === 0) {
          return;
        }

        let roleService = this.roleService;
        let filtered = sites.filter(item => {
          let associated = roleService.associated(item.site, {
            usersKey: 'associatedUsers',
            byId: true,
            filterKey: 'userId'
          });
          return associated && (0, _object.get)(associated, 'length') !== 0;
        });
        return filtered.mapBy('site').uniqBy('id');
      }

    }),
    status: (0, _object.computed)('biologistApproved', 'needsApproval', {
      get() {
        let approved = this.biologistApproved;
        let needsApproval = this.needsApproval;

        if (approved === null) {
          return needsApproval ? 'Pending' : '';
        }

        return approved ? 'Approved' : 'Rejected';
      }

    }),
    isHarvest: (0, _object.computed)('enrollmentTypeId', function () {
      let enrollmentTypeId = this.enrollmentTypeId;
      let harvestId = this.harvestId;
      return String(enrollmentTypeId) === harvestId;
    }),
    isConservation: (0, _object.computed)('enrollmentTypeId', function () {
      let enrollmentTypeId = this.enrollmentTypeId;
      let conservationId = this.conservationId;
      return String(enrollmentTypeId) === conservationId;
    }),
    harvestId: '1',
    conservationId: '2',
    whiteTailedId: '1',
    muleId: '2',
    bothId: '3',

    isEligible(eligibility, typeId) {
      typeId = typeId || this.enrollmentTypeId;

      if (!eligibility || !typeId) {
        return false;
      }

      switch (parseInt(typeId)) {
        case parseInt(this.conservationId):
          {
            return eligibility.isSiteCoEligible;
          }

        case parseInt(this.harvestId):
          {
            return eligibility.isSiteHoEligible;
          }

        default:
          {
            return false;
          }
      }
    }

  });

  _exports.default = _default;
});