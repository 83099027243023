define("mldp/components/site-aggregate-edit/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/component", "rsvp"], function (_exports, _component, _object, _service, _component2, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SiteAggregateEdit = (_dec = (0, _component.tagName)(""), _dec2 = (0, _service.inject)('role-service'), _dec3 = (0, _service.inject)('can'), _dec(_class = classic(_class = (_class2 = class SiteAggregateEdit extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "roleService", _descriptor2, this);

      _initializerDefineProperty(this, "canService", _descriptor3, this);
    }

    init() {
      super.init(...arguments);
      let createMode = this.get('model.isCreateMode');
      let submitLabel = createMode ? 'Next Step' : 'Update Aggregate';
      let submitPending = createMode ? 'Loading..' : 'Updating..';
      let isTpwd = this.roleService.isUserInTpwd();
      this.setProperties({
        submitLabel,
        submitPending,
        isTpwd
      });
    }

    continue(model) {
      if (this.canService.can('create aggregate without agreement in properties') || !this.get('model.isCreateMode')) {
        return this.saveAggregate(model);
      } else {
        this.set('showAgreement', true);
        return _rsvp.default.resolve();
      }
    }

    save(model) {
      return this.saveAggregate(model);
    }

    saveAggregate(model) {
      let promise = this.onSave(model);
      return promise.then(() => {
        let type = model.get('siteType');
        let id = model.id;
        let isTpwd = this.isTpwd;

        if (this.get('model.isCreateMode') && isTpwd) {
          return this.router.transitionTo('account.property.invite.manager', type, id);
        }

        return this.router.transitionTo('account.property', type, id);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "roleService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "canService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "continue", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "continue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = SiteAggregateEdit;
});