define("mldp/account/permits/create/controller", ["exports", "@ember/object", "@ember/service", "@ember/controller"], function (_exports, _object, _service, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  const params = ['siteName', 'county', 'landFname', 'landLname'];
  let CreateController = (_dec = (0, _service.inject)('notifications'), classic(_class = (_class2 = class CreateController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _defineProperty(this, "queryParams", ['page', 'siteName', 'county', 'landFname', 'landLname', 'primarySiteId', 'primarySiteType']);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "showSelectSiteModal", true);
    }

    async selectSite(site) {
      this.set('loading', true);
      let application = this.application;
      let currentSeason = this.currentSeason;
      const adapter = this.store.adapterFor(site.siteType);
      const units = await adapter.getManagementUnits(site.id);
      const eligibility = await application.eligibility({
        params: {
          siteId: site.id
        }
      });
      let selectedUnits = [];
      units.forEach(unit => {
        unit.set('eligibility', eligibility.children.find(muEligible => muEligible.siteId === unit.identifier));
        if (eligibility.isEligible && unit.eligibility && unit.eligibility.isEligible) selectedUnits.push(unit);
      });
      application.setProperties({
        site,
        units,
        primarySiteId: site.id,
        seasonId: currentSeason.id,
        applicationStatusId: 1,
        selectedUnits,
        selectedSite: site.id,
        siteEligibility: eligibility
      });
      this.set('loading', false);
    }

    addUnit(unit) {
      let {
        selectedUnits
      } = this.application;

      if (!selectedUnits.includes(unit)) {
        selectedUnits.pushObject(unit);
      }
    }

    removeUnit(unit) {
      let {
        selectedUnits
      } = this.application;

      if (selectedUnits.includes(unit)) {
        selectedUnits.removeObject(unit);
      }
    }

    addAllEligible() {
      let {
        units
      } = this.application;
      units.forEach(unit => {
        var _unit$eligibility;

        if ((_unit$eligibility = unit.eligibility) !== null && _unit$eligibility !== void 0 && _unit$eligibility.isEligible) this.send('addUnit', unit);
      });
    }

    updatePage(page) {
      this.set('page', page);
    }

    applyFilters() {
      const data = Object.assign({
        page: 1
      }, this.searchFields);
      this.setProperties(data);
    }

    clearFilters() {
      params.forEach(param => {
        this.set(param, null);
      });
    }

    async saveAcreage(application) {
      const notifications = this.notifications;
      await application.validate();

      if (application.get('validations.isValid')) {
        if (this.type === 'ph') {
          this.set('showLeaseListModal', true);
        } else {
          this.set('showScaryLanguageModal', true);
        }

        this.set('showAddAcreageModal', false);
      } else {
        let errors = application.get('validations.errors');
        errors.forEach(err => {
          notifications.error(err.message);
        });
      }
    }

    async submitApplication(application) {
      application.set('mUs', application.selectedUnits.map(unit => unit.id));
      const notifications = this.notifications;

      try {
        await application.save();
        await this.transitionToRoute('account.permits', this.type, {
          queryParams: {
            permitCreated: this.type
          }
        });
      } catch (err) {
        notifications.addApiErrors(err);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "selectSite", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "selectSite"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addUnit", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "addUnit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeUnit", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "removeUnit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addAllEligible", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "addAllEligible"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updatePage", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updatePage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "applyFilters", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "applyFilters"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clearFilters", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "clearFilters"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveAcreage", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "saveAcreage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "submitApplication", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "submitApplication"), _class2.prototype)), _class2)) || _class);
  _exports.default = CreateController;
});