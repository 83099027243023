define("mldp/account/outreach/index/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "R4IXbHaC",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"Viewing Outreach: \",[30,0,[\"outreach\",\"eventName\"]]],null]],null]],[1,\"\\n\\n\"],[1,[28,[35,2],null,[[\"otherStaff\",\"selectedContact\",\"outreach\"],[[30,0,[\"outreach\",\"selectedUsers\"]],[30,0,[\"outreach\",\"selectedContact\"]],[30,0,[\"outreach\"]]]]]],[1,\"\\n\"]],[],false,[\"page-banner\",\"concat\",\"outreach-view\"]]",
    "moduleName": "mldp/account/outreach/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});