define("mldp/account/permit/controller", ["exports", "@ember/object", "@ember/service", "@ember/controller"], function (_exports, _object, _service, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PermitController = (_dec = (0, _service.inject)('notifications'), classic(_class = (_class2 = class PermitController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);
    }

    async saveApplication(application) {
      const {
        notifications
      } = this;
      await application.validate();

      if (application.get('validations.isValid')) {
        try {
          await application.save();
          notifications.add('Crop Acreage Saved');
          this.set('editAcreage', false);
        } catch (err) {
          notifications.addApiErrors(err);
        }
      } else {
        const errors = application.get('validations.errors');
        errors.forEach(err => notifications.error(err.message));
      }
    }

    async cancleEdit(application) {
      return application.rollbackAttributes();
    }

    async onDelete(application) {
      const {
        notifications
      } = this;

      try {
        await application.destroyRecord();
        this.set('showDeleteModal', null);
        await this.transitionToRoute('account.permits', 'amd');
        return notifications.add('Permit Application Deleted');
      } catch (err) {
        notifications.error(err.message);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "saveApplication", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "saveApplication"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancleEdit", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "cancleEdit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onDelete", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onDelete"), _class2.prototype)), _class2)) || _class);
  _exports.default = PermitController;
});