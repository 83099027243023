define("mldp/components/form-fields/select-field/component", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object"], function (_exports, _component, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  // import layout from '../../templates/components/form-fields/select-field';
  let SelectFieldComponent = (_dec = (0, _component.tagName)(''), classic(_class = _dec(_class = class SelectFieldComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "control", 'one-way-select');
    }

    update(object, propertyName, value) {
      (0, _object.set)(object, propertyName, value);
    }

  }) || _class) || _class);

  window.__CLASSIC_OWN_CLASSES__.set(SelectFieldComponent, true);

  SelectFieldComponent.reopenClass({
    positionalParams: ['propertyName', 'options']
  });
  var _default = SelectFieldComponent;
  _exports.default = _default;
});