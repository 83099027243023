define("mldp/utils/permit-type-dictionary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = permitTypeDictionary;

  function permitTypeDictionary(type) {
    //TODO: have this lookup work both ways, be able to get short value form long
    const permitDisplayValues = new Map([['amd', 'Antlerless Mule Deer'], ['ph', 'Pronghorn']]);
    return permitDisplayValues.get(type);
  }
});