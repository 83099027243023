define("mldp/adapters/tlk-season", ["exports", "mldp/adapters/application", "mldp/utils/class-op"], function (_exports, _application, _classOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelName: 'tlk-season',
    amdFindCurrentApplication: (0, _classOp.default)({
      path: 'amdapplicationcurrent',
      single: true
    }),
    amdFindCurrentReporting: (0, _classOp.default)({
      path: 'amdreportingcurrent',
      single: true
    }),
    phFindCurrentApplication: (0, _classOp.default)({
      path: 'phapplicationcurrent',
      single: true
    }),
    phFindCurrentReporting: (0, _classOp.default)({
      path: 'phreportingcurrent',
      single: true
    })
  });

  _exports.default = _default;
});