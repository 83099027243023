define("mldp/account/payment/route", ["exports", "@ember/routing/route", "@ember/service", "rsvp"], function (_exports, _route, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountPaymentRoute = (_dec = (0, _service.inject)('can'), (_class = class AccountPaymentRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "canService", _descriptor, this);
    }

    beforeModel(transition) {
      if (this.canService.cannot('access payment')) {
        transition.abort();
        return this.transitionTo('account');
      }
    }

    async model(params) {
      let invoicesNotInCart = await this.store.query('fee-invoice', {
        'filter[paymentStatus]': 'Pending'
      });
      let adapter = this.store.adapterFor('feecart');
      let cart = await adapter.fetchCart();
      let invoicesInCart = cart.feeInvoices.toArray();
      let paymentStatus;

      if (params.token && params.outcome) {
        const adapter = this.store.adapterFor('feecart');

        try {
          const {
            outcome
          } = await adapter.finishCheckout(params.token, params.outcome);
          paymentStatus = outcome;
        } catch (e) {
          // JARED TODO: hook into notification system
          params = {};
          console.log(e);
        }
      }

      let allInvoices = invoicesInCart.concat(invoicesNotInCart.toArray());
      return (0, _rsvp.hash)({
        fees: allInvoices,
        cart,
        paymentStatus
      });
    }

    afterModel(model) {
      if (!model.paymentStatus) {
        this.transitionTo({
          queryParams: {
            token: null,
            outcome: null
          }
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "canService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AccountPaymentRoute;
});