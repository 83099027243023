define("mldp/components/reporting-wizard/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/object/computed", "@ember/component", "ember-concurrency"], function (_exports, _component, _object, _service, _computed, _component2, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ReportingWizard = (_dec = (0, _component.tagName)(""), _dec2 = (0, _service.inject)('store'), _dec3 = (0, _service.inject)('router'), _dec4 = (0, _service.inject)('state'), _dec5 = (0, _computed.alias)('stateService.reportingWizard.site'), _dec6 = (0, _computed.alias)('stateService.reportingWizard.unit'), _dec7 = (0, _emberConcurrency.task)(function* (site) {
    let nextStepName = this.stepAfterSite(site);
    this.set('selectedSite', site);
    yield this.fetchProngHornApps.perform(site); // if (site.get('siteType') === 'property') {

    return yield this.fetchUnits.perform(site, nextStepName);
  }), _dec8 = (0, _emberConcurrency.task)(function* (site, stepName) {
    let siteType = site.get('siteType');
    let adapter = this.storeService.adapterFor(siteType);
    let units = yield adapter.getManagementUnits(site.id);
    this.set('units', units); // Transition to unit step since we only have one unit

    if (units && units.length === 1) {
      let firstUnit = units[0];
      this.set('selectedUnit', firstUnit);
      this.set('singleUnit', true);
      stepName = 'reporting-unit';
    } else if (siteType === 'aggregate') {
      stepName = 'aggregate';
    }

    (0, _object.set)(this, 'currentStep', stepName);
  }), _dec9 = (0, _emberConcurrency.task)(function* (site) {
    const seasonAdapter = this.storeService.adapterFor('tlk-season');
    let currentSeason = yield seasonAdapter.phFindCurrentReporting();
    const options = {
      'filter[siteid]': site.id,
      'filter[seasonid]': Number(currentSeason.id)
    };
    let permitApps = yield this.storeService.query('ph-permit-app', options);
    permitApps = permitApps.toArray();
    this.set('permitApps', permitApps);
  }), _dec(_class = classic(_class = (_class2 = class ReportingWizard extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "storeService", _descriptor, this);

      _initializerDefineProperty(this, "routerService", _descriptor2, this);

      _initializerDefineProperty(this, "stateService", _descriptor3, this);

      _initializerDefineProperty(this, "selectedSite", _descriptor4, this);

      _initializerDefineProperty(this, "selectedUnit", _descriptor5, this);

      _initializerDefineProperty(this, "selectSite", _descriptor6, this);

      _initializerDefineProperty(this, "fetchUnits", _descriptor7, this);

      _initializerDefineProperty(this, "fetchProngHornApps", _descriptor8, this);
    }

    init() {
      super.init(...arguments);
      this.setupWizardFromState();
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.setProperties({
        currentStep: 'sites',
        selectedUnit: undefined,
        selectedSite: undefined
      });
    }

    setupWizardFromState() {
      const stateService = this.stateService;
      let reportingWizard = stateService.reportingWizard;

      if (!reportingWizard) {
        reportingWizard = {};
        stateService.set('reportingWizard', reportingWizard);
      }

      let currentStep = 'sites';

      if (reportingWizard.site) {
        let site = reportingWizard.site;
        currentStep = this.stepAfterSite(site);
        this.set('selectedSite', site);
      }

      if (reportingWizard.unit) {
        currentStep = 'reporting-unit';
        this.set('selectedUnit', reportingWizard.unit);
      }

      this.set('currentStep', currentStep);
    }

    stepAfterSite(site) {
      const siteType = site.get('siteType');
      let nextStepName = 'select-report-option';

      if (siteType === 'aggregate') {
        nextStepName = 'reporting-unit';
      }

      return nextStepName;
    }

    selectAnotherSite(nextStep) {
      this.setProperties({
        selectedSite: undefined,
        selectedUnit: undefined,
        singleUnit: false
      });
      (0, _object.set)(this, 'currentStep', 'sites');
    }

    selectAnotherUnit() {
      let site = this.selectedSite;
      let step = 'select-report-option';

      if (site.get('siteType') === 'aggregate') {
        step = 'reporting-aggregate';
      }

      this.set('selectedUnit', undefined);
      (0, _object.set)(this, 'currentStep', step);
    }

    showReporting(unit) {
      (0, _object.set)(this, 'selectedUnit', unit);
      (0, _object.set)(this, 'currentStep', 'reporting-unit');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "storeService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "routerService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "selectedSite", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "selectedUnit", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "selectSite", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "fetchUnits", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "fetchProngHornApps", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "selectAnotherSite", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "selectAnotherSite"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectAnotherUnit", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "selectAnotherUnit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showReporting", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "showReporting"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = ReportingWizard;
});