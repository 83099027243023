define("mldp/adapters/application", ["exports", "@ember/service", "@ember-data/adapter/json-api", "@ember/object", "jquery", "ember-simple-auth/mixins/data-adapter-mixin", "ember-simple-auth-token/mixins/token-authorizer", "qs"], function (_exports, _service, _jsonApi, _object, _jquery, _dataAdapterMixin, _tokenAuthorizer, _qs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Application = (_dec = (0, _service.inject)('ajax'), _dec2 = (0, _service.inject)('session'), _dec3 = (0, _object.computed)('session.data.authenticated.accessToken'), classic(_class = (_class2 = class Application extends _jsonApi.default.extend(_tokenAuthorizer.default, _dataAdapterMixin.default) {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajaxService", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _defineProperty(this, "namespace", 'api');
    }

    // headers: {
    //   // Hack for dotnetjsonapi not handling delete's correctly by the spec
    //   'Content-Type': 'application/vnd.api+json'
    // },
    get headers() {
      const headers = {};

      if (this.session.isAuthenticated) {
        // debugger;
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.accessToken}`; // Hack for dotnetjsonapi not handling delete's correctly by the spec

        headers['Content-Type'] = 'application/vnd.api+json';
      }

      return headers;
    } // Convert QPs to repeat stype for arrays
    // backend handles these by default


    ajaxOptions(url, type) {
      let result = super.ajaxOptions(...arguments);

      if (type === 'GET') {
        result.data = _qs.default.stringify(result.data, {
          arrayFormat: 'repeat'
        });
      }

      return result;
    } // Change the url path for the model name
    // Replace dashses with '' and keep singular


    pathForType(modelName) {
      // modelname is singular
      let normalized = modelName.replace(/-/g, '');

      if (normalized === 'tlkspecie') {
        return 'tlkspecies';
      }

      return normalized;
    }
    /**
     * Allows for adding query params to findRequest's
     *
     * Example:
     *  store.findRecord('company-invite', 1, {adapterOptions: {query: {token: "qwe2"}}});
     *
     * @param id
     * @param modelName
     * @param snapshot
     * @returns {*|String}
     */


    urlForFindRecord(id, modelName, snapshot) {
      let url = super.urlForFindRecord(id, modelName, snapshot);
      let query = (0, _object.get)(snapshot, 'adapterOptions.query');

      if (query) {
        url += '?' + _jquery.default.param(query);
      }

      return url;
    } // Prevent logout on 401 with ED requests


    ensureResponseAuthorized
    /* status ,headers, payload, requestData */
    () {// do nothing here on purpose
      // We are overriding the ember-simple-auth method that normally invalidates the session
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajaxService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "headers", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "headers"), _class2.prototype)), _class2)) || _class);
  _exports.default = Application;
});