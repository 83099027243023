define("mldp/components/merge-relationship-preview/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "IXlR47QE",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[[30,0,[\"data\"]],[30,0,[\"field\",\"attribute\"]]],null]],null]],null],null,[[[1,[52,[30,3],\",\"]],[1,\" \"],[1,[30,2,[\"displayValue\"]]]],[2,3]],null],[13]],[\"&attrs\",\"model\",\"index\"],false,[\"each\",\"-track-array\",\"get\",\"if\"]]",
    "moduleName": "mldp/components/merge-relationship-preview/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});