define("mldp/account/reports/amd-summary/route", ["exports", "@ember/routing/route", "@ember/service", "mldp/utils/get-report-auth", "mldp/account/reports/current-year"], function (_exports, _route, _service, _getReportAuth, _currentYear) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ajaxService: (0, _service.inject)('general-ajax'),
    breadCrumb: {
      title: 'Antlerless Mule Deer Summary'
    },

    async model() {
      let report = {
        name: 'Antlerless Mule Deer Summary',
        url: '/report/amdsummary',
        formats: ['html', 'pdf', 'excel']
      };
      let currentYear = (0, _currentYear.default)();
      let ajax = this.ajaxService;
      let auth = await (0, _getReportAuth.default)(report, ajax);
      return {
        report,
        query: {
          auth,
          year: currentYear,
          by: 'compartment'
        }
      };
    },

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    },

    resetController(controller) {
      controller.set('query', {});
    }

  });

  _exports.default = _default;
});