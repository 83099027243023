define("mldp/account/property/harvest/index/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0ymR1BUO",
    "block": "[[[1,[28,[35,0],[\"Harvest Information\"],null]],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"harvest\",\"site\",\"canEdit\",\"onDelete\"],[[30,0,[\"harvest\"]],[30,0,[\"site\"]],[28,[37,2],[\"edit harvest in properties\",[30,0,[\"site\"]]],null],[28,[37,3],[\"delete\"],null]]]]]],[],false,[\"page-banner\",\"harvest-details\",\"can\",\"route-action\"]]",
    "moduleName": "mldp/account/property/harvest/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});