define("mldp/need-account/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Q8qMi/n5",
    "block": "[[[1,[28,[35,0],[\"Need Account\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"login-bg\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-md-5 center-block login-form\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n            \"],[10,\"h3\"],[12],[1,\"Need Account\"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"col-md-10 col-md-offset-1 form-group\"],[12],[1,\"\\n            \"],[10,2],[14,0,\"login-desc\"],[12],[1,\"You have been invited to join a property in LMA\"],[13],[1,\"\\n            \"],[10,2],[14,0,\"login-desc\"],[12],[1,\"You will now create a new account to sign in and accept your invitation.\"],[13],[1,\"\\n            \"],[10,2],[14,0,\"login-desc\"],[12],[1,\"You must use the email address that received the invitation.\"],[13],[1,\"\\n            \"],[10,2],[14,0,\"login-desc\"],[12],[1,\"Once you have created an account you can accept the terms and conditions of LMA and begin adding properties or applying for tags.\"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"col-xs-4 col-xs-offset-4 col-md-6 col-md-offset-3\"],[12],[1,\"\\n            \"],[8,[39,1],[[24,0,\"btn btn-primary btn-block\"]],[[\"@route\"],[\"register\"]],[[\"default\"],[[[[1,\"Create An Account\"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"link-to\"]]",
    "moduleName": "mldp/need-account/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});