define("mldp/adapters/amd-recommendation", ["exports", "mldp/adapters/application", "mldp/utils/class-op"], function (_exports, _application, _classOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelName: 'amd-recommendation',
    nextGroupId: (0, _classOp.default)({
      path: 'nextgroupid',
      returnModel: false
    }),
    postCollection: (0, _classOp.default)({
      path: 'collection',
      type: 'post',
      returnModel: false
    }),
    accept: (0, _classOp.default)({
      path: '{groupId}/accept',
      type: 'post',
      returnModel: false
    }),
    decline: (0, _classOp.default)({
      path: '{groupId}/decline',
      type: 'post',
      returnModel: false
    }),
    invalidate: (0, _classOp.default)({
      path: '{groupId}/invalidate',
      type: 'post',
      returnModel: false
    })
  });

  _exports.default = _default;
});