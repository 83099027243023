define("mldp/components/notifications-zone/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7qrc6Z4z",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"notifications\",\"notifications\",\"length\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@stack\",\"@value\",\"@target\",\"@targetModifier\",\"@targetAttachment\",\"@attachment\",\"@offset\",\"@class\"],[\"notifications\",true,\"document.body\",\"visible\",\"top right\",\"top right\",\"-80px 30px\",\"notification-content\"]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"notifications\",\"notifications\"]]],null]],null],null,[[[1,\"        \"],[10,0],[15,0,[29,[\"alert alert-\",[30,2,[\"type\"]],\" alert-dismissible\"]]],[14,\"role\",\"alert\"],[12],[1,\"\\n          \"],[10,\"button\"],[14,0,\"close alert-close\"],[14,\"aria-label\",\"Close\"],[15,\"onclick\",[28,[37,4],[[30,0],\"hideNotification\",[30,2]],null]],[14,4,\"button\"],[12],[1,\"\\n            \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"Close Message\"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"],[41,[30,2,[\"title\"]],[[[1,\"            \"],[10,0],[14,0,\"alert-title\"],[12],[1,\"\\n              \"],[10,\"strong\"],[12],[1,[30,2,[\"title\"]]],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[10,1],[14,0,\"alert-message\"],[12],[1,[30,2,[\"message\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"n\"],false,[\"if\",\"liquid-tether\",\"each\",\"-track-array\",\"action\"]]",
    "moduleName": "mldp/components/notifications-zone/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});