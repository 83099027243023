define("mldp/serializers/management-unit", ["exports", "mldp/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let ManagementUnit = classic(_class = class ManagementUnit extends _application.default {
    extractRelationships(modelClass, resourceHash) {
      let relationships = super.extractRelationships(...arguments);
      relationships.harvests = {
        links: {
          self: resourceHash.links.self + '/harvests'
        }
      };
      return relationships;
    }

  }) || _class;

  _exports.default = ManagementUnit;
});