define("mldp/account/property/project/edit/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gaSzNwlz",
    "block": "[[[1,[28,[35,0],[\"Edit Habitat Project\"],null]],[1,\"\\n\"],[1,[28,[35,1],[\"Edit Habitat Project\"],null]],[1,\"\\n  \"],[8,[39,2],null,[[\"@f\",\"@model\",\"@property\",\"@editProjectArea\",\"@updateGeom\",\"@areaMapped\",\"@onSave\"],[\"f\",[30,0,[\"project\"]],[30,0,[\"property\"]],[30,0,[\"editProjectArea\"]],[28,[37,3],[[30,0,[\"updateGeom\"]]],null],[30,0,[\"areaMapped\"]],[28,[37,3],[[30,0,[\"save\"]]],null]]],null],[1,\"\\n\"],[46,[28,[37,5],null,null],null,null,null]],[],false,[\"page-title\",\"page-banner\",\"project/edit\",\"fn\",\"component\",\"-outlet\"]]",
    "moduleName": "mldp/account/property/project/edit/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});