define("mldp/account/consultation/index/route", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let IndexRoute = classic(_class = class IndexRoute extends _route.default {
    async model() {
      let hash = this.modelFor('account.consultation');
      return hash;
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }

  }) || _class;

  _exports.default = IndexRoute;
});