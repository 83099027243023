define("mldp/components/permit/select-county/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FbPjwuAT",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"loadCounties\",\"isRunning\"]],[[[1,\"    Loading counties...\\n\"]],[]],[[[1,\"    \"],[10,\"ul\"],[14,0,\"list-unstyled\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"loadCounties\",\"last\",\"value\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n          \"],[8,[39,3],[[24,0,\"btn btn-link btn-block text-left\"]],[[\"@route\",\"@models\"],[\"account.permits.amd-recommendations\",[28,[37,4],[\"amd\",[30,2,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"displayValue\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],[[[1,\"        No counties\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"county\"],false,[\"if\",\"each\",\"-track-array\",\"link-to\",\"array\"]]",
    "moduleName": "mldp/components/permit/select-county/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});