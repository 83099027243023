define("mldp/account/property/harvest/edit/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _object, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EditRoute = (_dec = (0, _service.inject)('notifications'), _dec2 = (0, _service.inject)('state'), classic(_class = (_class2 = class EditRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "state", _descriptor2, this);
    }

    model() {
      let {
        property: site
      } = this.modelFor('account.property');
      let currentSeasonId = this.get('state.currentSeason.identifier');
      return _rsvp.default.hash({
        site,
        currentSeasonId,
        harvest: this.modelFor('account.property.harvest'),
        harvests: site.findHarvests(),
        seasons: this.store.findAll('tlk-deer-season').then(list => list.sortBy('sortOrder'))
      });
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }

    save(harvest) {
      let notifications = this.notifications;
      return harvest.save().then(() => {
        let {
          type,
          siteId
        } = this.paramsFor('account.property');
        return this.transitionTo('account.property.harvests', type, siteId);
      }).then(() => notifications.add('Successfuly updated the harvest', {
        type: 'success'
      })).catch(error => notifications.addApiErrors(error));
    }

    delete(harvest) {
      let notifications = this.notifications;
      return harvest.destroyRecord().then(() => {
        let {
          type,
          siteId
        } = this.paramsFor('account.property');
        notifications.add('Successfully deleted the harvest', {
          type: 'success'
        });
        return this.transitionTo('account.property.harvests', type, siteId);
      }).catch(error => notifications.addApiErrors(error));
    }

    reset() {
      let model = this.currentModel.harvest;
      return model.rollbackAttributes();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "delete"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "reset"), _class2.prototype)), _class2)) || _class);
  _exports.default = EditRoute;
});