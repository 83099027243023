define("mldp/components/deer-population/header/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uZ66wPtu",
    "block": "[[[10,0],[14,5,\"width:100%; margin-bottom:30px\"],[12],[1,\"\\n\"],[10,0],[14,0,\"list-toolbar clearfix pull-right\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1],\"coop\"],null],[[[1,\"    \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,2]],null],[12],[1,\"\\n      Download Member Survey Data\\n    \"],[13],[1,\"\\n\"],[41,[28,[37,3],[\"edit deer population data in properties\",[30,3]],null],[[[1,\"      \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,4]],null],[12],[1,\"\\n        Set Member Survey Types\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[41,[28,[37,3],[\"edit deer population data in properties\",[30,3]],null],[[[1,\"    \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,5]],null],[12],[1,\"\\n      Set Survey Types\\n    \"],[13],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,4],[[30,0],[30,6]],null],[12],[1,\"\\n      Enter Season Summary\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,7]],null],[12],[1,\"\\n    Export to Excel\\n  \"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,8]],null],[12],[1,\"\\n    Enter Survey Data\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@siteType\",\"@openMemberReportModal\",\"@site\",\"@openMemberSurveyTypeModal\",\"@openSurveyTypeModal\",\"@openSeasonSummaryModal\",\"@downLoadExcelReport\",\"@openEnterSurveyModal\"],false,[\"if\",\"eq\",\"on\",\"can\",\"action\"]]",
    "moduleName": "mldp/components/deer-population/header/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});