define("mldp/adapters/feecart", ["exports", "mldp/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let FeecartAdapter = classic(_class = class FeecartAdapter extends _application.default {
    async fetchCart() {
      let ajax = this.ajaxService;
      let cart = await ajax.request('/feecart/pending', {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
      cart.data.type = 'fee-cart';
      let cartModel = this.store.push(cart);
      let feeInvoiceMap = cart.data.attributes.feeInvoices.map(feeInvoice => {
        return {
          id: feeInvoice.id,
          type: 'fee-invoice',
          attributes: {
            enrollmentId: feeInvoice.enrollmentId,
            primarySiteId: feeInvoice.primarySiteId,
            paymentStatusId: feeInvoice.paymentStatusId,
            paymentStatus: feeInvoice.paymentStatus,
            description: feeInvoice.description,
            totalAmount: feeInvoice.totalAmount,
            siteCounty: feeInvoice.siteCounty,
            siteName: feeInvoice.siteName,
            siteType: feeInvoice.siteType,
            unitCount: feeInvoice.unitCount
          },
          relationships: {
            feeCart: {
              data: {
                type: 'fee-cart',
                id: cart.data.id
              }
            },
            enrollment: {
              data: {
                type: 'enrollment',
                id: feeInvoice.enrollmentId
              }
            }
          }
        };
      });
      this.store.push({
        data: feeInvoiceMap
      });
      return cartModel;
    }

    async invoiceCounts() {
      let ajax = this.ajaxService;
      return await ajax.request('/feecart/invoicecounts', {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
    }

    async startCheckout(cartId) {
      let ajax = this.ajaxService;
      return await ajax.post(`/feecart/startcheckout/${cartId}`, {
        contentType: 'application/vnd.api+json',
        headers: {
          'Accept': 'application/vnd.api+json'
        }
      });
    }

    async finishCheckout(token, outcome) {
      let ajax = this.ajaxService;
      return await ajax.post(`/feecart/finishcheckout/${outcome}/${token}`);
    }

    async addInvoice(feeId) {
      let ajax = this.ajaxService;
      return ajax.post(`/feecart/addinvoice/${feeId}`);
    }

    async removeInvoice(feeId) {
      let ajax = this.ajaxService;
      return ajax.post(`/feecart/removeinvoice/${feeId}`);
    }

    async fetchFullFee(subTotal) {
      let ajax = this.ajaxService;
      return ajax.request(`feecart/fullprice?fee=${subTotal}`);
    }

  }) || _class;

  _exports.default = FeecartAdapter;
});