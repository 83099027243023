define("mldp/account/permits/amd-recommendations/route", ["exports", "@ember/service", "@ember/routing/route", "@ember/array", "rsvp", "moment"], function (_exports, _service, _route, _array, _rsvp, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let AmdRecommendationsRoute = (_dec = (0, _service.inject)('store'), classic(_class = (_class2 = class AmdRecommendationsRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    async model(_ref) {
      let {
        countyId
      } = _ref;
      const {
        counties
      } = this.modelFor('account');
      const seasonAdapter = this.store.adapterFor('tlk-season');
      const tallyAdapter = this.store.adapterFor('amd-tally');
      let currentSeason = await seasonAdapter.amdFindCurrentReporting();
      let nextSeason = await this.store.find('tlk-season', Number(currentSeason.id) + 1);
      let today = (0, _moment.default)(); //TODO: does this ever come into effect?

      if (today.isAfter(currentSeason.amdTpSeasonEndDate)) {
        currentSeason = nextSeason;
      }

      const tallys = await tallyAdapter.findAllForSeason({
        params: {
          seasonId: currentSeason.id
        }
      });
      const permits = this.store.query('amd-permit-app', {
        'filter[countyid]': countyId,
        'filter[seasonid]': currentSeason.id,
        mushapes: true
      });
      return _rsvp.default.hash({
        currentSeason,
        permits,
        county: counties.findBy('id', String(countyId)),
        countyId,
        tallys
      });
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        //TODO: This doesn't see like the best way to solve this.
        controller.set('selected', (0, _array.A)());
      }
    }

    afterModel(_ref2) {
      let {
        county
      } = _ref2;
      this.set('breadCrumb', {
        title: `Recs For ${county.get('displayValue')} County`
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = AmdRecommendationsRoute;
});