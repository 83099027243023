define("mldp/components/selectable-unit-layer/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VzGB2lcF",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"unit\"]],[30,0,[\"unit\",\"geoJSON\"]]],null],[[[41,[28,[37,2],[[30,0,[\"type\"]],\"selected\"],null],[[[1,\"      \"],[8,[30,2,[\"geojson\"]],null,[[\"@geoJSON\",\"@clickable\",\"@color\",\"@fillColor\",\"@onAdd\",\"@onMouseover\",\"@onMouseout\"],[[30,0,[\"unit\",\"geoJSON\"]],true,\"green\",\"green\",[28,[37,3],[[30,0],\"onAddCheck\"],null],[28,[37,3],[[30,0],[30,0,[\"onHover\"]],[30,0,[\"unit\"]]],null],[28,[37,3],[[30,0],[30,0,[\"onHover\"]]],null]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,0,[\"type\"]],\"default\"],null],[[[1,\"      \"],[8,[30,2,[\"geojson\"]],null,[[\"@geoJSON\",\"@clickable\",\"@color\",\"@fillColor\",\"@onAdd\",\"@onMouseover\",\"@onMouseout\"],[[30,0,[\"unit\",\"geoJSON\"]],true,\"blue\",\"blue\",[28,[37,3],[[30,0],\"onAddCheck\"],null],[28,[37,3],[[30,0],[30,0,[\"onHover\"]],[30,0,[\"unit\"]]],null],[28,[37,3],[[30,0],[30,0,[\"onHover\"]]],null]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,0,[\"type\"]],\"defaultHover\"],null],[[[1,\"      \"],[8,[30,2,[\"geojson\"]],null,[[\"@geoJSON\",\"@clickable\",\"@color\",\"@fillColor\",\"@onAdd\",\"@onMouseover\",\"@onMouseout\"],[[30,0,[\"unit\",\"geoJSON\"]],true,\"red\",\"red\",[28,[37,3],[[30,0],\"onAddCheck\"],null],[28,[37,3],[[30,0],[30,0,[\"onHover\"]],[30,0,[\"unit\"]]],null],[28,[37,3],[[30,0],[30,0,[\"onHover\"]]],null]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,0,[\"type\"]],\"selectedHover\"],null],[[[1,\"      \"],[8,[30,2,[\"geojson\"]],null,[[\"@geoJSON\",\"@clickable\",\"@color\",\"@fillColor\",\"@onAdd\",\"@onMouseover\",\"@onMouseout\"],[[30,0,[\"unit\",\"geoJSON\"]],true,\"maroon\",\"maroon\",[28,[37,3],[[30,0],\"onAddCheck\"],null],[28,[37,3],[[30,0],[30,0,[\"onHover\"]],[30,0,[\"unit\"]]],null],[28,[37,3],[[30,0],[30,0,[\"onHover\"]]],null]]],null],[1,\"\\n    \"]],[]],null]],[]]]],[]]]],[]]]],[]],null],[13]],[\"&attrs\",\"@map\"],false,[\"if\",\"and\",\"eq\",\"action\"]]",
    "moduleName": "mldp/components/selectable-unit-layer/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});