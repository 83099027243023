define("mldp/components/manage-users/component", ["exports", "@ember/object", "@ember-decorators/component", "@ember/service", "@ember/component", "mldp/utils/get-report-auth"], function (_exports, _object, _component, _service, _component2, _getReportAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ManageUsers = (_dec = (0, _component.tagName)(""), _dec2 = (0, _service.inject)('app-values'), _dec3 = (0, _service.inject)('notifications'), _dec4 = (0, _service.inject)('general-ajax'), _dec(_class = classic(_class = (_class2 = class ManageUsers extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "appValues", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "ajaxService", _descriptor3, this);
    }

    didReceiveAttrs() {
      const users = this.users;
      const owners = users.filter(user => user.type === 'Owner');
      const multipleOwners = owners.length > 1;

      if (owners.length === 1) {
        this.set('ownerAcceptanceDate', owners[0].ownerAcceptanceDate);
      }

      const defaultTypes = this.createTypes();
      let types = users.reduce((all, user) => {
        let typeObject = all.findBy('type', user.type);

        if (typeObject && !typeObject.users.findBy('userId', user.userId)) {
          typeObject.users.pushObject(user);
        }

        return all;
      }, defaultTypes);
      this.set('multipleOwners', multipleOwners);
      this.set('types', types);
    }

    async getPrintedRTA(site) {
      let siteId = site.id;
      let report = {
        name: 'RTA Agreement',
        url: '/report/rtaagreement' // formats: ['excel']

      };
      let ajax = this.ajaxService;
      let auth = await (0, _getReportAuth.default)(report, ajax);
      window.open(`/report/rtaagreement?siteid=${siteId}&auth=${auth}`);
    }

    removeUser(type, user) {
      let notifications = this.notifications;
      let values = this.appValues;
      let users = this.users;
      let isOwnerType = type.type === values.get('ownerUserType');
      let hasOneUser = type.users.length === 1;

      if (isOwnerType && hasOneUser) {
        return notifications.add('Cannot remove a single land owner');
      }

      type.users.removeObject(user);
      users.removeObject(user);
      this.onRemoveUser(user);
    }

    createTypes() {
      let values = this.appValues;
      let siteType = this.get('site.siteType');
      let isPublicLand = this.get('site.isPublicLand');
      let defaultTypes = [];

      if (siteType === 'property') {
        if (isPublicLand) {
          defaultTypes.pushObject({
            id: 'agent',
            type: values.get('agentUserType'),
            name: values.get('agentUserType'),
            users: []
          });
        } else {
          defaultTypes.pushObjects([{
            id: 'owner',
            type: values.get('ownerUserType'),
            name: values.get('ownerUserType'),
            users: []
          }, {
            id: 'agent',
            type: values.get('agentUserType'),
            name: values.get('agentUserType'),
            users: []
          }]);
        }
      } else if (siteType === 'aggregate' || siteType === 'coop') {
        defaultTypes.pushObject({
          id: 'manager',
          type: values.get('poolManagerUserType'),
          name: 'Pool Manager',
          users: []
        });
      }

      defaultTypes.pushObjects([{
        id: 'biologist',
        type: values.get('biologistUserType'),
        name: values.get('biologistUserType'),
        users: []
      }]);
      return defaultTypes;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "appValues", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "ajaxService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "getPrintedRTA", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "getPrintedRTA"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeUser", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "removeUser"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = ManageUsers;
});