define("mldp/helpers/get-enrollment-errors", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getEnrollmentErrors = getEnrollmentErrors;

  function getEnrollmentErrors(_ref, _ref2) {
    let [eligibility] = _ref;
    let {
      enrollment,
      typeId
    } = _ref2;

    if (!eligibility || !enrollment) {
      return [];
    }

    let enrollType = parseInt(typeId) || parseInt(enrollment.get('enrollmentTypeId'));

    switch (enrollType) {
      case parseInt(enrollment.get('conservationId')):
        {
          return eligibility.coSiteErrors;
        }

      case parseInt(enrollment.get('harvestId')):
        {
          return eligibility.hoSiteErrors;
        }

      default:
        {
          return [];
        }
    }
  }

  var _default = (0, _helper.helper)(getEnrollmentErrors);

  _exports.default = _default;
});