define("mldp/components/user-details-edit/component", ["exports", "@ember/component", "@ember/service", "ember-concurrency", "jquery", "@ember/object"], function (_exports, _component, _service, _emberConcurrency, _jquery, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['modal-window'],
    store: (0, _service.inject)('store'),
    notifications: (0, _service.inject)('notifications'),

    didReceiveAttrs() {
      // let userId = this.userId;
      this.set('currentUserState', _object.default.create(this.user)); // if (userId) {
      //   this.loadUserDetails.perform(userId);
      // }
    },

    didInsertElement() {
      let element = this.element;
      this.set('withScrollbar', element.scrollHeight > element.clientHeight);
      (0, _jquery.default)('body').addClass('no-scroll');
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)('body').removeClass('no-scroll');
    },

    // loadUserDetails: task(function * (id) {
    //   let store = this.store;
    //   let user = yield store.findRecord('user', id)
    //   this.set('user', user);
    // }),
    actions: {
      submit: function () {
        return this.site.editowneragent({
          data: this.user
        }).then(() => {
          this.set('editUser', false);
          return;
        }).catch(error => {
          if (error.errors.length) {
            this.notifications.error(error.errors[0].detail);
          }

          return;
        });
      },
      cancel: function () {
        // this.user.rollbackAttributes()
        // let something = this.user.changedAttributes()
        this.set('user', this.currentUserState);
        this.set('editUser', false);
      }
    }
  });

  _exports.default = _default;
});