define("mldp/account/outreach/route", ["exports", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let OutreachRoute = (_dec = (0, _service.inject)('state'), classic(_class = (_class2 = class OutreachRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stateService", _descriptor, this);
    }

    async model(_ref) {
      let {
        outreachId
      } = _ref;
      let stateService = this.stateService;
      let counties = this.get('stateService.counties');
      let outreach = await this.store.findRecord('outreach', outreachId, {
        include: 'otherStaff',
        reload: true
      });
      let userId = outreach.get('eventUserId');
      let contactId = outreach.get('eventContactId');
      let otherStaff = outreach.get('otherStaff');
      let {
        outreachEventTypes,
        outreachFundings,
        outreachGroups,
        wmas
      } = await stateService.loadOutreachTlks();
      let result = {
        outreach,
        counties,
        outreachEventTypes,
        outreachFundings,
        outreachGroups,
        wmas
      };

      if (userId) {
        result.outreach.set('selectedContact', await this.store.findRecord('user', userId));
      } else if (contactId) {
        result.outreach.set('selectedContact', await this.store.findRecord('contact', contactId));
      }

      if (otherStaff.get('length') !== 0) {
        let users = await (0, _rsvp.all)(otherStaff.map(staff => {
          return this.store.findRecord('user', staff.get('userId')).catch(e => {
            console.warn(e);
          });
        }));
        result.outreach.set('selectedUsers', users);
      }

      return (0, _rsvp.hash)(result);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = OutreachRoute;
});