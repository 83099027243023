define("mldp/components/practice/brush-mechanical/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "y7Su8dpu",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"create\"]],[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[33,1,[\"number-field\"]],[\"acres\"],[[\"required\"],[true]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[33,1,[\"select-field\"]],[\"treatmentId\"],[[\"options\",\"label\",\"optionTargetPath\",\"optionLabelPath\",\"prompt\",\"promptIsSelectable\",\"required\"],[[30,0,[\"state\",\"treatments\"]],\"Treatment (choose one)\",\"identifier\",\"displayValue\",\"Please select one:\",true,true]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"create\",\"f\"],[[30,0,[\"create\"]],[30,0,[\"f\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,3],null,[[\"model\",\"type\"],[[30,0,[\"model\"]],\"acres\"]]]],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"model\",\"type\"],[[30,0,[\"model\"]],\"treatment\"]]]],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"create\",\"model\"],[[30,0,[\"create\"]],[30,0,[\"model\"]]]]]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\"],false,[\"if\",\"f\",\"practice/brush-primary-target\",\"practice/display-field\"]]",
    "moduleName": "mldp/components/practice/brush-mechanical/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});