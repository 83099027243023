define("mldp/components/deer-population/set-survey-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Eb9YoWfE",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"selected\"]]],null]],null],null,[[[10,1],[14,0,\"px-4 py-2 rounded-full border border-gray-300 text-gray-500 font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease\"],[12],[1,\"\\n  \"],[1,[30,1,[\"displayValue\"]]],[1,\"\\n  \"],[11,\"button\"],[24,0,\"bg-transparent hover focus:outline-none\"],[24,4,\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"removeType\"]],[30,1]],null]],null],[12],[1,\"\\n    \"],[10,\"svg\"],[14,\"aria-hidden\",\"true\"],[14,\"focusable\",\"false\"],[14,\"data-prefix\",\"fas\"],[14,\"data-icon\",\"times\"],[14,0,\"w-3 ml-3\"],[14,\"role\",\"img\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 352 512\"],[12],[1,\"\\n      \"],[10,\"path\"],[14,\"fill\",\"currentColor\"],[14,\"d\",\"M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z\"],[12],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[1]],null],[8,[39,4],null,[[\"@options\",\"@placeholder\",\"@triggerClass\",\"@dropdownClass\",\"@onChange\"],[[30,0,[\"unselected\"]],\"Add Survey Types\",\"form-control\",\"biologist-select-dropdown\",[30,0,[\"addType\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,2,[\"displayValue\"]]],[1,\"\\n\"]],[2]]]]]],[\"survey\",\"survey\"],false,[\"each\",\"-track-array\",\"on\",\"fn\",\"power-select\"]]",
    "moduleName": "mldp/components/deer-population/set-survey-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});