define("mldp/account/consultation/edit/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pvs+2HLm",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"Edit Consultation\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@consultation\",\"@types\",\"@biologists\",\"@counties\",\"@selectedSite\",\"@selectedUsers\",\"@onUpdate\",\"@onReset\"],[[30,0,[\"consultation\"]],[30,0,[\"types\"]],[30,0,[\"biologists\"]],[30,0,[\"counties\"]],[30,0,[\"site\"]],[30,0,[\"consultation\",\"selectedUsers\"]],[28,[37,2],[[30,0],\"update\"],null],[28,[37,2],[[30,0],\"cancel\"],null]]],null]],[],false,[\"page-banner\",\"consultation-edit\",\"action\"]]",
    "moduleName": "mldp/account/consultation/edit/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});