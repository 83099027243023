define("mldp/components/payment-summary-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2n7mRYF4",
    "block": "[[[11,0],[24,0,\"flex flex-col\"],[4,[38,0],[[28,[37,1],[[30,0,[\"fetchFullFee\"]]],null]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex mb-6 text-2xl font-bold\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"mr-2\"],[12],[1,\"\\n      MLDP Enrollment Fee:\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex mb-6 text-2xl font-bold\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"mr-2\"],[12],[1,\"\\n      Texas.gov Price:\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n\"],[41,[30,0,[\"fetchFullFee\",\"isIdle\"]],[[[1,\"        \"],[1,[30,0,[\"fullFee\"]]],[1,\" *\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mb-6 font-bold\"],[12],[1,\"\\n    MLDP Fee payments must be submitted online using a valid credit or debit card (Visa, Mastercard, or Discover). By clicking Continue, I acknowledge that I will be charged the full amount indicated for the Texas.gov price, and that this payment is non-refundable.\\n  \"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    *Online payment transactions are processed by Texas.gov, the official website of Texas. The price of this service includes funds that support the ongoing operations and enhancements of Texas.gov, which is provided by a third party in partnership with the State.\\n  \"],[13],[1,\"\\n\"],[13]],[\"@formattedFeesSubTotal\"],false,[\"did-insert\",\"perform\",\"if\"]]",
    "moduleName": "mldp/components/payment-summary-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});