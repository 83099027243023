define("mldp/adapters/enrollment", ["exports", "mldp/adapters/application", "mldp/utils/filter-enrollment-units"], function (_exports, _application, _filterEnrollmentUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Enrollment = classic(_class = class Enrollment extends _application.default {
    async addMus(enrollment) {
      let ajax = this.ajaxService;
      return ajax.post(`/enrollment/${enrollment.id}/addmus`, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        },
        data: JSON.stringify(enrollment.mUsToAdd)
      });
    }

    payLater(enrollment) {
      let ajax = this.ajaxService;
      return ajax.request(`/enrollment/${enrollment.id}/paylater`, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
    }

    async getFeeEstimate(enrollment) {
      let ajax = this.ajaxService;
      return await ajax.request(`/enrollment/estimatefee?siteid=${enrollment.primarySiteId}&enrollmenttypeid=${enrollment.enrollmentTypeId}&unitcount=${enrollment.mUs.length}`, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
    }

    async waiveFee(enrollmentId, unitId) {
      let ajax = this.ajaxService;
      let url = `/enrollment/${enrollmentId}/waivefee`;

      if (unitId) {
        url = `/enrollment/${enrollmentId}/waivefee/${unitId}`;
      }

      return await ajax.post(url, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
    }

    async reinstateFee(enrollmentId, unitId) {
      let ajax = this.ajaxService;
      let url = `/enrollment/${enrollmentId}/reinstatefee`;

      if (unitId) {
        url = `/enrollment/${enrollmentId}/reinstatefee/${unitId}`;
      }

      return await ajax.post(url, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
    }

    async reinstateEnrollment(enrollmentId) {
      let ajax = this.ajaxService;
      let url = `/enrollment/${enrollmentId}/restore`;
      return await ajax.post(url, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
    }

    async cancelledEnrollments(enrollmentId) {
      let ajax = this.ajaxService;
      let url = `/api/enrollment/${enrollmentId}/cancelledcoopmus`;
      return await ajax.request(url, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
    }

    async restoreCoopMu(enrollmentId, unitId) {
      let ajax = this.ajaxService;
      let url = `/enrollment/${enrollmentId}/restorecoopmu/${unitId}`;
      return await ajax.post(url, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
    }

    async getArchived(enrollmentId) {
      let ajax = this.ajaxService;
      let url = `/api/enrollment/${enrollmentId}?archived=true`;
      let enrollment = await ajax.request(url, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
      enrollment.data.type = 'enrollment';
      return this.store.push({
        data: enrollment.data
      });
    }

    getManagementUnits(enrollment) {
      let archived = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      let ajax = this.ajaxService;
      let store = this.store;
      let siteType = enrollment.get('primarySite.siteType');
      let siteId = enrollment.get('primarySite.id');
      return ajax.request(`/${siteType}/${siteId}/managementUnits`, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        },
        data: {
          archived
        }
      }).then(result => {
        if (result) {
          result.data = result.data.map(datum => {
            datum.type = 'managementUnit';
            return datum;
          });
          let models = store.push(result);
          return (0, _filterEnrollmentUnits.default)(enrollment, models);
        }
      });
    }

  }) || _class;

  _exports.default = Enrollment;
});