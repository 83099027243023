define("mldp/account/consultations/create/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "fASQYG7U",
    "block": "[[[1,[28,[35,0],[\"Create Consultation\"],null]],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"consultation\",\"types\",\"biologists\",\"counties\",\"onUpdate\",\"onReset\"],[[30,0,[\"consultation\"]],[30,0,[\"types\"]],[30,0,[\"biologists\"]],[30,0,[\"counties\"]],[28,[37,2],[\"create\"],null],[28,[37,2],[\"cancel\"],null]]]]],[1,\"\\n\"]],[],false,[\"page-banner\",\"consultation-edit\",\"route-action\"]]",
    "moduleName": "mldp/account/consultations/create/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});