define("mldp/components/notices/reporting/amd-reapply/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xpuUS4V7",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"You have reported deer harvest for all management units of this site that received Antlerless Mule Deer permits this season. Would you like to reapply for next season now?\"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/notices/reporting/amd-reapply/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});