define("mldp/account/property/enrollment/index/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "rsvp", "@ember/application"], function (_exports, _object, _service, _route, _rsvp, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  const CANCELATION_CONFIRM = 'Cancellation of this enrollment will result in the invalidation of all tags issued and will withdraw this site from MLDP. Note that MLDP Fee payments are nonrefundable should you choose to withdraw from MLDP.<br><br>Click Yes to cancel your enrollment. Click No to exit this window without cancelling your enrollment.';
  let IndexRoute = (_dec = (0, _service.inject)('state'), _dec2 = (0, _service.inject)('notifications'), classic(_class = (_class2 = class IndexRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);
    }

    model() {
      let {
        type,
        siteId
      } = this.paramsFor('account.property');
      let {
        property
      } = this.modelFor('account.property');
      let enrollment = this.modelFor('account.property.enrollment');
      let adapter = this.store.adapterFor(type.toLowerCase());
      let state = this.state;
      return _rsvp.default.hash({
        enrollment,
        site: property,
        species: state.get('species'),
        currentSeason: state.get('currentSeason'),
        types: state.get('enrollmentTypes'),
        genders: this.store.findAll('tlk-enrollment-gender'),
        units: adapter.getManagementUnits(siteId)
      });
    }

    setupController(controller, resolved) {
      resolved.cancelationConfirmationText = CANCELATION_CONFIRM;
      let siteNotCoop = resolved.site.get('siteType') !== 'coop';
      let enrolledSiteIsCoop = resolved.enrollment.get('primarySite.siteType').toLowerCase() === 'coop';

      if (siteNotCoop && enrolledSiteIsCoop) {
        resolved.indirectEnrollment = true;
      } else {
        resolved.indirectEnrollment = false;
      }

      controller.setProperties(resolved);
    }

    reject(enrollment) {
      let notifications = this.notifications;
      return enrollment.reject().then(() => enrollment.reload()).then(() => notifications.add('Rejected enrollment')).catch(err => notifications.addApiErrors(err));
    }

    cancel(enrollment) {
      let notifications = this.notifications;
      let {
        type,
        siteId
      } = this.paramsFor('account.property');
      return enrollment.cancel().then(() => {
        this.controller.set('showCancelationConfirmation', false);
        return this.transitionTo('account.property.enrollments', type, siteId);
      }).then(() => {
        const applicationRouteInstance = (0, _application.getOwner)(this).lookup('route:application');
        applicationRouteInstance.fetchFeeInvoices();
        return notifications.add('Canceled enrollment');
      }).catch(err => notifications.addApiErrors(err));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "reject", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "reject"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "cancel"), _class2.prototype)), _class2)) || _class);
  _exports.default = IndexRoute;
});