define("mldp/components/consultation-view/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ConsultationView = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class ConsultationView extends _component2.default {
    didReceiveAttrs() {
      let counties = this.counties;
      let countyId = this.get('consultation.primaryCountyId');

      if (counties && countyId) {
        let county = counties.findBy('id', String(countyId));
        this.set('county', county);
      }
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.setProperties({
        site: undefined,
        county: undefined,
        consultation: undefined
      });
    }

  }) || _class) || _class);
  _exports.default = ConsultationView;
});