define("mldp/components/permit/amd-bulk-recommendation/map/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "oyaRmTPB",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[6,[39,0],null,[[\"permitType\"],[\"amd\"]],[[\"default\"],[[[[42,[28,[37,2],[[28,[37,2],[[30,4]],null]],null],null,[[[41,[30,6],[[[1,\"        \"],[1,[28,[35,4],null,[[\"map\",\"unit\",\"selectedUnits\",\"hoveredUnit\",\"onHover\",\"onAdd\"],[[30,2],[30,5,[\"site\"]],[28,[37,5],[[30,6,[\"site\"]]],null],[30,0,[\"hoveredUnit\"]],[28,[37,6],[[30,0],[28,[37,7],[[30,0,[\"hoveredUnit\"]]],null]],null],[30,3,[\"fitBounds\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,4],null,[[\"map\",\"unit\",\"selectedUnits\",\"hoveredUnit\",\"onHover\",\"onAdd\"],[[30,2],[30,5,[\"site\"]],[28,[37,8],[\"site\",[30,7]],null],[30,0,[\"hoveredUnit\"]],[28,[37,6],[[30,0],[28,[37,7],[[30,0,[\"hoveredUnit\"]]],null]],null],[30,3,[\"fitBounds\"]]]]]],[1,\"\\n\"]],[]]]],[5]],null]],[2,3]]]]],[13]],[\"&attrs\",\"map\",\"ops\",\"@units\",\"mu\",\"@highlighted\",\"@selected\"],false,[\"app-map-permit\",\"each\",\"-track-array\",\"if\",\"selectable-unit-layer\",\"array\",\"action\",\"mut\",\"map-by\"]]",
    "moduleName": "mldp/components/permit/amd-bulk-recommendation/map/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});