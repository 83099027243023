define("mldp/components/site/consultations-list/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ozpT31HF",
    "block": "[[[1,\"  \"],[10,\"dt\"],[12],[1,\"Consultations\"],[13],[1,\"\\n  \"],[10,\"dd\"],[14,0,\"form-group\"],[12],[1,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-xs btn-default\"],[15,\"onclick\",[28,[37,0],[[30,0],\"toggleConsultations\"],null]],[14,4,\"button\"],[12],[1,\"\\n      \"],[1,[52,[30,0,[\"showConsultations\"]],\"Hide\",\"View\"]],[1,\" Consultations\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showConsultations\"]],[[[41,[30,0,[\"consultations\",\"length\"]],[[[1,\"      \"],[10,\"dt\"],[14,0,\"text-muted\"],[12],[1,\"Date\"],[13],[1,\"\\n      \"],[10,\"dd\"],[14,0,\"text-muted\"],[12],[1,\"\\n        \"],[10,\"b\"],[12],[1,\"Biologist\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"consultations\"]]],null]],null],null,[[[1,\"      \"],[10,\"dt\"],[12],[1,[28,[35,4],[[30,1,[\"consultationDate\"]]],[[\"noTime\"],[true]]]],[13],[1,\"\\n      \"],[10,\"dd\"],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[30,1,[\"biologist\",\"name\"]]],[1,\"\\n        \"],[8,[39,5],[[24,0,\"btn btn-xs btn-default\"]],[[\"@route\",\"@model\"],[\"account.consultation\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n          View\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],[[[1,\"      \"],[10,0],[14,0,\"text-center\"],[12],[1,\"\\n        No consultations available.\\n      \"],[13],[1,\"\\n\"]],[]]]],[]],null]],[\"consultation\"],false,[\"action\",\"if\",\"each\",\"-track-array\",\"format-date-time\",\"link-to\"]]",
    "moduleName": "mldp/components/site/consultations-list/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});