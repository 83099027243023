define("mldp/serializers/outreach", ["exports", "mldp/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Outreach = classic(_class = class Outreach extends _application.default {
    modelNameFromPayloadKey() {
      let processed = super.modelNameFromPayloadKey(...arguments);

      switch (processed) {
        case 'otherStaff':
          {
            return 'userXOutreaches';
          }
      }

      return processed;
    } // skip relationships or EF tries to create new ones, but ED doesn't pass the attributes yet
    // just type and id


    serializeHasMany() {
      return;
    }

  }) || _class;

  _exports.default = Outreach;
});