define("mldp/components/biologist-select/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "MZelkJ1E",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[6,[39,0],null,[[\"close\"],[[30,0,[\"onCancel\"]]]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"modal-header\"],[12],[1,\"\\n      \"],[10,\"button\"],[14,0,\"close\"],[14,\"aria-label\",\"Close\"],[15,\"onclick\",[30,2,[\"close\"]]],[14,4,\"button\"],[12],[1,\"\\n        \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"×\"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[1,\"Select Biologist\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"modal-body\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"Please select a Biologist for the RTA for \\\"\"],[1,[30,0,[\"rta\",\"site\",\"siteName\"]]],[1,\"\\\".\"],[13],[1,\"\\n\\n      \"],[8,[39,1],null,[[\"@options\",\"@selected\",\"@placeholder\",\"@dropdownClass\",\"@matcher\",\"@onChange\"],[[30,0,[\"biologists\"]],[30,0,[\"biologist\"]],\"Select Biologist\",\"biologist-select-dropdown\",[28,[37,2],[[30,0],\"matchBiologist\"],null],[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"biologist\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"firstName\"]]],[1,\" \"],[1,[30,3,[\"lastName\"]]],[1,\" (\"],[1,[30,3,[\"email\"]]],[1,\")\\n      \"]],[3]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-default pull-left\"],[15,\"onclick\",[30,2,[\"close\"]]],[12],[1,\"Cancel\"],[13],[1,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[15,\"disabled\",[28,[37,4],[[30,0,[\"biologist\"]]],null]],[15,\"onclick\",[28,[37,2],[[30,0],[30,0,[\"onSubmit\"]],[30,0,[\"biologist\"]]],null]],[12],[1,\"Submit\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]]]]],[13]],[\"&attrs\",\"do\",\"item\"],false,[\"modal-window\",\"power-select\",\"action\",\"mut\",\"not\"]]",
    "moduleName": "mldp/components/biologist-select/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});