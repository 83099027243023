define("mldp/components/state-select/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eMCSM1M/",
    "block": "[[[1,[28,[30,1,[\"select-field\"]],[[30,2],[30,0,[\"states\"]]],[[\"prompt\",\"promptIsSelectable\",\"optionTargetPath\",\"optionLabelPath\",\"label\"],[\"Select State\",true,\"value\",\"name\",\"State\"]]]]],[\"@f\",\"@property\"],false,[]]",
    "moduleName": "mldp/components/state-select/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});