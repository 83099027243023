define("mldp/account/property/unit/index/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FAL5Ov07",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\\n\"],[1,[28,[35,2],[[28,[37,3],[\"Unit: \",[30,0,[\"unit\",\"siteName\"]]],null]],null]],[1,\"\\n\"],[41,[30,0,[\"parentPropertyInfo\"]],[[[1,\"  \"],[1,[28,[35,5],null,[[\"site\",\"siteInfo\"],[[30,0,[\"parentProperty\"]],[30,0,[\"parentPropertyInfo\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,[28,[35,6],null,[[\"unit\",\"site\",\"leavingPool\",\"onLeavePool\",\"onCancel\"],[[30,0,[\"unit\"]],[30,0,[\"site\"]],[30,0,[\"leavingPool\"]],[28,[37,7],[\"leavePool\"],null],[28,[37,7],[\"cancelModel\"],null]]]]],[1,\"\\n\\n\"],[1,[28,[35,8],null,[[\"unit\",\"site\",\"fenceType\",\"getReport\",\"onLeavePool\"],[[30,0,[\"unit\"]],[30,0,[\"site\"]],[30,0,[\"fenceType\"]],[28,[37,7],[\"getReport\"],null],[28,[37,7],[\"leavePoolConfirm\"],null]]]]],[1,\"\\n\"]],[],false,[\"component\",\"-outlet\",\"page-banner\",\"titleize\",\"if\",\"site-property-card\",\"leave-pool-confirm\",\"route-action\",\"managementunit-details\"]]",
    "moduleName": "mldp/account/property/unit/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});