define("mldp/components/permit/amd-bulk-recommendation/overview-card/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2b+A+lLC",
    "block": "[[[11,0],[24,0,\"permit-rec-card clearfix push-up\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"row-fluid overview\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n      \"],[10,\"h4\"],[12],[1,\"\\n        Antlerless Mule Deer Permit Recommendations\"],[10,\"br\"],[12],[13],[1,\"\\n        \"],[1,[30,2,[\"displayValue\"]]],[1,\" County\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n      \"],[10,\"dl\"],[14,0,\"dl-horizontal\"],[12],[1,\"\\n        \"],[10,\"dt\"],[12],[1,\"Total # of Applications in County:\"],[13],[1,\"\\n        \"],[10,\"dd\"],[12],[1,\" \"],[1,[28,[35,0],[[30,3]],null]],[13],[1,\"\\n        \"],[10,\"dt\"],[12],[1,\"Total Acreage of Applications:\"],[13],[1,\"\\n        \"],[10,\"dd\"],[12],[1,\" \"],[1,[28,[35,0],[[30,4]],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@county\",\"@totalPermits\",\"@totalAcreage\"],false,[\"number-commas\"]]",
    "moduleName": "mldp/components/permit/amd-bulk-recommendation/overview-card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});