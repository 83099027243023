define("mldp/components/admin/static-table-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "f6XIOPDT",
    "block": "[[[41,[28,[37,1],[[30,0,[\"season\"]],\"spring\"],null],[[[1,\"  \"],[10,2],[12],[1,\" Attention, you are about to capture a one-time, static snapshot of Recommendation, \\n    Harvest, and Habitat Practice data for sites enrolled in MLDP for the \"],[1,[30,0,[\"springYear\"]]],[1,\". \\n    Data previously captured for this season will be overwritten in the static tables if you continue. \\n    These static data are used for the MLDP Summary and MLDP Site Specific reports for all previous seasons. \\n    No changes are made to data in the live tables by running this process.\"],[13],[1,\" \\n\"]],[]],null],[41,[28,[37,1],[[30,0,[\"season\"]],\"fall\"],null],[[[1,\"  \"],[10,2],[12],[1,\" Attention, you are about to capture a one-time, static snapshot of Site, Enrollment, \\n    and SiteXEnrollment data for sites enrolled in MLDP for the \"],[1,[30,0,[\"fallYear\"]]],[1,\". \\n    Data previously captured for this season will be overwritten in the static tables if you continue. \\n    These static data are used for the MLDP Summary and MLDP Site Specific reports for all previous seasons. \\n    No changes are made to data in the live tables by running this process.\"],[13],[1,\" \\n\"]],[]],null]],[],false,[\"if\",\"eq\"]]",
    "moduleName": "mldp/components/admin/static-table-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});