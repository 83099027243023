define("mldp/account/property/unit/surveyseason/controller", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const allSurveyFields = [['startDate', 'Date']];
  const invididualSurveyFields = {
    1: [['flightRoute', 'Flight Name / Number'], ['percentAcreageFlown', 'Percent MU Acreage Flown']],
    2: [['flightRoute', 'Route Name / Number'], ['lengthMiles', 'Length in Miles'], ['acresOfVisibility', 'Acres of Visibility']],
    3: [['endDate', 'End Date']],
    4: [['endDate', 'End Date'], ['observationPoints', 'Number of Blinds with Observations'], ['observationPeriods', 'Number of Observation Periods']],
    5: [['endDate', 'End Date'], ['observationPoints', 'Number of Camera Sites'], ['observationPeriods', 'Number of Consecutive Days Surveyed'], ['acresSurveyed', 'Acres Surveyed']],
    6: [['acresSurveyed', 'Acres Surveyed'], ['notes', 'Notes']]
  };
  let AccountPropertyUnitSurveyseasonController = (_dec = (0, _service.inject)('notifications'), _dec2 = (0, _service.inject)('general-ajax'), (_class = class AccountPropertyUnitSurveyseasonController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "ajaxService", _descriptor2, this);
    }

    changeSurveyType(type) {
      this.survey.set('surveyTypeId', type.id);
    }

    changeSurveySeason(season) {
      this.survey.set('deerSeason', season);
      this.survey.set('deerSeasonId', season.id);
    }

    async saveSurvey() {
      let notifications = this.notifications;
      const requiredFields = allSurveyFields.concat(invididualSurveyFields[this.survey.surveyTypeId]);
      let missingFields = false;
      requiredFields.forEach(current => {
        let [field, label] = current;

        if (!this.survey[field]) {
          notifications.error(`${label} is required`);
          missingFields = true;
        }
      });

      if (!missingFields) {
        try {
          await this.survey.save();
          this.send('refreshModel');
          this.set('showModal', false);
        } catch (e) {
          notifications.addApiErrors(e);
          throw e;
        }
      }
    }

    async deleteSurvey() {
      let notifications = this.notifications;

      try {
        await this.selectedSurvey.destroyRecord();
        this.send('refreshModel');
        this.set('showModal', false);
      } catch (e) {
        notifications.addApiErrors(e);
        throw e;
      }

      this.set('showDeleteModal', false);
    }

    selectSurvey(survey) {
      this.set('selectedSurvey', survey);
      this.set('showDeleteModal', true);
    }

    async downLoadExcelReport() {
      const ajax = this.ajaxService;
      let token = await ajax.request('/api/deersurveyobservation/getauthtoken');
      window.open(`/api/deersurveyobservation/excel?siteid=${this.unit.id}&seasonid=${this.seasonId}&auth=${token}`, '_blank');
    }

    async openSurveyModal() {
      let survey = await this.store.createRecord(`deer-survey`);
      survey.setProperties({
        deerSeasonId: this.season.id,
        deerSeason: this.seasons.find(x => x.id === this.season.id),
        siteId: this.unit.id
      });
      this.set('survey', survey);
      this.set('showModal', true);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajaxService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeSurveyType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSurveyType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSurveySeason", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSurveySeason"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveSurvey", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveSurvey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteSurvey", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteSurvey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectSurvey", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectSurvey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downLoadExcelReport", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "downLoadExcelReport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openSurveyModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openSurveyModal"), _class.prototype)), _class));
  _exports.default = AccountPropertyUnitSurveyseasonController;
});