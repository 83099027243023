define("mldp/models/property", ["exports", "@ember-data/model", "@ember/service", "@ember/object/computed", "@ember/object", "mldp/models/site", "ember-cp-validations", "mldp/utils/instance-op", "mldp/utils/area-in-acres", "mldp/models/invite-model"], function (_exports, _model, _service, _computed, _object, _site, _emberCpValidations, _instanceOp, _areaInAcres, _inviteModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    address1: {
      description: 'Address Line 1',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 50
      })]
    },
    address2: {
      description: 'Address Line 2',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 50
      })]
    },
    city: (0, _emberCpValidations.validator)('length', {
      max: 50
    }),
    state: (0, _emberCpValidations.validator)('length', {
      max: 2
    }),
    primaryPhone: (0, _emberCpValidations.validator)('length', {
      max: 10
    }),
    secondaryPhone: (0, _emberCpValidations.validator)('length', {
      max: 10
    }),
    statedAcreage: {
      description: 'Property Acres',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: false,
        gt: 0
      }), (0, _emberCpValidations.validator)('presence', true)]
    },
    shape: {
      description: 'Property Boundary',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    zipCode: (0, _emberCpValidations.validator)('length', {
      min: 5,
      max: 5,
      description: 'Zip Code',
      disabled: (0, _object.computed)('model.zipCode', {
        get() {
          let zipCode = this.get('model.zipCode');
          if (!zipCode) return true;
          return !zipCode.length;
        }

      })
    }),
    isAgent: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Landowner information',
      disabled: (0, _object.computed)('model.{isCreateMode,roleService}', {
        get() {
          let isCreateMode = this.get('model.isCreateMode');
          let isTpwdUser = this.get('model.roleService').isUserInTpwd();
          return !isCreateMode || isCreateMode && isTpwdUser;
        }

      }).volatile()
    }),
    ownerFirstName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Owner\'s first name',
      disabled: (0, _object.computed)('model.{isAgent,isPublicLand}', {
        get() {
          return !this.get('model.isAgent') || this.get('model.isPublicLand');
        }

      }).volatile()
    }),
    ownerLastName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Owner\'s last name',
      disabled: (0, _object.computed)('model.{isAgent,isPublicLand}', {
        get() {
          return !this.get('model.isAgent') || this.get('model.isPublicLand');
        }

      }).volatile()
    }),
    ownerEmail: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Owner\'s email',
      disabled: (0, _object.computed)('model.{isAgent,isPublicLand}', {
        get() {
          return !this.get('model.isAgent') || this.get('model.isPublicLand');
        }

      }).volatile()
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      description: 'Owner\'s email',
      disabled: (0, _object.computed)('model.{isAgent,isPublicLand}', {
        get() {
          return !this.get('model.isAgent') || this.get('model.isPublicLand');
        }

      }).volatile()
    })]
  });

  var _default = _site.default.extend(Validations, {
    roleService: (0, _service.inject)('role-service'),
    address1: (0, _model.attr)('string'),
    address2: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    zipCode: (0, _model.attr)('string'),
    primaryPhone: (0, _model.attr)('string'),
    secondaryPhone: (0, _model.attr)('string'),
    statedAcreage: (0, _model.attr)('number'),
    isPublicLand: (0, _model.attr)('boolean'),
    trackResearchAgreement: (0, _model.attr)('boolean'),
    agreementDate: (0, _model.attr)('date'),
    isAgent: (0, _model.attr)('boolean', {
      defaultValue: null
    }),
    acceptAgreement: (0, _instanceOp.default)({
      type: 'post',
      urlType: 'findAll',
      path: 'SetAgreementDate/{id}'
    }),
    leaveEnrollment: (0, _instanceOp.default)({
      path: 'leaveenrollment/{enrollmentid}',
      type: 'post'
    }),
    editowneragent: (0, _instanceOp.default)({
      path: 'editowneragent',
      type: 'patch'
    }),
    isCreateMode: false,
    ownerFirstName: null,
    ownerLastName: null,
    ownerEmail: null,
    geoJSON: (0, _object.computed)('shape', {
      get() {
        let shape = this.shape;

        if (!shape) {
          return;
        }

        try {
          return JSON.parse(shape);
        } catch (e) {// pass
        }
      },

      set(key, value) {
        if (value) {
          this.set('shape', JSON.stringify(value));
        } else {
          this.set('shape', null);
        }

        return value;
      }

    }),
    geoJSONArea: (0, _object.computed)('geoJSON', {
      get() {
        let geojson = this.geoJSON;

        if (!geojson) {
          return 0;
        }

        let areaAcres = (0, _areaInAcres.default)(geojson);
        return areaAcres;
      }

    })
  });

  _exports.default = _default;
});