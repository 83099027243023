define("mldp/account/property/invite/biologist/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gb1ebz5v",
    "block": "[[[1,[28,[35,0],[\"Invite Biologist\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-xs-12\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"Invite Biologist for \"],[1,[28,[35,1],[[30,0,[\"site\",\"siteType\"]]],null]],[1,\" '\"],[1,[30,0,[\"site\",\"siteName\"]]],[1,\"'\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-xs-12 col-md-6\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Enter the information requested below to invite a Biologist to be added to this property in the Land Management Assistance\\n      (LMA) system.\\n    \"],[13],[1,\"\\n\\n\"],[6,[39,2],[[30,0,[\"invite\"]]],[[\"submit\",\"reset\"],[[28,[37,3],[\"onSave\"],null],[28,[37,3],[\"onCancel\"],null]]],[[\"default\"],[[[[1,\"      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@options\",\"@selected\",\"@placeholder\",\"@searchEnabled\",\"@matcher\",\"@triggerClass\",\"@dropdownClass\",\"@onChange\"],[[30,0,[\"state\",\"biologists\"]],[30,0,[\"invite\",\"biologist\"]],\"-- Biologist --\",true,[28,[37,3],[\"matchBiologist\"],null],\"form-control\",\"biologist-select-dropdown\",[28,[37,5],[[30,0],[28,[37,6],[[30,0,[\"invite\",\"biologist\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,2,[\"firstName\"]]],[1,\" \"],[1,[30,2,[\"lastName\"]]],[1,\" (\"],[1,[30,2,[\"email\"]]],[1,\")\\n        \"]],[2]]]]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[30,1,[\"reset\"]],[\"Cancel\"],[[\"class\"],[\"btn-transparent\"]]]],[1,\"\\n        \"],[1,[28,[30,1,[\"submit\"]],[\"Invite Biologist\"],[[\"class\",\"pending\"],[\"pull-right\",\"Processing..\"]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"f\",\"biologist\"],false,[\"page-banner\",\"titleize\",\"form-for\",\"route-action\",\"power-select\",\"action\",\"mut\"]]",
    "moduleName": "mldp/account/property/invite/biologist/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});