define("mldp/components/notices/property-research-agreement/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KhODKyOV",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"This property has been successfully\\n    registered. The landowner has been emailed\\n    an invitation to create an account to access\\n    this site record, however, no action is\\n    required by the landowner. Please upload the\\n    Landowner Permission for WL Research form (PWD-0153A) associated with\\n    this property.\"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/notices/property-research-agreement/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});