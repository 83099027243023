define("mldp/components/deer-population-estimator/header/component", ["exports", "rsvp", "@ember/object", "@ember/service", "@ember-decorators/component", "@ember/component"], function (_exports, _rsvp, _object, _service, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  ;
  let DeerPopulationEstimatorHeader = (_dec = (0, _component.tagName)(''), _dec2 = (0, _service.inject)('store'), _dec(_class = classic(_class = (_class2 = class DeerPopulationEstimatorHeader extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    async getPastSeasonHarvest() {
      if (!this.previousHarvest) {
        this.set('loading', true);
        let previousHarvest;

        if (this.site.isCoop) {
          let coopAdapter = this.store.adapterFor('coop');
          let mus = await coopAdapter.getManagementUnits(this.site.id);
          let harvests = await _rsvp.default.all(mus.map(mu => mu.findHarvests()));
          harvests = harvests.flat().filter(recommendation => recommendation.deerSeasonId === this.seasonId - 1);
          const harvestKeys = ['whiteTailAntlerless', 'whiteTailTotalBucks', 'whiteTailUnbranchedBucks', 'muleDeerAntlerless', 'muleDeerTotalBucks', 'muleDeerUnbranchedBucks'];
          previousHarvest = {
            deerSeasonId: this.seasonId - 1
          };
          harvestKeys.forEach(key => previousHarvest[key] = 0);
          harvests.forEach(harvest => {
            harvestKeys.forEach(key => previousHarvest[key] += harvest[key]);
          });
        } else {
          let results = await this.site.findHarvests();
          previousHarvest = results.find(recommendation => recommendation.deerSeasonId === this.seasonId - 1);
        }

        this.set('previousHarvest', previousHarvest);
        this.set('loading', false);
      }
    }

    async getPastSeasonHarvestRec() {
      if (!this.previousHarvestRec) {
        this.set('loading', true);
        const options = {
          'filter[primarysite]': this.site.siteType === 'managementunit' ? this.site.parentSiteId : this.site.id,
          'filter[season]': this.seasonId - 1
        };
        let recommendations = await this.store.query('recommendation', options);
        recommendations = await recommendations.toArray();

        if (recommendations.length) {
          const previousHarvestRec = {
            whiteTailDoe: 0,
            whiteTailTotalBucks: 0,
            whiteTailUnbranchedBucks: 0,
            muleDeerDoe: 0,
            muleDeerTotalBucks: 0,
            muleDeerUnbranchedBucks: 0
          };
          recommendations.forEach(rec => {
            if (rec.speciesId === 1) {
              previousHarvestRec.whiteTailTotalBucks += rec.buckTagsAny;
              previousHarvestRec.whiteTailUnbranchedBucks += rec.buckTagsUnbranched;
              previousHarvestRec.whiteTailDoe += rec.doeTags;
            }

            if (rec.speciesId === 2) {
              previousHarvestRec.muleDeerTotalBucks += rec.buckTagsAny;
              previousHarvestRec.muleDeerUnbranchedBucks += rec.buckTagsUnbranched;
              previousHarvestRec.muleDeerDoe += rec.doeTags;
            }
          });
          this.set('previousHarvestRec', previousHarvestRec);
        }

        this.set('loading', false);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "getPastSeasonHarvest", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "getPastSeasonHarvest"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getPastSeasonHarvestRec", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "getPastSeasonHarvestRec"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = DeerPopulationEstimatorHeader;
});