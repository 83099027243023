define("mldp/helpers/add", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.default = void 0;

  function add(params
  /*, hash*/
  ) {
    return params.reduce((acc, cur) => acc + cur);
  }

  var _default = (0, _helper.helper)(add);

  _exports.default = _default;
});