define("mldp/components/my-form/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "r0D9v0qz",
    "block": "[[[18,1,[[30,0,[\"promise\"]],[30,0,[\"submitting\"]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "mldp/components/my-form/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});