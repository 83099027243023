define("mldp/account/users/new/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QBihSzS4",
    "block": "[[[1,[28,[35,0],null,[[\"model\",\"roles\",\"counties\",\"supervisors\",\"createMode\",\"onsubmit\",\"onreset\"],[[30,0,[\"user\"]],[30,0,[\"roles\"]],[30,0,[\"counties\"]],[30,0,[\"supervisors\"]],true,[28,[37,1],[\"save\"],null],[28,[37,1],[\"reset\"],null]]]]],[1,\"\\n\"]],[],false,[\"user-edit\",\"route-action\"]]",
    "moduleName": "mldp/account/users/new/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});