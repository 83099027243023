define("mldp/models/custom-model", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Model = classic(_class = class Model extends _object.default {
    // Going to server
    deserialize() {
      let obj = {};
      let data = this;
      let attrs = this.constructor.attrs;
      Object.keys(data).forEach(attr => {
        if (attrs.includes(attr)) {
          obj[attr.classify()] = (0, _object.get)(data, attr);
        }
      });
      return obj;
    }

  }) || _class;

  window.__CLASSIC_OWN_CLASSES__.set(Model, true);

  Model.reopenClass({
    // Coming from server
    serialize(data) {
      let obj = {};
      Object.keys(data).forEach(attr => {
        obj[attr.camelize()] = data[attr];
      });
      return obj;
    }

  });
  var _default = Model;
  _exports.default = _default;
});