define("mldp/components/actions/navigate-path/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eTmY3zqJ",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,0,[\"model\",\"notificationTitle\"]]],null]],[1,\"\\n\\n  \"],[10,2],[12],[1,\"\\n    \"],[1,[30,0,[\"model\",\"notificationDetail\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,3],[15,6,[30,0,[\"model\",\"navigationPath\"]]],[14,\"title\",\"Navigate to url\"],[12],[1,\"\\n    \"],[1,[30,0,[\"model\",\"notificationTitle\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"page-banner\"]]",
    "moduleName": "mldp/components/actions/navigate-path/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});