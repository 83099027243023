define("mldp/models/tlk-enrollment-gender", ["exports", "@ember/object", "@ember/object/computed", "@ember-data/model"], function (_exports, _object, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let TlkEnrollmentGender = (_dec = (0, _model.attr)('string'), _dec2 = (0, _computed.equal)('id', '3'), _dec3 = (0, _computed.equal)('id', '2'), _dec4 = (0, _computed.equal)('id', '1'), _dec5 = (0, _object.computed)('id'), _dec6 = (0, _object.computed)('isBoth', 'isAntlerless', 'isBuck'), classic(_class = (_class2 = class TlkEnrollmentGender extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "displayValue", _descriptor, this);

      _initializerDefineProperty(this, "isBoth", _descriptor2, this);

      _initializerDefineProperty(this, "isAntlerless", _descriptor3, this);

      _initializerDefineProperty(this, "isBuck", _descriptor4, this);
    }

    get identifier() {
      let id = this.id;
      return Number(id);
    }

    get keys() {
      let isBoth = this.isBoth;

      if (!isBoth) {
        let isAntlerless = this.isAntlerless;
        let isBuck = this.isBuck;

        if (isBuck) {
          return ['buckTagsAny', 'buckTagsUnbranched'];
        } else if (isAntlerless) {
          return ['doeTags'];
        }
      }

      return ['buckTagsAny', 'buckTagsUnbranched', 'doeTags'];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "displayValue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isBoth", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isAntlerless", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isBuck", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "identifier", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "identifier"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "keys", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "keys"), _class2.prototype)), _class2)) || _class);
  _exports.default = TlkEnrollmentGender;
});