define("mldp/components/site/download-kml/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/4qW/K70",
    "block": "[[[41,[28,[37,1],[\"edit site in properties\",[30,1]],null],[[[1,\"  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n    \"],[10,\"button\"],[15,0,[29,[\"btn \",[30,2]]]],[15,\"onclick\",[28,[37,2],[[30,0],\"download\"],null]],[14,4,\"button\"],[12],[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"        Downloading..\\n\"]],[]],[[[1,\"        Download as KML\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@site\",\"@buttonClass\"],false,[\"if\",\"can\",\"action\"]]",
    "moduleName": "mldp/components/site/download-kml/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});