define("mldp/account/property/enrollment/edit/controller", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PropertyEnrollmentEditController extends _controller.default {}

  _exports.default = PropertyEnrollmentEditController;

  window.__CLASSIC_OWN_CLASSES__.set(PropertyEnrollmentEditController, true);
});