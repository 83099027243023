define("mldp/services/role-service", ["exports", "@ember/service", "@ember/object"], function (_exports, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let RoleServiceService = (_dec = (0, _service.inject)('session'), _dec2 = (0, _service.inject)('app-values'), _dec3 = (0, _object.computed)('session.isAuthenticated'), _dec4 = (0, _object.computed)('session.isAuthenticated'), _dec5 = (0, _object.computed)('session.roles'), _dec6 = (0, _object.computed)('session.isAuthenticated'), classic(_class = (_class2 = class RoleServiceService extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "appValues", _descriptor2, this);
    }

    get accessToken() {
      let session = this.session;

      if (session.get('isAuthenticated')) {
        return session.get('data.authenticated.accessToken');
      }

      return null;
    }

    get isAdmin() {
      let session = this.session;

      if (!session.get('isAuthenticated')) {
        return false;
      }

      let roles = session.get('roles');
      return roles && roles.indexOf('Admin') > -1;
    }

    get isTwpd() {
      return this.isUserInTpwd();
    }

    get isWPO() {
      let session = this.session;

      if (!session.get('isAuthenticated')) {
        return false;
      }

      let roles = session.get('roles');
      return roles && roles.indexOf('Wildlife Permit Office') > -1;
    }

    isInRole(role) {
      let session = this.session;

      if (!session.get('isAuthenticated') || !role) {
        return false;
      }

      let roles = session.get('roles');
      return roles && roles.indexOf(role) > -1;
    }

    isUserInTpwd() {
      let tpwdRoles = this.get('appValues.tpwdRoleList');
      let userRoles = this.get('session.roles');

      if (!userRoles) {
        return false;
      }

      for (var i = 0; i < tpwdRoles.length; i++) {
        let tpwdRole = tpwdRoles[i];

        if (userRoles.indexOf(tpwdRole) > -1) {
          return true;
        }
      }

      return false;
    }

    isSameUser(userId) {
      let currentUserId = this.get('session.userId');
      return String(userId) === String(currentUserId);
    }

    associated(model) {
      let {
        filterKey,
        byId,
        usersKey
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      usersKey = usersKey ? usersKey : 'associatedUsers';
      let associatedUsers = (0, _object.get)(model, usersKey);

      if (!associatedUsers) {
        return [];
      }

      filterKey = filterKey ? filterKey : byId ? 'id' : 'email';
      let filterValue = byId ? this.get('session.userId') : this.get('session.email');
      let associated = associatedUsers.filterBy(filterKey, filterValue);
      return associated;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "appValues", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "accessToken", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "accessToken"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isAdmin", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isAdmin"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isTwpd", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "isTwpd"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isWPO", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "isWPO"), _class2.prototype)), _class2)) || _class);
  _exports.default = RoleServiceService;
});