define("mldp/components/payment-status-modal/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PaymentStatusModal extends _component.default {}

  _exports.default = PaymentStatusModal;

  window.__CLASSIC_OWN_CLASSES__.set(PaymentStatusModal, true);
});