define("mldp/account/property/permit/reporting/harvest/controller", ["exports", "@ember/service", "@ember/controller", "@ember/object", "moment", "rsvp", "mldp/models/harvest"], function (_exports, _service, _controller, _object, _moment, _rsvp, _harvest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let HarvestController = (_dec = (0, _service.inject)('notifications'), _dec2 = (0, _service.inject)('store'), _dec3 = (0, _service.inject)('confirm-window'), _dec4 = (0, _object.computed)('model.{harvests,permit}'), classic(_class = (_class2 = class HarvestController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "confirmWindow", _descriptor3, this);
    }

    get harvests() {
      let {
        permit,
        currentSeason
      } = this.model;
      let existingHarvests = this.model.harvests;
      let harvests = permit.phPartitions.reduce((acc, part) => {
        // only show herd units that owner has accepted recommendations 
        if (part.phRecommendations && part.phRecommendations.length && part.phRecommendations.some(rec => rec.ownerAccepted)) {
          let harvest = existingHarvests.find(harvest => harvest.herdUnit === part.herdUnit);

          if (!harvest) {
            harvest = this.store.createRecord('ph-harvest', {
              archived: false,
              seasonId: currentSeason.id,
              phPermitAppId: permit.id,
              herdUnit: part.herdUnit,
              harvestedBucks: '0',
              harvestedDoes: '0'
            });
          }

          let recommendedBucks = 0,
              recommendedDoes = 0;
          part.phRecommendations.forEach(rec => {
            if (rec.invalidated) return;
            if (rec.recommendedBucks > 0) recommendedBucks += rec.recommendedBucks;
            if (rec.recommendedDoes > 0) recommendedDoes += rec.recommendedDoes;
          });
          harvest.setProperties({
            recommendedBucks,
            recommendedDoes
          });
          if (recommendedBucks > 0) acc.push(harvest);
        }

        return acc;
      }, []);
      return harvests;
    }

    async submitHarvests() {
      try {
        let result = await _rsvp.default.all(this.harvests.map(harvest => harvest.save()));
        let {
          currentSeason,
          permit,
          site
        } = this.model;
        let seasonsMatch = Number(currentSeason.id) === Number(permit.seasonId);
        let pastReportingDeadline = (0, _moment.default)(new Date()).isAfter(currentSeason.phHarvestReportingDueDate);
        let nextSeasonPermitApps = await this.store.query('ph-permit-app', {
          'filter[seasonid]': Number(currentSeason.id) + 1,
          'filter[siteid]': Number(site.id)
        });
        nextSeasonPermitApps = nextSeasonPermitApps.toArray();
        let unincludedMus;

        if (!nextSeasonPermitApps.length) {
          unincludedMus = true;
        } else {
          // check if all mus in current permit app are included in a permit app for next season
          unincludedMus = !permit.mUs.every(mu => nextSeasonPermitApps.some(permitApp => permitApp.mUs.find(cur => cur === mu)));
        }

        if (seasonsMatch && !pastReportingDeadline && unincludedMus) {
          this.set('reapplyModalOpen', true);
        } else {
          await this.transitionToRoute('account.permits', 'ph');
          return this.confirmWindow.open({
            title: 'Harvest Submitted',
            body: 'You have successfully submitted your harvest report, click OK to continue'
          });
        }
      } catch (err) {
        console.log('err', err);
      }
    }

    async continueToPermits() {
      this.setProperties({
        reapplyModalOpen: false,
        changePropertyModalOpen: false,
        changePropertyConfirmationModalOpen: false,
        continueReportingModalOpen: false
      });
      await this.transitionToRoute('account.permits', 'ph');
      return this.confirmWindow.open({
        title: 'Harvest Submitted',
        body: 'You have successfully submitted your harvest report, click OK to continue'
      });
    }

    async checkHarvests() {
      // Is harvest reported for all applications with an Approved status and accepted harvest rec >=1 under this primary site?
      let {
        currentSeason,
        site
      } = this.model;
      let permitApps = await this.store.query('ph-permit-app', {
        'filter[seasonid]': Number(currentSeason.id),
        'filter[siteid]': Number(site.id)
      });
      permitApps = permitApps.toArray();
      permitApps = permitApps.filter(permitApp => permitApp.applicationStatusId !== 2 || !permitApp.phPartitions.some(part => part.phRecommendations.some(rec => rec.recommendedBucks > 0)));
      let reporting = await Promise.all(permitApps.map(permit => permit.fetchReporting()));
      let moreReportingLeft = reporting.some(reporting => {
        let harvests = reporting.harvest.toArray();
        return _harvest.default.length && harvests[0].submitted;
      });

      if (moreReportingLeft) {
        this.set('continueReportingModalOpen', true);
      } else {
        this.send('continueToPermits');
      }

      this.set('continueReportingModalOpen');
    }

    goToProperty() {
      let {
        site
      } = this.model;
      this.transitionToRoute('account.property', site.siteType, site.id);
    }

    goToNewApplication() {
      let {
        site
      } = this.model;
      this.transitionToRoute('account.permits.create', 'ph', {
        queryParams: {
          primarySiteId: site.id,
          primarySiteType: site.siteType
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "confirmWindow", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "harvests", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "harvests"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "submitHarvests", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "submitHarvests"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "continueToPermits", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "continueToPermits"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "checkHarvests", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "checkHarvests"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "goToProperty", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "goToProperty"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "goToNewApplication", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "goToNewApplication"), _class2.prototype)), _class2)) || _class);
  _exports.default = HarvestController;
});