define("mldp/components/aggregate-manager-invite/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "fdI+OQ8j",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"Aggregate Manager Invite\"],null]],[1,\"\\n\\n  \"],[1,[30,0,[\"model\",\"property\",\"siteName\"]]],[1,\"\\n\\n  \"],[10,\"ol\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      I hereby acknowledge that I have been authorized as a “Pooled Property Manager” for a group of properties that \\n      are combining efforts as either a co-op or aggregate for the purpose of participation in the \\n      Managed Lands Deer Program (MLDP).\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      I understand that as a Pooled Property Manager, I have the authority to act on behalf of the participating landowners \\n      for the purpose of enrollment into MLDP and to ensure program requirements, including any reporting requirements related \\n      to pooled properties, are met.\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      I understand that failure to fulfill program requirements could jeopardize participation in MLDP for the participating \\n      properties of which I am designated as a Pooled Property Manager.\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      I understand that as the Pooled Property Manager, I am responsible for informing landowners of the participating \\n      properties of any correspondence, documents, and tags provided to me by Texas Parks and Wildlife Department related to \\n      participation in MLDP.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"action\",\"default\",\"pending\",\"class\"],[[28,[37,2],[[30,0],\"complete\"],null],\"I Agree\",\"Saving...\",\"btn btn-primary\"]]]],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"page-banner\",\"async-button\",\"action\"]]",
    "moduleName": "mldp/components/aggregate-manager-invite/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});