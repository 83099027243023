define("mldp/account/permits/amd-recommendations/controller", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@ember/array", "ember-concurrency", "ember-state-services/state-for", "rsvp", "@turf/turf"], function (_exports, _controller, _service, _object, _array, _emberConcurrency, _stateFor, _rsvp, _turf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    store: (0, _service.inject)('store'),
    notifications: (0, _service.inject)('notifications'),
    state: (0, _stateFor.default)('amd-recommendations'),
    selected: (0, _array.A)(),
    highlightedItem: null,
    totalAcreage: (0, _object.computed)('model.permits', function () {
      return this.model.permits.reduce((total, permit) => {
        total += permit.rangelandAcreage + permit.wheatAcreage + permit.alfalfaAcreage + permit.otherCropAcreage;
        return total;
      }, 0);
    }),
    managementUnits: (0, _object.computed)('model.permits', function () {
      const list = this.model.permits.reduce((all, permit) => {
        let managementUnits = extractManagementUnits(permit);
        all = all.concat(managementUnits);
        return all;
      }, []); //TODO: can be done on call to api added to options 'sort': '-PrimarySite.CalculatedAcreage'

      return list.sort(sortByCalculatedAcreage);
    }),
    partitions: (0, _object.computed)('selected.length', 'state', 'stateUpdated', function () {
      if (!this.state || !this.selected) {
        return [];
      }

      return this.selected.reduce((all, mu) => {
        let data = this.state.get(mu);

        if (data && data.recommendations.length) {
          data.recommendations.forEach(rec => {
            if (!rec.partitions || !rec.partitions.length) {
              return;
            }

            all = all.concat(rec.partitions);
          });
        }

        return all;
      }, []);
    }),
    actions: {
      unselect() {
        this.set('selected', []);
      },

      selectAll() {
        this.set('selected', this.managementUnits);
      },

      selectOne(mu) {
        this.selected.addObject(mu);
      },

      unselectOne(mu) {
        this.selected.removeObject(mu);
      },

      applyCalculatedRecs() {
        return this.calculatedRecs.perform();
      },

      applyLastSeasonsRecs() {
        return this.lastSeasonsRecs.perform();
      },

      submitRecs(closeModal) {
        return this.submitRecommendations.perform(this.partitions, closeModal);
      }

    },
    submitRecommendations: (0, _emberConcurrency.task)(function* (partitions, closeModal) {
      if (!partitions.length) {
        return;
      }

      let data = partitions.map(part => {
        return {
          GroupId: part.groupId,
          AmdPartitionId: part.partitionId,
          SeasonId: this.model.currentSeason.id,
          RecommendedCount: part.count,
          BiologistComment: part.note
        };
      });
      let adapter = this.store.adapterFor('amd-recommendation');

      try {
        yield adapter.postCollection({
          data
        });
      } catch (e) {
        this.notifications.addApiErrors(e);
        closeModal();
        return;
      } // Clear out custom inputed data after submit


      this.selected.forEach(mu => {
        let data = this.state.get(mu);

        if (!data) {
          return;
        }

        data.recommendations.clear();
      });
      const tallyAdapter = this.store.adapterFor('amd-tally');
      const [tallys, permits] = yield (0, _rsvp.all)([tallyAdapter.findAllForSeason({
        params: {
          seasonId: this.model.currentSeason.id
        }
      }), this.store.query('amd-permit-app', {
        'filter[countyid]': this.model.countyId,
        'filter[seasonid]': this.model.currentSeason.id,
        mushapes: true
      })]);
      this.set('model.permits', permits);
      this.set('model.tallys', tallys);
      closeModal();
      this.notifications.add('Submitted recommendations');
    }),
    lastSeasonsRecs: (0, _emberConcurrency.task)(function* () {
      let lastSeason = Number(this.model.currentSeason.id) - 1;
      const permits = yield this.store.query('amd-permit-app', {
        'filter[countyid]': this.model.county.id,
        'filter[seasonid]': lastSeason
      });

      if (!permits.length) {
        return this.notifications.add('No recommendations found for last season');
      } // let permit = permits.get('firstObject');


      yield (0, _rsvp.all)([permits.forEach(async permit => {
        if (!permit.amdPartitions.length) {
          return;
        }

        let adapter = this.store.adapterFor('amd-recommendation');
        let managementUnits = extractManagementUnits(permit);

        for (let muInfo of managementUnits) {
          let selected = this.selected.findBy('id', muInfo.id);

          if (!selected) {
            continue;
          }

          let data = this.state.get(selected);

          if (!data) {
            continue;
          }

          let {
            groupId
          } = await adapter.nextGroupId();
          let rec = data.addRecommendation(groupId, selected.partitions);
          muInfo.partitions.forEach(partition => {
            let total = 0;
            partition.amdRecommendations.forEach(rec => total += rec.recommendedCount);
            data.updatePartitionByCompartment(rec, partition.compartment, total);
          });
        }
      })]);
    }),
    calculatedRecs: (0, _emberConcurrency.task)(function* () {
      let tallys = this.model.tallys;
      yield (0, _rsvp.all)(this.selected.sort(sortByCalculatedAcreage).map(async item => {
        let data = this.state.get(item);

        if (!data) {
          return;
        }

        let adapter = this.store.adapterFor('amd-recommendation');
        let {
          groupId
        } = await adapter.nextGroupId();
        let rec = data.addRecommendation(groupId, item.partitions);
        item.partitions.forEach(partition => {
          let tally = tallys.findBy('compartment', partition.compartment);
          let remainingPermits = tally.totalPermits - tally.issuedPermits;
          let count = partition.calculatedCount > 1 ? Math.floor(partition.calculatedCount) : partition.calculatedCount;
          count = count < 1 && count > 0 ? 0 : count;

          if (remainingPermits >= count) {
            data.updatePartition(rec, partition.id, count);
          }
        });
      }));
    })
  });

  _exports.default = _default;

  function sortByCalculatedAcreage(a, b) {
    if (a.site.calculatedAcreage > b.site.calculatedAcreage) {
      return -1;
    } else if (a.site.calculatedAcreage < b.site.calculatedAcreage) {
      return 1;
    }

    return 0;
  }

  function extractManagementUnits(permit) {
    if (permit.primarySite.siteType === 'aggregate') {
      let aggregate = permit.primarySite;
      let footprints = [];
      permit.mUs.forEach(mu => {
        var _mu;

        mu = permit.siteXAmdPermitApps.find(site => site.siteId === mu);

        if ((_mu = mu) !== null && _mu !== void 0 && _mu.site && mu.site.shape) {
          if (!aggregate.geoJSON) {
            footprints.push(JSON.parse(mu.site.shape));
          }
        }
      });
      aggregate.geoJSON = _turf.default.featureCollection(footprints);
      return [{
        id: permit.primarySiteId,
        site: aggregate,
        partitions: permit.amdPartitions,
        permit
      }];
    }

    let managementUnits = permit.mUs.map(muId => {
      let partitions = permit.amdPartitions.reduce((partitions, partition) => {
        if (partition.siteId === muId) {
          partitions.push(partition);
        }

        return partitions;
      }, []);
      let siteX = permit.siteXAmdPermitApps.findBy('siteId', muId);
      let site = siteX === null || siteX === void 0 ? void 0 : siteX.site;

      try {
        if (site !== null && site !== void 0 && site.shape) {
          site.geoJSON = JSON.parse(site.shape);
        }
      } catch (e) {// noop
      }

      return {
        id: muId,
        site,
        partitions,
        permit
      };
    });
    return managementUnits;
  }
});