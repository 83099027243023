define("mldp/components/merge-attribute-preview/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qC/Rjz/0",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"field\",\"displayAttr\"]],[[[1,\"    \"],[1,[28,[35,1],[[30,0,[\"data\"]],[28,[37,2],[[30,0,[\"field\",\"attribute\"]],\".\",[30,0,[\"field\",\"displayAttr\"]]],null]],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,1],[[30,0,[\"data\"]],[30,0,[\"field\",\"attribute\"]]],null]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\"],false,[\"if\",\"get\",\"concat\"]]",
    "moduleName": "mldp/components/merge-attribute-preview/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});