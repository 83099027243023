define("mldp/components/permit/ph-reporting/change-property-confirmation-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "oVSszAe7",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"You indicated you need to make changes to your property map.\"],[13],[1,\" \\n\\n  \"],[10,2],[12],[1,\"Click Continue to visit your property information page where you can edit your map and other property details. You will need to start a new application once you have edited your property record.\"],[13],[1,\" \\n\\n  \"],[10,2],[12],[1,\"Click Back if you don’t need to edit your property.\"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/permit/ph-reporting/change-property-confirmation-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});