define("mldp/models/user", ["exports", "@ember-data/model", "@ember/object", "ember-cp-validations", "mldp/utils/instance-op"], function (_exports, _model, _object, _emberCpValidations, _instanceOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', true),
    lastName: (0, _emberCpValidations.validator)('presence', true),
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: (0, _object.computed)('model.{requirePassword,networkUserName}', function () {
        let rp = this.get('model.requirePassword');
        let nun = this.get('model.networkUserName');

        if (rp && nun) {
          return true;
        }

        return !rp;
      })
    }), (0, _emberCpValidations.validator)('length', {
      min: 8
    })],
    zipCode: [(0, _emberCpValidations.validator)('length', {
      min: 5,
      max: 5
    })],
    confirmPassword: [(0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: '{description} do not match',
      description: 'Passwords'
    })],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });

  var _default = _model.default.extend(Validations, {
    email: (0, _model.attr)('string'),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    networkUserName: (0, _model.attr)('string'),
    password: (0, _model.attr)('string'),
    confirmPassword: (0, _model.attr)('string'),
    middleName: (0, _model.attr)('string'),
    address1: (0, _model.attr)('string'),
    address2: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    zipCode: (0, _model.attr)('string'),
    primaryPhone: (0, _model.attr)('string'),
    secondaryPhone: (0, _model.attr)('string'),
    allowLogin: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    isSuperAdmin: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    supervisorId: (0, _model.attr)('number'),
    requirePassword: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    isTpwd: (0, _model.attr)('boolean'),
    isSuperAdmin: (0, _model.attr)('boolean'),
    roles: (0, _model.hasMany)('user-xrole'),
    counties: (0, _model.hasMany)('user-xcounty'),
    sites: (0, _model.hasMany)('user-xsite'),
    findCounties: (0, _instanceOp.default)({
      path: 'counties',
      type: 'get'
    }),

    /**
     * Assign sites associated to one biologist to another biologist.
     *
     * let numReassigned = await biologist.reassignSites({
     *   params: {
     *     toUserId: toBiologist.id
     *   },
     *   query: {
     *     countyId: county.id
     *   }
     * });
     */
    reassignBiologistSites: (0, _instanceOp.default)({
      path: 'bulkreassign/{toUserId}',
      type: 'post'
    }),

    /**
     * Merge another user into this one:
     *
     * intoUser.mergeUser({
     *   data: {
     *     data: {...}
     *   }
     * })
     */
    mergeUser: (0, _instanceOp.default)({
      path: 'formerge',
      type: 'patch',
      ajaxOptions: {
        headers: {
          'Content-Type': 'application/vnd.api+json'
        }
      }
    }),

    /**
     * Takes query: { mergeId }, where the mergeId is the user being deleted
     */
    finishMerge: (0, _instanceOp.default)({
      path: 'finishmerge',
      type: 'post'
      /*
      ajaxOptions: {
      headers: {
      'Content-Type': 'application/vnd.api+json'
      }
      }*/

    }),
    name: (0, _object.computed)('firstName', 'lastName', {
      get() {
        let first = this.firstName;
        let last = this.lastName;
        return `${first} ${last}`;
      }

    })
  });

  _exports.default = _default;
});