define("mldp/services/user-activity", ["exports", "ember-user-activity/services/user-activity"], function (_exports, _userActivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let _UserActivityService = classic(_class = class _UserActivityService extends _userActivity.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "EVENT_THROTTLE", 250);

      _defineProperty(this, "defaultEvents", ['keypress', 'mousedown', 'scroll']);
    }

  }) || _class;

  _exports.default = _UserActivityService;
});