define("mldp/mixins/can-invite-member", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    canInviteMember(siteId) {
      let ajax = this.ajaxService;
      return ajax.request(`/aggregate/${siteId}/caninvitemember/`, {
        contentType: 'application/json',
        dataType: 'json'
      });
    }

  });

  _exports.default = _default;
});