define("mldp/account/properties/index/route", ["exports", "@ember/service", "@ember/error", "@ember/routing/route", "@ember/runloop"], function (_exports, _service, _error, _route, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  const types = ['Property', 'CoOp', 'Aggregate'];
  const params = ['archived', 'county', 'resultsPerPage', 'enrollment', 'bioLname', 'bioFname', 'siteName', 'landFname', 'landLname', 'agentFname', 'agentLname', 'poolFname', 'poolLname', 'season', 'species', 'gender', 'type', 'shape', 'public', 'private', 'document', 'habitatproject'];
  const queryParams = {
    archived: {
      refreshModel: true
    },
    view: {
      refreshModel: false
    },
    page: {
      refreshModel: true
    },
    resultsPerPage: {
      refreshModel: true
    },
    type: {
      refreshModel: true
    },
    county: {
      refreshModel: true
    },
    enrollment: {
      refreshModel: true
    },
    bioFname: {
      refreshModel: true
    },
    bioLname: {
      refreshModel: true
    },
    siteName: {
      refreshModel: true
    },
    landFname: {
      refreshModel: true
    },
    landLname: {
      refreshModel: true
    },
    agentFname: {
      refreshModel: true
    },
    agentLname: {
      refreshModel: true
    },
    poolFname: {
      refreshModel: true
    },
    poolLname: {
      refreshModel: true
    },
    season: {
      refreshModel: true
    },
    species: {
      refreshModel: true
    },
    gender: {
      refreshModel: true
    },
    shape: {
      refreshModel: true
    },
    public: {
      refreshModel: true
    },
    private: {
      refreshModel: true
    },
    document: {
      refreshModel: true
    },
    habitatproject: {
      refreshModel: true
    }
  };
  let IndexRoute = (_dec = (0, _service.inject)('state'), _dec2 = (0, _service.inject)('session'), _dec3 = (0, _service.inject)('role-service'), _dec4 = (0, _service.inject)('notifications'), classic(_class = (_class2 = class IndexRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "roleService", _descriptor3, this);

      _initializerDefineProperty(this, "notificationService", _descriptor4, this);

      _defineProperty(this, "queryParams", queryParams);
    }

    async model() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let page = opts.page;
      let resultsPerPage = opts.resultsPerPage;
      let options = {
        'page[number]': page,
        'page[size]': resultsPerPage,
        reload: true
      };

      for (let param of params) {
        if (opts[param]) {
          if (param === 'archived' || param === 'shape') {
            // Archived mode only works on properties
            options[param] = opts[param];
            options['filter[type]'] = 'Property';
          } else {
            options[`filter[${param}]`] = opts[param];
          }
        }
      }

      return this.store.query('site', options).then(sites => {
        return {
          bioFname: opts.bioFname,
          bioLname: opts.bioLname,
          sites,
          meta: sites.meta
        };
      }).catch(error => {
        if (error.errors[0].detail === 'Shape area exceeds maximum allowed') {
          this.controller.set('shape', null);
          (0, _runloop.scheduleOnce)('afterRender', this, () => {
            this.notificationService.error('Shape area exceeds maximum allowed', 'Error');
          });
          this.refresh();
        } else {
          throw new _error.default(error);
        }
      });
    }

    setupController(controller, _ref) {
      let {
        sites,
        meta,
        bioFname,
        bioLname
      } = _ref;
      let roleService = this.roleService;
      let isTpwdUser = roleService.isUserInTpwd();
      let biologists = this.get('state.biologists');
      let currentUserEmail = this.get('session.email');
      let currentBiologist = biologists.findBy('email', currentUserEmail);
      let sorted = sites.toArray().sortBy('siteName').filter(item => item.get('dirtyType') !== 'deleted');
      controller.setProperties({
        sites: sorted,
        paging: meta.paging,
        filteredCount: meta.results.filteredCount,
        resultsPerPage: meta.paging.pageSize,
        searching: false,
        firstLoad: false,
        bioFname,
        bioLname,
        types,
        biologists,
        currentBiologist,
        isTpwdUser
      });
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('firstLoad', true);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "roleService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "notificationService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = IndexRoute;
});