define("mldp/components/search/tag/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ngUczkcT",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[6,[39,0],[[30,2]],[[\"submit\",\"update\"],[[28,[37,1],[[30,0],[30,3]],null],[28,[37,1],[[30,0],\"updateProperty\"],null]]],[[\"default\"],[[[[1,\"    \"],[1,[28,[30,4,[\"text-field\"]],[\"tagId\"],[[\"placeholder\",\"pattern\",\"maxlength\",\"label\",\"optionTargetPath\",\"optionLabelPath\"],[\"Enter a tag number\",\"[0-9]\",7,\"Tag Id\",\"value\",\"key\"]]]],[1,\"\\n\"],[41,[30,0,[\"invalidMessage\"]],[[[1,\"      \"],[10,1],[14,0,\"text-warning\"],[12],[1,[30,0,[\"invalidMessage\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"tagUrl\"]],[[[1,\"      \"],[10,\"iframe\"],[15,\"src\",[30,0,[\"tagUrl\"]]],[14,0,\"tag-search-iframe\"],[14,\"scrolling\",\"no\"],[12],[13],[1,\"\\n\"]],[]],null]],[4]]]]],[13]],[\"&attrs\",\"@data\",\"@onsubmit\",\"f\"],false,[\"form-for\",\"action\",\"if\"]]",
    "moduleName": "mldp/components/search/tag/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});