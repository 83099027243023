define("mldp/login/route", ["exports", "@ember/service", "jquery", "@ember/routing/route", "@ember/string", "@ember/object", "rsvp", "mldp/config/environment", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _service, _jquery, _route, _string, _object, _rsvp, _environment, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let LoginRoute = (_dec = (0, _service.inject)('session'), _dec2 = (0, _service.inject)('notifications'), _dec3 = (0, _service.inject)('state'), classic(_class = (_class2 = class LoginRoute extends _route.default.extend(_unauthenticatedRouteMixin.default) {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "state", _descriptor3, this);

      _defineProperty(this, "queryParams", {
        token: {},
        error: {
          refreshModel: true
        }
      });
    }

    beforeModel() {
      let session = this.session;

      if (session !== null && session !== void 0 && session.isAuthenticated) {
        return this.transitionTo('account');
      }

      let token = this.paramsFor(this.routeName).token;

      if (!token) {
        return;
      }

      return session.authenticate('authenticator:jwt', {
        identification: null,
        password: null
      }, {
        'entra-token': token
      }).then(() => {
        let adapter = this.store.adapterFor('user');
        return adapter.getAccountProfile().catch(() => _rsvp.default.resolve());
      }).then(profile => {
        this.set('session.profile', profile);
        return this.transitionTo('account');
      }).catch(e => {
        var _e$errors;

        let errorMessage;

        if ((_e$errors = e.errors) !== null && _e$errors !== void 0 && _e$errors.length) {
          errorMessage = e.errors[0].detail;
        } else if ((0, _object.get)(e, 'json.errors.firstObject.detail')) {
          errorMessage = (0, _object.get)(e, 'json.errors.firstObject.detail');
        } else {
          errorMessage = 'Unknown Error';
        }

        return this.transitionTo('/login').then(() => this.notifications.error(errorMessage));
      });
    }

    model(params) {
      return {
        email: null,
        password: null,
        error: params.error
      };
    }

    setupController(controller, model) {
      var _ENV$adfsUrl;

      controller.set('error', null);

      if (controller.msg) {
        this.notifications.error(controller.msg);
      }

      if (model.error && model.error.length) {
        if (model.error === 'invalid') {
          controller.set('msg', 'Invalid authentication received');
        }

        if (model.error === 'unknown') {
          controller.set('msg', 'Unknown TPWD user');
        }

        this.transitionTo('login', {
          queryParams: {
            error: ''
          }
        });
      }

      controller.set('adfsUrl', _environment.default.adfsUrl);
      controller.set('model', model);
      controller.set('showSecondADFS', _environment.default.environment === 'development' || _environment.default.environment === 'alpha');
      controller.set('secondADFS', (_ENV$adfsUrl = _environment.default.adfsUrl) === null || _ENV$adfsUrl === void 0 ? void 0 : _ENV$adfsUrl.replace(/TPWD/gi, 'AppGeo'));
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('msg', null);
      }
    } // TODO: don't handle DOM in the route, use a component


    activate() {
      let cssClass = this.toCssClass(); // you probably don't need the application class
      // to be added to the body

      if (cssClass !== 'application') {
        (0, _jquery.default)('body').addClass(cssClass);
      }
    }

    deactivate() {
      (0, _jquery.default)('body').removeClass(this.toCssClass());
    }

    authenticate(model) {
      let session = this.session;
      let notifications = this.notifications;
      return session.authenticate('authenticator:jwt', {
        Username: model.email,
        password: model.password
      }).then(() => {
        let adapter = this.store.adapterFor('user');
        return adapter.getAccountProfile().catch(() => _rsvp.default.resolve());
      }).then(profile => {
        this.set('session.profile', profile);
      }).catch(e => {
        if (!e) {
          return notifications.error('Server authentication error occurred');
        }

        let firstError = (0, _object.get)(e, 'json.errors.firstObject');

        if (!firstError) {
          notifications.error('Unknown error');
          return;
        }

        if (firstError.title === 'ExpiredPassword') {
          this.state.set('expiredPasswordCode', e.json.errors[1].detail);
          this.state.set('expiredEmail', model.email);
          return this.transitionTo('expired-password').then(() => notifications.error(firstError.detail));
        } else if (firstError.title === 'TwimsImportedUser') {
          return this.transitionTo('imported-user');
        } else {
          notifications.error(firstError.detail);
        }
      });
    }

    toCssClass() {
      return (0, _string.dasherize)(this.routeName.replace(/\./g, '-'));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "authenticate", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "authenticate"), _class2.prototype)), _class2)) || _class);
  _exports.default = LoginRoute;
});