define("mldp/components/property-accept-pool-invite/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/component", "ember-concurrency"], function (_exports, _component, _object, _service, _component2, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PropertyAcceptPoolInvite = (_dec = (0, _component.tagName)(''), _dec2 = (0, _service.inject)('store'), _dec3 = (0, _service.inject)('notifications'), _dec4 = (0, _emberConcurrency.task)(function* () {
    let store = this.store;
    let properties = yield store.findAll('property');
    this.set('properties', properties);
  }), _dec(_class = classic(_class = (_class2 = class PropertyAcceptPoolInvite extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "loadPropertiesTask", _descriptor3, this);
    }

    init() {
      super.init(...arguments);
      this.selectedManagementUnits = [];
      this.loadPropertiesTask.perform();
    }

    loadUnits(property, open) {
      property.set('open', open); // don't refetch

      if (property.set('unitsPromise')) {
        return property.set('unitsPromise');
      }

      let propertyId = property.id;
      let store = this.store;
      let adapter = store.adapterFor('property');
      let unitsPromise = adapter.getManagementUnits(propertyId);
      unitsPromise.then(units => {
        property.set('units', units);
      });
      property.set('unitsPromise', unitsPromise);
    }

    complete() {
      let data = this.model;
      let notifications = this.notifications;
      let selectedManagementUnits = this.selectedManagementUnits;
      let managementUnitIds = selectedManagementUnits.map(item => Number(item.id));
      let poolType = data.get('poolType');

      if (!managementUnitIds.length) {
        notifications.error('You must select at least one management unit');
        return;
      }

      return this.onComplete({
        guid: data.get('actionGuid'),
        message: `You have added your property to the ${poolType}`,
        type: 'PropertyAcceptPoolInvite',
        data: {
          managementUnitIds
        }
      });
    }

    addUnit(unit, site) {
      let notifications = this.notifications;

      if (site.get('agreementDate') || site.get('isPublicLand') === true) {
        this.selectedManagementUnits.pushObject(unit);
      } else {
        notifications.add('You must first accept the agreement on this property before adding this unit');
      }
    }

    removeUnit(unit) {
      this.selectedManagementUnits.removeObject(unit);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "loadUnits", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "loadUnits"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "complete", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "complete"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addUnit", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "addUnit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeUnit", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "removeUnit"), _class2.prototype), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "loadPropertiesTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = PropertyAcceptPoolInvite;
});