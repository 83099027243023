define("mldp/account/property/unit/edit/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "rsvp", "@turf/turf"], function (_exports, _object, _service, _route, _rsvp, _turf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EditRoute = (_dec = (0, _service.inject)('notifications'), classic(_class = (_class2 = class EditRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);
    }

    async model() {
      let {
        type,
        siteId
      } = this.paramsFor('account.property');
      let adapter = this.store.adapterFor(type);
      let unit = this.modelFor('account.property.unit');
      let intialGeoJson = unit.geoJson ? Object.assign({}, unit.geoJSON) : undefined;
      return _rsvp.default.hash({
        unit,
        intialGeoJson,
        units: adapter.getManagementUnits(siteId),
        property: this.modelFor('account.property'),
        fenceTypes: this.store.findAll('tlkFencetype')
      });
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }

    resetController(controller) {
      let unit = controller.unit;
      unit.rollbackAttributes();
    }

    checkGeoJsonChanges(unit) {
      let initial = this.currentModel.intialGeoJson;
      let noChanges = false;

      if (initial) {
        noChanges = _turf.default.booleanEqual(initial, unit.geoJSON);
      }

      if (noChanges) {
        return this.send('save', unit);
      }

      return this.controller.set('boundaryChange', true);
    }

    save(unit) {
      return unit.validate().then(() => {
        if (unit.get('validations.isValid')) {
          return unit.save();
        }

        return _rsvp.default.reject('Validations invalid');
      }).then(() => {
        let id = this.controller.get('property.property.id');
        let siteType = this.controller.get('property.property.siteType');
        return this.transitionTo('account.property', siteType, id);
      }).catch(e => {
        let notifications = this.notifications;

        if (e === 'Validations invalid') {
          return _rsvp.default.reject(notifications.addValidationErrors(unit));
        } else {
          return _rsvp.default.reject(notifications.addApiErrors(e));
        }
      });
    }

    reset(unit) {
      unit.rollbackAttributes();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "checkGeoJsonChanges", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "checkGeoJsonChanges"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "reset"), _class2.prototype)), _class2)) || _class);
  _exports.default = EditRoute;
});