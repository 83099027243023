define("mldp/components/notices/property-created-by-agent/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "biBbRCAn",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    An invitation has been sent to the landowner, who must log in and sign the Request for Technical Assistance form to complete the property registration process.\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/notices/property-created-by-agent/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});