define("mldp/components/enrollment/enroll-aggregate/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/component", "rsvp", "@turf/turf", "ember-concurrency-decorators"], function (_exports, _component, _object, _service, _component2, _rsvp, _turf, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EnrollAggregate = (_dec = (0, _component.tagName)(""), _dec2 = (0, _service.inject)('ajax'), _dec3 = (0, _service.inject)('notifications'), _dec4 = (0, _object.computed)('genders', 'enrollment.enrollmentGenderId'), _dec5 = (0, _object.computed)('units'), _dec(_class = classic(_class = (_class2 = class EnrollAggregate extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);
    }

    get gender() {
      let genders = this.genders;
      let id = this.get('enrollment.enrollmentGenderId');

      if (genders && id) {
        return genders.findBy('id', id);
      }
    }

    get unitsUnion() {
      let units = this.units;
      let features = units.mapBy('geoJSON').filter(geojson => !!geojson).map(geojson => _turf.default.feature(geojson));

      if (!features || !features.length) {
        return;
      }

      let union = _turf.default.union(...features);

      return union;
    }

    init() {
      super.init(...arguments);
      this.selectedUnits = this.selectedUnits || [];
      let enrollment = this.enrollment;
      let typeId = enrollment.get('enrollmentTypeId'); // for coming back to edit so select is displayed

      if (typeId) {
        typeId = String(typeId);
        this.updateSpeciesForType(typeId);
        this.updateGendersForType(typeId);
      }

      this.generateTags().then(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        this.autoAddUnits();
      });
    }

    changeEnrollmentType(typeId) {
      let enrollment = this.enrollment;
      enrollment.set('enrollmentTypeId', typeId);
      this.updateSpeciesForType(typeId);
      this.updateGendersForType(typeId);
      this.set('enrollment.enrollmentGenderId', undefined);
      this.set('enrollment.enrollmentGender', undefined);
      this.selectedUnits.forEach(unit => {
        this.selectedUnits.removeObject(unit);
      });
      this.generateTags().then(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        this.autoAddUnits();
      });
    }

    changeGender(genderId) {
      this.set('enrollment.enrollmentGenderId', genderId);
      this.generateTags().then(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        this.autoAddUnits();
      });
    }

    changeSpecies(speciesId) {
      this.set('enrollment.speciesId', speciesId);
      this.set('blockSubmit', true);
      return this.loadEligibility().then(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        return this.autoAddUnits();
      }).then(() => this.set('blockSubmit', false)).catch(e => {
        this.set('blockSubmit', false);
        console.log(e);
        return _rsvp.default.reject(e);
      });
    }

    *submitSetup(enrollment, selectedUnits) {
      let notifications = this.notifications;
      let selectedMus = selectedUnits.mapBy('id').map(mUs => parseInt(mUs));
      let preExistingMus = enrollment.mUs || [];
      let numSelected = selectedMus.length;
      let numUnits = this.get('units.length');

      if (numUnits !== numSelected) {
        throw notifications.error(`- There are ${numUnits} units, but only ${numSelected} were added`, {
          title: 'Validation Issue'
        });
      }

      enrollment.setProperties({
        mUsToAdd: selectedMus,
        mUs: [...selectedMus, ...preExistingMus]
      });

      try {
        yield enrollment.validate();

        if (!enrollment.get('validations.isValid')) {
          throw 'Validations invalid';
        }

        (0, _object.set)(this, 'showAgreement', true);
      } catch (e) {
        yield this.resetUnits();

        if (e === 'Validations invalid') {
          throw notifications.addValidationErrors(enrollment);
        } else {
          throw notifications.addApiErrors(e);
        }
      }
    }

    *submitEnrollment() {
      try {
        yield this.onSubmit(this.enrollment);
        yield this.hideAgreement();
        yield this.updateFeeInvoices();
        yield this.fetchEstimate();
        (0, _object.set)(this, 'showEnrollmentConfirmation', true);
      } catch (e) {
        yield this.resetUnits();
        yield this.hideAgreement();
      }
    }

    async fetchEstimate() {
      let adapter = this.store.adapterFor('enrollment');
      let {
        fee
      } = await adapter.getFeeEstimate(this.enrollment);

      if (fee === 0) {
        this.feeEstimate = 'Not Applicable';
      } else {
        this.feeEstimate = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(fee);
      }
    }

    resetUnits() {
      let mUsToRemove = this.enrollment.mUsToAdd;
      this.enrollment.setProperties({
        mUs: this.enrollment.mUs.filter(mU => !mUsToRemove.includes(mU)),
        mUsToAdd: []
      });
    }

    hideAgreement() {
      this.set('showAgreement', false);
      return _rsvp.default.resolve();
    }

    async loadEligibility() {
      let site = this.site;
      let speciesId = this.get('enrollment.speciesId');
      let eligibility = await site.enrollmentEligibility({
        query: {
          species: speciesId
        }
      });

      if (!this.isDestroyed || !this.isDestroying) {
        this.set('eligibility', eligibility);
      }
    }

    generateTags() {
      let isHarvest = this.get('enrollment.isHarvest');
      let gender = this.gender;
      let unitsUnion = this.unitsUnion;

      if (isHarvest && gender && unitsUnion) {
        this.set('loadingTags', true);
        return this.tagEstimate(unitsUnion).then(res => {
          this.setProperties({
            loadingTags: false,
            showTags: true,
            tags: res,
            canEnroll: res.totalTags !== 0
          });
        });
      }

      this.setProperties({
        loadingTags: false,
        showTags: false,
        canEnroll: true
      });
      return _rsvp.default.resolve();
    }

    updateSpeciesForType(typeId) {
      let enrollment = this.enrollment;
      let species = this.species;
      let whiteTailedSeasonOpen = this.whiteTailedSeasonOpen;
      let muleSeasonOpen = this.muleSeasonOpen;
      let muleId = this.get('enrollment.muleId');
      let whiteTailedId = this.get('enrollment.whiteTailedId');
      let bothId = '3';

      if (typeId === enrollment.get('conservationId')) {
        let filteredSpecies = species.filter(item => {
          switch (item.id) {
            case muleId:
              {
                return muleSeasonOpen;
              }

            case whiteTailedId:
              {
                return whiteTailedSeasonOpen;
              }

            case bothId:
              {
                return whiteTailedSeasonOpen && muleSeasonOpen;
              }

            default:
              {
                return false;
              }
          }
        });
        this.set('filteredSpecies', filteredSpecies);
      } else if (typeId === enrollment.get('harvestId')) {
        this.set('enrollment.speciesId', whiteTailedId);
      }
    }

    updateGendersForType(typeId) {
      let genders = this.genders;
      let conservationId = this.get('enrollment.conservationId');

      if (typeId === conservationId) {
        genders = genders.reject(item => item.get('displayValue') === 'Buck Only');
      }

      this.set('filteredGenders', genders);
    }

    autoAddUnits() {
      let enrollment = this.enrollment;
      let eligibility = this.eligibility;
      let isEligible = enrollment.isEligible(eligibility);
      let hasTags = this.get('tags.totalTags') !== 0;

      if (hasTags && isEligible) {
        let units = this.units;
        let eligibilities = eligibility.children;
        let unitsEligible = units.every(unit => {
          let eligibility = eligibilities.findBy('siteId', unit.get('identifier'));
          let isEligible = enrollment.isEligible(eligibility);
          return isEligible;
        });
        this.set('selectedUnits', unitsEligible ? units.slice() : []);
      } else {
        this.set('selectedUnits', []);
      }
    }

    tagEstimate(geojson) {
      let ajax = this.ajax;
      const url = '/TagEstimator/Calculate';
      const data = {
        HighFence: false,
        GeoJson: JSON.stringify(geojson.geometry)
      };
      return ajax.post(url, {
        data: JSON.stringify(data),
        dataType: 'json',
        contentType: 'application/json'
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "gender", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "gender"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "unitsUnion", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "unitsUnion"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeEnrollmentType", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "changeEnrollmentType"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeGender", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "changeGender"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeSpecies", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "changeSpecies"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "submitSetup", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "submitSetup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "submitEnrollment", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "submitEnrollment"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "fetchEstimate", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "fetchEstimate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resetUnits", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "resetUnits"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hideAgreement", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "hideAgreement"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = EnrollAggregate;
});