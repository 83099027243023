define("mldp/components/practice/subtype-select/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/GNltLla",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[1,[28,[33,1,[\"select-field\"]],[\"habitatPracticeSubTypeId\"],[[\"options\",\"label\",\"optionTargetPath\",\"optionLabelPath\",\"promptIsSelectable\",\"prompt\"],[[30,0,[\"subtypes\"]],\"Practice Type\",\"identifier\",\"displayValue\",true,\"Please select one:\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\"],false,[\"if\",\"f\"]]",
    "moduleName": "mldp/components/practice/subtype-select/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});