define("mldp/components/guidance/inactive/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JcWGVY0W",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  Looks like you've been inactive for a bit. You'll be logged out automatically in 30 seconds. Close this window if you're not done yet!\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/guidance/inactive/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});