define("mldp/loading/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ds1p/vOD",
    "block": "[[[1,[28,[35,0],[\"Loading..\"],null]],[1,\"\\n\\n\"],[10,\"h3\"],[14,0,\"loading-container\"],[12],[1,\"\\n  \"],[10,\"i\"],[14,0,\"material-icons text-muted spin\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"loop\"],[13],[1,\"\\n  Loading..\\n\"],[13]],[],false,[\"page-banner\"]]",
    "moduleName": "mldp/loading/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});