define("mldp/account/property/enrollment/edit/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "rsvp", "@ember/application"], function (_exports, _object, _service, _route, _rsvp, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EditRoute = (_dec = (0, _service.inject)('notifications'), classic(_class = (_class2 = class EditRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);
    }

    model() {
      let {
        type,
        siteId
      } = this.paramsFor('account.property');
      let {
        property: site
      } = this.modelFor('account.property');
      let enrollment = this.modelFor('account.property.enrollment');
      let adapter = this.store.adapterFor(type.toLowerCase());
      return _rsvp.default.hash({
        enrollment,
        site,
        types: this.store.findAll('tlk-enrollment-type'),
        genders: this.store.findAll('tlk-enrollment-gender'),
        species: this.store.findAll('tlk-specie'),
        units: adapter.getManagementUnits(siteId),
        eligibility: site.enrollmentEligibility({
          query: {
            species: 0
          }
        })
      });
    }

    setupController(controller, resolved) {
      super.setupController(controller, resolved);
      resolved.enrollment.set('isCoop', resolved.site.get('siteType') === 'coop');
      controller.setProperties(resolved);
    }

    refreshModel() {
      this.refresh();
    }

    async update(enrollment) {
      let notifications = this.notifications;
      const adapter = this.store.adapterFor('enrollment');

      try {
        await adapter.addMus(enrollment);
        await notifications.add('Updated the enrollment successfully');
      } catch (error) {
        notifications.addApiErrors(error);
        throw new Error(error);
      }
    }

    openDeactivateConfirmation(unit) {
      return new _rsvp.default.Promise((resolve, reject) => {
        this.setProperties({
          deactivatePromiseControls: {
            resolve,
            reject
          },
          'controller.unitToDeactivate': unit
        });
      });
    }

    deactivateUnit(unit) {
      let deactivatePromiseControls = this.deactivatePromiseControls;
      return unit.changePoolStatus({
        data: {
          isActive: false,
          poolStatusNotes: 'Deactivated from enrollment'
        }
      }).then(() => {
        this.currentModel.units.removeObject(unit);
        this.controller.set('unitToDeactivate', false);
        unit.set('poolActive', false);
        return deactivatePromiseControls.resolve();
      }).catch(err => deactivatePromiseControls.reject(err));
    }

    confirmEnrollment() {
      let {
        type,
        siteId
      } = this.paramsFor('account.property');
      this.transitionTo('account.property', type, siteId);
    }

    updateFeeInvoices() {
      const applicationRouteInstance = (0, _application.getOwner)(this).lookup('route:application');
      applicationRouteInstance.fetchFeeInvoices();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "refreshModel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "refreshModel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "update"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "openDeactivateConfirmation", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "openDeactivateConfirmation"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deactivateUnit", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "deactivateUnit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "confirmEnrollment", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "confirmEnrollment"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateFeeInvoices", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateFeeInvoices"), _class2.prototype)), _class2)) || _class);
  _exports.default = EditRoute;
});