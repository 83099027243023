define("mldp/utils/string-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = template;
  // from https://github.com/Matt-Esch/string-template
  const nargs = /\{([0-9a-zA-Z_]+)\}/g;

  function template(string) {
    var args;

    if (arguments.length === 2 && typeof arguments[1] === 'object') {
      args = arguments[1];
    } else {
      args = new Array(arguments.length - 1);

      for (var i = 1; i < arguments.length; ++i) {
        args[i - 1] = arguments[i];
      }
    }

    if (!args || !args.hasOwnProperty) {
      args = {};
    }

    return string.replace(nargs, function replaceArg(match, i, index) {
      if (string[index - 1] === '{' && string[index + match.length] === '}') {
        return i;
      } else {
        let result = args.hasOwnProperty(i) ? args[i] : null;

        if (!result) {
          return '';
        }

        return result;
      }
    });
  }
});