define("mldp/components/reporting-wizard/unit/component", ["exports", "@ember/object", "@ember-decorators/component", "@ember/service", "@ember/component", "ember-concurrency"], function (_exports, _object, _component, _service, _component2, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Unit = (_dec = (0, _component.tagName)(''), _dec2 = (0, _service.inject)('state'), _dec3 = (0, _service.inject)('store'), _dec4 = (0, _service.inject)('router'), _dec5 = (0, _emberConcurrency.task)(function* () {
    // Only load sites on open of the reporting wizard
    // since `@sites` is undefined and set later via `@onSitesLoaded`.
    let tasks = [];
    tasks.push(this.fetchHarvests.perform());

    if (this.permitApps.length) {
      tasks.push(this.fetchPronhornReportingStatus.perform());
    }

    if (this.unit) {
      tasks.push(this.fetchHabitatPractices.perform());
    }

    yield (0, _emberConcurrency.all)(tasks);
    this.set('loading', false);
  }), _dec6 = (0, _emberConcurrency.task)(function* () {
    let mu = yield this.storeService.findRecord('management-unit', this.unit.id);
    let harvests = yield mu.findHarvests();
    let currentSeason = yield this.stateService.get('currentSeason');
    let filteredHarvests = harvests.filter(harvest => harvest.deerSeasonId === currentSeason.identifier);
    this.set('filteredHarvests', filteredHarvests);
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    //check if unit is included in any permit apps
    let permitApp = this.permitApps.find(app => app.siteXPhPermitApps.find(site => Number(site.siteId) === Number(this.unit.id)));

    if (permitApp) {
      this.set('permitApp', permitApp); //insufficent acreage

      if (permitApp.applicationStatusId === 3) {
        this.set('phReportingStatus', 'N/A');
      } else {
        let reporting = yield permitApp.fetchReporting();
        let submittedReports = reporting.harvest.filter(report => report.submitted);
        this.set('phReportingStatus', submittedReports.length ? 'Yes' : 'No');
      }
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    let practices = yield this.unit.findPractices();
    let seasonAdapter = this.storeService.adapterFor('tlk-deer-season');
    let currentSeason = yield seasonAdapter.findCurrent();
    practices = practices.filter(cur => Number(cur.deerSeasonId) === Number(currentSeason.id));
    this.set('practices', practices);
  }), classic(_class = _dec(_class = (_class2 = class Unit extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stateService", _descriptor, this);

      _initializerDefineProperty(this, "storeService", _descriptor2, this);

      _initializerDefineProperty(this, "routerService", _descriptor3, this);

      _defineProperty(this, "loading", true);

      _initializerDefineProperty(this, "componentSetup", _descriptor4, this);

      _initializerDefineProperty(this, "fetchHarvests", _descriptor5, this);

      _initializerDefineProperty(this, "fetchPronhornReportingStatus", _descriptor6, this);

      _initializerDefineProperty(this, "fetchHabitatPractices", _descriptor7, this);
    }

    init() {
      super.init(...arguments);
      this.componentSetup.perform();
    }

    openHarvest(site, unit) {
      // transition to route based on site type
      const siteType = site.get('siteType');
      let {
        filteredHarvests: harvests
      } = this;

      if (!harvests.length) {
        this.routerService.transitionTo('account.property.unit.harvests.create', siteType, site.id, unit.id);
      } else if (harvests.length === 1) {
        let [harvest] = harvests;
        this.routerService.transitionTo('account.property.unit.harvest.edit', siteType, site.id, unit.id, harvest.id);
      } //close modal


      this.onReportOpen();
    }

    checkPronghorn() {
      const {
        permitApp
      } = this;

      if (!permitApp) {
        return this.onProngHornIneligible();
      } // check for any recommendations greater than zero where owner accepted


      let hasNonZeroRecs = permitApp.phPartitions.some(part => part.phRecommendations.some(rec => rec.ownerAccepted && rec.recommendedBucks > 0)); // check if application status is 'approved'(2) or submitted (1)

      let approvedOrSubmitted = permitApp.applicationStatusId === 2 || permitApp.applicationStatusId === 1;

      if (!approvedOrSubmitted || !hasNonZeroRecs) {
        this.onProngHornIneligible();
      } else {
        this.routerService.transitionTo('account.property.permit.reporting.harvest', permitApp.primarySite.siteType, permitApp.primarySite.id, 'ph', permitApp.id);
        this.onReportOpen();
      }
    }

    openHabitat(site, unit) {
      // transition to route based on site type
      const siteType = site.get('siteType');
      this.routerService.transitionTo('account.property.unit.practices.create', siteType, site.id, unit.id); //close modal

      this.onReportOpen();
    }

    openReportingStatus(site) {
      // transition to route based on site type and enrollment
      const siteType = site.get('siteType');
      this.routerService.transitionTo('account.property.enrollment.reporting-status', siteType, site.id, this.currentSeasonEnrollment.id); //close modal

      this.onReportOpen();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "storeService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "routerService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "componentSetup", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "fetchHarvests", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "fetchPronhornReportingStatus", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "fetchHabitatPractices", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "openHarvest", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "openHarvest"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "checkPronghorn", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "checkPronghorn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "openHabitat", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "openHabitat"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "openReportingStatus", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "openReportingStatus"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = Unit;
});