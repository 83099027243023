define("mldp/components/guidance/creating-sites/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "algRVi9z",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n  Click to add a property, or select from drop-down to add a co-op or aggregate.\\n  \"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[1,\"\\n  Complete the property information and delineate your property on the next screen.\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "mldp/components/guidance/creating-sites/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});