define("mldp/components/verify-landowner/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4NehdZ3C",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"Verify Landowner\"],null]],[1,\"\\n\\n  \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n    \"],[10,\"header\"],[14,0,\"agreement-header\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,\"Property '\"],[1,[30,2,[\"property\",\"siteName\"]]],[1,\"'\"],[13],[1,\"\\n      \"],[10,2],[12],[1,\"County: \"],[1,[30,2,[\"property\",\"countyName\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[1,[28,[35,1],null,[[\"site\"],[[30,2,[\"property\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,2],null,[[\"default\",\"pending\",\"class\",\"action\"],[\"I Agree\",\"Saving...\",\"btn btn-primary\",[28,[37,3],[[30,0],\"complete\"],null]]]]],[1,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-secondary\"],[14,\"aria-label\",\"Decline\"],[15,\"onclick\",[28,[37,3],[[30,0],\"decline\"],null]],[14,4,\"button\"],[12],[1,\"\\n      Decline\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@model\"],false,[\"page-banner\",\"rta-agreement\",\"async-button\",\"action\"]]",
    "moduleName": "mldp/components/verify-landowner/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});