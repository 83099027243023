define("mldp/account/outreach/edit/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "mldp/utils/update-intersections"], function (_exports, _object, _service, _route, _updateIntersections) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EditRoute = (_dec = (0, _service.inject)('state'), _dec2 = (0, _service.inject)('notifications'), classic(_class = (_class2 = class EditRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stateService", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);
    }

    model() {
      let hash = this.modelFor('account.outreach');
      return hash;
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }

    async update(otherStaff, outreach) {
      let notifications = this.notifications;

      try {
        await outreach.save();
        let {
          toAdd,
          toRemove
        } = (0, _updateIntersections.default)({
          updated: otherStaff,
          updatedKey: 'id',
          current: outreach.get('otherStaff'),
          currentKey: 'userId',
          createRecord: user => {
            return this.store.createRecord('user-xoutreach', {
              outreachId: outreach.id,
              userId: user.id
            });
          }
        }); // Must do add/remove serially so that the dotnet framework doesn't butcher the ids

        if (toRemove) {
          for (let item of toRemove) {
            await item.destroyRecord();
          }
        }

        if (toAdd) {
          for (let item of toAdd) {
            await item.save();
          }
        }

        await this.transitionTo('account.outreach', outreach.id);
        notifications.add('Updated the outreach successfully');
      } catch (err) {
        notifications.addApiErrors(err);
      }
    }

    cancel() {
      if (window.confirm('Are you sure?')) {
        this.transitionTo('account.outreaches');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "update"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "cancel"), _class2.prototype)), _class2)) || _class);
  _exports.default = EditRoute;
});