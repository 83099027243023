define("mldp/account/property/enrollment/index/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "q170bFtU",
    "block": "[[[1,[28,[35,0],[\"Viewing Enrollment\"],null]],[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"  \"],[10,\"h3\"],[14,0,\"loading-container\"],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"material-icons text-muted spin\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"loop\"],[13],[1,\"\\n    Approving Enrollment, please wait..\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[46,[28,[37,3],[\"site-\",[30,0,[\"site\",\"siteType\"]],\"-card\"],null],null,[[\"site\"],[[30,0,[\"site\"]]]],null],[1,\"\\n\\n  \"],[1,[28,[35,4],null,[[\"enrollment\",\"site\",\"units\",\"types\",\"genders\",\"species\",\"isCurrentSeason\",\"indirectEnrollment\",\"onCancel\",\"onApprove\",\"onReject\"],[[30,0,[\"enrollment\"]],[30,0,[\"site\"]],[30,0,[\"units\"]],[30,0,[\"types\"]],[30,0,[\"genders\"]],[30,0,[\"species\"]],[28,[37,5],[[30,0,[\"currentSeason\",\"identifier\"]],[30,0,[\"enrollment\",\"deerSeason\",\"id\"]]],null],[30,0,[\"indirectEnrollment\"]],[28,[37,6],[[30,0],[28,[37,7],[[30,0,[\"showCancelationConfirmation\"]]],null],true],null],[28,[37,6],[[30,0],\"approve\"],null],[28,[37,8],[\"reject\"],null]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"showCancelationConfirmation\"]],[[[1,\"    \"],[8,[39,9],null,[[\"@named\",\"@send\"],[\"modal\",[50,\"modal-window\",0,null,[[\"title\",\"submitLabel\",\"closeLabel\",\"body\",\"onsubmit\",\"close\"],[\"Are you sure?\",\"YES\",\"NO\",[50,\"general-value\",0,null,[[\"value\"],[[30,0,[\"cancelationConfirmationText\"]]]]],[28,[37,8],[\"cancel\",[30,0,[\"enrollment\"]]],null],[28,[37,6],[[30,0],[28,[37,7],[[30,0,[\"showCancelationConfirmation\"]]],null],false],null]]]]]],null],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"page-banner\",\"if\",\"component\",\"concat\",\"enrollment/enrolled-details\",\"eq\",\"action\",\"mut\",\"route-action\",\"to-elsewhere\"]]",
    "moduleName": "mldp/account/property/enrollment/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});