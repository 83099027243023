define("mldp/components/site/document-upload/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-concurrency-decorators"], function (_exports, _component, _tracking, _object, _service, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // const twoMb = 2000000;
  let DocumentUpload = (_dec = (0, _service.inject)('ajax'), (_class = class DocumentUpload extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajaxService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "docTypes", _descriptor4, this);

      _initializerDefineProperty(this, "documentType", _descriptor5, this);
    }

    *getDocTypes() {
      try {
        const adapter = this.store.adapterFor('document');
        const docTypes = yield adapter.getDocTypes();
        this.docTypes = docTypes;
      } catch (error) {
        var _error$payload;

        if (error !== null && error !== void 0 && (_error$payload = error.payload) !== null && _error$payload !== void 0 && _error$payload.errors.length) {
          this.notifications.error(error.payload.errors[0].detail);
        } else {
          this.notifications.error('An error has occurred');
        }
      }
    }

    updateDocType(selectedDocType) {
      this.documentType = selectedDocType;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajaxService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "docTypes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "documentType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getDocTypes", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getDocTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDocType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateDocType"), _class.prototype)), _class));
  _exports.default = DocumentUpload;
});