define("mldp/components/site-property-create/ownership-details-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TOkop9OY",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[6,[39,0],[[30,2]],null,[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-md-12\"],[12],[1,[28,[30,3,[\"text-field\"]],[\"ownerFirstName\"],[[\"label\"],[\"Landowner First Name\"]]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-md-12\"],[12],[1,[28,[30,3,[\"text-field\"]],[\"ownerLastName\"],[[\"label\"],[\"Landowner Last Name\"]]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n        \"],[1,[28,[30,3,[\"text-field\"]],[\"ownerEmail\"],[[\"label\"],[\"Landowner Email\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[3]]]]],[13]],[\"&attrs\",\"@model\",\"f\"],false,[\"form-for\"]]",
    "moduleName": "mldp/components/site-property-create/ownership-details-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});