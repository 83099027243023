define("mldp/components/enrollment/payment-status/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3Lniz6kO",
    "block": "[[[41,[28,[37,1],[[30,0,[\"displayOverallPaymentStatus\"]],[30,1,[\"archived\"]]],null],[[[1,\"  \"],[1,[30,1,[\"paymentStatus\"]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[30,0,[\"sitePaymentStatus\"]]],[1,\"\\n\"]],[]]]],[\"@enrollment\"],false,[\"if\",\"or\"]]",
    "moduleName": "mldp/components/enrollment/payment-status/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});