define("mldp/account/property/unit/practice/index/controller", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountPropertyProjectEditController = (_dec = (0, _service.inject)('notifications'), _dec2 = (0, _service.inject)('router'), _dec3 = (0, _service.inject)('store'), _dec4 = (0, _service.inject)('ajax'), (_class = class AccountPropertyProjectEditController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "routerService", _descriptor2, this);

      _initializerDefineProperty(this, "storeService", _descriptor3, this);

      _initializerDefineProperty(this, "ajaxService", _descriptor4, this);
    }

    async openProjectModal() {
      this.set('showModal', true);
    }

    async saveProject() {
      const ajax = this.ajaxService;

      try {
        await ajax.post(`/api/habitatpractice/${this.model.id}/addproject/${this.project.id}`);
        this.set('showModal', false);
        this.set('project', null);
        this.send('refreshModel');
        this.notifications.add('New project added');
      } catch (error) {
        this.notifications.addApiErrors(error);
      }
    }

    selectProject(selection) {
      this.set('project', selection);
    }

    closeProjectModal() {
      this.set('showModal', false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "routerService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storeService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "ajaxService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openProjectModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openProjectModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveProject", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveProject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectProject", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectProject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeProjectModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeProjectModal"), _class.prototype)), _class));
  _exports.default = AccountPropertyProjectEditController;
});