define("mldp/components/app-map/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cI01zyqh",
    "block": "[[[10,0],[14,0,\"base-toggle\"],[12],[1,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-sm btn-default\"],[15,\"disabled\",[28,[37,0],[[30,0,[\"satelliteButton\"]]],null]],[15,\"onclick\",[28,[37,1],[[30,0],\"toggleSatellite\"],null]],[14,4,\"button\"],[12],[1,\"\\n    \"],[1,[52,[30,0,[\"satellite\"]],\"Default\",\"Satellite\"]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@bounds\",\"@worldCopyJump\",\"@keyboard\",\"@minZoom\",\"@onZoomend\",\"@onLoad\"],[[30,0,[\"defaultBounds\"]],true,false,5,[30,0,[\"onZoomChange\"]],[30,0,[\"load\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"satellite\"]],[[[1,\"    \"],[8,[30,1,[\"tile\"]],null,[[\"@url\"],[\"https://txgi.tnris.org/login/path/table-guide-andy-brenda/tiles/texas/{z}/{x}/{y}\"]],null],[1,\"\\n    \"],[8,[30,1,[\"tile\"]],null,[[\"@url\"],[\"http://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[30,1,[\"tile\"]],null,[[\"@url\",\"@maxZoom\",\"@attribution\"],[\"https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png\",19,\"© <a href=\\\"http://www.openstreetmap.org/copyright\\\">OpenStreetMap</a>, © <a href=\\\"https://carto.com/attributions\\\">CARTO</a>\"]],null],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[18,2,[[30,1],[28,[37,5],null,[[\"fitBounds\",\"fitBoundsByGeoJSON\",\"showFootPrint\"],[[28,[37,1],[[30,0],\"fitBounds\"],null],[28,[37,1],[[30,0],\"fitBoundsByGeoJSON\"],null],[30,0,[\"showFootPrint\"]]]]]]],[1,\"\\n\"]],[1]]]]]],[\"map\",\"&default\"],false,[\"not\",\"action\",\"if\",\"leaflet-map\",\"yield\",\"hash\"]]",
    "moduleName": "mldp/components/app-map/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});