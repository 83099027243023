define("mldp/components/pending-invite-modal/component", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object"], function (_exports, _component, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PendingInviteModal = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class PendingInviteModal extends _component2.default {
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      let invites = this.invites;
      invites.forEach(invite => {
        switch (invite.actionType) {
          case 1:
            (0, _object.set)(invite, 'type', 'Agent');
            break;

          case 3:
            (0, _object.set)(invite, 'type', 'Land Owner');
            break;

          case 5:
            (0, _object.set)(invite, 'type', 'Property');
            break;

          case 7:
          case 8:
            (0, _object.set)(invite, 'type', 'Pool Manager');
            break;

          default:
            (0, _object.set)(invite, 'type', '');
        }
      });
    }

  }) || _class) || _class);
  _exports.default = PendingInviteModal;
});