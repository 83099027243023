define("mldp/account/property/harvests/create/route", ["exports", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CreateRoute = (_dec = (0, _service.inject)('notifications'), _dec2 = (0, _service.inject)('state'), _dec3 = (0, _service.inject)('store'), classic(_class = (_class2 = class CreateRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "state", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);
    }

    async model() {
      let {
        property: site
      } = this.modelFor('account.property');
      let {
        siteId,
        type
      } = this.paramsFor('account.property');

      if (type !== 'aggregate') {
        throw new Error('Only aggregate sites can contain harvests');
      }

      let seasons = await this.store.findAll('tlkDeerSeason').then(list => list.sortBy('sortOrder'));
      let currentSeasonId = this.get('state.currentSeason.identifier');
      const seasonAdapter = this.store.adapterFor('tlk-season');
      let currentAMDSeason = seasonAdapter.amdFindCurrentReporting();
      return _rsvp.default.hash({
        site,
        siteId,
        type,
        seasons,
        currentAMDSeason,
        currentSeasonId,
        harvest: this.store.createRecord('harvest', {
          aggregateId: siteId
        }),
        harvests: site.findHarvests()
      });
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }

    resetController(controller, isExiting, transition) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.set('submitting', false);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = CreateRoute;
});