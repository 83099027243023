define("mldp/account/property/project/index/route", ["exports", "@ember/routing/route", "rsvp", "@ember/service", "@ember/object"], function (_exports, _route, _rsvp, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountPropertyProjectIndexRoute = (_dec = (0, _service.inject)('state'), (_class = class AccountPropertyProjectIndexRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);
    }

    async model() {
      let {
        siteId
      } = this.paramsFor('account.property');
      let {
        projectId
      } = this.paramsFor('account.property.project');
      return _rsvp.default.hash({
        project: this.store.find('habitat-project', projectId),
        siteId,
        site: this.store.find('property', siteId)
      });
    }

    setupController(controller, resolved) {
      if (resolved.project.habitatProjectTypeId) {
        resolved.project.habitatProjectType = this.state.habitatProjectTypes.find(el => parseInt(el.id) === parseInt(resolved.project.habitatProjectTypeId));
      }

      if (resolved.project.coordinatorUserId) {
        resolved.coordinator = this.state.biologists.find(el => parseInt(el.id) === resolved.project.coordinatorUserId);
      }

      controller.setProperties(resolved);
    }

    refreshModel() {
      this.refresh();
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        //TODO: This doesn't see like the best way to solve this.
        controller.set('project', null);
        controller.set('siteId', null);
        controller.set('site', null);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype)), _class));
  _exports.default = AccountPropertyProjectIndexRoute;
});