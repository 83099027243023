define("mldp/templates/components/page-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "YgTsKo6q",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,1],[12],[1,\"\\n    \"],[1,[34,2]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,3],[16,6,[29,[[36,3]]]],[4,[38,4],[[30,0],\"select\"],null],[12],[1,\"\\n    \"],[1,[34,2]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"isDots\",\"page\",\"url\",\"action\"]]",
    "moduleName": "mldp/templates/components/page-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});