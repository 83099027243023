define("mldp/components/eligibility-errors/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TPT20y9m",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"alert alert-warning\"],[12],[1,\"\\n    This site is not eligible for this option.\\n\\n    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"errors\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"error\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "mldp/components/eligibility-errors/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});