define("mldp/components/practice/pretty-print/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HWoWhw2r",
    "block": "[[[41,[30,0,[\"display\"]],[[[1,\"  \"],[1,[34,1]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"display\"]]",
    "moduleName": "mldp/components/practice/pretty-print/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});