define("mldp/account/property/unit/harvests/create/controller", ["exports", "@ember/object", "@ember/service", "@ember/object/computed", "@ember/controller", "moment"], function (_exports, _object, _service, _computed, _controller, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CreateController = (_dec = (0, _service.inject)('notifications'), _dec2 = (0, _service.inject)('store'), _dec3 = (0, _service.inject)('state'), _dec4 = (0, _computed.alias)('stateService.postReportSubmit.missingReports'), _dec5 = (0, _computed.alias)('stateService.postReportSubmit.amdReapply'), _dec6 = (0, _computed.alias)('stateService.postReportSubmit.completedReportsSeason'), classic(_class = (_class2 = class CreateController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "stateService", _descriptor3, this);

      _initializerDefineProperty(this, "missingReports", _descriptor4, this);

      _initializerDefineProperty(this, "amdReapply", _descriptor5, this);

      _initializerDefineProperty(this, "completedReportsSeason", _descriptor6, this);

      _defineProperty(this, "submitting", false);
    }

    async checkIfHarvestIsCurrentSeason(harvest) {
      let {
        currentAMDSeason
      } = this;
      return Number(harvest.deerSeasonId) === Number(currentAMDSeason.id);
    }

    async checkOtherMusReported() {
      let {
        site
      } = this;
      let adapter = this.store.adapterFor('property');
      let mus = await adapter.getManagementUnits(site.id, false);
      return this.checkEachMuHasReported(mus);
    }

    async checkOtherMusReportedOnPermitApp(currentSeasonApp) {
      let mus = currentSeasonApp.mUs;
      mus = await Promise.all(mus.map(id => this.store.find('management-unit', id)));
      return this.checkEachMuHasReported(mus);
    }

    async checkEachMuHasReported(mus) {
      let {
        currentAMDSeason
      } = this;
      let otherMus = mus.filter(unit => Number(unit.id) !== Number(this.unit.id));
      let harvestsByMus = await Promise.all(otherMus.map(mu => mu.findHarvests()));
      return harvestsByMus.every(muHarvest => muHarvest.some(harvest => Number(harvest.deerSeasonId) === Number(currentAMDSeason.id)));
    }

    async checkCurrentSeasonApplications() {
      let {
        currentAMDSeason
      } = this;
      let currentSeasonAMDApp = await this.store.query('amd-permit-app', {
        'filter[seasonid]': Number(currentAMDSeason.id),
        'filter[siteid]': Number(this.site.id)
      });
      return currentSeasonAMDApp.length ? currentSeasonAMDApp.toArray()[0] : false;
    }

    async checkNextSeasonApplications() {
      let {
        currentAMDSeason
      } = this;
      let nextSeasonAMDApp = await this.store.query('amd-permit-app', {
        'filter[seasonid]': Number(currentAMDSeason.id) + 1,
        'filter[siteid]': Number(this.site.id)
      });
      return nextSeasonAMDApp.length ? nextSeasonAMDApp.toArray()[0] : false;
    }

    pastReportingDeadline() {
      let {
        currentAMDSeason
      } = this;
      return (0, _moment.default)(new Date()).isAfter(currentAMDSeason.harvestReportingEndDate);
    }

    async save(harvest) {
      let {
        notifications,
        stateService
      } = this;
      this.set('submitting', true); //setup state for post submit modals

      let postReportSubmit = stateService.postReportSubmit;

      if (!postReportSubmit) {
        postReportSubmit = {};
        stateService.set('postReportSubmit', postReportSubmit);
      }

      try {
        await harvest.save();
        let harvestIsCurrentSeason = await this.checkIfHarvestIsCurrentSeason(harvest);

        if (!harvestIsCurrentSeason || this.site.siteType !== 'property') {
          return this.send('goToHarvestSummary');
        }

        const currentSeasonApp = await this.checkCurrentSeasonApplications();
        const allMusReported = currentSeasonApp ? await this.checkOtherMusReportedOnPermitApp(currentSeasonApp) : await this.checkOtherMusReported();

        if (!currentSeasonApp && allMusReported) {
          // set harvest report success message, slide 27
          let {
            currentAMDSeason
          } = this;
          this.set('completedReportsSeason', currentAMDSeason);
          return this.send('goToSiteInfo');
        } else if (!allMusReported) {
          // display modal to let customer know they are missing harvest reports slide 25
          this.set('missingReports', true);
          return this.send('goToHarvestSummary');
        } // else is current amd permit app and all mus on permit pp are reported


        const nextSeasonApp = await this.checkNextSeasonApplications();

        if (!nextSeasonApp && !this.pastReportingDeadline()) {
          // display modal ask customer if they want to reapply slide 26
          return this.set('amdReapply', true);
        } else {
          return this.send('checkContinueReporting');
        }
      } catch (error) {
        notifications.addApiErrors(error);
      }
    }

    async goToHarvestSummary() {
      let {
        harvest,
        site
      } = this;
      let unitId = harvest.get('managementUnitId');
      return this.transitionToRoute('account.property.unit.harvests', site.siteType, site.id, unitId);
    }

    async checkContinueReporting() {
      let allMusReported = await this.checkOtherMusReported();

      if (allMusReported) {
        // set harvest report success message, slide 27
        let {
          currentAMDSeason
        } = this;
        this.set('completedReportsSeason', currentAMDSeason);
        return this.send('goToSiteInfo');
      } else {
        // display modal to let customer know they are missing harvest reports slide 25
        this.set('missingReports', true);
        return this.send('goToHarvestSummary');
      }
    }

    goToSiteInfo() {
      let site = this.site;
      return this.transitionToRoute('account.property', site.siteType, site.id);
    }

    goToNewApp() {
      let site = this.site;
      this.set('amdReapply', false);
      return this.transitionToRoute('account.permits.create', 'amd', {
        queryParams: {
          primarySiteId: site.id,
          primarySiteType: site.siteType
        }
      });
    }

    reset() {
      let harvest = this.harvest; //TODO: harvest.rollbackProperties() was not working at all because the store record was deleted
      // might try to only create a store record on save, but this will require rearchitecting for HarvestEdit component

      return harvest.setProperties({
        whiteTailAntlerless: undefined,
        whiteTailTotalBucks: undefined,
        whiteTailUnbranchedBucks: undefined,
        muleDeerAntlerless: undefined,
        muleDeerTotalBucks: undefined,
        muleDeerUnbranchedBucks: undefined
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "missingReports", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "amdReapply", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "completedReportsSeason", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "goToHarvestSummary", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "goToHarvestSummary"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "checkContinueReporting", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "checkContinueReporting"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "goToSiteInfo", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "goToSiteInfo"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "goToNewApp", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "goToNewApp"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "reset"), _class2.prototype)), _class2)) || _class);
  _exports.default = CreateController;
});