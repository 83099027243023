define("mldp/components/user-details-card/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "SbP3Fj52",
    "block": "[[[11,0],[24,0,\"user-details-card\"],[17,1],[12],[1,\"\\n\"],[1,\"    \"],[10,\"dl\"],[14,0,\"dl-horizontal\"],[12],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"User:\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[30,0,[\"user\",\"firstName\"]]],[1,\" \"],[1,[30,0,[\"user\",\"lastName\"]]],[13],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"Phone number:\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[30,0,[\"user\",\"primaryPhone\"]]],[13],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"Email Address:\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,\"\\n\"],[41,[30,0,[\"user\",\"email\"]],[[[1,\"          \"],[10,3],[15,6,[29,[\"mailto:\",[30,0,[\"user\",\"email\"]]]]],[12],[1,[30,0,[\"user\",\"email\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"Address:\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,\"\\n        \"],[1,[30,0,[\"user\",\"address1\"]]],[10,\"br\"],[12],[13],[1,\"\\n\"],[41,[30,0,[\"user\",\"address2\"]],[[[1,\"          \"],[1,[30,0,[\"user\",\"address2\"]]],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[1,[30,0,[\"user\",\"city\"]]],[41,[28,[37,1],[[30,0,[\"user\",\"city\"]],[30,0,[\"user\",\"state\"]]],null],[[[1,\", \"]],[]],null],[1,\"\\n        \"],[1,[30,0,[\"user\",\"state\"]]],[41,[28,[37,1],[[30,0,[\"user\",\"state\"]],[30,0,[\"user\",\"zipcode\"]]],null],[[[1,\", \"]],[]],null],[1,\"\\n        \"],[1,[30,0,[\"user\",\"zipcode\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"canEditSelectedUser\"]],[[[1,\"      \"],[10,3],[14,\"role\",\"button\"],[15,\"onclick\",[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"editUser\"]]],null],true],null]],[12],[1,\"Edit User\"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\"],false,[\"if\",\"and\",\"action\",\"mut\"]]",
    "moduleName": "mldp/components/user-details-card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});