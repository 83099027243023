define("mldp/components/reporting-wizard/units-list/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "15dLpCng",
    "block": "[[[10,0],[14,0,\"reporting-wizard-units-list\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"panel panel-default\"],[12],[1,\"\\n\"],[41,[30,0,[\"fetchUnits\",\"isRunning\"]],[[[1,\"      \"],[10,0],[14,0,\"panel-body bg-primary\"],[12],[1,\"\\n        Loading Management Units..\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"panel-body bg-primary\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[[30,1],\"Please select a management unit\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"list-group\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"units\"]]],null]],null],null,[[[1,\"          \"],[10,\"button\"],[14,0,\"list-group-item\"],[15,\"onclick\",[28,[37,4],[[30,0],[30,3],[30,2]],null]],[12],[1,\"\\n            \"],[1,[30,2,[\"siteName\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@instructions\",\"unit\",\"@onSelect\"],false,[\"if\",\"or\",\"each\",\"-track-array\",\"action\"]]",
    "moduleName": "mldp/components/reporting-wizard/units-list/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});