define("mldp/utils/extname", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = extname;

  function extname(filename) {
    if (!filename) {
      return '';
    }

    let ext = (/[^./\\]*$/.exec(filename) || [''])[0];
    return '.' + ext.toLowerCase();
  }
});