define("mldp/components/form-fields/number-field", ["exports", "ember-form-for/components/form-fields/number-field"], function (_exports, _numberField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _numberField.default;
    }
  });
});