define("mldp/account/reports/mldp-fee-summary/controller", ["exports", "@ember/service", "@ember/controller", "@ember/object", "mldp/utils/get-report-auth", "qs"], function (_exports, _service, _controller, _object, _getReportAuth, _qs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let MldpSummaryController = (_dec = (0, _service.inject)('general-ajax'), classic(_class = (_class2 = class MldpSummaryController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajaxService", _descriptor, this);
    }

    async updateQuery(key, value) {
      let query = this.query;
      let report = this.report;
      let ajax = this.ajaxService;
      let auth = await (0, _getReportAuth.default)(report, ajax);
      let newQuery = Object.assign({}, query);
      newQuery.auth = auth;
      (0, _object.set)(newQuery, key, value);
      (0, _object.set)(this, 'query', newQuery);
    }

    async getReport(_ref) {
      let {
        format
      } = _ref;
      let query = this.query;
      let newQuery = Object.assign({
        format
      }, query);
      let report = this.report;
      let ajax = this.ajaxService;
      let auth = await (0, _getReportAuth.default)(report, ajax);
      newQuery.auth = auth;

      let url = report.url + '?' + _qs.default.stringify(newQuery, {
        arrayFormat: 'repeat'
      });

      window.open(url);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajaxService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "updateQuery", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateQuery"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getReport", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "getReport"), _class2.prototype)), _class2)) || _class);
  _exports.default = MldpSummaryController;
});