define("mldp/components/search/permit-form/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "NpH8boSD",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[6,[39,0],[[30,0,[\"data\"]]],[[\"submit\"],[[28,[37,1],[[30,0],[30,0,[\"onsubmit\"]]],null]]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"row-fluid\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-md-6\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n          \"],[1,[28,[30,2,[\"text-field\"]],[\"sitename\"],[[\"label\"],[\"Site Name\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"col-md-6\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n          \"],[1,[28,[30,2,[\"select-field\"]],[\"countyname\"],[[\"label\",\"options\",\"optionTargetPath\",\"optionLabelPath\"],[\"County\",[30,0,[\"counties\"]],\"value\",\"key\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"row-fluid\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-md-6\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n          \"],[1,[28,[30,2,[\"select-field\"]],[\"seasonname\"],[[\"label\",\"options\",\"optionTargetPath\",\"optionLabelPath\"],[\"Season\",[30,0,[\"permitSeasons\"]],\"value\",\"key\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n          \"],[1,[28,[30,2,[\"select-field\"]],[\"biologistid\"],[[\"label\",\"options\",\"optionTargetPath\",\"optionLabelPath\"],[\"Biologist\",[30,0,[\"biologists\"]],\"value\",\"label\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]]]]],[13]],[\"&attrs\",\"f\"],false,[\"form-for\",\"action\"]]",
    "moduleName": "mldp/components/search/permit-form/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});