define("mldp/utils/get-report-auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getReportAuth;

  function getReportAuth(report, ajax) {
    return ajax.request(`${report.url}/getauthtoken`).catch(() => {
      throw new Error(`Authorization could not be acquired for the '${report.name}' report.`);
    });
  }
});