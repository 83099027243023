define("mldp/helpers/url-query", ["exports", "@ember/component/helper", "qs"], function (_exports, _helper, _qs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.urlQuery = urlQuery;

  function urlQuery(_ref, _ref2) {
    let [url, query] = _ref;
    let {
      format
    } = _ref2;
    let newQuery = Object.assign({
      format
    }, query);
    return url + '?' + _qs.default.stringify(newQuery, {
      arrayFormat: 'repeat'
    });
  }

  var _default = (0, _helper.helper)(urlQuery);

  _exports.default = _default;
});