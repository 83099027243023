define("mldp/components/harvest-calculator/header/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "aXPEh0HI",
    "block": "[[[10,\"header\"],[14,0,\"clearfix\"],[14,5,\"width:100%\"],[12],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"inline\"],[12],[1,\"\\n    Harvest Calculator\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"container-fluid\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row-fluid\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-xs-4\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"lower-details\"],[14,5,\"margin-bottom: 20px;\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"season\"],[14,5,\"padding-right: 25px;\"],[12],[10,\"b\"],[12],[1,\"Hunting Season: \"],[13],[1,[30,1]],[13],[10,\"br\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"season\"],[12],[10,\"b\"],[12],[1,\"MU Acreage: \"],[13],[1,[30,2]],[13],[10,\"br\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"season\"],[12],[10,\"b\"],[12],[1,\"Species: \"],[13],[1,[30,3]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"col-xs-4\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"lower-details\"],[14,5,\"margin-bottom: 20px; border: solid; padding: 5px;\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"season\"],[14,5,\"padding-right: 25px;\"],[12],[10,\"b\"],[12],[1,\"Past Season Data: \"],[13],[1,[30,4]],[13],[10,\"br\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"season\"],[12],[10,\"b\"],[12],[1,\"Recommended Harvest: \"],[13],[1,[30,5]],[13],[10,\"br\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"season\"],[12],[10,\"b\"],[12],[1,\"Reported Harvest: \"],[13],[1,[30,6]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@seasonName\",\"@acreage\",\"@species\",\"@pastSeasonName\",\"@pastSeasonRecTotal\",\"@pastSeasonHarvestTotal\"],false,[]]",
    "moduleName": "mldp/components/harvest-calculator/header/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});