define("mldp/components/practice/help-text/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  const text = {
    1: 'Enter the number of acres treated, and the date of the burn',
    2: 'Enter the overall Stocking Rate for this property/management unit, including all livestock types. You may enter specific information or notes not otherwise covered in "Other Info."',
    3: 'Select the appropriate practice type and enter the information requested. Enter each type of mechanical treatment as a separate practice. You may enter specific information or notes not otherwise covered in "Other."',
    4: 'Select the appropriate practice type and enter the information requested. Native food plot plantings should be reported as either Reseeding Grasses/Forbs or Planting Shrubs. You may enter specific information or notes not otherwise covered in "Other."',
    5: 'Select the appropriate practice type and enter the information requested. You may enter specific information or notes not otherwise covered in "Other."',
    6: 'Select the appropriate practice type and enter the information requested. You may enter specific information or notes not otherwise covered in "Other."',
    7: 'Select the appropriate practice type and enter the information requested. You may enter specific information or notes in "Other."',
    8: 'Select the appropriate practice type and enter the information requested. You may enter specific information or notes not otherwise covered in "Other." Note: Contact your cooperating TPWD biologist to determine the intensity of control of feral hogs or exotic ungulate species necessary to count this as a practice for the purposes of MLDP participation. Recreational or incidental hunting of feral hogs or exotic ungulates will not count as a practice.',
    9: 'Select the appropriate practice type and enter the information requested. You may enter specific information or notes not otherwise covered in "Other.',
    10: 'Select the appropriate practice type and enter the information requested. You may enter specific information or notes not otherwise covered in "Other.',
    11: 'Select the appropriate practice type and enter the information requested. You may enter specific information or notes not otherwise covered in "Other."',
    12: 'Enter a description of wildlife habitat enhancement activities that were conducted that are not otherwise covered. Note: Contact your cooperating TPWD biologist to ensure the information entered in this section will count as a practice, if the practice is intended to count for MLDP participation.'
  };
  let HelpText = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class HelpText extends _component2.default {
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.set('helpText', text[this.type]);
    }

  }) || _class) || _class);
  _exports.default = HelpText;
});