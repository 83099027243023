define("mldp/states/recommendations-form", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let StateObject = classic(_class = class StateObject extends _object.default {}) || _class;

  window.__CLASSIC_OWN_CLASSES__.set(StateObject, true);

  const createAdd = state => {
    return function (siteId, enrollmentId, speciesId, seasonId) {
      let buckTagsAny = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : undefined;
      let doeTags = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : undefined;

      // mule and wt
      if (speciesId === 3) {
        state.recommendations.unshiftObject({
          siteId,
          enrollmentId,
          speciesId: 2,
          seasonId,
          buckTagsAny,
          doeTags
        });
        speciesId = 1;
      }

      state.recommendations.unshiftObject({
        siteId,
        enrollmentId,
        speciesId,
        seasonId,
        buckTagsAny,
        doeTags
      });
    };
  };

  StateObject.reopenClass({
    initialState(instance) {
      let canCreate = instance.canService.can('create recommendations', instance.site);
      let state = {
        recommendations: [],
        showCurrentRecommendations: !canCreate
      };
      let addRecommendation = createAdd(state);
      state.addRecommendation = addRecommendation;
      return state;
    }

  });
  var _default = StateObject;
  _exports.default = _default;
});