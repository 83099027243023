define("mldp/models/outreach", ["exports", "@ember/object", "@ember/service", "@ember-data/model"], function (_exports, _object, _service, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Outreach = (_dec = (0, _service.inject)('state'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('boolean'), _dec4 = (0, _model.attr)('number'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('number'), _dec7 = (0, _model.attr)('number'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('number'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('number'), _dec14 = (0, _model.attr)('number'), _dec15 = (0, _model.attr)('number'), _dec16 = (0, _model.attr)('number'), _dec17 = (0, _model.attr)('number'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.hasMany)('user-xoutreach'), _dec20 = (0, _model.attr)('number'), _dec21 = (0, _model.attr)('number'), classic(_class = (_class2 = class Outreach extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stateService", _descriptor, this);

      _initializerDefineProperty(this, "eventName", _descriptor2, this);

      _initializerDefineProperty(this, "tPWDEvent", _descriptor3, this);

      _initializerDefineProperty(this, "eventTypeId", _descriptor4, this);

      _initializerDefineProperty(this, "primaryGroupId", _descriptor5, this);

      _initializerDefineProperty(this, "fundingId", _descriptor6, this);

      _initializerDefineProperty(this, "countyId", _descriptor7, this);

      _initializerDefineProperty(this, "wmaId", _descriptor8, this);

      _initializerDefineProperty(this, "presentations", _descriptor9, this);

      _initializerDefineProperty(this, "eventLocation", _descriptor10, this);

      _initializerDefineProperty(this, "eventComment", _descriptor11, this);

      _initializerDefineProperty(this, "outreachDate", _descriptor12, this);

      _initializerDefineProperty(this, "attendeesMaleAdult", _descriptor13, this);

      _initializerDefineProperty(this, "attendeesFemaleAdult", _descriptor14, this);

      _initializerDefineProperty(this, "attendeesMaleYouth", _descriptor15, this);

      _initializerDefineProperty(this, "attendeesFemaleYouth", _descriptor16, this);

      _initializerDefineProperty(this, "entryBy", _descriptor17, this);

      _initializerDefineProperty(this, "entryDate", _descriptor18, this);

      _initializerDefineProperty(this, "otherStaff", _descriptor19, this);

      _initializerDefineProperty(this, "eventUserId", _descriptor20, this);

      _initializerDefineProperty(this, "eventContactId", _descriptor21, this);
    }

    get type() {
      let typeId = this.eventTypeId;
      let types = this.get('stateService.outreachEventTypes');
      return types.findBy('id', String(typeId));
    }

    get primaryGroup() {
      let groupId = this.primaryGroupId;
      let groups = this.get('stateService.outreachGroups');
      return groups.findBy('id', String(groupId));
    }

    get funding() {
      let fundingId = this.fundingId;
      let fundings = this.get('stateService.outreachFundings');
      return fundings.findBy('id', String(fundingId));
    }

    get wma() {
      let wmaId = this.wmaId;
      let wmas = this.get('stateService.wmas');
      return wmas.findBy('id', String(wmaId));
    }

    get county() {
      let countyId = this.countyId;
      let counties = this.get('stateService.counties');
      return counties.findBy('id', String(countyId));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "eventName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "tPWDEvent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "eventTypeId", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "primaryGroupId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "fundingId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "countyId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "wmaId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "presentations", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "eventLocation", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "eventComment", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "outreachDate", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "attendeesMaleAdult", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "attendeesFemaleAdult", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "attendeesMaleYouth", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "attendeesFemaleYouth", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class2.prototype, "entryBy", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class2.prototype, "entryDate", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class2.prototype, "otherStaff", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class2.prototype, "eventUserId", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class2.prototype, "eventContactId", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = Outreach;
});