define("mldp/helpers/lowercase", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lowercase = lowercase;

  function lowercase(params
  /*, hash*/
  ) {
    const [string] = params;
    return typeof string === 'string' ? string.toLowerCase() : '';
  }

  var _default = (0, _helper.helper)(lowercase);

  _exports.default = _default;
});