define("mldp/components/practice/deer-season/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OHSLwRKD",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"create\"]],[[[1,\"    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[1,[28,[33,1,[\"select-field\"]],[\"deerSeasonId\"],[[\"options\",\"label\",\"optionTargetPath\",\"prompt\",\"optionLabelPath\",\"hint\",\"required\"],[[30,0,[\"seasons\"]],\"Hunting Season\",\"identifier\",\"-- Select Reporting Season --\",\"displayValue\",\"Reporting year period is (March 1 through Feb. 28) for each report year.\",true]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"model\",\"type\"],[[30,0,[\"model\"]],\"deerSeason\"]]]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\"],false,[\"if\",\"f\",\"practice/display-field\"]]",
    "moduleName": "mldp/components/practice/deer-season/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});