define("mldp/components/permit/new-permit-notice/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QBP/tYrp",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"Your \"],[1,[28,[35,0],[[28,[37,1],[[30,0,[\"type\"]]],null]],null]],[1,\" permit application has been submitted successfully. Your application status will remain as Submitted until a biologist determines if your property is eligible to receive permits for the upcoming hunting season. You will receive an email to notify you whether a harvest recommendation will result in permit issuance. \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"lowercase\",\"permit-display-value\"]]",
    "moduleName": "mldp/components/permit/new-permit-notice/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});