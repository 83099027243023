define("mldp/account/permits/index/controller", ["exports", "@ember/object/computed", "@ember/controller", "@ember/service", "@ember/object"], function (_exports, _computed, _controller, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const params = ['sitename', 'primarysite', 'countyname', 'seasonname', 'biologistid'];

  var _default = _controller.default.extend({
    ajaxService: (0, _service.inject)('general-ajax'),
    stateService: (0, _service.inject)('state'),
    storeService: (0, _service.inject)('store'),
    reportingWizardActive: (0, _computed.alias)('stateService.reportingWizard.active'),
    reportingWizardSelectedSite: (0, _computed.alias)('stateService.reportingWizard.site'),
    queryParams: ['page', 'sitename', 'primarysite', 'countyname', 'seasonname', 'biologistid', 'permitCreated'],
    page: 1,
    activePermitType: 'amd',
    tagFormData: _object.default.create({}),
    showClearSearch: (0, _object.computed)('searchFields', function () {
      let searchFields = this.searchFields;

      for (let param of params) {
        let value = searchFields.get(param);

        if (value && value !== null) {
          return true;
        }
      }
    }),
    actions: {
      updatePage(page) {
        this.set('page', page);
      },

      updateSearch() {
        const data = Object.assign({
          page: 1
        }, this.searchFields);
        this.setProperties(data);
      },

      clearSearch() {
        params.forEach(param => {
          this.set(param, null);
        });
      },

      queryTags() {
        let ajax = this.ajaxService;
        this.set('tagUrl', '');
        return ajax.request(`/${this.activePermitType}tags/tag/${this.tagFormData.tagId}/validate`).then((result, error) => {
          if (result !== null && result !== void 0 && result.valid) {
            this.set('tagUrl', `/${this.activePermitType}tags/tag/${this.tagFormData.tagId}/?auth=${result.token}`);
          } else if (result && !result.valid) {
            this.set('invalidTagMessage', result.message);
          } else if (error) {
            this.set('invalidTagMessage', 'Something went wrong, please try again.');
          }
        });
      },

      onCloseTagSeach() {
        this.setProperties({
          showPermitTagSearch: false,
          invalidTagMessage: '',
          tagFormData: _object.default.create({}),
          tagUrl: ''
        });
      },

      async openReportingWizard(application) {
        let {
          siteType,
          id
        } = application.primarySite;
        let site = await this.store.findRecord(siteType, id);
        this.set('reportingWizardActive', true);
        this.set('reportingWizardSelectedSite', site);
      }

    }
  });

  _exports.default = _default;
});