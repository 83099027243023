define("mldp/components/invite-form/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let InviteForm = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class InviteForm extends _component2.default {
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      let createMode = this.createMode;

      if (createMode) {
        this.set('submitBtnText', 'Next Step');
      } else {
        this.set('submitBtnText', 'Invite ' + this.inviteText);
      }
    }

  }) || _class) || _class);
  _exports.default = InviteForm;
});