define("mldp/helpers/round", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.round = round;

  function round(params
  /*, hash*/
  ) {
    const [number, decimals] = params;
    return number ? number.toFixed(decimals || 0) : 0;
  }

  var _default = (0, _helper.helper)(round);

  _exports.default = _default;
});