define("mldp/services/notifications", ["exports", "@ember/object", "@ember/array", "@ember/service"], function (_exports, _object, _array, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let NotificationsService = classic(_class = class NotificationsService extends _service.default {
    init() {
      super.init(...arguments);
      this.notifications = (0, _array.A)();
    }

    add(message, options) {
      options = Object.assign({
        type: 'primary'
      }, options);

      if (message && message.includes('TransitionAborted')) {
        return;
      }

      let notifications = this.notifications;
      notifications.pushObject(_object.default.create({ ...options,
        message,

        remove() {
          notifications.removeObject(this);
        }

      }));
    }

    error(message, options) {
      options = Object.assign({
        type: 'danger'
      }, options);
      this.add(message, options);
    }

    addApiErrors(err, options) {
      let message = jsonApiErrorToMessage(err);
      options = Object.assign({
        title: 'Issue Encountered'
      }, options);
      this.error(message, options);
      return new Error(err);
    }

    addValidationErrors(model, options) {
      let errors = model.get('validations.errors');
      let message = errors.mapBy('message').join('\n -');
      options = Object.assign({
        title: 'Validation Issue'
      }, options);
      this.error(`- ${message}`, options);
      return new Error(message);
    }

    clear() {
      this.set('notifications', (0, _array.A)());
    }

  }) || _class;

  _exports.default = NotificationsService;

  function jsonApiErrorToMessage(err) {
    if (err && err.errors) {
      let messages = err.errors.mapBy('detail');
      return messages.join('\n');
    }

    return err && err.message;
  }
});