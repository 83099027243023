define("mldp/components/async-button/component", ["exports", "@glimmer/tracking", "@ember-decorators/component", "@ember-decorators/object", "@ember/object/computed", "@ember/application/deprecations", "@ember/object", "@ember/component"], function (_exports, _tracking, _component, _object, _computed, _deprecations, _object2, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let positionalParamsMixin = {
    positionalParams: 'params'
  };
  let ButtonComponent = (_dec = (0, _component.tagName)('button'), _dec2 = (0, _component.classNames)('async-button'), _dec3 = (0, _component.classNameBindings)('textState'), _dec4 = (0, _component.attributeBindings)('disabled', 'type', '_href:href', 'tabindex'), _dec5 = (0, _computed.alias)('default'), _dec6 = (0, _object2.computed)('textState', 'disableWhen'), _dec7 = (0, _object2.computed)('textState', 'default', 'pending', 'resolved', 'fulfilled', 'rejected'), _dec8 = (0, _object.observes)('textState', 'reset'), _dec9 = (0, _object.observes)('promise'), _dec10 = (0, _object2.computed)('href', 'tagName'), classic(_class = _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class ButtonComponent extends _component2.default.extend(positionalParamsMixin) {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "textState", _descriptor, this);

      _initializerDefineProperty(this, "asyncState", _descriptor2, this);

      _defineProperty(this, "reset", false);

      _defineProperty(this, "type", 'submit');
    }

    get disabled() {
      let textState = this.textState;
      let disableWhen = this.disableWhen;
      return disableWhen || textState === 'pending';
    }

    click() {
      let params = this.params || [];

      let callbackHandler = promise => {
        (0, _object2.set)(this, 'promise', promise);
      };

      if (typeof this.action === 'function') {
        let deprecatingCallbackHandler = function (promise) {
          (true && !(false) && (0, _deprecations.deprecate)(`When using closure style actions with ember-async-button,
please return the promise instead of using the callback argument.
The callback for closure actions will be removed in future versions.`, false, {
            id: 'ember-async-button.action-callback',
            until: '0.8.0'
          }));
          callbackHandler(promise);
        };

        let promise = this.action(deprecatingCallbackHandler, ...params);

        if (promise && typeof promise.then === 'function') {
          callbackHandler(promise);
        }
      }

      (0, _object2.set)(this, 'textState', 'pending'); // If this is part of a form, it will perform an HTML form
      // submission without returning false to prevent action bubbling

      return false;
    }

    get text() {
      if (['resolved', 'fulfilled', 'rejected'].includes(this.textState)) return this.default;
      return this.textState || this.default;
    } // eslint-disable-next-line ember/no-observers


    resetObserver() {
      let states = ['resolved', 'rejected', 'fulfilled'];
      let found = false;
      let textState = this.textState;

      for (let idx = 0; idx < states.length && !found; idx++) {
        found = textState === states[idx];
      }

      if (this.reset && found) {
        (0, _object2.set)(this, 'textState', 'default');
      }
    } // eslint-disable-next-line ember/no-observers


    handleActionPromise() {
      let promise = this.promise;

      if (!promise) {
        return;
      }

      promise.then(() => {
        if (!this.isDestroyed) {
          (0, _object2.set)(this, 'textState', 'fulfilled');
        }
      }).catch(() => {
        if (!this.isDestroyed) {
          (0, _object2.set)(this, 'textState', 'rejected');
        }
      });
    }

    setUnknownProperty(key, value) {
      if (key === 'resolved') {
        (true && !(false) && (0, _deprecations.deprecate)('The \'resolved\' property is deprecated. Please use \'fulfilled\'', false));
        key = 'fulfilled';
      }

      this[key] = null;
      this.set(key, value);
    }

    get _href() {
      let href = this.href;

      if (href) {
        return href;
      }

      let tagName = this.tagName.toLowerCase();

      if (tagName === 'a' && href === undefined) {
        return '';
      }
    }

    get _stateObject() {
      let textState = this.textState;
      let isFulfilled = textState === 'fulfilled' || textState === 'resolved';
      return {
        isPending: textState === 'pending',
        isFulfilled,
        isResolved: isFulfilled,
        isRejected: textState === 'rejected',
        isDefault: textState === 'default'
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "textState", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.default || 'default';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "asyncState", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "disabled", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "disabled"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "text", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "text"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resetObserver", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "resetObserver"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleActionPromise", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "handleActionPromise"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_href", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "_href"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class) || _class); // Ember 1.13.6 will deprecate specifying `positionalParams` on the
  // instance in favor of class level property
  //
  // Having both defined keeps us compatible with Ember 1.13+ (all patch versions)

  window.__CLASSIC_OWN_CLASSES__.set(ButtonComponent, true);

  ButtonComponent.reopenClass(positionalParamsMixin);
  var _default = ButtonComponent;
  _exports.default = _default;
});