define("mldp/utils/get-quarters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getQuarters;

  function getQuarters() {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let startOfFirstQuarter = new Date(currentYear, 0, 1);
    let endOfFirstQuarter = new Date(currentYear, 3, 1);
    let startOfSecondQuarter = new Date(currentYear, 3, 1);
    let endOfSecondQuarter = new Date(currentYear, 6, 1);
    let startOfThirdQuarter = new Date(currentYear, 6, 1);
    let endOfThirdQuarter = new Date(currentYear, 9, 1);
    let startOfFourthQuarter = new Date(currentYear, 9, 1);
    let endOfFourthQuarter = new Date(currentYear + 1, 0, 1);
    return {
      1: {
        start: startOfFirstQuarter,
        end: endOfFirstQuarter
      },
      2: {
        start: startOfSecondQuarter,
        end: endOfSecondQuarter
      },
      3: {
        start: startOfThirdQuarter,
        end: endOfThirdQuarter
      },
      4: {
        start: startOfFourthQuarter,
        end: endOfFourthQuarter
      }
    };
  }
});