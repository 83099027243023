define("mldp/account/property/enrollments/create/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "rsvp", "@ember/application"], function (_exports, _object, _service, _route, _rsvp, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CreateRoute = (_dec = (0, _service.inject)('notifications'), classic(_class = (_class2 = class CreateRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);
    }

    model() {
      let {
        type,
        siteId
      } = this.paramsFor('account.property');
      let {
        property: site
      } = this.modelFor('account.property');
      let adapter = this.store.adapterFor(type);
      return _rsvp.default.hash({
        site,
        types: this.store.findAll('tlk-enrollment-type'),
        genders: this.store.findAll('tlk-enrollment-gender'),
        species: this.store.findAll('tlk-specie'),
        enrollment: this.store.createRecord('enrollment', {
          primarySiteId: siteId
        }),
        eligibility: site.enrollmentEligibility({
          query: {
            species: 0
          }
        }),
        units: adapter.getManagementUnits(siteId)
      });
    }

    setupController(controller, resolved) {
      resolved.enrollment.set('isCoop', resolved.site.get('siteType') === 'coop');
      controller.setProperties(resolved);
    }

    create(enrollment) {
      let notifications = this.notifications;
      return enrollment.save().catch(error => {
        notifications.addApiErrors(error);
        throw new Error(error);
      });
    }

    confirmEnrollment() {
      let {
        type,
        siteId
      } = this.paramsFor('account.property');
      this.transitionTo('account.property', type, siteId);
    }

    payEnrollmentNow() {
      this.transitionTo('account.payment');
    }

    payEnrollmentLater() {
      let adapter = this.store.adapterFor('enrollment');
      adapter.payLater(this.currentModel.enrollment);
      let {
        type,
        siteId
      } = this.paramsFor('account.property');
      this.transitionTo('account.property', type, siteId);
    }

    updateFeeInvoices() {
      const applicationRouteInstance = (0, _application.getOwner)(this).lookup('route:application');
      applicationRouteInstance.fetchFeeInvoices();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "create", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "create"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "confirmEnrollment", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "confirmEnrollment"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "payEnrollmentNow", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "payEnrollmentNow"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "payEnrollmentLater", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "payEnrollmentLater"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateFeeInvoices", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateFeeInvoices"), _class2.prototype)), _class2)) || _class);
  _exports.default = CreateRoute;
});