define("mldp/components/actions/new-property-contact-bio/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let NewPropertyContactBio = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class NewPropertyContactBio extends _component2.default {}) || _class) || _class);
  _exports.default = NewPropertyContactBio;
});