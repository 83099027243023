define("mldp/components/collapse-panel/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7HDtQe7u",
    "block": "[[[11,0],[24,0,\"collapse-panel\"],[17,1],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"header btn btn-default\"],[24,4,\"button\"],[4,[38,0],[[30,0],[30,2],[28,[37,1],[[30,3]],null]],null],[12],[1,\"\\n    \"],[1,[30,4]],[1,\" \"],[41,[28,[37,3],[[30,5],[28,[37,4],[[30,6],1],null]],null],[[[10,1],[14,0,\"badge badge-light\"],[12],[1,[30,6]],[13]],[]],null],[1,\"\\n\"],[41,[30,7],[[[1,\"      \"],[10,1],[14,0,\"text-warning\"],[15,\"title\",[30,7]],[12],[1,\"\\n        \"],[10,\"i\"],[14,0,\"ion-alert-circled\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,\"i\"],[15,0,[29,[\"fa \",[52,[30,3],\"fa-angle-up\",\"fa-angle-down\"],\" pull-right text-muted\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[6,[39,5],[[30,3]],[[\"class\"],[\"up-down\"]],[[\"default\"],[[[[1,\"    \"],[18,8,null],[1,\"\\n\"]],[]]]]],[13]],[\"&attrs\",\"@ontoggle\",\"@open\",\"@name\",\"@showBadge\",\"@count\",\"@alert\",\"&default\"],false,[\"action\",\"not\",\"if\",\"or\",\"gt\",\"liquid-if\",\"yield\"]]",
    "moduleName": "mldp/components/collapse-panel/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});