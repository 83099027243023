define("mldp/services/i18n", ["exports", "@ember/service", "ember-form-for/utils/strings"], function (_exports, _service, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let I18NService = // Prevents error in latest ember
  classic(_class = class I18NService extends _service.default {
    t(key) {
      let keyParts = key.split('.');
      return (0, _strings.humanize)(keyParts[keyParts.length - 1]);
    }

  }) || _class;

  _exports.default = I18NService;
});