define("mldp/components/selectable-unit-layer/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/component"], function (_exports, _component, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SelectableUnitLayer = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = (_class2 = class SelectableUnitLayer extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "wasAdded", false);
    }

    init() {
      super.init(...arguments);
      this.colors = {
        default: 'default',
        selected: 'selected',
        defaultHover: 'defaultHover',
        selectedHover: 'selectedHover'
      };
    }

    didReceiveAttrs() {
      this.updateColors();
    }

    updateColors() {
      let colors = this.colors;
      let unit = this.unit;
      let selectedUnits = this.selectedUnits;
      let hoveredUnit = this.hoveredUnit;

      if (!selectedUnits) {
        this.set('type', colors.default);
        return;
      }

      let isSelected = selectedUnits.includes(unit);
      let isHovered = unit === hoveredUnit;
      let type;

      if (isSelected && isHovered) {
        //console.log(`selectedHover ${unit.get('siteName')}`)
        type = colors.selectedHover;
      } else if (isHovered) {
        //console.log(`defaultHover  ${unit.get('siteName')}`)
        type = colors.defaultHover;
      } else if (isSelected) {
        //console.log(`selected  ${unit.get('siteName')}`)
        type = colors.selected;
      } else {
        //console.log(`default  ${unit.get('siteName')}`)
        type = colors.default;
      }

      this.set('type', type);
    }

    onAddCheck() {
      if (this.wasAdded) {
        return;
      }

      this.set('wasAdded', true);
      this.onAdd(...arguments);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "onAddCheck", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onAddCheck"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = SelectableUnitLayer;
});