define("mldp/adapters/ph-tally", ["exports", "mldp/adapters/application", "mldp/utils/class-op"], function (_exports, _application, _classOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelName: 'ph-tally',
    findAllForSeason: (0, _classOp.default)({
      path: 'forseason/{seasonId}',

      transform(payload) {
        let data;

        if (payload) {
          data = payload.map(datum => {
            datum.attributes = datum;
            datum.type = 'ph-tally';
            return datum;
          });
        }

        return this.store.push({
          data
        });
      }

    }),
    findAllLatest: (0, _classOp.default)({
      path: 'latest',

      transform(payload) {
        let data;

        if (payload) {
          data = payload.map(datum => {
            datum.attributes = datum;
            datum.type = 'ph-tally';
            return datum;
          });
        }

        return this.store.push({
          data
        });
      }

    })
  });

  _exports.default = _default;
});