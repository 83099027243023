define("mldp/components/payment-status-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Gw96w4Qd",
    "block": "[[[10,0],[14,0,\"flex flex-col\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1],\"Succeeded\"],null],[[[1,\"    \"],[10,0],[14,0,\"mb-4\"],[12],[1,\"\\n      Thank you, your payment was successfully processed. A transaction receipt has been emailed to the address provided. An itemized receipt can be printed from this page.\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      The MLDP enrollment(s) associated with this payment have been updated to a Paid status. You can now act on harvest recommendations, when available, and print tags.\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"Failed\"],null],[[[1,\"    \"],[10,0],[12],[1,\"\\n      Your payment could not be processed. Payment is still due for any records shown on this page. Please attempt payment again. Contact the Help Desk if you need additional assistance.\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"Cancelled\"],null],[[[1,\"      Your payment was cancelled. Payment is still due for any records shown on this page. Please attempt payment again. Contact the Help Desk if you need additional assistance.\\n  \"]],[]],null]],[]]]],[]]],[13]],[\"@paymentStatus\"],false,[\"if\",\"eq\"]]",
    "moduleName": "mldp/components/payment-status-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});