define("mldp/components/fees-table/row/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "ember-concurrency-decorators"], function (_exports, _component, _tracking, _service, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FeesTableRowComponent = (_class = class FeesTableRowComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "state", _descriptor2, this);

      _initializerDefineProperty(this, "enrollment", _descriptor3, this);

      _initializerDefineProperty(this, "currentSeason", _descriptor4, this);

      _initializerDefineProperty(this, "totalAmount", _descriptor5, this);
    }

    get withdrawalDate() {
      var _this$enrollment;

      let currentSeason = this.state.currentSeason;
      return (_this$enrollment = this.enrollment) !== null && _this$enrollment !== void 0 && _this$enrollment.isConservation ? currentSeason.coEnrollmentWithdrawalDate : currentSeason.hoEnrollmentWithdrawalDate;
    }

    *fetchEnrollment() {
      const enrollment = yield this.store.findRecord('enrollment', this.args.fee.enrollmentId);
      this.enrollment = enrollment;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "state", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "enrollment", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentSeason", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.state.currentSeason;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "totalAmount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(this.args.fee.totalAmount);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchEnrollment", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchEnrollment"), _class.prototype)), _class);
  _exports.default = FeesTableRowComponent;
});