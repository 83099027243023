define("mldp/account/property/unit/population/controller", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const allSurveyFields = [['startDate', 'Date']];
  const invididualSurveyFields = {
    1: [['flightRoute', 'Flight Name / Number'], ['percentAcreageFlown', 'Percent MU Acreage Flown']],
    2: [['flightRoute', 'Route Name / Number'], ['lengthMiles', 'Length in Miles'], ['acresOfVisibility', 'Acres of Visibility']],
    3: [['endDate', 'End Date']],
    4: [['endDate', 'End Date'], ['observationPoints', 'Number of Blinds with Observations'], ['observationPeriods', 'Number of Observation Periods']],
    5: [['endDate', 'End Date'], ['observationPoints', 'Number of Camera Sites'], ['observationPeriods', 'Number of Consecutive Days Surveyed'], ['acresSurveyed', 'Acres Surveyed']],
    6: [['acresSurveyed', 'Acres Surveyed'], ['notes', 'Notes']]
  };
  let AccountPropertyPopulationController = (_dec = (0, _service.inject)('notifications'), _dec2 = (0, _service.inject)('general-ajax'), _dec3 = (0, _service.inject)('router'), (_class = class AccountPropertyPopulationController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "ajaxService", _descriptor2, this);

      _initializerDefineProperty(this, "routerService", _descriptor3, this);
    }

    openSeasonSummaryModal(model) {
      let newSummary;

      if (model) {
        newSummary = model;
      } else {
        newSummary = this.store.createRecord('deer-population', {
          siteId: this.unit.id
        });
      }

      this.set('newSummary', newSummary);
      this.set('showSeasonSummaryModal', true);
    }

    async openEnterSurveyModal() {
      let survey = await this.store.createRecord(`deer-survey`);
      survey.setProperties({
        deerSeasonId: this.season.id,
        deerSeason: this.seasons.find(x => x.id === this.season.id),
        siteId: this.unit.id
      });
      this.set('survey', survey);
      this.set('showEnterSeasonModal', true);
    }

    changeSurveyType(type) {
      this.survey.set('surveyTypeId', type.id);
    }

    changeSurveySeason(season) {
      this.survey.set('deerSeason', season);
      this.survey.set('deerSeasonId', season.id);
    }

    async saveSurvey() {
      let notifications = this.notifications;
      const requiredFields = allSurveyFields.concat(invididualSurveyFields[this.survey.surveyTypeId]);
      let missingFields = false;
      requiredFields.forEach(current => {
        let [field, label] = current;

        if (!this.survey[field]) {
          notifications.error(`${label} is required`);
          missingFields = true;
        }
      });

      if (!missingFields) {
        try {
          await this.survey.save();
          this.set('showEnterSeasonModal', false);
          this.routerService.transitionTo('account.property.unit.surveyseason', this.site.siteType, this.site.id, this.unit.id, this.survey.deerSeasonId);
        } catch (e) {
          notifications.addApiErrors(e);
          throw e;
        }
      }
    }

    openViewNotesModal(notes) {
      this.set('selectedNotes', notes);
      this.set('showSeasonNotes', true);
    }

    async downLoadExcelReport() {
      const ajax = this.ajaxService;
      let token = await ajax.request('/api/deersurveyobservation/getauthtoken');
      window.open(`/api/deersurveyobservation/excel?siteid=${this.unit.id}&auth=${token}`, '_blank');
    }

    saveSurveyTypes() {
      const muAdapter = this.store.adapterFor('management-unit');
      const body = this.selected.map(cur => Number(cur.id));
      muAdapter.setSurveyType(this.unit.id, body);
      this.set('showSurveyTypeModal', false);
    }

    async saveSeasonSummary() {
      const {
        newSummary,
        populations
      } = this;

      if (!newSummary.deerSeasonId || !newSummary.speciesId) {
        this.notifications.error('Hunting Season and Species Type are required');
        return;
      }

      const matchedPopulation = populations.find(pop => pop.deerSeasonId === newSummary.deerSeasonId && pop.speciesId === newSummary.speciesId);

      if (matchedPopulation && !newSummary.id) {
        this.notifications.error('Population already exists for this species and season. Please edit the existing entry in the table');
        return;
      }

      await this.newSummary.save();
      this.set('showSeasonSummaryModal', false);
      this.get('target').send('refreshModel');
    }

    addType(type) {
      const idx = this.unselected.indexOf(type);
      const unSel = [...this.unselected];
      const sel = [...this.selected];
      unSel.splice(idx, 1);
      sel.push(type);
      this.set('unselected', unSel);
      this.set('selected', sel);
    }

    removeType(type) {
      const idx = this.selected.indexOf(type); // const newArray = this.selected.push(this.unselected[idx])

      const unSel = [...this.unselected];
      const sel = [...this.selected];
      sel.splice(idx, 1);
      unSel.push(type);
      this.set('unselected', unSel);
      this.set('selected', sel);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajaxService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "routerService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openSeasonSummaryModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openSeasonSummaryModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEnterSurveyModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openEnterSurveyModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSurveyType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSurveyType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSurveySeason", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSurveySeason"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveSurvey", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveSurvey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openViewNotesModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openViewNotesModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downLoadExcelReport", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "downLoadExcelReport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveSurveyTypes", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveSurveyTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveSeasonSummary", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveSeasonSummary"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeType"), _class.prototype)), _class));
  _exports.default = AccountPropertyPopulationController;
});