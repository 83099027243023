define("mldp/components/site-property-create/fence-type-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "X5I0u0Zr",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[6,[39,0],[[30,2]],null,[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n\"],[6,[30,3,[\"radio-group\"]],[\"fenceTypeId\"],[[\"options\",\"label\"],[[30,0,[\"fenceTypeOptions\"]],\"Fencing Type\"]],[[\"default\"],[[[[1,\"        \"],[8,[30,4,[\"radio-field\"]],null,[[\"@fieldClasses\",\"@labelClasses\",\"@inputClasses\"],[\"radio-inline\",\"radio-inline\",\"small-form-control\"]],null],[1,\"\\n\"]],[4]]]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[28,[37,2],[[30,2,[\"fenceTypeId\"]],2],null],[[[1,\"      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[30,3,[\"text-field\"]],[\"deerBreederFacilityNumber\"],[[\"label\",\"hint\"],[\"Deer Breeder Release Site ID\",\"Example: 1234R - enter only if applicable\"]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[3]]]]],[13]],[\"&attrs\",\"@singleMu\",\"f\",\"controls\"],false,[\"form-for\",\"if\",\"eq\"]]",
    "moduleName": "mldp/components/site-property-create/fence-type-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});