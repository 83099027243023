define("mldp/account/action/controller", ["exports", "@ember/object", "@ember/service", "@ember/controller", "rsvp"], function (_exports, _object, _service, _controller, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ActionController = (_dec = (0, _service.inject)('notifications'), _dec2 = (0, _service.inject)('store'), classic(_class = (_class2 = class ActionController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);
    }

    complete(_ref) {
      let {
        guid,
        message,
        type,
        data,
        silent
      } = _ref;
      let adapter = this.store.adapterFor('userAction');
      let notifications = this.notifications;
      return adapter.completeAction(guid, type, data).then(() => {
        if (silent && message) {
          notifications.add(message);
          return;
        }

        return this.transitionToRoute('account').then(() => {
          if (message) {
            return notifications.add(message);
          } else {
            return notifications.add('You have completed the action');
          }
        });
      }).catch(e => {
        return this.transitionToRoute('account').then(() => notifications.error(e.payload.errors[0].detail));
      });
    }

    ignoreAlert(alertBody) {
      let alert = this.model;
      let adapter = this.store.adapterFor('userAction');
      return adapter.ignoreAction(alert.actionGuid).then(() => {
        this.set('showNotice', false);
        return this.transitionToRoute('account').then(() => this.notifications.add(alertBody));
      });
    }

    toggleNPOLModal(bool) {
      this.set('NPOLConfirmModal', bool);
      return _rsvp.default.resolve('resolved');
    }

    saveForLater() {
      this.set('NPOLConfirmModal', false);
      this.transitionToRoute('account');
    }

    submitNPOLModal() {
      const {
        actionGuid
      } = this.model;
      this.send('complete', {
        guid: actionGuid
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "complete", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "complete"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "ignoreAlert", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "ignoreAlert"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleNPOLModal", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleNPOLModal"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveForLater", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "saveForLater"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "submitNPOLModal", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "submitNPOLModal"), _class2.prototype)), _class2)) || _class);
  _exports.default = ActionController;
});