define("mldp/account/property/permit/reporting/status/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jQgx5a3i",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"Reporting Status\"]],null],[1,\"\\n\\n\"],[10,\"h2\"],[12],[1,\"\\n  \"],[1,[30,0,[\"model\",\"permit\",\"season\",\"displayValue\"]]],[1,\": \"],[1,[28,[35,1],[[30,0,[\"model\",\"permitType\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,3],[\"permit/reporting-status/\",[30,0,[\"model\",\"permitType\"]]],null],null,[[\"permit\",\"harvests\"],[[30,0,[\"model\",\"permit\"]],[30,0,[\"model\",\"harvests\"]]]],null]],[],false,[\"page-banner\",\"permit-display-value\",\"component\",\"concat\"]]",
    "moduleName": "mldp/account/property/permit/reporting/status/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});