define("mldp/components/recommendations/submit-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XTjAz/dl",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  Are you sure you are ready to submit the harvest recommendations for the selected management units?\"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[1,\"\\n  An email notice will be sent to the associated landowners and agents if you proceed.\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/recommendations/submit-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});