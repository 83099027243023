define("mldp/mixins/survey-types-by-site", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    getSurveyType(siteId) {
      let ajax = this.ajaxService;
      let siteType = this.modelName;
      return ajax.request(`/${siteType}/${siteId}/surveytypes`);
    },

    setSurveyType(siteId, surveyTypes) {
      let ajax = this.ajaxService;
      let siteType = this.modelName;
      return ajax.post(`/${siteType}/${siteId}/surveytypes`, {
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify(surveyTypes)
      });
    },

    //For Coops and Aggregates, will not work with Mus
    getMemberSurveyType(siteId) {
      let ajax = this.ajaxService;
      let siteType = this.modelName;
      return ajax.request(`/${siteType}/${siteId}/membersurveytypes`);
    },

    setMemberSurveyType(siteId, surveyTypes) {
      let ajax = this.ajaxService;
      let siteType = this.modelName;
      return ajax.post(`/${siteType}/${siteId}/membersurveytypes`, {
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify(surveyTypes)
      });
    }

  });

  _exports.default = _default;
});