define("mldp/adapters/process", ["exports", "mldp/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let ProcessAdapter = classic(_class = class ProcessAdapter extends _application.default {
    async getProcessTypes() {
      let ajax = this.ajaxService;
      let response = await ajax.request('tlkprocessingtype', {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
      return response.data;
    }

    async checkCanStart(processId) {
      let ajax = this.ajaxService;
      let response = await ajax.request(`processingjob/canstart/${processId}`, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
      return response;
    }

    async startProcess(processId) {
      let ajax = this.ajaxService;
      let response = await ajax.request(`processingjob/start/${processId}`, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
      return response;
    }

    async checkLatest(processId) {
      let ajax = this.ajaxService;
      let response = await ajax.request(`processingjob/latest/${processId}`, {
        contentType: 'application/vnd.api+json',
        headers: {
          Accept: 'application/vnd.api+json'
        }
      });
      return response;
    }

  }) || _class;

  _exports.default = ProcessAdapter;
});