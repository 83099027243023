define("mldp/components/permit/ph-recommendation-view/decline-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bX4aOeT8",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Please leave a comment about why you are declining this recommendation.\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"label\"],[14,0,\"form-group form-block\"],[12],[1,\"\\n    Decline Comment\\n    \"],[8,[39,0],null,[[\"@rows\",\"@class\",\"@value\",\"@update\"],[5,\"form-control\",[30,0,[\"comment\"]],[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"comment\"]]],null]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"alert alert-warning\"],[12],[1,\"\\n    Warning - are you sure you want to decline this recommendation? This action cannot be undone.\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"one-way-textarea\",\"action\",\"mut\"]]",
    "moduleName": "mldp/components/permit/ph-recommendation-view/decline-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});