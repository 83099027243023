define("mldp/forgot-password/index/route", ["exports", "@ember/service", "@ember/routing/route", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _service, _route, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_unauthenticatedRouteMixin.default, {
    notifications: (0, _service.inject)('notifications'),

    setupController(controller) {
      controller.set('forgotPasswordModel', {});
    },

    actions: {
      submit(data) {
        let notifications = this.notifications;
        let adapter = this.store.adapterFor('user');
        let message = 'Please check your email for instructions on changing your password';
        return adapter.requestPasswordChange(data.email).then(() => this.transitionTo('index')).then(() => notifications.add(message, {
          type: 'info'
        })).catch(e => {
          let message = '';
          e.errors.forEach(err => {
            message += `${err.detail}\n`;
          });
          notifications.add(message);
        });
      }

    }
  });

  _exports.default = _default;
});