define("mldp/components/show-page-banner/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kVAs8Ca6",
    "block": "[[[11,0],[24,0,\"container-fluid\"],[17,1],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,0,[\"state\",\"pageBannerTitle\"]]],null]],[1,\"\\n\\n  \"],[10,0],[14,0,\"row page-head\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,[30,0,[\"state\",\"pageBannerTitle\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"page-title\"]]",
    "moduleName": "mldp/components/show-page-banner/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});