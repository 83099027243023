define("mldp/expired-password/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "e+TDSkf1",
    "block": "[[[1,[28,[35,0],[\"Expired Password\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"container login-page\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-md-5 center-block login-form\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-md-12\"],[12],[1,\"\\n          \"],[10,\"h3\"],[12],[1,\"Expired LMA Password\"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-md-10 col-md-offset-1\"],[12],[1,\"\\n          \"],[10,2],[14,0,\"login-desc\"],[12],[1,\"Your LMA password has expired and must be changed.\"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"spacer\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-md-10 col-md-offset-1\"],[12],[1,\"\\n\"],[6,[39,1],[[30,0,[\"model\"]]],[[\"submit\"],[[28,[37,2],[\"resetPassword\",[30,0,[\"model\"]]],null]]],[[\"default\"],[[[[1,\"            \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n              \"],[1,[28,[30,1,[\"password-field\"]],[\"currentPassword\"],null]],[1,\"\\n              \"],[1,[28,[30,1,[\"password-field\"]],[\"newPassword\"],null]],[1,\"\\n              \"],[1,[28,[30,1,[\"password-field\"]],[\"confirmNewPassword\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,0],[14,0,\"form-group clearfix\"],[12],[1,\"\\n              \"],[1,[28,[30,1,[\"submit\"]],[\"Change Password\"],[[\"pending\",\"class\"],[\"Submitting..\",\"btn-block\"]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"f\"],false,[\"page-banner\",\"form-for\",\"route-action\"]]",
    "moduleName": "mldp/expired-password/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});