define("mldp/components/property-agreement-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Id+D2WGb",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[28,[37,1],[[28,[37,2],[[30,0,[\"property\",\"agreementDate\"]]],null],[28,[37,2],[[30,0,[\"property\",\"isPublicLand\"]]],null]],null],[[[1,\"    \"],[10,0],[14,0,\"padded center-block\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"          \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"          Land owner must first accept the property agreement, before continuing with this task.\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"],[41,[28,[37,5],[\"accept rta agreement in properties\",[30,0,[\"property\"]]],null],[[[1,\"        \"],[10,\"button\"],[14,0,\"btn btn-primary btn-block\"],[15,\"onclick\",[28,[37,6],[[30,0],[28,[37,7],[[30,0,[\"showAgreement\"]]],null],true],null]],[14,4,\"button\"],[12],[1,\"\\n          Show Agreement\\n        \"],[13],[1,\"\\n\"],[41,[30,0,[\"showAgreement\"]],[[[1,\"          \"],[8,[39,8],null,[[\"@named\",\"@send\"],[\"modal\",[50,\"modal-window\",0,null,[[\"title\",\"submitLabel\",\"body\",\"close\",\"onsubmit\"],[\"Accept Agreement\",\"Agree\",[50,\"rta-agreement\",0,null,null],[28,[37,6],[[30,0],[28,[37,7],[[30,0,[\"showAgreement\"]]],null],false],null],[28,[37,6],[[30,0],[28,[37,10],[[28,[37,6],[[30,0],\"acceptAgreement\",[30,0,[\"property\"]]],null],[28,[37,6],[[30,0],[28,[37,7],[[30,0,[\"showAgreement\"]]],null],false],null]],null]],null]]]]]],null],[1,\"\\n\"]],[]],null]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"and\",\"not\",\"has-block\",\"yield\",\"can\",\"action\",\"mut\",\"to-elsewhere\",\"component\",\"pipe\"]]",
    "moduleName": "mldp/components/property-agreement-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});