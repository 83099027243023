define("mldp/maintenance/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HmsQYV+N",
    "block": "[[[10,0],[14,0,\"maintenance login-bg\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container login-page\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row form-group\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-md-5 center-block bg-container clearfix\"],[12],[1,\"\\n        \"],[10,\"h3\"],[12],[1,\"LMA Maintenance\"],[13],[1,\"\\n        \"],[10,2],[12],[1,[28,[35,0],[[30,0,[\"state\",\"maintenance\",\"message\"]],\"We are currently performing maintenance and will be back shortly. Thank you for your patience.\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"or\"]]",
    "moduleName": "mldp/maintenance/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});