define("mldp/account/profile/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "E4rw4Ep+",
    "block": "[[[1,[28,[35,0],[\"My Profile\"],null]],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"model\",\"onSubmit\",\"onReset\",\"onChangePassword\"],[[30,0,[\"profile\"]],[28,[37,2],[\"save\"],null],[28,[37,2],[\"reset\"],null],[28,[37,2],[\"changePassword\"],null]]]]]],[],false,[\"page-banner\",\"profile-form\",\"route-action\"]]",
    "moduleName": "mldp/account/profile/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});