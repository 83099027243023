define("mldp/mixins/has-manager", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    inviteManager(siteId, inviteModel) {
      let ajax = this.ajaxService;
      let siteType = this.modelName;
      return ajax.post(`/${siteType}/${siteId}/inviteManager/`, {
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify(inviteModel.deserialize())
      });
    }

  });

  _exports.default = _default;
});