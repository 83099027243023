define("mldp/components/permit/ph-recommendation-view/accept-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Q3U1b2I8",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[10,\"b\"],[12],[1,\"Site:\"],[13],[1,\" \"],[1,[30,0,[\"siteName\"]]],[13],[1,\"\\n  \"],[10,2],[12],[10,\"b\"],[12],[1,\"Herd Unit:\"],[13],[1,\" \"],[1,[30,0,[\"herdUnitId\"]]],[13],[1,\"\\n  \"],[10,2],[12],[1,\"By clicking the “Accept Recommendations” button below, I certify each of the following statements: \"],[13],[1,\"\\n  \"],[10,\"ul\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"I am the landowner of the above described property or an authorized agent for the landowner. \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"I acknowledge the recommended pronghorn harvest and understand that I will be issued an appropriate number of permits to sustain healthy pronghorn populations.\"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"I understand this recommendation and associated permits apply only to the acreage included on this application which lies within the Herd Unit referenced above.\"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"I understand that each permit issued is valid for the harvest of one pronghorn.\"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"I understand I am required to maintain a daily harvest log on a form provided by the department. \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"I understand that all harvest data must be submitted no later than October 31, and failure to report harvest data by this deadline will result in pronghorn permit application ineligibility for one year following the failure to report.\"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"I understand all individuals who hunt pronghorn on this property are required to possess the appropriate Resident or Non-resident General hunting license.\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/permit/ph-recommendation-view/accept-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});