define("mldp/components/permit/select-mus/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SelectMus = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class SelectMus extends _component2.default {
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.setProperties({
        noEligibleUnits: this.units.every(unit => !unit.eligibility || !unit.eligibility.isEligible),
        noSelectedUnits: !this.selectedUnits.length
      });
    }

  }) || _class) || _class);
  _exports.default = SelectMus;
});