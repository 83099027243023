define("mldp/components/admin/refund-modal/refund-details/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RefundModalRefundDetails = (_class = class RefundModalRefundDetails extends _component.default {
    get formattedFeesTotal() {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(this.args.selectedItem.amount);
    }

    get convertLocalDateToUTCDate() {
      var _this$args$selectedRe;

      let date = (_this$args$selectedRe = this.args.selectedRefundDate) === null || _this$args$selectedRe === void 0 ? void 0 : _this$args$selectedRe.date;

      if (date) {
        let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        let offset = date.getTimezoneOffset() / 60;
        let hours = date.getHours();
        newDate.setHours(hours + offset);
        return newDate;
      } else {
        return '';
      }
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centerMonth", _descriptor, this);

      _initializerDefineProperty(this, "centerYear", _descriptor2, this);

      _initializerDefineProperty(this, "years", _descriptor3, this);

      _initializerDefineProperty(this, "months", _descriptor4, this);

      this.today = new Date();
      this.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      this.centerMonth = this.months[this.today.getMonth()];
      this.centerYear = this.today.getYear() + 1900 + '';
    }

    changeCenter(unit, calendar, e) {
      let newCenter = new Date(calendar.center);

      if (unit === 'month') {
        newCenter.setMonth(this.months.indexOf(e.target.value));
      } else if (unit === 'year') {
        newCenter.setFullYear(e.target.value);
      }

      calendar.actions.changeCenter(newCenter);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centerMonth", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "centerYear", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "years", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Array(...Array(85)).map((_, i) => `${i + 1940}`);
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "months", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "changeCenter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeCenter"), _class.prototype)), _class);
  _exports.default = RefundModalRefundDetails;
});