define("mldp/components/survey-season/delete-survey-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jIsCWf3H",
    "block": "[[[10,2],[12],[1,\"Are you sure you'd like to delete this survey? Doing so will also delete any observations of other species that were submitted at the same time.\"],[13]],[],false,[]]",
    "moduleName": "mldp/components/survey-season/delete-survey-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});