define("mldp/account/consultation/route", ["exports", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ConsultationRoute = (_dec = (0, _service.inject)('session'), _dec2 = (0, _service.inject)('state'), classic(_class = (_class2 = class ConsultationRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionService", _descriptor, this);

      _initializerDefineProperty(this, "stateService", _descriptor2, this);
    }

    async model(_ref) {
      let {
        consultationId
      } = _ref;
      let types = this.get('stateService.consultationTypes');
      let biologists = this.get('stateService.biologists');
      let counties = this.get('stateService.counties');
      let isAdmin = this.get('sessionService.isAdmin');
      let isSupervisor = this.get('sessionService.isSupervisor');
      let consultation = await this.store.findRecord('consultation', consultationId, {
        include: 'otherStaff',
        reload: true
      });
      let siteId = consultation.get('siteId');
      let otherStaff = consultation.get('otherStaff');
      let customBiologists = isAdmin || isSupervisor;
      let query = {};
      let supervisor;

      if (isAdmin) {
        // otherstaff is admin, supervisors, and biologists
        query['filter[otherstaff]'] = true;
      } else if (isSupervisor) {
        let supervisorId = this.get('sessionService.userId');
        supervisor = await this.store.findRecord('user', supervisorId);
        query['filter[supervisorid]'] = supervisorId;
      }

      let result = {
        consultation,
        types,
        biologists: customBiologists ? this.store.query('user', query) : biologists,
        counties,
        supervisor
      };

      if (siteId) {
        result.site = this.store.findRecord('site', siteId, {
          reload: true
        });
      }

      if (otherStaff.get('length') !== 0) {
        let users = await (0, _rsvp.all)(otherStaff.map(staff => {
          return this.store.findRecord('user', staff.get('userId')).catch(e => {
            console.warn(e);
          });
        }));
        result.consultation.set('selectedUsers', users);
      }

      return (0, _rsvp.hash)(result);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "sessionService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = ConsultationRoute;
});