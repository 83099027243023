define("mldp/components/practice/exotic-vegetation/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HKl/1byr",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"create\"]],[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[33,1,[\"text-field\"]],[\"description\"],[[\"label\",\"required\"],[\"Species\",true]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[33,1,[\"text-field\"]],[\"method\"],[[\"label\",\"required\"],[\"Method\",true]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[33,1,[\"number-field\"]],[\"acres\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[33,1,[\"number-field\"]],[\"number\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"model\",\"type\",\"label\"],[[30,0,[\"model\"]],\"description\",\"Species\"]]]],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"model\",\"type\",\"label\"],[[30,0,[\"model\"]],\"method\",\"Method\"]]]],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"model\",\"type\"],[[30,0,[\"model\"]],\"acres\"]]]],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"model\",\"type\"],[[30,0,[\"model\"]],\"number\"]]]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\"],false,[\"if\",\"f\",\"practice/display-field\"]]",
    "moduleName": "mldp/components/practice/exotic-vegetation/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});