define("mldp/utils/date-quarter", ["exports", "moment", "mldp/utils/get-quarters"], function (_exports, _moment, _getQuarters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = dateQuarter;

  /**
   * Q1 starts jan 1 - ends march 31
   * Q2 starts april 1 - ends june 30 
   * Q3 starts july 1 - ends september 30
   * Q4 starts oct 1 - ends dec 31st
   * 
   * @param {*} date 
   */
  function dateQuarter(date) {
    if (!date) {
      return;
    }

    let mo = (0, _moment.default)(date);
    let quarters = (0, _getQuarters.default)();
    let first = quarters['1'];
    let second = quarters['2'];
    let third = quarters['3'];
    let fourth = quarters['4'];

    if (mo.isBetween(first.start, first.end)) {
      return 1;
    } else if (mo.isBetween(second.start, second.end)) {
      return 2;
    } else if (mo.isBetween(third.start, third.end)) {
      return 3;
    } else if (mo.isBetween(fourth.start, fourth.end)) {
      return 4;
    }

    return;
  }
});