define("mldp/account/property/enrollment/reporting-status/route", ["exports", "@ember/routing/route", "rsvp", "@ember/array"], function (_exports, _route, _rsvp, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadCrumb: {
      title: 'Reporting Status'
    },

    model() {
      let enrollment = this.modelFor('account.property.enrollment');
      let reporting = enrollment.fetchReporting();
      return (0, _rsvp.hash)({
        enrollment,
        reporting
      });
    },

    setupController(controller, resolved) {
      var _resolved$reporting$h, _resolved$reporting$h2;

      let primarySite = resolved.enrollment.get('primarySite');
      let isAggregate = primarySite.siteType === 'aggregate';
      let practiceUnitIds = ((_resolved$reporting$h = resolved.reporting.habitatPractice) === null || _resolved$reporting$h === void 0 ? void 0 : _resolved$reporting$h.mapBy('siteId')) || [];
      let harvestUnitIds = ((_resolved$reporting$h2 = resolved.reporting.harvest) === null || _resolved$reporting$h2 === void 0 ? void 0 : _resolved$reporting$h2.mapBy('siteId')) || [];
      let siteIds = (0, _array.A)([...practiceUnitIds, ...harvestUnitIds]).uniq();
      let sites = siteIds.map(siteId => {
        let [practice] = resolved.reporting.habitatPractice.filter(report => report.siteId === siteId);
        let [harvest] = resolved.reporting.harvest.filter(report => report.siteId === siteId);
        let siteName = (harvest === null || harvest === void 0 ? void 0 : harvest.siteName) || (practice === null || practice === void 0 ? void 0 : practice.siteName);
        let site = {
          id: siteId,
          siteType: 'management unit',
          siteName
        };

        if (isAggregate && primarySite.id === siteId) {
          site = {
            siteType: primarySite.siteType,
            id: primarySite.id,
            siteName: primarySite.siteName
          };
        }

        return {
          site,
          harvest,
          practice,
          show: harvest || practice
        };
      });
      resolved.sites = sites.sort((a, b) => {
        let aName = a.site.siteName;
        let bName = b.site.siteName;
        return aName < bName ? -1 : 1;
      });
      controller.setProperties(resolved);
    }

  });

  _exports.default = _default;
});