define("mldp/helpers/generate-color", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.generateColor = generateColor;

  function generateColor(_ref, _ref2) {
    let [unit, selectedUnits, hoverUnit] = _ref;
    let {
      colors
    } = _ref2;
    let isSelected = selectedUnits.includes(unit);
    let isHovered = unit === hoverUnit;

    if (isSelected && isHovered) {
      return colors.selectedHover;
    } else if (isHovered) {
      return colors.defaultHover;
    } else if (isSelected) {
      return colors.selected;
    } else {
      return colors.default;
    }
  }

  var _default = (0, _helper.helper)(generateColor);

  _exports.default = _default;
});