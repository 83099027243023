define("mldp/components/fees-table/component", ["exports", "@glimmer/component", "@glimmer/tracking", "ember-concurrency-decorators", "@ember/service"], function (_exports, _component, _tracking, _emberConcurrencyDecorators, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FeesTableComponent = (_class = class FeesTableComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "state", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "displayPaymentSummaryModal", _descriptor4, this);

      _initializerDefineProperty(this, "displayFeeDetail", _descriptor5, this);

      _initializerDefineProperty(this, "selectedFees", _descriptor6, this);

      _initializerDefineProperty(this, "currentSeason", _descriptor7, this);

      _initializerDefineProperty(this, "feeSchedules", _descriptor8, this);
    }

    get feesSubTotal() {
      return this.selectedFees.reduce(function (accumulator, fee) {
        return accumulator + fee.totalAmount;
      }, 0);
    }

    get formattedFeesSubTotal() {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(this.feesSubTotal);
    }

    *updateInvoice(fee, event) {
      const adapter = this.store.adapterFor('feecart');

      try {
        if (event.target.checked) {
          yield adapter.addInvoice(fee.id);
          this.selectedFees.pushObject(fee);
        } else {
          yield adapter.removeInvoice(fee.id);
          this.selectedFees.removeObject(fee);
        }
      } catch (e) {
        if (event.target.checked) {
          var _e$payload;

          let message = 'The fee invoice you selected cannot be added to your cart.';

          if ((_e$payload = e.payload) !== null && _e$payload !== void 0 && _e$payload.errors && e.payload.errors.length) {
            message = e.payload.errors[0].detail;
          }

          this.notifications.error(message);
          event.target.checked = false;
        } else {
          var _e$payload2;

          let message = 'The fee invoice you selected cannot be removed from your cart.';

          if ((_e$payload2 = e.payload) !== null && _e$payload2 !== void 0 && _e$payload2.errors && e.payload.errors.length) {
            message = e.payload.errors[0].detail;
          }

          this.notifications.error(message);
          event.target.checked = true;
        }
      }
    }

    *fetchFeeSchedules() {
      const adapter = this.store.adapterFor('feeschedule');
      const feeSchedules = yield adapter.getForSeason(this.currentSeason.id);
      this.feeSchedules = feeSchedules;
    }

    *startCheckout() {
      const adapter = this.store.adapterFor('feecart');
      const cart = this.store.peekAll('fee-cart');
      const cartId = cart.toArray()[0].id;

      try {
        const {
          paymentUrl
        } = yield adapter.startCheckout(cartId);
        this.displayPaymentSummaryModal = false;
        window.location.href = paymentUrl;
      } catch (e) {
        var _e$payload3;

        this.displayPaymentSummaryModal = false;
        let message = 'The checkout process could not be started.';

        if ((_e$payload3 = e.payload) !== null && _e$payload3 !== void 0 && _e$payload3.errors && e.payload.errors.length) {
          message = e.payload.errors[0].detail;
        }

        this.notifications.error(message);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "state", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "displayPaymentSummaryModal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "displayFeeDetail", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedFees", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.fees.filter(fee => fee.paymentStatus === 'In Cart');
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "currentSeason", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.state.currentSeason;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "feeSchedules", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateInvoice", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateInvoice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchFeeSchedules", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchFeeSchedules"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startCheckout", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "startCheckout"), _class.prototype)), _class);
  _exports.default = FeesTableComponent;
});