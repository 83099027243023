define("mldp/components/site/consultations-list/component", ["exports", "@ember/object", "@ember-decorators/component", "@ember/service", "@ember/component"], function (_exports, _object, _component, _service, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ConsultationsList = (_dec = (0, _component.tagName)(''), _dec2 = (0, _service.inject)('store'), classic(_class = _dec(_class = (_class2 = class ConsultationsList extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "storeService", _descriptor, this);

      _defineProperty(this, "showConsultations", false);
    }

    async loadConsultations() {
      let store = this.storeService;
      let siteId = this.get('site.id');
      let consultations = await store.query('consultation', {
        'filter[site]': siteId
      });
      this.set('consultations', consultations);
    }

    toggleConsultations() {
      let consultations = this.consultations;
      let show = this.toggleProperty('showConsultations');

      if (show && !consultations) {
        this.loadConsultations();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "storeService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "toggleConsultations", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleConsultations"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = ConsultationsList;
});