define("mldp/account/property/harvests/index/route", ["exports", "@ember/routing/route", "rsvp"], function (_exports, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let IndexRoute = classic(_class = class IndexRoute extends _route.default {
    model() {
      let {
        property: site
      } = this.modelFor('account.property');

      if (site.get('siteType') !== 'aggregate') {
        return _rsvp.default.reject('Only aggregate sites can contain harvests');
      }

      return _rsvp.default.hash({
        site,
        harvests: site.findHarvests()
      });
    }

    setupController(controller, resolved) {
      resolved.harvests = resolved.harvests.sortBy('deerSeasonId').reverse();
      controller.setProperties(resolved);
    }

  }) || _class;

  _exports.default = IndexRoute;
});