define("mldp/account/permits/ph-recommendations/route", ["exports", "@ember/service", "@ember/routing/route", "rsvp", "@ember/array", "@ember/object", "moment"], function (_exports, _service, _route, _rsvp, _array, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PhRecommendationsRoute = (_dec = (0, _service.inject)('store'), _dec2 = (0, _service.inject)('state'), _dec3 = (0, _service.inject)('session'), _dec4 = (0, _service.inject)('role-service'), _dec5 = (0, _service.inject)('ajax'), classic(_class = (_class2 = class PhRecommendationsRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "stateService", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "roleService", _descriptor4, this);

      _initializerDefineProperty(this, "ajax", _descriptor5, this);
    }

    async model(opts) {
      //get ph tally current season
      const {
        herdUnitId
      } = opts;
      const seasonAdapter = this.store.adapterFor('tlk-season');
      const phTallyAdapter = this.store.adapterFor('ph-tally');
      let currentSeason = await seasonAdapter.phFindCurrentReporting();
      let nextSeason = await this.store.find('tlk-season', Number(currentSeason.id) + 1);
      let today = (0, _moment.default)();

      if (today.isAfter(currentSeason.phSeasonEndDate)) {
        currentSeason = nextSeason;
      }

      let options = {
        'filter[herdunit]': herdUnitId,
        'filter[seasonid]': currentSeason.id,
        'sort': '-PrimarySite.CalculatedAcreage',
        mushapes: true
      };

      if (herdUnitId === '999' && !this.roleService.isAdmin) {
        let allCounties = this.get('stateService.counties');
        let countyNames = this.get('session.profile.counties');
        let counties = allCounties.filter(county => countyNames.includes(county.get('displayValue'))).map(county => county.id);
        if (counties.length) options['filter[countyid]'] = counties;
      } //TODO: will probably need Herd unit Tally for previous season as well


      const herdUnitTally = phTallyAdapter.findAllForSeason({
        params: {
          seasonId: currentSeason.id
        }
      });
      const phPermits = this.store.query('ph-permit-app', options);
      const hash = {
        currentSeason,
        phPermits,
        herdUnitTally,
        herdUnitId
      };
      return _rsvp.default.hash(hash);
    }

    setupController(controller, resolved) {
      const {
        herdUnitTally,
        herdUnitId,
        phPermits
      } = resolved;
      phPermits.forEach(app => {
        app.set('selected', false);
        const partition = app.phPartitions.find(cur => parseInt(cur.herdUnit) === parseInt(herdUnitId));
        partition.newPhRecommendations = (0, _array.A)();
        partition.totalBuckThisHu = partition.phRecommendations.reduce((acc, cur) => acc + cur.recommendedBucks, 0);
        partition.totalDoeThisHu = partition.phRecommendations.reduce((acc, cur) => acc + cur.recommendedDoes, 0);
        let totalBuckAllHus = 0,
            totalDoeAllHus = 0,
            otherHusToolTipText = [];
        app.phPartitions.forEach(partion => {
          if (parseInt(partion.herdUnit) !== parseInt(herdUnitId)) {
            let totalRecs = partion.phRecommendations.reduce((acc, cur) => acc + cur.recommendedBucks + cur.recommendedDoes, 0);
            otherHusToolTipText.push(`Herd Unit ${partion.herdUnit}: ${totalRecs} Total Recommendations`);
          }

          partion.phRecommendations.forEach(rec => {
            if (!rec.invalidated) {
              totalBuckAllHus += rec.recommendedBucks;
              totalDoeAllHus += rec.recommendedDoes;
            }
          });
        });
        app.setProperties({
          partition,
          totalBuckAllHus,
          totalDoeAllHus,
          otherHusToolTipText
        });
      });
      resolved.phPermits = (0, _array.A)(phPermits.toArray().sort(sortByHerdUnitAcreage));
      resolved.tally = herdUnitTally ? herdUnitTally.find(tally => tally.herdUnit === parseInt(herdUnitId)) : undefined;
      resolved.newTotalBuckIssued = resolved.tally ? resolved.tally.issuedPermitsBucks : undefined;
      resolved.permitAppCount = resolved.phPermits.length;
      controller.setProperties(resolved);
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('newTotalBuckIssued', controller.tally ? controller.tally.issuedPermitsBucks : undefined);
      }
    }

    refreshModel() {
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "roleService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "refreshModel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "refreshModel"), _class2.prototype)), _class2)) || _class);
  _exports.default = PhRecommendationsRoute;

  function sortByHerdUnitAcreage(a, b) {
    if (a.partition.totalAcreage > b.partition.totalAcreage) {
      return -1;
    } else if (a.partition.totalAcreage < b.partition.totalAcreage) {
      return 1;
    }

    return 0;
  }
});