define("mldp/components/enrollment/other-enrollment-warning/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OtherEnrollmentWarning extends _component.default {}

  _exports.default = OtherEnrollmentWarning;

  window.__CLASSIC_OWN_CLASSES__.set(OtherEnrollmentWarning, true);
});