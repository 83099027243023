define("mldp/contact/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ml59VPlO",
    "block": "[[[10,0],[14,1,\"contact-page\"],[14,0,\"container-fluid\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"panel panel-default\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"panel-heading\"],[12],[1,\"\\n          \"],[10,\"h3\"],[14,0,\"panel-title\"],[12],[1,\"We are here to help!\"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"panel-body\"],[12],[1,\"\\n          \"],[10,2],[12],[1,\"\\n            Call our Help Desk: \"],[10,3],[14,6,\"tel:1-512-389-4815\"],[14,\"title\",\"Call Help Desk\"],[12],[1,\"1-512-389-4815\"],[13],[10,\"br\"],[12],[13],[1,\"\\n            Available Monday to Friday 8:00 AM to 5:00 PM excluding Texas State Holidays\\n          \"],[13],[1,\"\\n\\n          \"],[10,2],[12],[1,\"\\n            Send us an Email: \"],[10,3],[14,6,\"mailto:LMA.Help@tpwd.texas.gov\"],[14,\"title\",\"Email Support\"],[12],[1,\"LMA.Help@tpwd.texas.gov\"],[13],[10,\"br\"],[12],[13],[1,\"\\n            Find a Bioligist: \"],[10,3],[14,6,\"http://tpwd.texas.gov/landwater/land/technical_guidance/biologists/\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[14,\"title\",\"Link to help finding a biologist\"],[12],[1,\"http://tpwd.texas.gov/landwater/land/technical_guidance/biologists\"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "mldp/contact/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});