define("mldp/helpers/route-idle", ["exports", "ember-app-scheduler/helpers/route-idle"], function (_exports, _routeIdle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _routeIdle.default;
    }
  });
  Object.defineProperty(_exports, "routeIdle", {
    enumerable: true,
    get: function () {
      return _routeIdle.routeIdle;
    }
  });
});