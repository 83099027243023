define("mldp/states/amd-recommendations", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let StateObject = classic(_class = class StateObject extends _object.default {}) || _class;

  window.__CLASSIC_OWN_CLASSES__.set(StateObject, true);

  const createAdd = (state, tick) => {
    return (groupId, partitions) => {
      let data = partitions.reduce((all, p) => {
        all.partitions.addObject(_object.default.create({
          groupId,
          partitionId: p.id,
          compartment: p.compartment,
          count: 0
        }));
        return all;
      }, {
        partitions: []
      });

      let rec = _object.default.create(data);

      state.recommendations.unshiftObject(rec);
      tick();
      return rec;
    };
  };

  const createRemove = (state, tick) => {
    return rec => {
      state.recommendations.removeObject(rec);
      tick();
    };
  };

  const createUpdate = (_state, key, tick) => {
    return (rec, id, value) => {
      let item = rec.partitions.findBy(key, id);

      if (item) {
        item.set('count', value);
        tick();
      }
    };
  };

  StateObject.reopenClass({
    initialState(instance) {
      let state = _object.default.create({
        recommendations: []
      }); // Calls the action `stateUpdated` passed to the permit-mu component
      // This updates `stateUpdated` on the parent which gets used by sibling components


      let tick = () => {
        instance.stateUpdated(new Date());
      };

      state.addRecommendation = createAdd(state, tick);
      state.removeRecommendation = createRemove(state, tick);
      state.updatePartition = createUpdate(state, 'partitionId', tick);
      state.updatePartitionByCompartment = createUpdate(state, 'compartment', tick);
      return state;
    }

  });
  var _default = StateObject;
  _exports.default = _default;
});