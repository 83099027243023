define("mldp/components/managementunit-new-property/component", ["exports", "@ember-decorators/component", "@ember-decorators/object", "@ember/object", "@ember/component", "@ember/runloop"], function (_exports, _component, _object, _object2, _component2, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ManagementunitNewProperty = (_dec = (0, _component.tagName)(""), _dec2 = (0, _object.observes)('model.fenceTypeId'), _dec(_class = classic(_class = (_class2 = class ManagementunitNewProperty extends _component2.default {
    init() {
      super.init(...arguments);
      let fenceTypes = this.fenceTypes;
      this.fenceTypeOptions = fenceTypes.map(type => {
        return {
          value: type.get('identifier'),
          label: type.get('displayValue')
        };
      });
    }

    didReceiveAttrs() {
      _runloop.run.next(this, () => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        let units = this.units;
        let siteId = this.get('model.id');

        if (units) {
          this.set('filteredUnits', units.filter(u => u.get('id') !== siteId));
        }
      });
    }

    fenceTypeChanged() {
      let model = this.model;

      if (model.fenceTypeId === 1) {
        model.set('deerBreederFacilityNumber', undefined);
      }
    }

    drawBoundary() {
      this.eventsBus.trigger('appMapEditable:drawBoundary');
    }

    editBoundary() {
      this.eventsBus.trigger('appMapEditable:editBoundary');
    }

    deleteBoundary() {
      this.eventsBus.trigger('appMapEditable:deleteBoundary');
    }

    geoJsonLayerAdd(e) {
      e.target._map.fitBounds(e.target.getBounds());
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "fenceTypeChanged", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "fenceTypeChanged"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "drawBoundary", [_object2.action], Object.getOwnPropertyDescriptor(_class2.prototype, "drawBoundary"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "editBoundary", [_object2.action], Object.getOwnPropertyDescriptor(_class2.prototype, "editBoundary"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteBoundary", [_object2.action], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteBoundary"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "geoJsonLayerAdd", [_object2.action], Object.getOwnPropertyDescriptor(_class2.prototype, "geoJsonLayerAdd"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = ManagementunitNewProperty;
});