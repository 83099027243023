define("mldp/account/property/population/route", ["exports", "@ember/routing/route", "@ember/service", "@ember/object", "rsvp"], function (_exports, _route, _service, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let AccountPropertyPopulationRoute = (_dec = (0, _service.inject)('state'), classic(_class = (_class2 = class AccountPropertyPopulationRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);
    }

    async model() {
      let {
        property
      } = this.modelFor('account.property');
      let currentSeasonId = this.get('state.currentSeason.identifier');
      let seasons = this.get('state.seasons');
      let currentSeason = this.get('state.currentSeason');
      const siteAdapter = this.store.adapterFor('site');
      const deerPopAdapter = this.store.adapterFor('deer-population');
      const currentSeasonStats = await siteAdapter.getSiteStatistics(property.id);
      const populations = await deerPopAdapter.findAllForSite({
        params: {
          siteId: property.id
        }
      }); //Add rows to populatin table if survey entries exist for a season

      currentSeasonStats.forEach(stat => {
        if (!stat.surveyCount) return;
        const found = populations.find(pop => pop.deerSeasonId === stat.seasonId && pop.speciesId === stat.speciesId);

        if (!found) {
          populations.push({
            statistics: { ...stat
            },
            deerSeasonId: stat.seasonId,
            speciesId: stat.speciesId,
            unsubmittedDeerPopulation: true
          });
        }
      });
      seasons = this.state.seasons.filter(x => x.identifier <= currentSeasonId);
      const season = this.state.seasons.find(cur => cur.identifier === currentSeasonId); //sort descending by deer season

      populations.sort((a, b) => b.deerSeasonId - a.deerSeasonId);
      return _rsvp.default.hash({
        site: property,
        currentSeasonId,
        populations,
        currentSeason,
        seasons,
        season
      });
    }

    async setupController(controller, resolved) {
      controller.setProperties(resolved);
      const {
        site
      } = resolved;
      const muAdapter = this.store.adapterFor(site.siteType);
      const currentTypes = await muAdapter.getSurveyType(site.id);
      const allTypes = this.state.surveyTypes;
      controller.set('unselectedSurveyTypes', [...allTypes].filter(_ref => {
        let {
          id
        } = _ref;
        return !currentTypes.includes(Number(id));
      }));

      if (currentTypes.length) {
        controller.set('selectedSurveyTypes', [...allTypes].filter(_ref2 => {
          let {
            id
          } = _ref2;
          return currentTypes.includes(Number(id));
        }));
      } else {
        controller.set('selectedSurveyTypes', [...allTypes]);
      }

      if (site.siteType === 'coop') {
        const currentMemberTypes = await muAdapter.getMemberSurveyType(site.id);
        controller.set('unselectedMemberSurveyTypes', [...allTypes].filter(_ref3 => {
          let {
            id
          } = _ref3;
          return !currentMemberTypes.includes(Number(id));
        }));
        controller.set('selectedMemberSurveyTypes', [...allTypes].filter(_ref4 => {
          let {
            id
          } = _ref4;
          return currentMemberTypes.includes(Number(id));
        }));

        if (currecurrentMemberTypestTypes.length) {
          controller.set('selectedSurveyTypes', [...allTypes].filter(_ref5 => {
            let {
              id
            } = _ref5;
            return currentMemberTypes.includes(Number(id));
          }));
        } else {
          controller.set('selectedSurveyTypes', [...allTypes]);
        }
      }
    }

    refreshModel() {
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "refreshModel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "refreshModel"), _class2.prototype)), _class2)) || _class);
  _exports.default = AccountPropertyPopulationRoute;
});