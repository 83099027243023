define("mldp/components/practice/form-body/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Fh42melm",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"create\"]],[[[1,\"    \"],[46,[30,0,[\"componentName\"]],null,[[\"acres\",\"number\",\"f\",\"create\"],[[30,0,[\"model\",\"acres\"]],[30,0,[\"model\",\"number\"]],[30,0,[\"f\"]],[30,0,[\"create\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"create\",\"model\"],[[30,0,[\"create\"]],[30,0,[\"model\"]]]]]],[1,\"\\n    \"],[46,[30,0,[\"componentName\"]],null,[[\"model\",\"create\"],[[30,0,[\"model\"]],[30,0,[\"create\"]]]],null],[1,\"\\n\"],[41,[28,[37,3],[[30,0,[\"model\",\"habitatProjects\"]]],null],[[[1,\"      \"],[10,\"dt\"],[12],[1,\"Habitat Projects\"],[13],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"model\",\"habitatProjects\"]]],null]],null],null,[[[1,\"        \"],[10,\"dd\"],[12],[1,[30,2,[\"projectName\"]]],[13],[1,\"\\n\"]],[2]],null]],[]],null]],[]]],[13]],[\"&attrs\",\"project\"],false,[\"if\",\"component\",\"practice/deer-season\",\"has-items\",\"each\",\"-track-array\"]]",
    "moduleName": "mldp/components/practice/form-body/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});