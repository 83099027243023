define("mldp/components/site-project-layer/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "oWcfbhcb",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"projects\"]]],null]],null],null,[[[41,[30,2,[\"geoJSON\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@geoJSON\",\"@color\",\"@onAdd\",\"@fillOpacity\"],[[30,2,[\"geoJSON\"]],\"#009933\",[30,0,[\"onAdd\"]],0.1]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[39,5],[[24,0,\"push-down block\"]],[[\"@route\",\"@models\"],[\"account.property.project\",[28,[37,6],[[30,0,[\"site\",\"siteType\"]],[30,0,[\"site\",\"id\"]],[30,2,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n            Habitat Project Id \"],[1,[30,2,[\"id\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n\\n          \"],[10,0],[12],[1,\"\\n            \"],[1,[30,2,[\"projectName\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[12],[1,\"\\n            \"],[1,[30,2,[\"habitatProjectType\",\"displayValue\"]]],[1,\"\\n          \"],[13],[1,\"\\n\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null]],[2]],null],[13]],[\"&attrs\",\"project\"],false,[\"each\",\"-track-array\",\"if\",\"geojson-layer\",\"popup-layer\",\"link-to\",\"array\"]]",
    "moduleName": "mldp/components/site-project-layer/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});