define("mldp/components/my-alerts/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-concurrency-decorators"], function (_exports, _component, _tracking, _object, _service, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MyAlerts = (_class = class MyAlerts extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "state", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "roleService", _descriptor4, this);

      _initializerDefineProperty(this, "alerts", _descriptor5, this);

      _initializerDefineProperty(this, "isViewingCompleted", _descriptor6, this);

      _initializerDefineProperty(this, "startDate", _descriptor7, this);

      _initializerDefineProperty(this, "endDate", _descriptor8, this);

      _initializerDefineProperty(this, "page", _descriptor9, this);

      _initializerDefineProperty(this, "lastPage", _descriptor10, this);

      _initializerDefineProperty(this, "selectedActionType", _descriptor11, this);

      this.isTpwdUser = this.roleService.isUserInTpwd();
      this.actionTypes = this.state.actionTypes.slice();
      this.actionTypes.unshift({
        id: null,
        displayValue: '-- Filter by Type --'
      });
      this.fetchAlerts.perform();
    }

    *fetchAlerts() {
      var _this$startDate, _this$endDate, _this$startDate2, _this$endDate2, _this$selectedActionT, _this$selectedActionT2;

      // /api/useraction/inactive?start={date}&end={date}&page[size]={n}&page[number]={n}
      let startDate = (_this$startDate = this.startDate) === null || _this$startDate === void 0 ? void 0 : _this$startDate.date.toISOString();
      let endDate = (_this$endDate = this.endDate) === null || _this$endDate === void 0 ? void 0 : _this$endDate.date.toISOString();
      let size = 75;
      let adapter = this.store.adapterFor('userAction');
      let alerts;
      let meta; // if (this.isViewingCompleted) {
      //   let archivedCompleted = yield adapter.getActions(startDate, endDate, size, this.page, this.selectedActionType?.id);
      //   alerts = archivedCompleted.data;
      //   meta = archivedCompleted.meta;
      // } else {

      const qs = {
        'filter[completed]': this.isViewingCompleted,
        'filter[startdate]': (_this$startDate2 = this.startDate) === null || _this$startDate2 === void 0 ? void 0 : _this$startDate2.date.toISOString(),
        'filter[enddate]': (_this$endDate2 = this.endDate) === null || _this$endDate2 === void 0 ? void 0 : _this$endDate2.date.toISOString(),
        'page[number]': this.page,
        'page[size]': 75,
        reload: true
      };
      if ((_this$selectedActionT = this.selectedActionType) !== null && _this$selectedActionT !== void 0 && _this$selectedActionT.id) qs['filter[alerttype]'] = (_this$selectedActionT2 = this.selectedActionType) === null || _this$selectedActionT2 === void 0 ? void 0 : _this$selectedActionT2.id;
      alerts = yield this.store.query('userAction', qs);
      meta = alerts.meta; // }

      this.alerts = alerts;
      this.lastPage = meta.paging.last;
    }

    ignoreAlert(alert) {
      let adapter = this.store.adapterFor('userAction');
      this.notifications.add('Alert has been ignored');
      const {
        actionGuid,
        actionType
      } = alert;

      if (actionType === "SimpleNotification") {
        return adapter.completeAction(actionGuid, '', alert).then(() => {
          return this.fetchAlerts.perform();
        });
      }

      return adapter.ignoreAction(actionGuid).then(() => {
        return this.fetchAlerts.perform();
      });
    }

    updateStartDate(dateObj) {
      this.startDate = dateObj;
      this.fetchAlerts.perform();
    }

    updateEndDate(dateObj) {
      this.endDate = dateObj;
      this.fetchAlerts.perform();
    }

    updatePage(page) {
      this.page = page;
      this.fetchAlerts.perform();
    }

    updateAlertType(type) {
      this.selectedActionType = type;
      this.fetchAlerts.perform();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "state", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "roleService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "alerts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isViewingCompleted", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "lastPage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "selectedActionType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchAlerts", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchAlerts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ignoreAlert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "ignoreAlert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateStartDate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateStartDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateEndDate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateEndDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updatePage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAlertType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateAlertType"), _class.prototype)), _class);
  _exports.default = MyAlerts;
});