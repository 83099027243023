define("mldp/components/merge-user-select/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "BqMvsFmL",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Please select the user that you would like to merge this user into.\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@triggerClass\",\"@dropdownClass\",\"@searchPlaceholder\",\"@options\",\"@search\",\"@selected\",\"@onChange\"],[\"form-control\",\"biologist-select-dropdown\",\"Find additional users by email here (partial values are accepted)\",[30,0,[\"currentUsers\"]],[28,[37,1],[[30,0],\"searchUsers\"],null],[30,0,[\"mergeInto\"]],[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"mergeInto\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2,[\"firstName\"]]],[1,\" \"],[1,[30,2,[\"lastName\"]]],[1,\" (\"],[1,[30,2,[\"email\"]]],[1,\")\\n  \"]],[2]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"user\"],false,[\"power-select\",\"action\",\"mut\"]]",
    "moduleName": "mldp/components/merge-user-select/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});