define("mldp/components/practice/display-field/component", ["exports", "@ember-decorators/component", "@ember/component", "moment"], function (_exports, _component, _component2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let DisplayField = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class DisplayField extends _component2.default {
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      const type = this.type;
      let model = this.model;
      this.setLabel();
      var value; // note: thise will be filled in with formatting things

      switch (type) {
        case 'description':
          this.set('displayValue', model.get('description'));
          return;

        case 'acres':
          this.set('displayValue', model.get('acres'));
          return;

        case 'number':
          this.set('displayValue', model.get('number'));
          return;

        case 'date':
          value = this.formatDate(model.get('date'));
          this.set('displayValue', value);
          return;

        case 'date2':
          value = this.formatDate(model.get('date2'));
          this.set('displayValue', value);
          return;

        case 'length':
          this.set('displayValue', model.get('length'));
          return;

        case 'miscFloat':
          value = model.get('miscFloat');

          if (this.display === 'percent') {
            value += '%';
          }

          this.set('displayValue', value);
          return;

        case 'methods':
          this.set('displayValue', model.get('methods'));
          return;
      }
    }

    setLabel() {
      if (this.label) {
        return;
      }

      const type = this.type;

      if (!type) {
        return;
      }

      var label = type.slice(0, 1).toUpperCase() + type.slice(1);
      this.set('label', label);
    }

    formatDate(date) {
      return (0, _moment.default)(date).format('MMMM Do Y');
    }

  }) || _class) || _class);
  _exports.default = DisplayField;
});