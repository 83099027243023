define("mldp/validators/different", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Different = classic(_class = class Different extends _base.default {
    validate(value, options, model) {
      var currentPassword = model.get(options.on);

      if (value === currentPassword) {
        return options.message;
      }

      return true;
    }

  }) || _class;

  window.__CLASSIC_OWN_CLASSES__.set(Different, true);

  var _default = Different;
  _exports.default = _default;
});