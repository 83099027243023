define("mldp/account/property/projects/create/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Cpmc1OP/",
    "block": "[[[1,[28,[35,0],[\"Create Habitat Project\"],null]],[1,\"\\n  \"],[8,[39,1],null,[[\"@f\",\"@model\",\"@property\",\"@updateGeom\",\"@areaMapped\",\"@onSave\"],[\"f\",[30,0,[\"project\"]],[30,0,[\"property\"]],[28,[37,2],[[30,0,[\"updateGeom\"]]],null],[30,0,[\"areaMapped\"]],[28,[37,2],[[30,0,[\"save\"]]],null]]],null],[1,\"\\n\"],[46,[28,[37,4],null,null],null,null,null]],[],false,[\"page-banner\",\"project/edit\",\"fn\",\"component\",\"-outlet\"]]",
    "moduleName": "mldp/account/property/projects/create/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});