define("mldp/components/enrollment-agreement/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service"], function (_exports, _component, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EnrollmentAgreement = (_dec = (0, _service.inject)('role-service'), (_class = class EnrollmentAgreement extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "roleService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "state", _descriptor3, this);

      _initializerDefineProperty(this, "feeEstimate", _descriptor4, this);

      this.fetchEstimate();
    }

    async fetchEstimate() {
      let adapter = this.store.adapterFor('enrollment');
      let {
        fee
      } = await adapter.getFeeEstimate(this.args.enrollment);

      if (fee === 0) {
        this.feeEstimate = 'Not Applicable';
      } else {
        this.feeEstimate = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(fee);
      }
    }

    get withdrawalDate() {
      let currentSeason = this.state.currentSeason;
      return this.args.enrollment.isConservation ? currentSeason.coEnrollmentWithdrawalDate : currentSeason.hoEnrollmentWithdrawalDate;
    }

    get isHarvestOption() {
      let typeName = this.args.enrollmentType.displayValue;
      return typeName === 'Harvest Option';
    }

    get unitsDescription() {
      let length = this.args.units.length;
      let unitName = this.args.units.firstObject.siteName;
      return length === 1 ? unitName : '(multiple management units)';
    }

    get showPoolManagerAgreements() {
      let model = this.args.site;
      let roleService = this.roleService;
      let isAdmin = roleService.isAdmin;

      if (isAdmin) {
        return true;
      }

      let associated = roleService.associated(model);
      let poolRoles = ['Biologist', 'Supervisor', 'PoolManager'];
      let isAssociated = associated.any(user => poolRoles.includes(user.type));

      if (isAssociated) {
        return true;
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "roleService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "state", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "feeEstimate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = EnrollmentAgreement;
});