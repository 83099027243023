define("mldp/account/reports/mldp-transactions/route", ["exports", "@ember/routing/route", "@ember/service", "mldp/utils/get-report-auth", "mldp/account/reports/current-year"], function (_exports, _route, _service, _getReportAuth, _currentYear) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountReportsMldpTransactionsRoute = (_dec = (0, _service.inject)('general-ajax'), (_class = class AccountReportsMldpTransactionsRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajaxService", _descriptor, this);
    }

    afterModel() {
      this.set('breadCrumb', {
        title: 'MLDP Summary'
      });
    }

    async model() {
      let report = {
        name: 'MLDP Fee Transaction by Dates',
        url: '/report/feetransactions',
        formats: ['excel']
      };
      let ajax = this.ajaxService;
      let auth = await (0, _getReportAuth.default)(report, ajax);
      let startDate = new Date();
      let endDate = new Date();
      return {
        report,
        query: {
          auth,
          start: startDate.valueOf(),
          end: endDate.valueOf()
        },
        data: {
          startDate,
          endDate
        }
      };
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }

    resetController(controller) {
      controller.set('query', {});
      controller.set('data', {});
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajaxService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AccountReportsMldpTransactionsRoute;
});