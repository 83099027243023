define("mldp/adapters/tlk-deer-season", ["exports", "mldp/adapters/application", "mldp/utils/class-op"], function (_exports, _application, _classOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelName: 'tlk-deer-season',
    findCurrent: (0, _classOp.default)({
      path: 'current',
      single: true
    })
  });

  _exports.default = _default;
});