define("mldp/components/select-add-contact/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KmXBJjWz",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@extra\",\"@listboxId\",\"@onInput\",\"@onKeydown\",\"@searchEnabled\",\"@onFocus\",\"@onBlur\",\"@placeholder\",\"@placeholderComponent\",\"@searchPlaceholder\",\"@select\",\"@selectedItemComponenT\"],[[30,0,[\"extra\"]],[30,0,[\"listboxId\"]],[30,0,[\"onInput\"]],[30,0,[\"onKeydown\"]],[30,0,[\"searchEnabled\"]],[30,0,[\"onFocus\"]],[30,0,[\"onBlur\"]],[30,0,[\"placeholder\"]],[30,0,[\"placeholderComponent\"]],[30,0,[\"searchPlaceholder\"]],[30,0,[\"select\"]],[30,0,[\"selectedItemComponenT\"]]]],null],[1,\"\\n\\n  \"],[10,0],[14,0,\"add-contact\"],[12],[1,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-default btn-xs\"],[15,\"onclick\",[28,[37,1],[[30,0],[30,0,[\"assignSelf\"]]],null]],[14,4,\"button\"],[12],[1,\"\\n\"],[41,[30,0,[\"assignSelf\",\"isRunning\"]],[[[1,\"        Loading..\\n\"]],[]],[[[1,\"        I'm The Contact\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"button\"],[14,0,\"btn btn-default btn-xs\"],[15,\"onclick\",[28,[37,1],[[30,0],[30,0,[\"addNew\"]]],null]],[14,4,\"button\"],[12],[1,\"\\n      Add New Contact\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"power-select/before-options\",\"action\",\"if\"]]",
    "moduleName": "mldp/components/select-add-contact/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});