define("mldp/components/actions/show-fee-receipt/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/k7hRRHw",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,0,[\"model\",\"notificationTitle\"]]],null]],[1,\"\\n\\n  \"],[10,2],[12],[1,\"\\n    \"],[1,[30,0,[\"model\",\"notificationDetail\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[15,\"onclick\",[28,[37,1],[[30,0,[\"openReceipt\"]]],null]],[15,\"disabled\",[30,0,[\"openReceipt\",\"isRunning\"]]],[14,4,\"button\"],[12],[1,\"\\n    View Receipt\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"page-banner\",\"perform\"]]",
    "moduleName": "mldp/components/actions/show-fee-receipt/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});