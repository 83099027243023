define("mldp/components/practice/new-project-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "k9sUnUNh",
    "block": "[[[1,\"Please select a Habitat Project to associate with this Habitat Practice from the dropdown below. \\n\"],[8,[39,0],null,[[\"@options\",\"@selected\",\"@triggerClass\",\"@dropdownClass\",\"@onChange\"],[[30,0,[\"projects\"]],[30,0,[\"project\"]],\"form-control\",\"biologist-select-dropdown\",[28,[37,1],[[30,0],[30,0,[\"selectProject\"]]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,1,[\"projectName\"]]],[1,\" \"],[1,[30,1,[\"projectNumber\"]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[18,2,null]],[\"project\",\"&default\"],false,[\"power-select\",\"action\",\"yield\"]]",
    "moduleName": "mldp/components/practice/new-project-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});