define("mldp/components/admin/chargeback-modal/enter-chargeback/enter-chargeback-warning/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "P8li8seP",
    "block": "[[[1,\"Are you sure you want to proceed? This transaction record will be updated accordingly to mark that chargeback occurred. Determine if this enrollment should be cancelled due to non-payment and follow up accordingly.\\n\"]],[],false,[]]",
    "moduleName": "mldp/components/admin/chargeback-modal/enter-chargeback/enter-chargeback-warning/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});