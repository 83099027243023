define("mldp/account/profile/route", ["exports", "@ember/service", "@ember/routing/route", "@ember/object", "rsvp"], function (_exports, _service, _route, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    notifications: (0, _service.inject)('notifications'),
    session: (0, _service.inject)('session'),
    breadCrumb: {
      title: 'My Profile'
    },

    model() {
      let adapter = this.store.adapterFor('user');
      return _rsvp.default.hash({
        profile: adapter.getAccountProfile()
      });
    },

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    },

    actions: {
      save(data) {
        let session = this.session;
        let notifications = this.notifications;
        let adapter = this.store.adapterFor('user');
        return adapter.updateProfile(data).then(() => {
          let currentEmail = this.get('session.tokenData.sub');
          let email = (0, _object.get)(data, 'email');

          if (email !== currentEmail) {
            notifications.add('You have changed your email, please log back in');
            return new _rsvp.default.Promise(resolve => {
              setTimeout(() => {
                resolve();
              }, 1000 * 3);
            }).then(() => session.invalidate());
          }

          return this.transitionTo('account').then(() => notifications.add('Your profile has been updated'));
        }).catch(e => {
          if (e.errors) {
            let errorAttrs = e.errors.reduce((all, item) => {
              if (!all[item.title]) {
                all[item.title] = [];
              }

              let err = {
                message: item.detail
              };
              all[item.title].push(err);
              return all;
            }, {});
            data.validations.attrs = errorAttrs;
          }

          console.log('Catch', e);
        });
      },

      reset(model) {
        model.rollbackAttributes();
      },

      changePassword(data) {
        let adapter = this.store.adapterFor('user');
        let notifications = this.notifications;
        return adapter.changePassword(data).then(() => this.transitionTo('account')).then(() => notifications.add('Your password has been updated')).catch(e => {
          let message = '';

          if (typeof e === 'string' || e instanceof String) {
            message = e;
          } else {
            e.errors.forEach(err => {
              message += `${err.detail}\n`;
            });
          }

          notifications.error(message);
        });
      }

    }
  });

  _exports.default = _default;
});