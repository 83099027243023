define("mldp/components/practice/fence-riparian/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FenceRiparian = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class FenceRiparian extends _component2.default {
    init() {
      super.init(...arguments);
      this.needsBoth = true;
    }

    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      const acres = this.acres;
      const number = this.number;

      if (!acres && !number) {
        this.set('needsBoth', true);
      } else {
        this.set('needsBoth', false);
      }
    }

  }) || _class) || _class);
  _exports.default = FenceRiparian;
});