define("mldp/components/info-tooltip/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jS6FYDSD",
    "block": "[[[11,0],[24,0,\"info-tooltip\"],[17,1],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n  \"],[10,\"i\"],[14,0,\"material-icons\"],[12],[1,\"info\"],[13],[1,\"\\n\"],[41,[30,0,[\"textAsArray\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@class\",\"@side\"],[\"tooltip-wide\",[30,0,[\"side\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,2]],null]],null],null,[[[1,\"        \"],[1,[30,3]],[10,\"br\"],[12],[13],[1,\"\\n\"]],[3]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],null,[[\"@text\",\"@side\"],[[30,2],[30,0,[\"side\"]]]],null],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@text\",\"line\",\"&default\"],false,[\"yield\",\"if\",\"ember-tooltip\",\"each\",\"-track-array\"]]",
    "moduleName": "mldp/components/info-tooltip/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});