define("mldp/helpers/has-items", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasItems = hasItems;

  function hasItems(_ref) {
    let [array] = _ref;
    return Array.isArray(array) && array.length;
  }

  var _default = (0, _helper.helper)(hasItems);

  _exports.default = _default;
});