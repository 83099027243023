define("mldp/utils/total-from-gender", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = totalFromGender;

  function totalFromGender(gender, source) {
    let keys = gender.get('keys');
    let total = keys.reduce((total, key) => {
      let value = (0, _object.get)(source, key);
      value = Number(value);
      value = isNaN(value) ? 0 : value;
      total += value;
      return total;
    }, 0);
    return total;
  }
});