define("mldp/components/map-help-guide/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EAeNvB/9",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"text-md text-bold\"],[12],[1,\"Map Help Guide\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"row push-down\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-xs-2\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"leaflet-pm-helper-icon leaflet-pm-icon-polygon\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-xs-10\"],[12],[1,\"Click this icon to start drawing a new boundary.\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"row push-down\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-xs-2\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"leaflet-pm-helper-icon leaflet-pm-icon-cut\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-xs-10\"],[12],[1,\"Click this icon to cut out from existing boundary\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"row push-down\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-xs-2\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"leaflet-pm-helper-icon leaflet-pm-icon-edit\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-xs-10\"],[12],[1,\"Click this icon to edit an existing boundary.\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"row push-down\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-xs-2\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"leaflet-pm-helper-icon leaflet-pm-icon-delete\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-xs-10\"],[12],[1,\"Click this icon then click on a boundary to delete it and start over. \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/map-help-guide/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});