define("mldp/account/rtas/index/controller", ["exports", "@ember/object", "@ember/service", "@ember/controller"], function (_exports, _object, _service, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let IndexController = (_dec = (0, _service.inject)('store'), classic(_class = (_class2 = class IndexController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "queryParams", ['countyid', 'page', 'sitename', 'sitetype']);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "siteTypes", ['Aggregate', 'CoOp', 'Property']);
    }

    applyFilters() {
      let {
        siteNameFilter,
        selectedSiteType,
        selectedCounty
      } = this;

      if (siteNameFilter) {
        this.set('sitename', siteNameFilter);
      } else {
        this.set('sitename', undefined);
      }

      if (selectedSiteType) {
        this.set('sitetype', selectedSiteType.toLowerCase());
      }

      if (selectedCounty) {
        let countyid = selectedCounty.id;
        this.set('countyid', countyid);
      }

      this.set('page', 1);
    }

    updateField(field, value) {
      switch (field) {
        case 'siteType':
          this.set('selectedSiteType', value);

          if (!value) {
            this.set('sitetype', value);
          }

          break;

        case 'county':
          this.set('selectedCounty', value);

          if (!value) {
            this.set('countyid', value);
          }

      }
    }

    clearFilters() {
      let properties = ['selectedCounty', 'selectedSiteType', 'siteNameFilter', 'sitename', 'sitetype', 'countyid'];
      let data = {};
      properties.forEach(prop => data[prop] = undefined);
      this.setProperties(data);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "applyFilters", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "applyFilters"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateField", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateField"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clearFilters", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "clearFilters"), _class2.prototype)), _class2)) || _class);
  _exports.default = IndexController;
});