define("mldp/account/property/harvests/create/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DbUB9qAV",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"Create Harvest\"]],null],[1,\"\\n\"],[41,[30,0,[\"submitting\"]],[[[1,\"  \"],[10,\"h3\"],[14,0,\"loading-container\"],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"material-icons text-muted spin\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"loop\"],[13],[1,\"\\n    Submitting..\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@harvest\",\"@harvests\",\"@seasons\",\"@site\",\"@currentSeasonId\",\"@onSave\",\"@onReset\"],[[30,0,[\"harvest\"]],[30,0,[\"harvests\"]],[30,0,[\"seasons\"]],[30,0,[\"site\"]],[30,0,[\"currentSeasonId\"]],[28,[37,3],[[30,0],\"save\"],null],[28,[37,3],[[30,0],\"reset\"],null]]],null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"amdReapply\"]],[[[1,\"  \"],[8,[39,4],null,[[\"@named\",\"@send\"],[\"modal\",[50,\"modal-window\",0,null,[[\"title\",\"body\",\"close\",\"closeLabel\",\"onsubmit\",\"submitLabel\"],[\"Missing\",[50,\"notices/reporting/amd-reapply\",0,null,[[\"currentSeason\"],[[30,0,[\"currentAMDSeason\"]]]]],[28,[37,6],[[28,[37,3],[[30,0],[28,[37,7],[[30,0,[\"amdReapply\"]]],null],false],null],[28,[37,3],[[30,0],\"harvestSuccess\"],null]],null],\"No, do not reapply\",[28,[37,3],[[30,0],\"goToNewApp\"],null],\"Yes, reapply\"]]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"page-banner\",\"if\",\"harvest-edit\",\"action\",\"to-elsewhere\",\"component\",\"pipe\",\"mut\"]]",
    "moduleName": "mldp/account/property/harvests/create/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});