define("mldp/components/practice/help-text/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "y7pdWOYG",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"helpText\"]],[[[1,\"    \"],[10,2],[12],[1,[30,0,[\"helpText\"]]],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\"],false,[\"if\"]]",
    "moduleName": "mldp/components/practice/help-text/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});