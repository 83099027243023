define("mldp/adapters/supervisor", ["exports", "mldp/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Supervisor = classic(_class = class Supervisor extends _application.default {
    buildURL() {
      return `/${this.namespace}/user/supervisors`;
    }

  }) || _class;

  _exports.default = Supervisor;
});