define("mldp/components/user-edit/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/component"], function (_exports, _component, _object, _service, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let UserEdit = (_dec = (0, _component.tagName)(""), _dec2 = (0, _service.inject)('notifications'), _dec3 = (0, _service.inject)('session'), _dec(_class = classic(_class = (_class2 = class UserEdit extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "sessionService", _descriptor2, this);

      _defineProperty(this, "createMode", false);
    }

    init() {
      super.init(...arguments);
      let createMode = this.createMode;
      let submitLabelPrefix = createMode ? 'Save' : 'Update';
      let submitPending = createMode ? 'Saving..' : 'Updating..';
      const {
        isSuperAdmin: sessionIsSuperAdmin
      } = this.sessionService;
      const userIsAdmin = createMode ? false : Boolean(this.model.displayRoles.find(role => role.displayValue === 'Admin'));
      this.setProperties({
        submitLabel: `${submitLabelPrefix} User`,
        submitPending,
        sessionIsSuperAdmin,
        userIsAdmin
      });
    }
    /*
    updateModel(obj, key, value) {
      debugger;
    }*/


    handleRoleChange(arg) {
      const userIsAdmin = Boolean(arg.find(role => role.displayValue === 'Admin'));
      this.set('userIsAdmin', userIsAdmin);

      if (!userIsAdmin) {
        this.model.set('isSuperAdmin', false);
      }

      this.model.set('displayRoles', arg);
    }

    confirmEmail() {
      let adapter = this.model.store.adapterFor('user');
      let notifications = this.notifications;
      return adapter.confirmUserEmailAddress(this.model.id).then(() => {
        notifications.add('User\'s email address has been confirmed');
      }).catch(e => {
        notifications.error(e.message);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "sessionService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "handleRoleChange", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleRoleChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "confirmEmail", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "confirmEmail"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = UserEdit;
});