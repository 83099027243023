define("mldp/account/users/merge/route", ["exports", "@ember/service", "@ember/routing/route", "@ember/object", "rsvp"], function (_exports, _service, _route, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let MergeRoute = (_dec = (0, _service.inject)('state'), _dec2 = (0, _service.inject)('notifications'), classic(_class = (_class2 = class MergeRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stateService", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);
    }

    async model(_ref) {
      let {
        fromUserId,
        intoUserId
      } = _ref;
      let fromUser = await this.store.findRecord('user', fromUserId, {
        include: 'roles,counties',
        reload: true
      });
      let intoUser = await this.store.findRecord('user', intoUserId, {
        include: 'roles,counties',
        reload: true
      });
      let supervisors = await this.store.findAll('supervisor');
      let fromCounties = this.getRelationship(fromUser, 'counties', {
        idAttribute: 'countyId'
      });
      let intoCounties = this.getRelationship(intoUser, 'counties', {
        idAttribute: 'countyId'
      });
      let fromRoles = this.getRelationship(fromUser, 'roles', {
        idAttribute: 'roleId'
      });
      let intoRoles = this.getRelationship(intoUser, 'roles', {
        idAttribute: 'roleId'
      }); // Convert to JSON

      let fromUserData = fromUser.toJSON();
      let intoUserData = intoUser.toJSON(); // Assign final relationship objects to users

      fromUserData.roles = fromRoles;
      intoUserData.roles = intoRoles;
      fromUserData.counties = fromCounties;
      intoUserData.counties = intoCounties;
      fromUserData.supervisor = this.getSupervisor(fromUserData, supervisors);
      intoUserData.supervisor = this.getSupervisor(intoUserData, supervisors);
      return {
        fromUser,
        intoUser,
        fromUserData,
        intoUserData,
        supervisors
      };
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }
    /**
     * Filter relationship by name from the state service, which is preloaded in the account route.
     * 
     * @param {Object} data - Data from which the intersection data is used to fetch the final relationship object
     * @param {String} name - Name of the relationship on the data and in the state service
     * @param {Object} options - { idAttribute } - The attribute to check against when comparing relationships in the state service
     */


    getRelationship(data, name, _ref2) {
      let {
        idAttribute
      } = _ref2;
      let intersections = data.get(name);
      let ids = intersections.map(item => String(item.get(idAttribute)));
      let items = this.get(`stateService.${name}`);
      let filtered = items.filter(item => ids.includes(item.id));
      return filtered;
    }

    getSupervisor(data, supervisors) {
      if (!data.supervisorId) {
        return;
      }

      return supervisors.findBy('identifier', data.supervisorId);
    }

    async merge(data) {
      let notifications = this.notifications;
      let fromUser = this.get('controller.fromUser');
      let intoUser = this.get('controller.intoUser');
      let attributes = Object.assign({}, data); // Use selected display model's id as the final value
      // null makes sure that the serialization doesn't strip it out of the payload

      attributes.supervisorId = (0, _object.get)(data, 'supervisor.id') || null; // Delete unused supervisor, was used for display only

      delete attributes.supervisor; // Handle relationships separately, since they have intersection tables

      delete attributes.counties;
      delete attributes.roles;

      try {
        await intoUser.mergeUser({
          data: {
            data: {
              id: intoUser.id,
              type: 'users',
              attributes
            }
          }
        }); // Create new intersections for new relationships

        await this.updateRelationship(intoUser, data, 'roles', 'roleId', 'user-xrole');
        await this.updateRelationship(intoUser, data, 'counties', 'countyId', 'user-xcounty');
        await intoUser.finishMerge({
          query: {
            mergedId: fromUser.id
          }
        });
        await this.transitionTo('account.users');
        notifications.add('Successfully merged users');
      } catch (err) {
        notifications.addApiErrors(err);
      }
    }
    /**
     * Check if there are any new relationships to be added or removed,
     * and add and/or remove the necessary relationships.
     * 
     * @param {User} originalUser - Ember Data model of the unchanged user
     * @param {Object} data - The new object with new values and the array of the relationship models
     * @param {String} relName - Name of the relationship attribute on both the originalUser and data
     * @param {String} relAttr - Attribute to use to compare against old/new models
     * @param {String} relModel - Type of the intersection model to be created if there are new relationships
     */


    updateRelationship(originalUser, data, relName, relAttr, relModel) {
      let adapter = this.store.adapterFor(relModel);
      let updatedData = (0, _object.get)(data, relName);
      let currentData = originalUser.get(relName);
      let toAdd = [];
      let toRemove = [];

      if (!updatedData) {
        return (0, _rsvp.resolve)();
      }

      updatedData.forEach(updated => {
        if (!currentData.findBy(relAttr, Number(updated.id))) {
          toAdd.pushObject(updated);
        }
      });
      currentData.forEach(current => {
        if (!updatedData.findBy('id', String(current.get(relAttr)))) {
          toRemove.pushObject(current);
        }
      });
      toAdd = toAdd.map(add => {
        return {
          data: {
            type: relModel,
            attributes: {
              [relAttr]: add.id,
              userId: originalUser.id
            }
          }
        };
      });
      let addPromises = toAdd.map(data => {
        return adapter.createForMerge({
          data
        });
      });
      let removePromises = toRemove.invoke('deleteForMerge');
      return (0, _rsvp.all)(addPromises.concat(removePromises));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "merge", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "merge"), _class2.prototype)), _class2)) || _class);
  _exports.default = MergeRoute;
});