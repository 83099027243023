define("mldp/components/harvest-edit/component", ["exports", "@ember-decorators/component", "@ember/component", "mldp/utils/calculate-reporting-year", "moment"], function (_exports, _component, _component2, _calculateReportingYear, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  // We didn't start keeping online harvest reports until after 2008 in TWIMS
  const preTwimsSeason = '2008';
  let HarvestEdit = (_dec = (0, _component.tagName)(""), _dec(_class = classic(_class = class HarvestEdit extends _component2.default {
    init() {
      super.init(...arguments);

      if (!this.harvests) {
        return;
      }

      let harvestSeasonIds = this.harvests.map(item => String(item.deerSeasonId)); // If the harvest is being edited remove current selected season
      // from "already exists" list

      let harvest = this.harvest;
      let isEditing = !harvest.get('isNew');

      if (isEditing) {
        harvestSeasonIds = harvestSeasonIds.without(String(harvest.get('deerSeasonId')));
      } // Filter seasons based on harvests already reported


      let seasons = this.seasons.filter(s => {
        let noneIncludedAlready = !harvestSeasonIds.includes(s.id); //Don't allow reporting until after harvest reporting start date

        if (!(0, _moment.default)(new Date()).isAfter((0, _moment.default)(s.harvestReportingStartDate))) return false;
        return noneIncludedAlready && s.id > preTwimsSeason;
      });
      let newReport = !harvest.get('deerSeason'); // Set default season id on harvest 

      if (this.currentSeasonId && newReport) {
        harvest.set('deerSeasonId', Number(seasons.get('firstObject.id')));
      }

      this.setProperties({
        seasons
      });
    }

  }) || _class) || _class);
  _exports.default = HarvestEdit;
});