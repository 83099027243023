define("mldp/components/permit/reporting-status/amd/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Uw3KFfou",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"reporting-status-item\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"collapse-panel\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"header\"],[12],[1,\"\\n        \"],[10,\"strong\"],[12],[1,\"\\n          \"],[1,[30,0,[\"permit\",\"primarySite\",\"siteName\"]]],[1,\" (\"],[1,[28,[35,0],[[30,0,[\"permit\",\"primarySite\",\"siteType\"]]],null]],[1,\")\\n        \"],[13],[1,\"\\n        \"],[10,\"i\"],[15,0,[29,[\"fa \",[52,[30,2],\"fa-angle-up\",\"fa-angle-down\"],\" pull-right text-muted\"]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"padded\"],[12],[1,\"\\n        \"],[10,\"strong\"],[12],[1,\"\\n          Harvest Report:\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"dl\"],[14,0,\"dl-horizontal push-down\"],[12],[1,\"\\n          \"],[10,\"dt\"],[12],[1,\"Submitted\"],[13],[1,\"\\n\"],[1,\"          \"],[10,\"dd\"],[12],[1,[52,[30,0,[\"submitted\"]],\"Yes\",\"No\"]],[13],[1,\"\\n\\n          \"],[10,\"dt\"],[12],[1,\"On Time\"],[13],[1,\"\\n          \"],[10,\"dd\"],[12],[1,[52,[30,0,[\"onTime\"]],\"Yes\",\"No\"]],[13],[1,\"\\n\"],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@open\"],false,[\"titleize\",\"if\"]]",
    "moduleName": "mldp/components/permit/reporting-status/amd/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});