define("mldp/components/site/documents-list/component", ["exports", "@ember/object", "@ember-decorators/component", "@ember/service", "@ember/object/computed", "@ember/component", "ember-concurrency"], function (_exports, _object, _component, _service, _computed, _component2, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let DocumentsList = (_dec = (0, _component.tagName)(''), _dec2 = (0, _service.inject)('ajax'), _dec3 = (0, _computed.alias)('loadDocuments.lastSuccessful.value'), _dec4 = (0, _emberConcurrency.task)(function* () {
    if (!this.documentToUpload) {
      this.notifications.error('Document type must be set and a file must be selected before you can upload a file.');
      return;
    }

    let notifications = this.notifications;
    let ajax = this.ajaxService;
    let siteId = this.site.id;
    let fileName = this.documentToUpload.blob.name;
    let dataUri = yield this.documentToUpload.readAsDataURL();
    let content = dataUri.split(',')[1];

    try {
      yield ajax.post('/api/document/site', {
        contentType: 'application/json',
        data: {
          Content: content,
          FileName: fileName,
          ContentType: this.documentToUpload.blob.type,
          EntityId: siteId,
          documentTypeId: this.selectedDocType.id
        }
      });
      notifications.add(`Document "${fileName}" was uploaded successfully!`);
    } catch (e) {
      var _e$payload;

      let message = e;

      if ((_e$payload = e.payload) !== null && _e$payload !== void 0 && _e$payload.errors && e.payload.errors.length) {
        message = e.payload.errors[0].detail;

        if (message === 'Request body too large.') {
          message = 'File exceeds maximum file size.';
        }
      }

      notifications.error(`Encountered an error while uploading your document: ${message}`);
    }
  }), _dec5 = (0, _emberConcurrency.task)(function* () {
    try {
      delete this.documentToEdit.url;
      delete this.documentToEdit.documentType;
      (0, _object.set)(this, 'documentToEdit.documentTypeId', this.selectedDocType.id);
      let ajax = this.ajaxService;
      yield ajax.post('/api/document/site', {
        contentType: 'application/json',
        data: this.documentToEdit
      });
      this.notifications.add(`Document "${this.documentToEdit.fileName}" was edited successfully!`);
    } catch (e) {
      var _e$payload2;

      let message = e;

      if ((_e$payload2 = e.payload) !== null && _e$payload2 !== void 0 && _e$payload2.errors && e.payload.errors.length) {
        message = e.payload.errors[0].detail;

        if (message === 'Request body too large.') {
          message = 'File exceeds maximum file size.';
        }
      }

      this.notifications.error(`Encountered an error while uploading your document: ${message}`);
    }
  }), _dec6 = (0, _emberConcurrency.task)(function* () {
    let ajax = this.ajaxService;
    let siteId = this.site.id;
    let rawDocuments = yield ajax.request(`/api/document/site/${siteId}`);
    let documents = rawDocuments.map(document => {
      document.url = `/document/site/${siteId}/${document.fileName}?auth=`;
      return document;
    });
    return documents;
  }).restartable(), classic(_class = _dec(_class = (_class2 = class DocumentsList extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajaxService", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "documents", _descriptor3, this);

      _defineProperty(this, "showDocuments", false);

      _defineProperty(this, "documentToEdit", null);

      _defineProperty(this, "documentToUpload", null);

      _defineProperty(this, "selectedDocType", null);

      _initializerDefineProperty(this, "uploadDocument", _descriptor4, this);

      _initializerDefineProperty(this, "editDocument", _descriptor5, this);

      _initializerDefineProperty(this, "loadDocuments", _descriptor6, this);
    }

    addFile(file) {
      (0, _object.set)(this, 'documentToUpload', file);
    }

    updateDocType(selectedDocType) {
      (0, _object.set)(this, 'selectedDocType', selectedDocType);
    }

    toggleDocuments() {
      let documents = this.documents;
      let show = this.toggleProperty('showDocuments');

      if (show && !documents) {
        this.loadDocuments.perform();
      }
    }

    async download(document) {
      let ajax = this.ajaxService;
      let siteId = this.site.id;
      let token = await ajax.request(`/api/document/site/${siteId}/getauthtoken`);
      let url = document.url + token;
      window.open(url, '_blank');
    }

    confirmDelete(document) {
      this.set('documentToDelete', document);
    }

    async deleteDocument(document) {
      let ajax = this.ajaxService;
      let name = document.fileName;
      let siteId = this.site.id;

      try {
        await ajax.delete('/api/document/site', {
          contentType: 'application/json',
          data: {
            EntityId: siteId,
            FileName: name
          }
        }); // Reload the documents

        this.loadDocuments.perform(); // Close the modal

        this.set('documentToDelete', undefined);
      } catch (error) {
        this.notifications.error('Encountered an error while deleting your document.');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajaxService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "documents", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "uploadDocument", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "editDocument", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "loadDocuments", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "addFile", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "addFile"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateDocType", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateDocType"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleDocuments", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleDocuments"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "download", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "download"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "confirmDelete", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "confirmDelete"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteDocument", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteDocument"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = DocumentsList;
});