define("mldp/components/loading-button/component", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let LoadingButton = (_dec = (0, _component.tagName)('button'), _dec2 = (0, _component.attributeBindings)('type', 'disabled'), classic(_class = _dec(_class = _dec2(_class = class LoadingButton extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "type", 'submit');

      _defineProperty(this, "disabled", false);

      _defineProperty(this, "loading", false);
    }

    didReceiveAttrs() {
      var promise = this.promise;

      if (promise) {
        this.handleAction(promise);
      }
    }

    submit() {
      var promise = this.action ? this.action() : this.promise;
      this.handleAction(promise);
    }

    click() {
      var promise = this.action ? this.action() : this.promise;
      this.handleAction(promise);
    }

    handleAction(promise) {
      if (promise !== null && promise !== void 0 && promise.then) {
        this.setProperties({
          loading: true,
          disabled: true
        });
        promise.then(result => {
          if (this.isDestroying || this.isDestroyed) {
            return;
          }

          this.setProperties({
            actionResult: result,
            loading: false,
            disabled: false
          });
        }).catch(error => {
          if (this.isDestroying || this.isDestroyed) {
            return;
          }

          this.setProperties({
            error: true,
            disabled: false,
            actionResult: error
          });
        });
      } else {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }

        this.setProperties({
          loading: false,
          errored: false,
          actionResult: undefined
        });
      }
    }

  }) || _class) || _class) || _class);
  _exports.default = LoadingButton;
});