define("mldp/components/search/site-form/component", ["exports", "rsvp", "@ember/object", "@ember/service", "@ember/component"], function (_exports, _rsvp, _object, _service, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    state: (0, _service.inject)('state'),

    init() {
      this._super(...arguments);

      this.show = false;
      this.types = this.types.map(item => {
        return {
          key: item,
          value: item
        };
      });
      this.types.unshift(_object.default.create({
        key: '-- Filter by Type --',
        value: ''
      }));
      this.counties = this.get('state.counties').map(item => {
        return {
          key: item.get('displayValue'),
          value: item.get('displayValue')
        };
      });
      this.counties.unshift(_object.default.create({
        key: '-- Filter by County --',
        value: ''
      }));
      this.enrollments = this.get('state.enrollmentTypes').map(item => {
        return {
          key: item.get('displayValue'),
          value: item.get('displayValue')
        };
      });
      this.enrollments.unshift(_object.default.create({
        key: '-- Filter by Enrollment --',
        value: ''
      }));
      this.habitatProjectTypes = this.get('state.habitatProjectTypes').map(item => {
        return {
          key: item.get('displayValue'),
          value: item.get('displayValue')
        };
      });
      this.habitatProjectTypes.unshift(_object.default.create({
        key: 'All Funding Sources',
        value: 'all'
      }));
      this.habitatProjectTypes.unshift(_object.default.create({
        key: '-- Filter by Funding Source --',
        value: ''
      }));
      this.documentTypes = this.get('state.documentTypes').map(item => {
        return {
          key: item.get('displayValue'),
          value: item.get('displayValue')
        };
      });
      this.documentTypes.unshift(_object.default.create({
        key: '-- Filter by Document Type --',
        value: ''
      }));
      this.seasons = this.get('state.enrollmentSeasons').map(item => {
        return {
          key: item.get('displayValue'),
          value: item.get('displayValue')
        };
      });
      this.seasons.unshift(_object.default.create({
        key: '-- Filter by Season --',
        value: ''
      }));
      this.specieses = [_object.default.create({
        key: '-- Filter by Species --',
        value: ''
      }), 'Whitetail Deer', 'Mule Deer'].map(item => {
        if (typeof item === 'string') {
          return _object.default.create({
            key: item,
            value: item
          });
        }

        return item;
      });
      this.publicLandOptions = [_object.default.create({
        key: '-- Filter by Public/Private --',
        value: ''
      }), 'Public Lands Only', 'Private Lands Only'].map(item => {
        if (typeof item === 'string') {
          return _object.default.create({
            key: item,
            value: item
          });
        }

        return item;
      });
      this.genders = [_object.default.create({
        key: '-- Filter by Gender --',
        value: ''
      }), 'Buck Only', 'Antlerless Only', 'Buck & Anterless'].map(item => {
        if (typeof item === 'string') {
          return _object.default.create({
            key: item,
            value: item
          });
        }

        return item;
      });
      this.siteIsProperty = true;
      this.siteIsLegion = true;
      this.siteIsNeither = true;
    },

    actions: {
      updateForm(e) {
        this.updateForm(e);
        return (0, _rsvp.resolve)(true);
      },

      updateProperty(object, property, value) {
        if (property === 'type') {
          let type = value;
          let out = {
            page: 1,
            type
          };

          if (type === 'CoOp' || type === 'Aggregate') {
            out.agentFname = out.agentLname = out.landFname = out.landLname = null;
          }

          if (type === 'Property') {
            out.poolFname = out.poolLname = null;
          }

          object.setProperties(out);
          this.updateTypeRelated(type);
        } else {
          object.set(property, value);
        }
      }

    },

    updateTypeRelated(type) {
      if (type === 'Property') {
        this.setProperties({
          siteIsProperty: true,
          siteIsLegion: false,
          siteIsNeither: false
        });
      } else if (type === 'CoOp' || type === 'Aggregate') {
        this.setProperties({
          siteIsProperty: false,
          siteIsLegion: true,
          siteIsNeither: true
        });
      } else {
        this.setProperties({
          siteIsProperty: true,
          siteIsLegion: true,
          siteIsNeither: true
        });
      }
    }

  });

  _exports.default = _default;
});