define("mldp/account/users/new/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _object, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let NewRoute = (_dec = (0, _service.inject)('notifications'), classic(_class = (_class2 = class NewRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);
    }

    model() {
      return _rsvp.default.hash({
        roles: this.store.findAll('tlkRole'),
        counties: this.store.findAll('tlkCounty'),
        user: this.store.createRecord('user', {
          requirePassword: true
        }),
        supervisors: this.store.findAll('supervisor')
      });
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }

    resetController(controller) {
      let model = controller.user;
      model.rollbackAttributes();
    }

    save(user) {
      return user.validate().then(() => {
        if (!user.get('validations.isValid')) {
          return _rsvp.default.reject(new Error('Validations invalid'));
        }

        return user.save();
      }).then(user => {
        let userAdapter = this.store.adapterFor('user');
        return _rsvp.default.all([userAdapter.updateRoles(user), userAdapter.updateCounties(user)]);
      }).then(() => {
        user.set('requirePassword', false);
        this.transitionTo('account.users');
      }).catch(e => {
        var _e$errors;

        let message = '';
        console.log('error', e);

        if (e.isAdapterError) {
          let errors = e.errors;
          errors.forEach(error => {
            message += `${error.detail}\n`;
          });
        } else if (user.get('validations.errors.length')) {
          let errors = user.get('validations.errors');
          errors.forEach(error => {
            message += `${error.attribute}: ${error.message}\n`;
          });
        } else if ((_e$errors = e.errors) !== null && _e$errors !== void 0 && _e$errors.length) {
          message = e.errors[0].detail;
        } else {
          message = e.message;
        }

        this.notifications.add(message);
      });
    }

    reset() {
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "reset"), _class2.prototype)), _class2)) || _class);
  _exports.default = NewRoute;
});