define("mldp/components/consultation-edit/component", ["exports", "@ember/component", "@ember/service", "ember-concurrency"], function (_exports, _component, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    storeService: (0, _service.inject)('store'),
    session: (0, _service.inject)('session'),

    init() {
      this._super(...arguments);

      let typeId = this.get('consultation.consultationTypeId'); // Preselect the existing type on the model

      if (typeId !== undefined) {
        let types = this.types;
        let type = types.findBy('id', String(typeId));
        this.set('selectedType', type);
      }

      let biologistId = this.get('consultation.biologistUserId');
      let isAdmin = this.get('session.isAdmin');
      let isBiologist = this.get('session.isBiologist');
      let isSupervisor = this.get('session.isSupervisor');
      let setBiologist = isBiologist || isSupervisor; // Assign current user as the biologist if they are a biologist

      if (!biologistId && setBiologist) {
        // Only if biologist and not admin or super
        let disableBiologistSelect = isBiologist && !isAdmin && !isSupervisor;
        biologistId = this.get('session.userId'); // set on the consultation

        this.set('consultation.biologistUserId', biologistId);
        this.set('disableBiologistSelect', disableBiologistSelect);
      } // Preselect the existing biologist on the model by the id


      if (biologistId !== undefined) {
        let biologists = this.biologists;
        let biologist = biologists.findBy('id', String(biologistId));
        this.set('selectedBiologist', biologist);
      }

      let countyId = this.get('consultation.primaryCountyId'); // Preselect the existing county on the model by the id

      if (countyId !== undefined) {
        let counties = this.counties;
        let county = counties.findBy('id', String(countyId));
        this.set('selectedCounty', county);
      }

      this.maxDate = new Date();
    },

    loadSites: (0, _emberConcurrency.task)(function* () {
      // debounce
      yield (0, _emberConcurrency.timeout)(400);
      let biologistId = this.get('selectedBiologist.id');

      if (!biologistId) {
        return;
      }

      let store = this.storeService;
      let sites = yield store.query('site', {
        'filter[bioId]': biologistId
      }); // Populate select so on subsequent open your last results are still there

      this.set('lastSiteResults', sites.sortBy('siteName'));
      return sites;
    }).on('init').restartable(),
    searchUsers: (0, _emberConcurrency.task)(function* () {
      let store = this.storeService;
      let users = yield store.query('user', {
        'filter[otherstaff]': true
      });
      return users.sortBy('lastName');
    }).on('init'),
    actions: {
      updateType(type) {
        this.set('consultation.consultationTypeId', type.id);
        this.set('selectedType', type); // Clear site if not site specific

        if (type.id === '4') {
          this.setProperties({
            'consultation.siteId': undefined,
            'selectedSite': undefined
          });
        } else {
          this.setProperties({
            'consultation.primaryCountyId': undefined,
            'consultation.contactEntity': undefined,
            'selectedCounty': undefined
          });
        }
      },

      updateBiologist(biologist) {
        this.set('consultation.biologistUserId', biologist.id);
        this.set('selectedBiologist', biologist);
        this.loadSites.perform(); // reset selected site

        this.send('updateSite');
      },

      updateSite(site) {
        let siteId = site ? site.id : undefined;
        let siteName = site ? site.siteName : undefined;
        this.set('consultation.siteId', siteId);
        this.set('consultation.siteName', siteName);
        this.set('selectedSite', site);
      },

      updateCounty(county) {
        this.set('consultation.primaryCountyId', county.id);
        this.set('selectedCounty', county);
      },

      updateUsers(users) {
        this.set('selectedUsers', users);
      }

    },

    // clean up state
    willDestroyElement() {
      this._super(...arguments);

      this.setProperties({
        selectedType: undefined,
        selectedCounty: undefined,
        selectedBiologist: undefined,
        selectedSite: undefined
      });
    }

  });

  _exports.default = _default;
});