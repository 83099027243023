define("mldp/account/property/enrollments/index/route", ["exports", "@ember/service", "@ember/routing/route", "rsvp", "mldp/utils/within-date-range", "@ember/array"], function (_exports, _service, _route, _rsvp, _withinDateRange, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IndexRoute = (_class = class IndexRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);

      _initializerDefineProperty(this, "roleService", _descriptor2, this);

      _defineProperty(this, "queryParams", {
        archivedEnrollment: {
          refreshModel: true
        }
      });
    }

    async model() {
      let {
        property
      } = this.modelFor('account.property');
      let {
        archivedEnrollment
      } = this.paramsFor('account.property.enrollments.index');

      if (!this.roleService.isAdmin) {
        archivedEnrollment = false;
      }

      let enrollments;

      if (!archivedEnrollment) {
        try {
          enrollments = await property.findAllEnrollments({
            query: {
              archived: archivedEnrollment
            }
          });
        } catch (error) {
          if (error.errors[0].status === 404) enrollments = (0, _array.A)([]);
        }
      } else {
        try {
          enrollments = await property.findAllArchivedEnrollments({
            query: {
              archived: archivedEnrollment
            }
          });
        } catch (error) {
          if (error.errors[0].status === 404) enrollments = (0, _array.A)([]);
        }
      }

      return _rsvp.default.hash({
        property,
        enrollments
      });
    }

    setupController(controller, resolved) {
      let currentSeason = this.state.currentSeason;
      let startDate = currentSeason.get('enrollmentStartDate');
      let endDate = currentSeason.get('enrollmentEndDate');
      resolved.enrollments = resolved.enrollments.sortBy('deerSeason.id').reverse();
      resolved.withinEnrollmentPeriod = (0, _withinDateRange.default)(startDate, endDate);

      if (!this.roleService.isAdmin) {
        controller.archivedEnrollment = false;
      }

      controller.setProperties(resolved);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "state", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "roleService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = IndexRoute;
});