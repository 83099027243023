define("mldp/adapters/site", ["exports", "mldp/adapters/application", "qs"], function (_exports, _application, _qs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Site = classic(_class = class Site extends _application.default {
    getManagementUnits(siteId) {
      let archived = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      let ajax = this.ajaxService;
      let store = this.store;
      let siteType = this.modelName;
      return ajax.request(`/${siteType}/${siteId}/managementUnits`, {
        contentType: 'application/vnd.api+json',
        headers: {
          'Accept': 'application/vnd.api+json'
        },
        data: {
          archived
        }
      }).then(result => {
        if (result) {
          result.data = result.data.map(datum => {
            datum.type = 'managementUnit';
            return datum;
          });
          let models = store.push(result);
          return models;
        }
      });
    }

    getSiteStatistics(siteId, options) {
      let ajax = this.ajaxService;
      return ajax.request(`/site/${siteId}/statistics?${_qs.default.stringify(options)}`, {
        contentType: 'application/vnd.api+json',
        headers: {
          'Accept': 'application/vnd.api+json'
        }
      }).then(result => {
        if (result) {
          result = result.map(datum => {
            datum.type = 'site-statistics';
            return datum;
          });
          return result;
        }
      });
    }

    assignBiologistSelf(siteId) {
      let ajax = this.ajaxService;
      let siteType = this.modelName;
      return ajax.post(`/${siteType}/${siteId}/AddBiologistSelf`);
    }

    addBiologist(siteId, userId) {
      let ajax = this.ajaxService;
      let siteType = this.modelName;
      return ajax.post(`/${siteType}/${siteId}/AddBiologist/${userId}`);
    }

    removeUser(propertyId, associatedUser) {
      let ajax = this.ajaxService;
      let siteType = this.modelName;
      return ajax.post(`/${siteType}/${propertyId}/removeUser`, {
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify(associatedUser)
      });
    }

    pendingInvitations(propertyId) {
      let ajax = this.ajaxService;
      let siteType = this.modelName;
      return ajax.request(`/${siteType}/${propertyId}/pendinginvitations/`, {
        contentType: 'application/json',
        dataType: 'json'
      });
    }

    clearPendingInvitation(siteId, actionId) {
      let ajax = this.ajaxService;
      let siteType = this.modelName;
      return ajax.request(`/${siteType}/${siteId}/clearpendinginvitation/${actionId}`, {
        contentType: 'application/json',
        dataType: 'json'
      });
    }

  }) || _class;

  _exports.default = Site;
});