define("mldp/components/payment-history-table/row/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RLZNGrEr",
    "block": "[[[10,\"tr\"],[15,0,[52,[28,[37,1],[[28,[37,2],[[30,1],2],null],0],null],\"bg-white\",\"bg-shadow-100\"]],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"px-6 py-4 whitespace-nowrap text-center font-medium\"],[12],[1,\"\\n    \"],[1,[28,[35,3],[[30,2,[\"transactionDate\"]]],[[\"noTime\",\"timezone\"],[true,true]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"td\"],[14,0,\"px-6 py-4 whitespace-nowrap text-center font-medium\"],[12],[1,\"\\n    \"],[1,[30,0,[\"totalAmount\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"td\"],[14,0,\"px-6 py-4 whitespace-nowrap text-center font-medium\"],[12],[1,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[15,\"onclick\",[28,[37,4],[[30,0,[\"openReceipt\"]]],null]],[15,\"disabled\",[30,0,[\"openReceipt\",\"isRunning\"]]],[14,4,\"button\"],[12],[1,\"\\n      View Receipt\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@index\",\"@fee\"],false,[\"if\",\"eq\",\"mod\",\"format-date-time\",\"perform\"]]",
    "moduleName": "mldp/components/payment-history-table/row/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});