define("mldp/components/site-property-create/show-mu-page-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eP7KAWC8",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Do any of the following apply:\\n  \"],[13],[1,\"\\n  \"],[10,\"ol\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      This property has two or more high-fenced pastures.\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      This property has both high-fenced acreage and low fenced acreage.\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      This property has non-contiguous sections that have been mapped.\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[14,0,\"tooltip-ultra-wide\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@side\",\"@text\"],[\"top\",[30,0,[\"toolTipText\"]]]],[[\"default\"],[[[[1,\"I want to create multiple management units for this property.\"]],[]]]]],[1,\"  \\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"radio-inline\"],[12],[1,\"\\n        \"],[10,\"input\"],[14,0,\"radio-inline small-form-control\"],[15,\"checked\",[30,2]],[14,2,\"yes\"],[15,\"onclick\",[28,[37,1],[[30,0],[30,0,[\"toggleShowMuPage\"]],true],null]],[14,4,\"radio\"],[12],[13],[1,\"Yes\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"radio-inline\"],[12],[1,\"\\n        \"],[10,\"input\"],[14,0,\"radio-inline small-form-control\"],[15,\"checked\",[28,[37,2],[[30,2]],null]],[14,2,\"no\"],[15,\"onclick\",[28,[37,1],[[30,0],[30,0,[\"toggleShowMuPage\"]],false],null]],[14,4,\"radio\"],[12],[13],[1,\"No\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@showMuPage\"],false,[\"info-tooltip\",\"action\",\"not\"]]",
    "moduleName": "mldp/components/site-property-create/show-mu-page-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});