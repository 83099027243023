define("mldp/components/recommendations/decline-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eNMT7hYC",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,\"h5\"],[12],[1,[30,0,[\"site\",\"siteName\"]]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"alert alert-warning\"],[12],[1,\"\\n    Warning - are you sure you want to decline this recommendations? This action cannot be undone.\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "mldp/components/recommendations/decline-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});