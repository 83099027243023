define("mldp/components/deer-population/pick-report-season/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let PickReportSeasonModal = classic(_class = class PickReportSeasonModal extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "state", void 0);
    }

    init() {
      super.init(...arguments);
    }

  }) || _class;

  _exports.default = PickReportSeasonModal;
});