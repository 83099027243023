define("mldp/forgot-password/verify/route", ["exports", "@ember/service", "@ember/routing/route", "rsvp", "@ember/application", "ember-simple-auth/mixins/unauthenticated-route-mixin", "mldp/models/complete-forgot-password"], function (_exports, _service, _route, _rsvp, _application, _unauthenticatedRouteMixin, _completeForgotPassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_unauthenticatedRouteMixin.default, {
    notifications: (0, _service.inject)('notifications'),

    model(p) {
      let model = _completeForgotPassword.default.create((0, _application.getOwner)(this).ownerInjection());

      model.set('code', p.confirmationCode);
      return model;
    },

    setupController(controller, model) {
      controller.set('model', model);
    },

    actions: {
      submit(data) {
        const notifications = this.notifications;
        const adapter = this.store.adapterFor('user');
        const message = 'Your password has been reset. You can now log in.';
        return data.validate().then(() => {
          if (data.get('validations.isValid')) {
            return adapter.completeChangePassword(data);
          }

          return _rsvp.default.reject('Validations invalid');
        }).then(() => this.transitionTo('login')).then(() => notifications.add(message, {
          type: 'info'
        })).catch(e => {
          if (e.message && e.message === 'Resource was not found.') {
            let err = notifications.error('Reset code is incorrect or has already been used.');
            return _rsvp.default.reject(err);
          }

          if (e === 'Validations invalid') {
            return _rsvp.default.reject(notifications.addValidationErrors(data));
          } else {
            return _rsvp.default.reject(notifications.addApiErrors(e));
          }
        });
      }

    }
  });

  _exports.default = _default;
});