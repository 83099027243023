define("mldp/components/search/consultation-form/component", ["exports", "rsvp", "@ember/object", "@ember/service", "@ember/component", "ember-concurrency"], function (_exports, _rsvp, _object, _service, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    state: (0, _service.inject)('state'),
    store: (0, _service.inject)('store'),

    init() {
      this._super(...arguments);

      this.show = false;
      this.types = this.get('state.consultationTypes').map(item => {
        return {
          label: item.get('displayValue'),
          value: item.get('displayValue')
        };
      });
      this.types.unshift(_object.default.create({
        label: '-- Filter by Type --',
        value: ''
      }));
      this.counties = this.get('state.counties').map(item => {
        return {
          label: item.get('displayValue'),
          value: item.get('displayValue')
        };
      });
      this.counties.unshift(_object.default.create({
        label: '-- Filter by County --',
        value: ''
      }));
      this.biologists = this.get('state.biologists').map(item => {
        return {
          label: `${item.get('firstName')} ${item.get('lastName')} (${item.get('email')})`,
          value: item.get('id')
        };
      });
      this.biologists.unshift(_object.default.create({
        label: '-- Filter By Biologist --',
        value: ''
      }));
    },

    searchUsers: (0, _emberConcurrency.task)(function* () {
      let store = this.store;
      let users = yield store.query('user', {
        'filter[otherstaff]': true
      });
      return users.sortBy('lastName');
    }).on('init'),
    actions: {
      updateForm(e) {
        this.updateForm(e);
        return (0, _rsvp.resolve)(true);
      },

      updateProperty(object, property, value) {
        if (property === 'type') {
          let type = value;
          let out = {
            page: 1,
            type
          };
          object.setProperties(out);
        } else {
          object.set(property, value);
        }
      },

      async searchSites(term) {
        let store = this.store;
        let users = await store.query('site', {
          'filter[siteName]': term
        });
        this.set('currentUsers', users);
        return users;
      },

      setSite(site) {
        this.set('site', site);
        this.set('data.site', site.id);
      },

      setOtherStaff(staff) {
        this.set('otherStaff', staff);
        this.set('data.biologistIdOther', staff.id);
      }

    }
  });

  _exports.default = _default;
});