define("mldp/components/report-details-container/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mLQfQkiV",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\\n  \"],[10,0],[14,0,\"panel panel-default\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"panel-heading clearfix\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-xs-8\"],[12],[1,\"\\n        \"],[46,[30,0,[\"filterComponent\"]],null,[[\"query\"],[[30,0,[\"query\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"col-xs-4\"],[12],[1,\"\\n\"],[41,[30,0,[\"report\"]],[[[1,\"          \"],[10,0],[14,0,\"pull-right\"],[12],[1,\"\\n            \"],[10,\"label\"],[14,0,\"block\"],[12],[1,\"Download\"],[13],[1,\"\\n            \"],[10,0],[14,0,\"btn-group\"],[14,\"role\",\"group\"],[14,\"aria-label\",\"export excel\"],[12],[1,\"\\n              \"],[10,3],[15,\"onclick\",[28,[37,3],[[30,0],\"getReport\",\"excel\"],null]],[14,\"role\",\"button\"],[14,0,\"btn btn-default\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[1,\"\\n                Excel\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"panel-body\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        Select the options above and click \\\"Excel\\\" to download the document\\n        for the selected options.\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"component\",\"if\",\"action\"]]",
    "moduleName": "mldp/components/report-details-container/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});