define("mldp/services/app-values", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let AppValuesService = // TODO: this doesn't need to be a service, a plain utility would have been fine..
  classic(_class = class AppValuesService extends _service.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "biologistRole", 'Biologist');

      _defineProperty(this, "supervisorRole", 'Supervisor');

      _defineProperty(this, "lawEnforcementRole", 'Law Enforcement');

      _defineProperty(this, "wildlifePermitOfficeRole", 'Wildlife Permit Office');

      _defineProperty(this, "adminRole", 'Admin');

      _defineProperty(this, "ownerUserType", 'Owner');

      _defineProperty(this, "agentUserType", 'Agent');

      _defineProperty(this, "biologistUserType", 'Biologist');

      _defineProperty(this, "poolManagerUserType", 'PoolManager');
    }

    init() {
      super.init(...arguments);
      this.tpwdRoleList = [this.biologistRole, this.supervisorRole, this.lawEnforcementRole, this.wildlifePermitOfficeRole, this.adminRole];
    }

  }) || _class;

  _exports.default = AppValuesService;
});