define("mldp/serializers/user", ["exports", "mldp/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let User = classic(_class = class User extends _application.default {
    modelNameFromPayloadKey() {
      let processed = super.modelNameFromPayloadKey(...arguments);

      switch (processed) {
        case 'roles':
          {
            return 'userXRoles';
          }

        case 'counties':
          {
            return 'userXCounties';
          }
      }

      return processed;
    } // skip relationships or EF tries to create new ones, but ED doesn't pass the attributes yet
    // just type and id


    serializeHasMany() {
      return;
    }

  }) || _class;

  _exports.default = User;
});