define("mldp/account/consultations/create/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _object, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CreateRoute = (_dec = (0, _service.inject)('session'), _dec2 = (0, _service.inject)('state'), _dec3 = (0, _service.inject)('notifications'), classic(_class = (_class2 = class CreateRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionService", _descriptor, this);

      _initializerDefineProperty(this, "stateService", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);
    }

    async model() {
      let isAdmin = this.get('sessionService.isAdmin');
      let isSupervisor = this.get('sessionService.isSupervisor');
      let biologists = this.get('stateService.biologists');
      let customBiologists = isAdmin || isSupervisor;
      let query = {};
      let supervisor;

      if (isAdmin) {
        // otherstaff is admin, supervisors, and biologists
        query['filter[otherstaff]'] = true;
      } else if (isSupervisor) {
        let supervisorId = this.get('sessionService.userId');
        supervisor = await this.store.findRecord('user', supervisorId);
        query['filter[supervisorid]'] = supervisorId;
      }

      return (0, _rsvp.hash)({
        consultation: this.store.createRecord('consultation'),
        biologists: customBiologists ? this.store.query('user', query) : biologists,
        supervisor
      });
    }

    setupController(controller, resolved) {
      let types = this.get('stateService.consultationTypes');
      let counties = this.get('stateService.counties');
      resolved.biologists = resolved.biologists.toArray(); // Add the supervisor to the biologists list

      if (resolved.supervisor) {
        resolved.biologists.pushObject(resolved.supervisor);
      }

      resolved.types = types;
      resolved.counties = counties;
      resolved.biologists = resolved.biologists.sortBy('lastName');
      controller.setProperties(resolved);
    }

    async create(otherStaff, consultation) {
      let notifications = this.notifications;

      try {
        var _intersections;

        let intersections;

        if (otherStaff !== null && otherStaff !== void 0 && otherStaff.length) {
          intersections = otherStaff.map(staff => {
            return this.store.createRecord('user-xconsultation', {
              userId: staff.id
            });
          });
        }

        await consultation.save();

        if ((_intersections = intersections) !== null && _intersections !== void 0 && _intersections.length) {
          for (let item of intersections) {
            item.set('consultationId', consultation.id);
            await item.save();
          }
        }

        await consultation.reload();
        await this.transitionTo('account.consultations');
        notifications.add('Created a new consultation');
      } catch (err) {
        notifications.addApiErrors(err);
      }
    }

    cancel() {
      if (window.confirm('Are you sure?')) {
        this.transitionTo('account.consultations');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "sessionService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "stateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "create", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "create"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "cancel"), _class2.prototype)), _class2)) || _class);
  _exports.default = CreateRoute;
});