define("mldp/account/property/agreement/route", ["exports", "@ember/object", "@ember/service", "@ember/routing/route"], function (_exports, _object, _service, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let AgreementRoute = (_dec = (0, _service.inject)('ajax'), _dec2 = (0, _service.inject)('notifications'), classic(_class = (_class2 = class AgreementRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);
    }

    model() {
      let model = this.modelFor('account.property');
      model.property.set('newProperty', false);
      return model;
    }

    setupController(controller, _ref) {
      let {
        property: site
      } = _ref;
      let submitLabel = 'I Agree';
      let submitPending = 'Saving..';
      controller.setProperties({
        submitLabel,
        submitPending,
        site
      });
    }

    onSave() {
      let type = this.get('controller.site.siteType');
      let id = this.get('controller.site.id');
      let site = this.get('controller.site');
      let notifications = this.notifications;
      let adapter = this.store.adapterFor(type);
      return adapter.acceptAgreement(id).then(() => site.reload()).then(() => this.transitionTo('account.property', type, id)).then(() => notifications.add('You have accepted the agreement')).catch(error => {
        if (error.jqXHR.status === 403 || error.jqXHR.status === 404 || error.jqXHR.status === 409) {
          return this.transitionTo('account.properties.index').then(() => notifications.error(error.payload.errors[0].message));
        } else {
          console.error('accept agreement error', {
            error
          });
          notifications.error('An error has occurred');
        }
      });
    }

    onDisagree() {
      let type = this.get('controller.site.siteType');
      let id = this.get('controller.site.id');
      return this.transitionTo('account.property', type, id);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "onSave", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onSave"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onDisagree", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onDisagree"), _class2.prototype)), _class2)) || _class);
  _exports.default = AgreementRoute;
});