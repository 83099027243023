define("mldp/account/users/merge/controller", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let MergeController = classic(_class = class MergeController extends _controller.default {}) || _class;

  _exports.default = MergeController;
});