define("mldp/components/reports/my-customers/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "c+SpiX78",
    "block": "[[[11,0],[24,0,\"row-fluid clearfix no-gutter\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-xs-6\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"block\"],[12],[1,\"\\n      Biologist (optional)\\n      \"],[8,[39,0],null,[[\"@options\",\"@selected\",\"@placeholder\",\"@searchEnabled\",\"@allowClear\",\"@disabled\",\"@matcher\",\"@triggerClass\",\"@dropdownClass\",\"@onChange\"],[[30,0,[\"biologists\"]],[30,0,[\"biologist\"]],\"All Biologists\",true,true,[30,0,[\"sessionService\",\"isBiologistWithoutAdminOrSuper\"]],[28,[37,1],[\"matchBiologist\"],null],\"form-control\",\"biologist-select-dropdown\",[28,[37,2],[[30,0],\"updateBiologist\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"firstName\"]]],[1,\" \"],[1,[30,2,[\"lastName\"]]],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"biologist\"],false,[\"power-select\",\"route-action\",\"action\"]]",
    "moduleName": "mldp/components/reports/my-customers/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});