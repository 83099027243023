define("mldp/components/search/permit/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TmnNVLsg",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[6,[39,0],[[30,2]],[[\"submit\",\"update\"],[[28,[37,1],[[30,0],[30,3]],null],[28,[37,1],[[30,0],\"updateProperty\"],null]]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"container-fluid\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-xs-2 permit-tag-search-prefix\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[[28,[37,3],[[30,0,[\"permitType\"]]],null],\" -\"],null]],[1,\"\\n        \"],[13],[1,\" \\n        \"],[10,0],[14,0,\"col-xs-10 permit-tag-search-input\"],[12],[1,\"\\n          \"],[1,[28,[30,4,[\"text-field\"]],[\"tagId\"],[[\"placeholder\",\"pattern\",\"maxlength\",\"label\",\"optionTargetPath\",\"optionLabelPath\"],[\"Enter a permit number\",\"[0-9]\",7,\"Permit Id\",\"value\",\"key\"]]]],[1,\"\\n\"],[41,[30,0,[\"invalidMessage\"]],[[[1,\"            \"],[10,1],[14,0,\"text-warning\"],[12],[1,[30,0,[\"invalidMessage\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"  \\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n\"],[41,[30,0,[\"tagUrl\"]],[[[1,\"          \"],[10,\"iframe\"],[15,\"src\",[30,0,[\"tagUrl\"]]],[14,0,\"tag-search-iframe\"],[14,\"scrolling\",\"no\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[4]]]]],[13]],[\"&attrs\",\"@data\",\"@onsubmit\",\"f\"],false,[\"form-for\",\"action\",\"concat\",\"uppercase\",\"if\"]]",
    "moduleName": "mldp/components/search/permit/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});