define("mldp/account/property/unit/harvestcalculator/route", ["exports", "@ember/routing/route", "@ember/service", "rsvp"], function (_exports, _route, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  const blankSeason = {
    harvest: {
      antlerlessCount: 0,
      count: 0,
      totalBuckCount: 0,
      unbranchedBuckCount: 0
    },
    recommendation: {
      anyBuckCount: 0,
      count: 0,
      doeCount: 0,
      unbranchedBuckCount: 0
    },
    surveyCount: 0
  };
  let AccountPropertyUnitHarvestcalculatorRoute = (_dec = (0, _service.inject)('state'), classic(_class = (_class2 = class AccountPropertyUnitHarvestcalculatorRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "state", _descriptor, this);
    }

    afterModel() {
      this.set('breadCrumb', {
        title: 'Harvest Calculator'
      });
    }

    async model() {
      let unit = this.modelFor('account.property.unit');
      let {
        property
      } = this.modelFor('account.property');
      let {
        seasonId,
        speciesId
      } = this.paramsFor('account.property.unit.harvestcalculator');
      let {
        type: parentSiteType,
        siteId: parentSiteId
      } = this.paramsFor('account.property');
      const pastSeasonId = (parseInt(seasonId) - 1).toString();
      let siteAdapter = this.store.adapterFor('site');
      let currentSeasonStats = siteAdapter.getSiteStatistics(unit.id, {
        seasonId,
        speciesId
      });
      let pastSeasonStats = siteAdapter.getSiteStatistics(unit.id, {
        seasonId: pastSeasonId,
        speciesId
      });
      let speciesList = this.state.species;
      const species = speciesList.find(cur => cur.id === speciesId);
      const season = this.state.seasons.find(cur => cur.id === seasonId);
      const pastSeason = this.state.seasons.find(cur => cur.id === pastSeasonId);
      let populations = this.store.query('deer-population', {
        'filter[siteId]': unit.id,
        'filter[seasonId]': seasonId
      });
      let enrollments = property.findAllEnrollments();
      return _rsvp.default.hash({
        unit,
        enrollments,
        populations,
        currentSeasonStats,
        pastSeasonStats,
        season,
        pastSeason,
        species,
        seasonId,
        speciesId,
        parentSiteType,
        parentSiteId
      });
    }

    async setupController(controller, resolved) {
      resolved.pastSeasonStats = resolved.pastSeasonStats[0];
      resolved.currentSeasonStats = resolved.currentSeasonStats[0];
      if (!resolved.pastSeasonStats) resolved.pastSeasonStats = blankSeason;
      if (!resolved.currentSeasonStats) resolved.currentSeasonStats = blankSeason;
      resolved.pastSeasonRecTotal = resolved.pastSeasonStats.recommendation.count;
      resolved.currentSeasonRecs = resolved.currentSeasonStats.recommendation.count;
      resolved.pastSeasonHarvestTotal = resolved.pastSeasonStats.harvest.count;
      let enrollments = resolved.enrollments.toArray();
      resolved.currentEnrollment = enrollments.find(cur => parseInt(resolved.seasonId) === cur.deerSeason.id);
      let populations = resolved.populations.toArray();
      resolved.currentPopulation = populations.find(cur => cur.speciesId === parseInt(resolved.speciesId));

      if (resolved.currentPopulation) {
        let {
          bucks,
          does,
          fawns,
          acresPerDeer
        } = resolved.currentPopulation;
        resolved.currentPopulation.setProperties({
          buckFawns: Math.ceil(fawns / 2),
          doeFawns: Math.floor(fawns / 2),
          totalAdults: bucks + does,
          totalBucks: bucks + Math.ceil(fawns / 2),
          totalDoes: does + Math.floor(fawns / 2),
          totalDeer: bucks + does + fawns,
          deerPerOneK: Math.round(1000 / acresPerDeer)
        });
        const adultBucks = resolved.currentSeasonStats.recommendation.anyBuckCount;
        const adultDoes = resolved.currentSeasonStats.recommendation.doeCount;
        controller.adultBucksPcnt = Math.round(bucks ? adultBucks / bucks * 100 : 0);
        controller.adultDoesPcnt = Math.round(does ? adultDoes / does * 100 : 0);
        controller.fawnBucksPcnt = 0;
        controller.fawnDoesPcnt = 0;
        controller.setProperties(resolved);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = AccountPropertyUnitHarvestcalculatorRoute;
});